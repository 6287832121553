import { UserFeatures } from "auth/UserSlice"
import { LabelledCheckBox } from 'app/components/LabelledCheckBox'
import { Theme } from 'app/model/Theme'
import { Button } from "app/components/Button"

export type UserFeaturesComponentProps = {
  currentUserId: string
  features: UserFeatures
  theme: Theme
  updateUserFeature: (userId: string, featureId: string, status: string) => Promise<void>
  updateUserFeatures: (userId: string, features: any) => Promise<void>
  toggleShowUiDebug: () => Promise<void>
}

export const UserFeaturesComponent = ({
  features, currentUserId, theme,
  updateUserFeature, toggleShowUiDebug, updateUserFeatures
}: UserFeaturesComponentProps) => {
  return (
    <div>
      <h2>User features</h2>
      <div style={{width: '300px'}}>    
        <ul style={{border: '1px solid grey', borderRadius: '10px', padding: '10px'}}>
        <FeatureDisplay 
            featureId='always-show-cases' 
            displayName='Always show cases'
            checked={features.alwaysShowCases} 
            onClick={() => updateUserFeature(currentUserId, 'always-show-cases', features.alwaysShowCases ? 'disabled' : 'enabled')} 
          />
          <FeatureDisplay 
            featureId='view-datasets' 
            displayName='Always view datasets'
            checked={features.alwaysViewDatasets} 
            onClick={() => updateUserFeature(currentUserId, 'view-datasets', features.alwaysViewDatasets ? 'disabled' : 'enabled')} 
          />
          <div style={{margin: '3px'}}>
            <Button text={"Add all basic features"} theme={theme} dataCy="add-all-basic-features" onClick={() => {
              updateUserFeatures(currentUserId, {
                'always-show-cases': 'enabled',
                'view-datasets': 'enabled'
              })
            }} />
          </div>
        </ul>
        <ul style={{border: '1px solid grey', borderRadius: '10px', padding: '10px'}}>
          <SyncFeatureDisplay 
            featureId='show-ui-debug-toggles' 
            displayName='Show UI debug toggles'
            checked={features.showUiDebugToggles} 
            onClick={toggleShowUiDebug} 
          />
          <FeatureDisplay 
            featureId='import-annotations' 
            displayName='Import Annotations'
            checked={features.importAnnotations} 
            onClick={() => updateUserFeature(currentUserId, 'import-annotations', features.importAnnotations ? 'disabled' : 'enabled')} 
          />
          <FeatureDisplay 
            featureId='request-model-run' 
            displayName='Request model run'
            checked={features.requestModelRun} 
            onClick={() => updateUserFeature(currentUserId, 'request-model-run', features.requestModelRun ? 'disabled' : 'enabled')} 
          />
          <FeatureDisplay 
            featureId='remove-self-permission' 
            displayName='Remove self permission'
            checked={features.removeSelfPermission} 
            onClick={() => updateUserFeature(currentUserId, 'remove-self-permission', features.removeSelfPermission ? 'disabled' : 'enabled')} 
          />
          <FeatureDisplay 
            featureId='edit-features' 
            displayName='Edit Features'
            checked={features.editFeatures} 
            onClick={() => updateUserFeature(currentUserId, 'edit-features', features.editFeatures ? 'disabled' : 'enabled')} 
          />
          <div style={{margin: '3px'}}>
            <Button text={"Add all admin features"} theme={theme} dataCy="add-all-admin-features" onClick={() => {
              updateUserFeatures(currentUserId, {
                'import-annotations': 'enabled',
                'request-model-run': 'enabled',
                'remove-self-permission': 'enabled'
              })
            }} />
          </div>
        </ul>        
        <ul style={{border: '1px solid grey', borderRadius: '10px', padding: '10px'}}>
          <FeatureDisplay 
            featureId='injury-analytics' 
            displayName='Injury Analytics'
            checked={features.injuryAnalytics} 
            onClick={() => updateUserFeature(currentUserId, 'injury-analytics', features.injuryAnalytics ? 'disabled' : 'enabled')} 
          />
          <div style={{margin: '3px'}}>
            <Button text={"Add all sports management features"} theme={theme} dataCy="add-all-sports-features" onClick={() => {
              updateUserFeature(currentUserId, 'injury-analytics', 'enabled')
            }} />
          </div>
        </ul>        
        <ul style={{border: '1px solid grey', borderRadius: '10px', padding: '10px'}}>
          <FeatureDisplay 
            featureId='create-annotations' 
            displayName='Create Annotations'
            checked={features.createAnnotations} 
            onClick={() => updateUserFeature(currentUserId, 'create-annotations', features.createAnnotations ? 'disabled' : 'enabled')} 
          />
          <FeatureDisplay 
            featureId='configure-datasets' 
            displayName='Configure datasets'
            checked={features.configureDatasets} 
            onClick={() => updateUserFeature(currentUserId, 'configure-datasets', features.configureDatasets ? 'disabled' : 'enabled')} 
          />
          <div style={{margin: '3px'}}>
            <Button text={"Add all radiology features"} theme={theme} dataCy="add-all-radiology-features" onClick={() => {
              updateUserFeatures(currentUserId, {
                'create-annotations': 'enabled',
                'configure-datasets': 'enabled'
              })
            }} />
          </div>
        </ul>   
      </div>
      <div style={{display: 'flex'}}>      
        <Button text={"Clear all"} theme={theme} dataCy="add-all-features" onClick={() => {
          updateUserFeatures(currentUserId, {
            'import-annotations': 'disabled',
            'request-model-run': 'disabled',
            'remove-self-permission': 'disabled',
            'create-annotations': 'disabled',
            'configure-datasets': 'disabled'
          })
        }} />
      </div>      
    </div>
  )
}

type FeatureDisplayProps = {
  featureId: string
  displayName: string
  checked: boolean
  onClick: () => Promise<void>
}

const FeatureDisplay = ({featureId, displayName, checked, onClick}: FeatureDisplayProps) => {

  return (
    <li style={{
      border: featureId === 'edit-features' ? '1px solid red' : '',
      paddingLeft: '10px',
      borderRadius: '10px',
    }}>
      <LabelledCheckBox 
        id={`toggle_feature_${featureId}`} 
        label={displayName} 
        checked={checked} 
        onChange={onClick} 
      />
    </li>
  )
}

type SyncFeatureDisplayProps = {
  featureId: string
  displayName: string
  checked: boolean
  onClick: () => Promise<void>
}

const SyncFeatureDisplay = ({featureId, displayName, checked, onClick}: SyncFeatureDisplayProps) => {
   
  return (
    <li style={{
      paddingLeft: '10px',
      borderRadius: '10px',
    }}>
      <LabelledCheckBox 
        id={`toggle_feature_${featureId}`} 
        label={displayName} 
        checked={checked} 
        onChange={onClick} 
      />
    </li>
  )
}
