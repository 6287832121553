import { Link } from 'react-router-dom'
import { ScanListingEntry } from 'uploads/api/Model'
import _ from 'lodash'
import { ReportsApi } from 'reports/api/ReportsApi'
import { connect } from 'react-redux'
import { downloadReportOutput } from 'reports/api/ReportsSlice'
import { RootState } from 'app/store'
import { Dataset, DatasetAggregateCaseInfo } from 'uploads/model/Dataset'
import { AnnotationMode } from 'uploads/model/AnnotationMode'

export type PatientScansTableProps = {
  dataset: Dataset
  scans: ScanListingEntry[]
  linkTo: (caseId: string) => string
  downloadReportOutput: (reportId: string, outputId: string) => void
}

export const PatientScansTable = ({
  dataset, scans, 
  linkTo, downloadReportOutput
}: PatientScansTableProps) => {

  return(
    <div className="scans-list-component">
      <table className="table table-bordered table-striped" style={{maxWidth: '500px'}}>
        <thead>
          <tr>
            <th style={{ width: '100px' }} scope="col">Scan date</th>
            <th style={{ width: '100px' }} scope="col">Report</th>
            <th style={{ width: '130px' }} scope="col">{dataset.datasetInfo.annotationMode === AnnotationMode.ACL ? 'ACL Impression' : 'BAMIC Grade' }</th>                       
          </tr>
        </thead>
        <tbody>
          { _.sortBy(scans, ['name']).map((scan: ScanListingEntry) => 
            <ScanRow 
              key={scan.scanId}               
              scan={scan} 
              linkTo={linkTo} 
              downloadReportOutput={downloadReportOutput} 
              datasetCaseInfo={dataset.aggregateInfo.caseInfos.find(c => c.caseId === scan.scanId)}
            />
          )}
        </tbody>
      </table>    
    </div>
  )
}

type ScanRowProps = {
  scan: ScanListingEntry
  datasetCaseInfo?: DatasetAggregateCaseInfo
  linkTo: (caseId: string) => string
  downloadReportOutput: (reportId: string, outputId: string) => void
}

const ScanRow = ({
  scan, datasetCaseInfo,
  linkTo, downloadReportOutput
}: ScanRowProps) => {

  const reportsApi = new ReportsApi(window.RuntimeConfig.backend)

  const report = _.orderBy(scan.reportOutputs, o => o.createdDate, 'desc')[0]

  const onDownloadClick = (e: { preventDefault: () => void }) => {
    e.preventDefault()
    if(report !== undefined) {
      downloadReportOutput(report.reportId, report.outputId)
    }    
  }

  const classifications = datasetCaseInfo?.classifications.map((classification) => {
    if(classification === 'intact'){
      return 'Intact'
    } else if(classification === 'torn') {
      return 'Torn'
    } else {
      return classification
    }
  })

  return (
    <tr>
      <td><Link to={linkTo(scan.scanId)}  >{scan.studyDate}</Link></td>  
      { report !== undefined && 
        <td><a href={reportsApi.reportOutputUrl(report.reportId, report.outputId)} onClick={onDownloadClick} >Available</a></td>              
      }
      { report === undefined &&
        <td>Pending</td>              
      } 
      <td>{classifications?.join(', ')}</td>    
    </tr>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    
  }
}

const mapDispatchToProps = { 
  downloadReportOutput
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PatientScansTable)
