import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom'

import Header from 'app/Header'
import ScansPage from 'cases/ScansPage'
import NotificationBar from 'app/components/NotificationBar'
import DatasetsPage from 'datasets/DatasetsPage'
import AnnotationPage from 'annotation/AnnotationPage'
import AnnotationsPage from 'annotation/AnnotationsPage'
import DatasetPage from 'datasets/DatasetPage'
import { clearRemoteNavigte, listUsers, loadProfile, remoteNavigte, setCookie } from 'auth/UserSlice'
import { RootState } from './store'
import { nanoid } from 'nanoid'
import InjuryAnalyticsPage from 'reports/pages/InjuryAnalyticsPage'
import DashboardPage from 'app/DashboardPage'
import CaseDetailsPage from 'cases/CaseDetailsPage'
import ConfigEditor from './components/ConfigEditor'
import ImportAnnotationsPage from 'uploads/pages/ImportAnnotationsPage'
import UserProfilePage from 'auth/UserProfilePage'
import { LoadingSpinner } from './components/LoadingSpinner'
import DatasetPatientPage from 'datasets/DatasetPatientPage'
import HomePage from './HomePage'
import ChatBox from './components/ChatBox'
import { RemoteNavigateLoation } from 'auth/Rtc'

type LoggedInAppProps = {
  currentUserId?: string
  showExperimentalUi: boolean
  remoteNavigate?: RemoteNavigateLoation
  loadProfile: () => void
  listUsers: () => void
  setCookie: () => Promise<void>
  clearRemoteNavigate: () => void
}

export const LoggedInApp = ({
  currentUserId, showExperimentalUi, remoteNavigate,
  loadProfile, listUsers, setCookie, clearRemoteNavigate
}: LoggedInAppProps) => {

  const [cookieSet, setCookieSet] = useState<boolean>(false)
  
  const navigate = useNavigate()

  useEffect(() => {listUsers()}, [listUsers])
  useEffect(() => {loadProfile()}, [loadProfile])
  useEffect(() => {setCookie().then(() => setCookieSet(true))}, [setCookie])
  useEffect(() => {
    if(remoteNavigate !== undefined){
      if(document.location.pathname !== remoteNavigate.pathname || document.location.search !== remoteNavigate.search) {
        navigate(`${remoteNavigate.pathname}/${remoteNavigate.search}`)
      }
      clearRemoteNavigate()
    }
  }, [remoteNavigate])

  if(currentUserId === undefined || !cookieSet) {
    return <LoadingSpinner />
  }

  return (
    <div style={{height: '100%'}}>
      <Header />

      <Routes>
        <Route path="home" element={<HomePage currentUserId={currentUserId} />} />            
        <Route path="dashboard" element={<DashboardPage currentUserId={currentUserId} />} />
        <Route path="injury-analytics" element={<InjuryAnalyticsPage currentUserId={currentUserId} />} />
        <Route path="scans" element={<ScansPage currentUserId={currentUserId} />} />
        <Route path="cases/:caseId" element={<CaseDetailsPage currentUserId={currentUserId} />} />
        <Route path="cases/:scanId/view" element={<AnnotationPage currentUserId={currentUserId} annotateMode={false}/>} />             
        <Route path="annotations" element={<AnnotationsPage currentUserId={currentUserId}/>} />
        <Route path="annotations/import" element={<ImportAnnotationsPage currentUserId={currentUserId}/>} />
        <Route path="annotations/:scanId" element={<AnnotationPage currentUserId={currentUserId} annotateMode={true}/>} />
        <Route path="datasets" element={<DatasetsPage currentUserId={currentUserId}/>} />        
        <Route path="datasets/:datasetId" element={<DatasetPage currentUserId={currentUserId}/>} />        
        <Route path="datasets/:datasetId/patient/:patientId" element={<DatasetPatientPage currentUserId={currentUserId}/>} />
        <Route path="profile" element={<UserProfilePage currentUserId={currentUserId} />} />
        <Route path="config-editor" element={<ConfigEditor />} />
        <Route path="*" element={<Navigate to="/home" />} />            
      </Routes>       
      <NotificationBar />

      { showExperimentalUi &&
      <ChatBox currentUserId={currentUserId}  />
      }
    </div>
  )
}

const mapStateToProps = (state: RootState) => ({
  currentUserId: state.user.currentUserId,
  showExperimentalUi: state.user.features.showExperimentalUi,
  remoteNavigate: state.user.remoteNavigate
})


const mapDispatchToProps = { 
  listUsers: () => listUsers(nanoid()), 
  loadProfile: () => loadProfile(),
  setCookie: () => setCookie(nanoid()),
  clearRemoteNavigate: clearRemoteNavigte
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LoggedInApp)
