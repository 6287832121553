import { createSlice } from '@reduxjs/toolkit'
import { ServiceApi } from 'app/api/ErrorApi'
import { RootState } from 'app/store'


const initialState = window.RuntimeConfig
  
const configSlice = createSlice({
  name: 'config',
  initialState,
  reducers: {
    setState(state, { payload }) {
      state = payload.config
    }
  }
})

export default configSlice.reducer

const serviceApi = new ServiceApi(window.RuntimeConfig.backend)

export const selectConfig = (state: RootState) => state.config

export const updateLogLevel = (level: string, correlationId: string) => async dispatch => {  
  try {  
    await serviceApi.updateLogLevel(level, correlationId)
  } catch (err) {
    console.log({reportErrorFailure: err})
  }
}
