
export type SliderProps = {
  label?: string
  className?: string
  value?: number
  min: number
  max: number
  title?: string
  width?: string
  height?: string
  onChange: (number) => void 
}

export const Slider = ({
  label, className, value, min, max, width, height, title,
  onChange
}: SliderProps) => {
  
  return (    
    <div className={`slidecontainer`}>
      <label style={{display: 'block'}}>
        <input 
          className={className}
          type="range"
          min={`${min}`} 
          max={`${max}`} 
          value={value}
          title={title || value?.toString() || ''}
          onInput={(e) => { 
            const target: any = e.target
            if(target.value !== undefined) {
              onChange(target.value) 
            }          
          }}
          style={{
            width: width || '400px',
            height: height || '17px',
            marginRight: '10px',
            paddingTop: '1px', 
            verticalAlign: 'middle', 
            position: 'relative', 
            bottom: '1px'
          }}
          />
        {label}          
      </label>
    </div>          
  )
}