import { useState } from "react"
import { DirectoryUploadProgress } from "./DirectoryUploadProgress"

export type DirectoryUploadProps = {
  uploadFile: (formData: FormData) => Promise<void>
  onComplete?: () => void
  buttonSecondary?: boolean
  hidden: boolean
}

const IgnoreFiles: Set<string> = new Set(['.DS_Store'])

const shouldProcessFile: (file: File) => boolean = (file) => 
  !IgnoreFiles.has(file.name) && file.name.indexOf('.') !== 0

export const DirectoryUpload = ({
  buttonSecondary, hidden,
  uploadFile, onComplete
}: DirectoryUploadProps) => {

  const [selectedFiles, setSelectedFiles] = useState<File[] | undefined>(undefined)
  const [uploadInProgress, setUploadInProgress] = useState<boolean>(false)
  const [uploadComplete, setUploadComplete] = useState<boolean>(false)
  const [filesToUploadCount, setFilesToUploadCount] = useState<number>(0)
  const [filesUploadedCount, setFilesUploadedCount] = useState<number>(0) 
  const [isHidden, setIsHidden] = useState<boolean>(hidden)
  
  const changeHandler = (event) => {        
    const files: File[] = Array.from(event.target.files)
    const filtered = files.filter(shouldProcessFile)
    
    setSelectedFiles(filtered)      
    setFilesToUploadCount(filtered.length)    
	}


  const handleSubmission: () => Promise<void> = async () => {
    setUploadInProgress(true)

    for (let file of selectedFiles || []) {
      const formData = new FormData()
      formData.append(file.name, file)

      try {
        await uploadFile(formData)
        setFilesUploadedCount(current => current + 1)
      } catch (err) {
        console.log(err)
      }          
    }
    setUploadInProgress(false)
    setUploadComplete(true)
    if(onComplete !== undefined) {
      onComplete()
    }    
  }
  
  return (
    <div className="file-upload-component" style={{
      border: '1px solid lightgrey',
      borderRadius: '1em',
      margin: '5px',
      position: 'relative',
      height: 'max-content',
      blockSize: 'max-content',
      width: 'fit-content', 
      padding: '5px',
      paddingRight: '20px',
      display: isHidden ? 'none' : 'block'
      }}
    >
      { selectedFiles !== undefined && !uploadInProgress && filesUploadedCount !== filesToUploadCount &&
        <div>{selectedFiles.length} selected for upload</div>
      } 

      { selectedFiles !== undefined && 
        <DirectoryUploadProgress filesUploadedCount={filesUploadedCount} filesToUploadCount={filesToUploadCount} />
      }          

      <div style={ {display: 'flex'} }>
        <div style={{margin: 0, left: '10px', position: 'relative', paddingTop: '10px', paddingBottom: '10px' }} >
          <input type="file" id="fileElem" name="file" onChange={changeHandler} className="visually-hidden" multiple webkitdirectory=""/>
          <label htmlFor="fileElem" className={`btn ${(selectedFiles !== undefined || buttonSecondary === true ) ? "btn-secondary" : "btn-primary"}`} >
            Upload scan data
          </label>
        </div>

        {selectedFiles !== undefined && (        
          <div style={ {marginLeft:'10px', marginTop:'10px'} }>
            <button 
              type="button" 
              className="btn btn-primary upload-files" 
              onClick={handleSubmission} 
              disabled={selectedFiles === undefined || uploadInProgress || uploadComplete} 
              data-cy="begin-directory-upload"
              >Begin upload</button>
          </div>
        )}
      </div>
    </div>
  )
}