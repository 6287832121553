import { ExtractedImage } from "uploads/store/Model"
import { SeriesProps } from "./SeriesProps"

export type ScanData = {
  patientId?: string
  seriesProps: SeriesProps[] 
}

export const getSeriesIds: (images: ExtractedImage[]) => string[] = (images) => {

  const seriesIds: string[] = []

  images.forEach(i => {
    if(i.SeriesInstanceUID !== undefined && !seriesIds.includes(i.SeriesInstanceUID)) {
      seriesIds.push(i.SeriesInstanceUID)
    }
  })

  return seriesIds
}
