import Select from 'react-select'

import { SelectOption } from "utils/SelectOption"
import { LoadingSpinner } from 'app/components/LoadingSpinner'
import { BodyRegion } from 'uploads/model/BodyRegion'


export type BodyRegionSelectorProps = {
  bodyRegions?: BodyRegion[]
  selectedBodyRegionId?: string
  setSelectedBodyRegionId: (bodyRegionId: string | undefined) => void
}

export const BodyRegionSelector = ({bodyRegions, selectedBodyRegionId, setSelectedBodyRegionId}: BodyRegionSelectorProps) => {
  const options: SelectOption<string>[] = (bodyRegions || []).map((bodyRegion) => ({value: bodyRegion.id, label: bodyRegion.display }))
  
  const current = selectedBodyRegionId !== undefined ? { value: selectedBodyRegionId, label: bodyRegions?.find(d => d.id === selectedBodyRegionId)?.display || '' } : undefined

  if(bodyRegions === undefined){
    return <LoadingSpinner />
  } else {
    return (
      <div 
        className="bodyRegion-selector"
        style={{ display: 'flex', marginRight: '10px' }}
      >

        <div style={{ width: '250px' }}>
          <Select 
            classNamePrefix="react-select-bodyRegion-selector"
            options={options} 
            isClearable={true}
            placeholder="Body region" 
            value={current}             
            onChange={(e) => e !== null ? setSelectedBodyRegionId(e.value) : setSelectedBodyRegionId(undefined)}
          /> 
        </div>
        
      </div>
    )
  }
}
 
