import { useState } from "react"

export type LabelledTextInputProps = {
  id: string
  label: string
  dataCy?: string
  value: string
  disabled?: boolean
  onChange: (value: string) => void
}

export const LabelledTextInput = ({
  id, dataCy, value, disabled, label,
  onChange
}: LabelledTextInputProps) => {

  const [editedText, setEditedText] = useState<string | undefined>(undefined)

  const handler = (text: string) => {    
    setEditedText(text)
    onChange(text)
  }

  return (
    <label 
      htmlFor={id} 
      style={{
        display: 'block', 
        // width: '230px', 
        height: '25px', 
        margin: '5px'}}
    >
      {label}:        
      <input 
        id={id} 
        disabled={disabled}
        data-cy={ dataCy }
        value={ editedText || value } 
        onChange={(e) => handler(e.target.value) }
        style={{
          width: '140px',
          marginRight: '5px', 
          verticalAlign: 'middle', 
          position: 'relative', 
          bottom: '1px'}}
      />
    </label>
  )
}