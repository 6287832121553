export type BodyRegion = {
  id: string
  display: string
}

export const Knee = {
  id: 'knee',
  display: 'Knee'
}

export const Hamstring = {
  id: 'hamstring',
  display: 'Hamstring'
}

export const AllBodyRegions: BodyRegion[] = [Knee, Hamstring]