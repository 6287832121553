import { Annotation } from "annotation/model/Annotation"
import { PixelSpacing } from "annotation/model/PixelSpacing"
import { ExtractedImage } from "uploads/store/Model"

export const getAnnotations: (
  displayIndex: number | undefined,
  images: ExtractedImage[] | undefined, 
  annotations: Annotation[] | undefined, 
  displayedUserAnnotations: string[],
  displayModelAnnotations: boolean
) => Annotation[] = 
  (displayIndex, images, annotations, displayedUserAnnotations, displayModelAnnotations) => { 

  if(displayIndex === undefined || annotations === undefined || images === undefined) {
    return []
  } else {
    let image = images[displayIndex]
    if(image === undefined) {
      console.log(`******** images[displayIndex] == undefined. displayIndex: ${displayIndex}, images.length: ${images.length}`)
      image = images[images.length - 1]
    } 
    
    return annotations.filter(a => 
      a.InstanceNumber === image.InstanceNumber && (
        (a.createdBy !== undefined && displayedUserAnnotations.includes(a.createdBy)) ||
        (displayModelAnnotations && a.modelName !== undefined))   
      )
  }
}

export type ImageUrlWithPixelSpacing = {
  imageUrl: string
  pixelSpacing?: PixelSpacing
}


export const classNameForSeries: (seriesId: string) => string = (seriesId) => `image-scroller-${seriesId}`
