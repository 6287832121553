import React from 'react'
import Select from 'react-select'
import { AnnotationShapeType } from 'annotation/model/AnnotationShape'

import { SelectOption } from 'utils/SelectOption'

export type AnnotationShapeSelectorProps = {
  annotationShape?: AnnotationShapeType
  disabled: boolean
  setAnnotationShape: (shape: AnnotationShapeType) => void
}

export const AnnotationShapeSelector = ({
  annotationShape, disabled, 
  setAnnotationShape
}: AnnotationShapeSelectorProps) => {

  const options: SelectOption<string>[] = [
    AnnotationShapeType.Line, 
    AnnotationShapeType.Box, 
    AnnotationShapeType.Circle, 
    AnnotationShapeType.Oval, 
    AnnotationShapeType.Point, 
    AnnotationShapeType.FreeHand, 
    AnnotationShapeType.OpenPolygon, 
    AnnotationShapeType.ClosedPolygon, 
  ].map(label => { return { value: label.toString(), label: label.toString() } })

  const current = annotationShape === undefined ? undefined : { value: annotationShape.toString(), label: annotationShape.toString() }

  return (
    <div style={{ margin: '10px', width: '200px' }}>
      <Select
        classNamePrefix="react-select-annotation-shape-selector"
        options={options} 
        placeholder="Select shape" 
        value={current} 
        isDisabled={disabled}
        onChange={(e) => { if (e !== null) setAnnotationShape(AnnotationShapeType[e.label])} }  
        styles={{ menuPortal: base => ({ ...base, zIndex: 3 }) }}     
        />   
      
    </div>
  )
}