import { createSlice } from '@reduxjs/toolkit'
import { nanoid } from 'nanoid'

import { ListReportsResponse, ReportMetaData, ReportsApi } from './ReportsApi'
import { fetchScan } from 'cases/api/ScansSlice'
import { errorToString } from 'utils/ErrorUtils'
import { ServiceApi } from 'app/api/ErrorApi'

interface ReportsState {
  all: ReportMetaData[]
  error?: string
}

const initialState: ReportsState = {
  all: [],
  error: undefined
}

const reportsSlice = createSlice({
  name: 'reports',
  initialState,
  reducers: {
    listReportsSuccess(state, { payload }) {            
      state.all = payload.reports
      if(window.RuntimeConfig.frontend.autoClearErrors){
        state.error = undefined
      }   
    },
    requestReportSuccess(state, { payload }) {                  
      state.all = [
        ...state.all.filter(report => report.reportId !== payload.reportId), 
        payload
      ]
      if(window.RuntimeConfig.frontend.autoClearErrors){
        state.error = undefined
      }   
    },
    reportsFailure(state, { payload }) {
      state.error = payload
    },
    errorDismissed(state) {
      state.error = undefined
    }
  }
})

const { 
  listReportsSuccess, 
  requestReportSuccess,
  reportsFailure,
  errorDismissed
} = reportsSlice.actions

export default reportsSlice.reducer

const reportsApi = new ReportsApi(window.RuntimeConfig.backend)
const errorApi = new ServiceApi(window.RuntimeConfig.backend)

export const listReports = (correlationId: string = nanoid()) => async dispatch => {
  try {
    const response: ListReportsResponse = await reportsApi.listReports(correlationId)    

    dispatch(listReportsSuccess(response))
  } catch (err) {    
    dispatch(reportError('listReports', err, correlationId))
  }
}

export const requestReport = (scanId: string, selectedUserId: string | undefined, correlationId: string = nanoid()) => async dispatch => {
  try {
    const reportMetaData = await reportsApi.requestReport(scanId, selectedUserId, correlationId)
    dispatch(requestReportSuccess(reportMetaData))
    dispatch(fetchScan(scanId, correlationId))
    return reportMetaData
  } catch (err) {
    dispatch(reportError('requestReport', err, correlationId))
  }
}

export const downloadReportOutput = (reportId: string, outputId) => async dispatch => {
  const correlationId = nanoid()
  try {
    window.open(reportsApi.reportOutputUrl(reportId, outputId), '_blank')
  } catch (err) {
    dispatch(reportError('downloadReportOutput', err, correlationId))
  }
}


export const clearReportError = () => async dispatch => {
  dispatch(errorDismissed())
}


export const reportError = (method: string, err: any, correlationId: string) => async dispatch => {  
  try {
    console.log({method: err})
    const message = errorToString(err)
    dispatch(reportsFailure(message))
    await errorApi.reportError(method, message, correlationId)
  } catch (err2) {
    console.log({reportErrorFailure: err2})
  }
}
