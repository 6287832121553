import { TransformControls, useGLTF } from "@react-three/drei"
import { useFrame } from "@react-three/fiber"
import { XR, Controllers, Hands, VRButton, RayGrab } from '@react-three/xr'
import _ from "lodash"
import { useRef, useState } from "react"
import { Group } from 'three'
import { serialiseLocation, VrSceneState } from "./model/VrSceneState"

export type VrSceneProps = {
  broadcastVrSceneState: (state: VrSceneState) => void
}

const modelRotation = [Math.PI / 2, Math.PI, 0]


export const VrScene = ({broadcastVrSceneState}: VrSceneProps) => {

  const { nodes } = useGLTF('/test_output.glb')

  const ref = useRef<Group>()
  const ref1 = useRef<Group>()

  const [lastStateBroadcase, setLastStateBroadcase] = useState<VrSceneState>()

  useFrame((state, delta, xrFrame) => {
    if(ref.current !== null) { 
      const newState = {
        model: serialiseLocation(ref.current),
        camera: serialiseLocation(state.camera)
      }

      if(!_.isEqual(newState, lastStateBroadcase)){
        broadcastVrSceneState(newState)
        setLastStateBroadcase(newState)
      }      
    }
  })

  return (    
    <XR>
      <ambientLight intensity={0.5} />
      <pointLight position={[5, 5, 5]} />
      <Controllers />
      <Hands />
      <RayGrab>
        <mesh>
          <boxGeometry />
          <meshBasicMaterial color="blue" />
        </mesh>
      </RayGrab>    
      <RayGrab>
        <group dispose={null} ref={ref}>
          <mesh
            ref={ref1}
            castShadow
            receiveShadow
            geometry={nodes.mesh13.geometry}
            material={nodes.mesh13.material}
            rotation={modelRotation}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.mesh12.geometry}
            material={nodes.mesh12.material}
            rotation={modelRotation}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.mesh11.geometry}
            material={nodes.mesh11.material}
            rotation={modelRotation}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.mesh10.geometry}
            material={nodes.mesh10.material}
            rotation={modelRotation}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.mesh9.geometry}
            material={nodes.mesh9.material}
            rotation={modelRotation}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.mesh8.geometry}
            material={nodes.mesh8.material}
            rotation={modelRotation}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.mesh7.geometry}
            material={nodes.mesh7.material}
            rotation={modelRotation}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.mesh6.geometry}
            material={nodes.mesh6.material}
            rotation={modelRotation}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.mesh5.geometry}
            material={nodes.mesh5.material}
            rotation={modelRotation}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.mesh4.geometry}
            material={nodes.mesh4.material}
            rotation={modelRotation}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.mesh3.geometry}
            material={nodes.mesh3.material}
            rotation={modelRotation}
          />
          <lineSegments
            geometry={nodes.mesh2.geometry}
            material={nodes.mesh2.material}
            rotation={modelRotation}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.mesh1.geometry}
            material={nodes.mesh1.material}
            rotation={modelRotation}
          />
          <lineSegments
            geometry={nodes.mesh0.geometry}
            material={nodes.mesh0.material}
            rotation={modelRotation}
          />
        </group>
      </RayGrab>
    </XR>  
  )
}

