import _ from 'lodash'
import Select from 'react-select'
import { useEffect, useRef, useState } from 'react'
import { ReportOutputMetadata } from "uploads/api/Model"
import { formatDate } from 'utils/DateUtils'
import { SelectOption } from 'utils/SelectOption'
import { Theme } from 'app/model/Theme'
import { Button } from 'app/components/Button'

export type DownloadReportProps = {
  reportOutputs: ReportOutputMetadata[]
  showDebugUi: boolean
  theme: Theme
  downloadReportOutput: (reportId: string, outputId: string) => void
}


export const DownloadReport = ({
  reportOutputs, showDebugUi, theme,
  downloadReportOutput  
}: DownloadReportProps) => {
  
  const orderedReports = _.orderBy(reportOutputs, o => o.createdDate, 'desc')

  const [selectedReport, setSelectedReport] = useState<ReportOutputMetadata | undefined>(orderedReports[0])
  const selectedReportRef = useRef<ReportOutputMetadata>()
  selectedReportRef.current = selectedReport
  useEffect(() => {
    if(selectedReport === undefined && reportOutputs.length > 0) {
      setSelectedReport(_.orderBy(reportOutputs, o => o.createdDate, 'desc')[0])
    }
  }, [reportOutputs, selectedReport])

  const options: SelectOption<ReportOutputMetadata>[] = orderedReports.map((r) => ({
    value: r, 
    label: formatDate(r.createdDate) 
  }))
  
  const current = selectedReport === undefined ? undefined : { 
    value: selectedReport, 
    label: formatDate(selectedReport.createdDate)
  }

  if(selectedReport === undefined)  {
    return <></>
  } else {
    return (
      <div 
        style={{ 
          padding: '10px', 
          margin: '10px', 
          border: '1px solid lightgrey', 
          borderRadius: '10px' 
        }}
      >
        { showDebugUi &&
        <div style={{ width: '250px', marginBottom: '5px' }}>
          <Select 
            classNamePrefix="react-select-report-selector"
            options={options} 
            placeholder="Select report" 
            value={current} 
            onChange={(e) => { if (e !== null) {setSelectedReport(e.value)}}}
          /> 
        </div>
        }
  
        <a 
          href={ `/eliteai/reports/${selectedReport.reportId}/outputs/${selectedReport.outputId}`}      
          data-cy="download-report-link"
          style={{textDecoration: 'none'}}
          onClick={(e) => { 
            if(selectedReportRef.current !== undefined) {
              e.preventDefault()
              downloadReportOutput(selectedReportRef.current.reportId, selectedReportRef.current.outputId)
            }
          }}
        >
          <div style={{fontSize: '15pt'}}>
            <Button 
              text="Download Report"
              dataCy="download-report"
              theme={theme}
            />
          </div>
          
        </a>
        
      </div>
    )
  }
}
