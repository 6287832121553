import { Button } from 'app/components/Button'
import { UserSelector } from 'app/components/UserSelector'
import { Theme } from 'app/model/Theme'
import { User } from 'auth/model/User'
import { useRef } from 'react'
import { Link } from 'react-router-dom'
import { ReportOutputMetadata } from 'uploads/api/Model'

export type CaseLinkProps = {
  to: string
  text: string
  dataCy: string
  theme: Theme
  minWidth?: number
}

const style = { 
  padding: '10px', 
  margin: '10px', 
  border: '1px solid lightgrey', 
  borderRadius: '10px' 
}

export const CaseLink = ({to, text, dataCy, theme, minWidth}: CaseLinkProps) => {
  return (
    <Link 
      style={{
        textDecoration: 'none',
        minWidth: minWidth === undefined ? undefined : `${minWidth}px`
      }}
      to={to}
    >
      <div style={{fontSize: '15pt'}}>
        <Button 
          dataCy={dataCy}
          text={text}
          theme={theme} 
        />
      </div>
    </Link>
  )
}

type CaseButtonProps = {
  text: string
  dataCy: string
  theme: Theme
  onClick?: () => void
}

export const CaseButton = ({
  text, dataCy, theme,
  onClick
}: CaseButtonProps) => {
  return (    
    <div 
      style={style} 
    >
      <Button 
        dataCy={dataCy}
        onClick={onClick}
        theme={theme} 
        text={text}      
      />
    </div>
  )
}

export type RequestReportLinkProps = {
  requested: boolean
  output?: ReportOutputMetadata
  onReportRequestedClick: (selectedUserId: string | undefined) => void
  regenerate: boolean
  buttonSecondary: boolean
  showDebugUi: boolean
  users?: User[]
  selectedUserId?: string
  setSelectedUserId: (selectedUserId: string | undefined) => void
}


export const RequestReportLink = ({
  requested, output, regenerate, buttonSecondary, users, showDebugUi, selectedUserId,
  onReportRequestedClick, setSelectedUserId
}: RequestReportLinkProps) => {  

  const selectedUserIdRef = useRef<string>()
  selectedUserIdRef.current = selectedUserId

  const complete = output !== undefined

  const text: string = (() =>  {
    if(requested && !complete) {
      return 'Report generating...'
    } else if(complete) {
      return 'Report generated'
    } else if(regenerate) {
      return 'Regenerate Report'
    } else {
      return 'Generate Report'
    }
  })()

  const onClick = () => {
    if(!requested) {
      onReportRequestedClick(selectedUserIdRef.current)
    }
  }



  return (
    <>
      <div style={style} >
        {showDebugUi &&
          <div style={{marginBottom: '5px'}}>
            <UserSelector users={users} selectedUserId={selectedUserId} setSelectedUserId={setSelectedUserId} />  
          </div>
        }
        <button 
          style={{
            minWidth: '150px',
            cursor: text === undefined ? 'default' : 'pointer'
          }}
          type="button" 
          onClick={onClick}
          className={`btn ${ buttonSecondary || complete || regenerate ? 'btn-secondary' : 'btn-primary'} upload-files`}
          >{text}</button>
      </div>
    </>   
  )
}
