import { User } from 'auth/model/User'
import _ from 'lodash'
import { useCallback, useRef, useState } from "react"
import { Annotation, AnnotationApprovalStatus } from 'annotation/model/Annotation'

import { Scan, ScanInfo } from "uploads/api/Model"
import { Dataset, DatasetInfo } from 'uploads/model/Dataset'
import { getSeriesDisplayName, SeriesDescriptionsMap } from 'uploads/util/SeriesDisplayName'
import { SeriesAnnotationsList } from "./SeriesAnnotationsList"
import { SeriesAnnotationIdsMap } from './SeriesPanel'
import { DatasetAnnotationLabel, SeriesDatasetAnnotationLabelMap } from 'uploads/model/DatasetAnnotationLabel'

export type ReorderableSeriesListProps = {
  scanId: string
  seriesIds: string[] 
  seriesOrderState?: string[]
  annotations?: Annotation[]
  hasWriteAccess: boolean
  scan: Scan
  dataset?: Dataset
  seriesDescriptions: SeriesDescriptionsMap
  hoveredAnnotationId?: string
  users: User[] 
  displayedUserAnnotations: string[]
  displayModelAnnotations: boolean
  annotationApprovers: string[]
  currentUserId: string
  editingAnnotation: string | undefined
  showDebugUi: boolean
  savingAnnotationIds: SeriesAnnotationIdsMap
  zoomedSeriesInstanceUID?: string
  hiddenAnnotations: string[]
  selectedSeriesId?: string
  seriesAnnotationLabels: SeriesDatasetAnnotationLabelMap
  setHiddenAnnotations: (hiddenAnnotations: string[]) => void
  setZoomedSeriesInstanceUID: (seriesId: string | undefined) => void
  setSeriesOrderState: (seriesOrderState: string[]) => void
  updateScan: (scanId: string, name: string, scanInfo: ScanInfo) => Promise<void>
  setHoveredAnnotationMeta: (hoveredAnnotationId: string | undefined) => void
  deleteAnnotation: (scanId: string, annotationId: string) => Promise<void>
  updateAnnotationApproval: (scanId: string, annotationId: string, status: AnnotationApprovalStatus | undefined) => void
  displayAnnotation: (annotation: Annotation) => void
  setEditingAnnotation: (annotationId: string|undefined) => void
  updateDataset: (datasetId: string, name: string, datasetInfo: DatasetInfo) => Promise<void>
}

export const ReorderableSeriesList = ({
  seriesOrderState, seriesIds, hasWriteAccess, scan, scanId, dataset, hoveredAnnotationId, annotationApprovers, 
  currentUserId, annotations, displayModelAnnotations, displayedUserAnnotations, users, showDebugUi, 
  seriesDescriptions, editingAnnotation, savingAnnotationIds, zoomedSeriesInstanceUID, hiddenAnnotations,
  selectedSeriesId, seriesAnnotationLabels,
  setSeriesOrderState, updateScan, setHoveredAnnotationMeta, deleteAnnotation, displayAnnotation, 
  updateAnnotationApproval, setEditingAnnotation, setZoomedSeriesInstanceUID, setHiddenAnnotations
}: ReorderableSeriesListProps) => {

  const [expandedSeriesIds, setExpandedSeriesIds] = useState<string[]>([])
  const expandedSeriesIdsRef = useRef<string[]>()
  expandedSeriesIdsRef.current = expandedSeriesIds

  const [dragStartSeriesOrder, setDragStartSeriesOrder] = useState<string[]>([])

  const [dragItem, setDragItem] = useState<number | undefined>(undefined)

  const filteredAnnotations = annotations?.filter(a => 
    seriesIds.includes(a.SeriesInstanceUID) && (
      (a.createdBy !== undefined && displayedUserAnnotations.includes(a.createdBy)) ||
      (a.modelName !== undefined && displayModelAnnotations)
    )
  )

  const onDragStart = useCallback((index) => {
    setDragItem(index)

    if(seriesOrderState === undefined) {
      setSeriesOrderState(seriesIds)
      setDragStartSeriesOrder(seriesIds)
    }
  }, [seriesOrderState, dragItem])

  const onDragEnter = useCallback((index) => {
    if(dragItem !== undefined && seriesOrderState !== undefined)  {
      const copyListItems = [...seriesOrderState]
      const dragItemContent = copyListItems[dragItem]
      copyListItems.splice(dragItem, 1)
      copyListItems.splice(index, 0, dragItemContent)
      setDragItem(index)

      if(!_.isEqual(seriesOrderState, copyListItems))  {
        setSeriesOrderState(copyListItems)                            
      }
    }
  }, [dragItem, seriesOrderState])

  const onDragEnd = useCallback(() => {
    if(seriesOrderState !== undefined && !_.isEqual(dragStartSeriesOrder, seriesOrderState))  {
      if(hasWriteAccess){
        updateScan(scan.scanId, scan.name || '', { ...scan.scanInfo, orderedSeriesIds: seriesOrderState })
      }                
    }
  }, [seriesOrderState, dragStartSeriesOrder])

  return (
    <div className="reorderable-series-list">
      <h5 style={{ fontSize: '1rem' }}>Series:</h5>
      { (seriesOrderState || []).map((seriesId, index) =>
      <div key={seriesId}>
        {index === 4 &&
          <hr style={{borderTop: 'dotted 1px'}} />
        }
        <div 
          onDragStart={(e) => {
            onDragStart(index)
          }}
          onDragEnter={(e) => {
            onDragEnter(index)
          }}
          onDragOver={(e) => e.preventDefault()}
          onDragEnd={onDragEnd}
          draggable
        >
          <SeriesAnnotationsList 
            seriesId={seriesId}
            scanId={scanId}
            scan={scan}
            dataset={dataset}
            hoveredAnnotationId={hoveredAnnotationId}
            annotationApprovers={annotationApprovers}
            currentUserId={currentUserId}
            annotations={filteredAnnotations?.filter(a => a.SeriesInstanceUID === seriesId) || []}
            setHoveredAnnotationMeta={setHoveredAnnotationMeta}
            deleteAnnotation={deleteAnnotation}
            displayAnnotation={displayAnnotation}
            updateAnnotationApproval={updateAnnotationApproval}
            setEditingAnnotation={setEditingAnnotation}
            editingAnnotation={editingAnnotation} 
            seriesDescription={getSeriesDisplayName(seriesId, seriesDescriptions[seriesId], scan, dataset)} 
            annotationLabels={dataset?.annotationLabels || []}   
            users={users}
            showDebugUi={showDebugUi}    
            isActiveSeries={selectedSeriesId === seriesId}
            labels={seriesAnnotationLabels[seriesId]}
            savingAnnotationIds={savingAnnotationIds[seriesId] || []}
            zoomedSeriesInstanceUID={zoomedSeriesInstanceUID}
            setZoomedSeriesInstanceUID={setZoomedSeriesInstanceUID}
            onExpandSeries={() => {
              if(expandedSeriesIdsRef.current?.includes(seriesId)){
                setExpandedSeriesIds([
                  ...expandedSeriesIdsRef.current.filter(s => s !== seriesId)
                ])
              } else {
                setExpandedSeriesIds([...(expandedSeriesIdsRef.current || []), seriesId])
              }
            }} 
            hiddenAnnotations={hiddenAnnotations}
            setHiddenAnnotations={setHiddenAnnotations}
          />
        </div>
      </div>
      )}      
    </div>
  )
}