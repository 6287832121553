import { DeleteX } from 'app/components/icons/DeleteX'
import { VisibilityToggle } from 'app/components/icons/VisibilityToggle'
import { LoadingSpinner } from 'app/components/LoadingSpinner'
import { getUserName, User } from 'auth/model/User'
import { Annotation, AnnotationApprovalStatus } from 'annotation/model/Annotation'
import { shapePointsDescription } from 'annotation/model/AnnotationShape'
import { annotationDisplayText, DatasetAnnotationLabel } from 'uploads/model/DatasetAnnotationLabel'
import { formatDate } from 'utils/DateUtils'
import { AnnotationApprovalButtons } from './AnnotationApprovalButtons'

export type AnnotationMetadataProps = {
  annotation: Annotation
  highlight: boolean
  currentUserId: string
  users?: User[]
  showDebugUi: boolean
  annotationApprovers: string[]
  seriesDescription?: string
  isEditingAnnotation: boolean
  annotationLabels: DatasetAnnotationLabel[]
  isSaving: boolean
  muscleCrossSectionalArea?: number
  tendinousCrossSectionalArea?: number
  hiddenAnnotations: string[]
  setHiddenAnnotations: (hiddenAnnotations: string[]) => void
  onMouseEnter: () => void
  onMouseLeave: () => void
  onClick: () => void
  removeAnnotation: () => void
  updateAnnotationApprovalStatus: (status: AnnotationApprovalStatus | undefined) => void
  setEditingAnnotation: (annotationId: string|undefined) => void
}

export const AnnotationMetadata = ({
  annotation, highlight, currentUserId, users, showDebugUi, annotationApprovers, seriesDescription, 
  isEditingAnnotation, annotationLabels, isSaving, muscleCrossSectionalArea, tendinousCrossSectionalArea,
  hiddenAnnotations,
  onMouseEnter, onMouseLeave, onClick, removeAnnotation, updateAnnotationApprovalStatus, setEditingAnnotation,
  setHiddenAnnotations
}: AnnotationMetadataProps) => {

  const boxShadow = '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'

  const showDeleteAnnotationButton = currentUserId === annotation.createdBy || annotation.modelId !== undefined

  const approvals = annotation.approvals.filter(a => annotationApprovers.includes(a.userId) && a.status === AnnotationApprovalStatus.Approved)
  const rejections = annotation.approvals.filter(a => annotationApprovers.includes(a.userId) && a.status === AnnotationApprovalStatus.Rejected)

  const label = annotationDisplayText(annotation, annotationLabels)

  let title = `${label}\nCreated on: ${formatDate(annotation.createdDate)}`

  if(seriesDescription !== undefined){
    title = title + `\n` + 
      `Series: ${seriesDescription}`
  } 

  if(annotation.createdBy !== undefined) {
    title = title + `\n` + 
      `Created by: ${getUserName(users, annotation.createdBy)}`
  }      

  if(annotation.updatedBy !== undefined) {
    title = title + `\n` + 
      `Updated by: ${getUserName(users, annotation.updatedBy)}`
  }      

  if(annotation.label === 'tear_csa' && annotation.derivedData?.area !== undefined && muscleCrossSectionalArea !== undefined) {
    title = title + '\n' +
      `CSA of tear in muscle: ${Math.round((annotation.derivedData.area * 100) / muscleCrossSectionalArea)}%`
  }

  if(annotation.label === 'tear_intratendinous' && annotation.derivedData?.area !== undefined && tendinousCrossSectionalArea !== undefined) {
    title = title + '\n' +
      `CSA of tear in tendon: ${Math.round((annotation.derivedData.area * 100) / tendinousCrossSectionalArea)}%`
  }

  if(annotation.modelName !== undefined) {
    title = title + `\n` + 
      `Model: ${annotation.modelName}`
  }      
  if(showDebugUi && annotation.modelId !== undefined) {
    title = title + `\n` + 
      `Model ID: ${annotation.modelId}`
  }
  if(showDebugUi && annotation.derivedData !== undefined) {
    title = title + `\n` + 
      `Derived data: ${JSON.stringify(annotation.derivedData, null, 2)}`
  }

  if(approvals.length > 0) {
    title = title + `\n` + 
      `Approved by: ${approvals.map(a => getUserName(users, a.userId)).join(', ')}`
  }
  if(rejections.length > 0) {
    title = title + `\n` + 
      `Rejected by: ${rejections.map(a => getUserName(users, a.userId)).join(', ')}`
  }

  if(annotation.derivedData?.area !== undefined) {
    title = title + '\n' +
      `Area: ${Math.round(annotation.derivedData.area) / 100}cm²`
  }
  if(annotation.derivedData?.length !== undefined) {
    title = title + '\n' +
      `Length: ${Math.round(annotation.derivedData.length) / 10}cm`
  }
  if(annotation.derivedData?.distanceToTJunction !== undefined) {
    title = title + '\n' +
      `Distance to T-Junction: ${Math.round(annotation.derivedData.distanceToTJunction) / 10}cm`
  }

  if(showDebugUi) {
    title = title + `\n` +
      `Annotation ID: ${annotation.annotationId}\n` +
      `Model ID: ${annotation.modelId}\n` +
      `Label: ${annotation.label}\n` +
      shapePointsDescription(annotation.shape)
  }


  return (
    <div 
      className="annotation-metadata" 
      title={title}
      style={{
        maxWidth: '215px',
        boxShadow: highlight ? boxShadow : '',
        border: '1px solid lightgray',
        borderRadius: '10px',
        padding: '3px',
        paddingLeft: '5px',
        paddingRight: '1px',
        margin: '5px',
        position: 'relative',
        cursor: 'pointer'
      }}
      onMouseEnter={onMouseEnter} 
      onMouseLeave={onMouseLeave}
      onClick={(e) => {
        e.stopPropagation()
        onClick()
      }}
    >
      <div style={{display:'flex'}}>
        <div style={{minWidth: '80px', overflow: 'hidden', whiteSpace: 'nowrap', marginRight: '4px'}} title={title}>{label}</div>     
             
        <div style={{marginLeft: 'auto', marginRight: '-7px', paddingRight: '5px', display: 'flex'}}>
          { isSaving && 
          <div style={{paddingRight: '5px'}}>
            <LoadingSpinner size={18} />
          </div>
          }   
          { !isSaving &&
          <>
          <VisibilityToggle 
            isVisible={!hiddenAnnotations.includes(annotation.annotationId)} 
            onVisibilityToggle={() => {
              if(hiddenAnnotations.includes(annotation.annotationId)) {
                setHiddenAnnotations(hiddenAnnotations.filter(a => a !== annotation.annotationId))
              } else {
                setHiddenAnnotations([...hiddenAnnotations, annotation.annotationId])
              }
            }}  />
          <AnnotationApprovalButtons 
            annotationApprovers={annotationApprovers || []}
            currentUserId={currentUserId}
            annotationCreatedBy={annotation.createdBy}
            approvals={approvals}
            rejections={rejections}
            updateAnnotationApprovalStatus={updateAnnotationApprovalStatus}
            onEditingAnnotation={() => setEditingAnnotation(annotation.annotationId)}
            clearEditingAnnotation={() => setEditingAnnotation(undefined)}
            isEditingAnnotation={isEditingAnnotation}
          />
          {showDeleteAnnotationButton &&
          <div className="remove-annotation-button" title="Delete annotation" style={{width: '25px', height: '25px'}}>
            <DeleteX 
              title="Delete annotation"
              onClick={() => {
                removeAnnotation()
                if(isEditingAnnotation) {
                  setEditingAnnotation(undefined)
                }
              }}  
            />
          </div>
          }    
          </>
          }      
        </div>      
      </div>
    </div>
  )
}
