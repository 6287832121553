import { useCallback, useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
import { useParams } from 'react-router-dom'
import _ from 'lodash'

import { fetchAnnotations, fetchScan, fetchImagesForScan, updateScan, updateCaseAccessPermissions, fetchCaseAccessPermissions, ImageExtractionStatus, requestModelRun, fetchClassificationForms } from 'cases/api/ScansSlice'
import { fetchScanUploads, downloadFile, uploadFile, fileUploadsComplete } from 'uploads/api/UploadsSlice'
import { downloadReportOutput, listReports } from 'reports/api/ReportsSlice'
import { fetchDataset, fetchDatasetAccessPermissions, updateDataset } from 'datasets/api/DatasetSlice'
import { RootState } from 'app/store'
import { sortByFileName, UploadRecord } from 'uploads/model/UploadRecord'
import { nanoid } from 'nanoid'
import { Dataset, DatasetInfo } from 'uploads/model/Dataset'
import { ModelRun, ModelRunStatus, Scan, ScanInfo } from 'uploads/api/Model'
import { KeycloakProfile } from 'keycloak-js'
import { LoadingSpinner } from 'app/components/LoadingSpinner'
import { getUserName, User } from 'auth/model/User'
import { Annotation } from 'annotation/model/Annotation'
import { CaseSeriesTable } from 'uploads/component/CaseSeriesTable'
import { CaseUploadsTable } from 'uploads/component/CaseUploadsTable'
import { BackendConfig } from 'config/ApplicationConfig'
import { isOwnerAccess, isWriteAccess, mergePermissionLevels, PermissionLevel } from 'auth/model/PermissionLevel'
import { AccessEntry } from 'auth/model/AccessEntry'
import { UserFeatures } from 'auth/UserSlice'
import { DirectoryUpload } from 'uploads/component/DirectoryUpload'
import RequestOrViewReport from 'uploads/component/RequestOrViewReport'
import { CaseDetailsHeader } from 'uploads/component/CaseDetailsHeader'
import { setSeriesDisplayName } from 'uploads/util/SeriesDisplayName'
import { ClassificationForm } from 'annotation/model/ClassificationForm'
import { Theme } from 'app/model/Theme'
import { AnnotationMode, getAnnotationMode } from 'uploads/model/AnnotationMode'
import { ACLFormDisplay } from 'annotation/components/ACLFormDisplay'
import { HamstringClassification } from 'annotation/components/HamstringClassification'
import { CaseInfo } from './components/CaseInfo'
import CaseDetailsSeriesView from './components/CaseDetailsSeriesView'

type CaseDetailsPageProps = {
  fetchScan: (scanId: string) => void
  listUploads: (scanId: string, datasetId?: string) => void
  updateScan: (scanId: string, name: string, scanInfo: ScanInfo | undefined, studyDate: string | undefined) => Promise<void>
  fetchImagesForScan: (scanId: string) => void
  listReports: () => void
  fetchDataset: (datasetId: string) => void
  fetchAnnotations: (scanId: string) => void
  fetchClassificationForms: (scanId: string) => void
  uploadFile: (scanId: string, formData: FormData) => Promise<void>
  downloadFile: (uploadId: string) => void
  loadAccessEntries: (scanId: string) => void
  updateAccessPermissions: (caseId: string, userId: string, level: PermissionLevel, refresh: boolean) => void
  fetchDatasetAccessPermissions: (datasetId: string) => void
  updateDataset: (datasetId: string, name: string, datasetInfo: DatasetInfo) => Promise<void>
  requestModelRun: (scanId: string) => Promise<string>
  fileUploadsComplete: (scanId: string) => Promise<string>
  downloadReportOutput: (reportId: string, outputId: string) => void
  uploads?: UploadRecord[]
  caseId: string
  scan?: Scan
  dataset?: Dataset
  images?: ImageExtractionStatus
  annotations?: Annotation[]
  classificationForms?: ClassificationForm[]
  profile?: KeycloakProfile
  currentUserId: string
  users?: User[]
  config: BackendConfig
  scanAccessEntries?: AccessEntry[]
  datasetAccessEntries?: AccessEntry[]
  features: UserFeatures
  showDebugUi: boolean
  theme: Theme
}

const CaseDetailsPage = ({
  fetchScan, updateScan, fetchImagesForScan, fetchAnnotations, updateAccessPermissions, loadAccessEntries,
  listUploads, uploadFile, downloadFile, fetchClassificationForms, downloadReportOutput,
  fetchDataset, fetchDatasetAccessPermissions, requestModelRun, fileUploadsComplete,
  uploads, caseId, scan, dataset, users, images, annotations, config, scanAccessEntries, datasetAccessEntries,
  currentUserId, features, classificationForms, showDebugUi, theme
}: CaseDetailsPageProps) => {
  
  useEffect(() => {if(scan === undefined || scan.uploadIds === undefined) fetchScan(caseId)}, [fetchScan, scan, caseId])
  useEffect(() => {if(scanAccessEntries === undefined) loadAccessEntries(caseId)}, [loadAccessEntries, caseId])
  useEffect(() => {if(scan !== undefined && scan.uploadIds !== undefined && (scan.uploadIds.length > (uploads?.length || 0))) {
    listUploads(caseId, scan.datasetId)}
  }, [listUploads, caseId, scan?.datasetId, scan?.uploadIds?.length, uploads?.length])
  useEffect(() => {if(annotations === undefined) {fetchAnnotations(caseId) }}, [fetchAnnotations, caseId])
  useEffect(() => {if(images === undefined) fetchImagesForScan(caseId)}, [fetchImagesForScan, caseId, images])
  useEffect(() => {if(scan !== undefined && scan.datasetId !== undefined && dataset === undefined) fetchDataset(scan.datasetId)}, [fetchDataset, scan?.datasetId, dataset === undefined])
  useEffect(() => {if(scan?.datasetId !== undefined && datasetAccessEntries === undefined  ) fetchDatasetAccessPermissions(scan.datasetId)}, [fetchDatasetAccessPermissions, dataset?.datasetId, datasetAccessEntries])
  useEffect(() => {if(classificationForms === undefined) fetchClassificationForms(caseId)}, [classificationForms, caseId])

  const [imagesLastRequested, setImagesLastRequested] = useState<number>(new Date().getTime())
  const imagesLastRequestedRef = useRef<number>(new Date().getTime())
  imagesLastRequestedRef.current = imagesLastRequested

  const imagesStatusRef = useRef<string>()
  imagesStatusRef.current = images?.status

  const minIntervalMs = 5000

  const doCheck = () => {
    const now = new Date().getTime()
  
    if(imagesLastRequestedRef.current === undefined || now - imagesLastRequestedRef.current > minIntervalMs) {
      fetchImagesForScan(caseId)
      setImagesLastRequested(now)
    }            
  }

  useEffect(() => {
    const checkOnImages = (timesCalled: number) => {
      if(imagesStatusRef.current === 'requested') {
        doCheck()        
        setTimeout(() => checkOnImages(timesCalled + 1), minIntervalMs * Math.pow(2, timesCalled))      
      }
    }
    checkOnImages(0)
  }, [images?.metadata.length, images?.status])
  
  const updateBodyRegion = (bodyRegionId: string | undefined) => {
    if(scan !== undefined) {
      updateScan(
        caseId, 
        scan.name || '', 
        {
          ...scan.scanInfo,
          bodyRegionId
        },
        undefined
      )
    }    
  }  

  const scanAccessLevel = scanAccessEntries?.find(e => e.userId === currentUserId)?.accessLevel    
  const datasetAccessLevel = datasetAccessEntries?.find(e => e.userId === currentUserId)?.accessLevel
  const accessLevel = mergePermissionLevels(scanAccessLevel, datasetAccessLevel)

  const hasSharingAccess = isOwnerAccess(accessLevel)
  const hasWriteAccess = isWriteAccess(accessLevel)

  const annotationCreatedByUsers = [...new Set(annotations?.map(a => a.createdBy))]
  const annotationUpdatedByUsers = [...new Set(annotations?.map(a => a.updatedBy))]
  const formUsers = [...new Set(classificationForms?.map(c => c.createdBy))]
  const reportUsers = [...new Set(
    annotationCreatedByUsers.concat(annotationUpdatedByUsers).concat(formUsers).filter(id => id !== undefined)
  )] as string[]

  const latestModelRun: ModelRun | undefined = _.orderBy(scan?.modelRuns, r => r.createdDate, 'desc')[0]
  const form: ClassificationForm | undefined = classificationForms === undefined ? undefined : classificationForms[0]
  const annotationMode = getAnnotationMode(dataset, scan)


  const [runningModelId, setRunningModelId] = useState<string | undefined>(undefined)  
  const [lastPolled, setLastPolled] = useState<number>(new Date().getTime())
  const lastPolledRef = useRef<number>(new Date().getTime())
  lastPolledRef.current = lastPolled
  
  const modelPoll = useCallback(() => {
    const now = new Date().getTime()

    const run = scan?.modelRuns.find(run => run.modelRunId === runningModelId)

    if(run === undefined || (run.status !== ModelRunStatus.complete && run.status !== ModelRunStatus.failed)) {
      if(now - lastPolledRef.current > minIntervalMs) {
        setLastPolled(now)       
        fetchScan(caseId)    
      }
      setTimeout(modelPoll, 10000)   
    }
  }, [scan])

  const onModelRunRequested = () => {
    requestModelRun(caseId).then((modelRunId) => {
      setRunningModelId(modelRunId)
      modelPoll()
    })    
  }

  const onFileUpladsComplete = () => {
    fileUploadsComplete(caseId).then((modelRunId) => {
      setRunningModelId(modelRunId)
      modelPoll()
    })    
  }


  if(scan === undefined){
    return (
      <div style={{margin: '30px'}}>
        <LoadingSpinner />
      </div>      
    )
  } else {      
     
    const radiologists: string[] | undefined = scan.datasetId === undefined ? undefined : 
      dataset?.datasetInfo.annotationApprovers.map(u => getUserName(users, u))

    const annotationsCreatedBy: string[] = annotations === undefined ? [] : annotations.reduce((acc: string[], annotation: Annotation) => {
      if(annotation.createdBy !== undefined && !acc.includes(annotation.createdBy)){
        return [...acc, annotation.createdBy]
      } else {
        return acc
      }
    }, [])

    return (
      <div  className="case-details-page">
        <div style={{display: 'flex', maxWidth: '550px', margin: '10px'}}>
          <div className='case-info-left-pane'>
            <CaseDetailsHeader 
              scan={scan} 
              updateScan={updateScan} 
              hasWriteAccess={hasWriteAccess} 
              hasSharingAccess={hasSharingAccess} 
              users={users} 
              dataset={dataset}
              scanAccessEntries={scanAccessEntries}
              updateAccessPermissions={updateAccessPermissions}
              theme={theme}
            />
            { (scan.datasetId !== undefined || scan.uploadIds.length > 0) &&
              <CaseInfo 
                scan={scan} 
                dataset={dataset}
                hasWriteAccess={hasWriteAccess}
                latestModelRun={latestModelRun}
                radiologists={radiologists}
                updateScan={updateScan}
                updateBodyRegion={updateBodyRegion} 
                downloadReportOutput={downloadReportOutput}
                theme={theme}
              />
            }

            { form !== undefined && annotationMode === AnnotationMode.ACL &&
              <ACLFormDisplay current={form?.formData} />
            }
            { form !== undefined && annotationMode === AnnotationMode.Hamstring &&
              <HamstringClassification 
                current={form.formData} 
                scan={scan} 
              /> 
            }            
          </div>

          <div className='case-info-right-pane' style={{ marginLeft: '20px', marginTop: '15px'}}>
            <CaseDetailsSeriesView 
              scanId={scan.scanId} 
              scan={scan} 
              dataset={dataset}
              currentUserId={currentUserId} 
              annotationsCreatedBy={annotationsCreatedBy} 
              features={features} 
              theme={theme} 
              hasWriteAccess={hasWriteAccess} 
            />
          </div>
        </div>

        <div style={{maxWidth: '800px', marginLeft: '10px'}}>
          { hasWriteAccess && scan.datasetId === undefined &&
            <DirectoryUpload 
              uploadFile={(formData) => 
                uploadFile(scan.scanId, formData)
                  .then(() => {setTimeout(doCheck, minIntervalMs)})
              }
              buttonSecondary={uploads !== undefined} 
              hidden={scan.uploadIds.length > 0}
              onComplete={onFileUpladsComplete}
            />                
          }

          { showDebugUi && (scan.datasetId !== undefined || scan.uploadIds.length > 0) &&
            <RequestOrViewReport 
              scan={scan}
              uploadsCount={uploads?.length || 0} 
              caseId={caseId} 
              hasWriteAccess={hasWriteAccess} 
              reportUsers={reportUsers}
              theme={theme}
              onModelRunRequested={onModelRunRequested}
            />      
          }

          { (scan.datasetId !== undefined || scan.uploadIds.length > 0) &&
          <CaseSeriesTable 
            scan={scan} 
            images={images?.metadata} 
            annotations={annotations} 
            hasWriteAccess={hasWriteAccess}
            setSeriesDisplayName={(description, name) => setSeriesDisplayName(scan, description, name, updateScan)} 
          /> 
          }

          <div className="scan-files-conatiner" style={{marginTop: '30px'}}>
            { hasWriteAccess && scan.datasetId === undefined &&
              <DirectoryUpload 
                uploadFile={(formData) => uploadFile(scan.scanId, formData).then(() => { setTimeout(doCheck, minIntervalMs)})}
                buttonSecondary={uploads !== undefined} 
                hidden={scan.uploadIds.length === 0}
                onComplete={onFileUpladsComplete}
              />                
            }

            { scan.datasetId === undefined && scan.uploadIds.length > 0 && uploads !== undefined &&
              <CaseUploadsTable 
                uploads={uploads} 
                images={images?.metadata} 
                config={config} 
                downloadFile={downloadFile} 
                isDataset={scan.datasetId !== undefined} 
              />    
            }        
          </div>  
        </div>     
      </div>
    )
  }
}


const mapStateToProps = (state: RootState, { caseId }) => {
  const scan = state.scans.all?.find(scan => scan.scanId === caseId)

  const uploads: UploadRecord[] | undefined = scan === undefined ? undefined : 
    (scan.datasetId === undefined ? state.uploads.byScan[caseId] : state.uploads.byDataset[scan.datasetId]?.filter(u => scan.uploadIds?.includes(u.id)))
  
  const sortedUploads = sortByFileName(uploads)

  const dataset = state.datasets.all?.find(d => d.datasetId === scan?.datasetId)

  return {
    caseId,
    scan,
    uploads: sortedUploads,
    config: state.config.backend,
    images: state.scans.imagesMetadata[caseId],
    annotations: state.scans.annotations[caseId],    
    classificationForms: state.scans.forms[caseId],
    profile: state.user.profile,
    users: state.user.users,
    dataset,
    scanAccessEntries: state.scans.accessEntries[caseId],
    datasetAccessEntries: scan?.datasetId === undefined ? undefined : state.datasets.accessEntries[scan.datasetId],
    features: state.user.features,
    showDebugUi: state.user.features.showDebugUi,
    theme: state.user.features.theme
  }
}

const mapDispatchToProps = { 
  fetchScan: (scanId: string) => fetchScan(scanId, nanoid()),
  updateScan: (scanId: string, name: string, scanInfo: ScanInfo | undefined, studyDate: string | undefined) => updateScan(scanId, name, scanInfo, studyDate, nanoid()),
  listUploads: (scanId: string, datasetId: string | undefined) => fetchScanUploads(scanId, datasetId, nanoid()),
  uploadFile: (scanId: string, formData: FormData) => uploadFile(scanId, formData),
  updateDataset: (datasetId: string, name: string, datasetInfo: DatasetInfo) => updateDataset(datasetId, name, datasetInfo, nanoid()),
  downloadFile,
  downloadReportOutput: downloadReportOutput,
  fetchImagesForScan: (scanId: string) => fetchImagesForScan(scanId, nanoid()),
  listReports: () => listReports(nanoid()),
  fetchDataset: (datasetId: string) => fetchDataset(datasetId, nanoid()),
  fetchAnnotations: (scanId: string) => fetchAnnotations(scanId, nanoid()),
  fetchClassificationForms: (scanId: string) => fetchClassificationForms(scanId, nanoid()),
  loadAccessEntries: (scanId: string) => fetchCaseAccessPermissions(scanId, nanoid()),
  updateAccessPermissions: (datasetId: string, userId: string, level: PermissionLevel, refresh: boolean) => updateCaseAccessPermissions(datasetId, userId, level, refresh, nanoid()),
  fetchDatasetAccessPermissions: (datasetId: string) => fetchDatasetAccessPermissions(datasetId, nanoid()),
  requestModelRun: (scanId: string) => requestModelRun(scanId, nanoid()),
  fileUploadsComplete: (scanId: string) => fileUploadsComplete(scanId, nanoid())
}



const ConnectedCaseDetailsPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(CaseDetailsPage)

type CaseDetailsPageWrapperProps = {
  currentUserId: string
}

export default function CaseDetailsPageWrapper({currentUserId}: CaseDetailsPageWrapperProps) {
  const { caseId } = useParams()
  return (
    <ConnectedCaseDetailsPage caseId={caseId} currentUserId={currentUserId} />
  )
}

