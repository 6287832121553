import { useState } from "react"

export type ColorPickerProps = {
  color: string | undefined
  setColor: (color: string) => Promise<void>
}

export const ColorPicker = ({color, setColor}: ColorPickerProps) => {

  const [chosenColor, setChosenColor] = useState<string | undefined>(undefined)

  const onChange = (newColor: string) => {
    setChosenColor(newColor)    

    setColor(newColor).then(() => {
      setChosenColor(undefined)
    })
  }

  const [activated, setActivated] = useState<boolean>(false)

  const value: string = chosenColor || color || '#000000'
  
  const isFirefox = (navigator.userAgent.match(/firefox|fxios/i)?.length || 0) > 0  

  if(isFirefox && !activated) {
    
    return (
      <div 
        style={{backgroundColor: value, width: '40px', height: '20px'}} 
        onClick={() => setActivated(true)}
      />      
    )
  } else {
    return (
      <input 
        style={{
          width: '40px', 
          height: '20px', 
          marginLeft: '20px', 
          position: 'relative',
        }}
        type="color" 
        value={value}
        onChange={e => onChange(e.target.value)}
      />
    )    
  }
}