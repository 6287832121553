import axios from 'axios'
import retry from 'async-retry'

import { currentAuthHeaders } from 'auth/KeyCloak' 
import { BackendConfig } from 'config/ApplicationConfig'
import { ListUploadsResponse, RequestModelRunResponse, UploadFilesResponse } from './Model'

export class UploadsApi {

  scanUploadsUrl: (scanId: string) => string
  uploadsUrl: string
  uploadsForScanUrl: (scanId: string | undefined, datasetId: string | undefined) => string
  retryCount: number

  constructor(config: BackendConfig) {
    this.retryCount = config.retryCount
    this.scanUploadsUrl = (scanId) => `${config.url}/eliteai/scans/${scanId}/uploads`  
    this.uploadsUrl = `${config.url}/eliteai/uploads`
    this.uploadsForScanUrl = (scanId: string | undefined, datasetId: string | undefined) => {
      if(datasetId !== undefined) {
        return `${config.url}/eliteai/uploads?datasetId=${datasetId}`
      } else if (scanId !== undefined) {
        return `${config.url}/eliteai/uploads?scanId=${scanId}`
      } else {
        return `${config.url}/eliteai/uploads`
      }
    }
  }

  async listScanUploads(scanId: string, datasetId: string | undefined, correlationId: string)  {
    return retry(async (bail) => {
      const headers = await currentAuthHeaders(correlationId)

      const response = await axios.get<ListUploadsResponse>(this.uploadsForScanUrl(scanId, datasetId), { headers })

      return response.data
    }, {
      retries: this.retryCount
    })    
  }

  async listDatasetUploads(datasetId: string, correlationId: string)  {
    return retry(async (bail) => {
      const headers = await currentAuthHeaders(correlationId)

      const response = await axios.get<ListUploadsResponse>(this.uploadsForScanUrl(undefined, datasetId), { headers })

      return response.data
    }, {
      retries: this.retryCount
    })    
  }

  async uploadFiles(scanId: string, formData: FormData, correlationId: string) {
    return retry(async (bail) => {
      const headers = await currentAuthHeaders(correlationId)

      const response = await axios.post<UploadFilesResponse>(this.scanUploadsUrl(scanId), formData, { headers })

      return response.data
    }, {
      retries: this.retryCount
    })    
  }

  async fileUploadsComplete(scanId: string, correlationId: string) {
    return retry(async (bail) => {
      const headers = await currentAuthHeaders(correlationId)

      const response = await axios.post<RequestModelRunResponse>(`${this.scanUploadsUrl(scanId)}/complete`, {}, { headers })

      return response.data
    }, {
      retries: this.retryCount
    })    
  }
}
