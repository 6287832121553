import Papa from 'papaparse'

import { RTPCalculator } from 'app/components/RTPCalculator'
import { RootState } from 'app/store'
import { listScans } from 'cases/api/ScansSlice'
import { fetchDataset } from 'datasets/api/DatasetSlice'
import { DatasetSelector } from 'datasets/components/DatasetSelector'
import { nanoid } from 'nanoid'
import React, { useEffect, useMemo, useState } from 'react'
import { connect } from 'react-redux'
import { ScanListingEntry } from 'uploads/api/Model'
import { AmsFileUpload } from 'uploads/component/AmsFileUpload'
import { Dataset, DatasetAmsData, DatasetListingEntry } from 'uploads/model/Dataset'
import { Theme } from 'app/model/Theme'

export type InjuryAnalyticsPageProps = {
  datasetsList?: DatasetListingEntry[]
  allDatasets?: Dataset[]
  cases?: ScanListingEntry[] 
  theme: Theme
  currentUserId: string
  fetchDataset: (datasetId: string) => void
  listCases: (datasetId: string) => void
}

const InjuryAnalyticsPage = ({
  datasetsList, cases, allDatasets, theme,
  fetchDataset, listCases
}: InjuryAnalyticsPageProps) => {

  const [datasetId, setDatasetId] = useState<string|undefined>(undefined)
  const [datasetIdInitialised, setDatasetIdInitialised] = useState<boolean>(false)
  useEffect(() => { 
    if(!datasetIdInitialised && datasetsList !== undefined && datasetsList.length > 0) { 
      setDatasetId(datasetsList[0].datasetId)
      setDatasetIdInitialised(true)
    } 
  }, [datasetIdInitialised, datasetsList?.length, datasetId])

  const dataset = allDatasets?.find(d => d.datasetId === datasetId)

  useEffect(() => { if(datasetId !== undefined && dataset === undefined) fetchDataset(datasetId) }, [datasetId, fetchDataset])
  useEffect(() => { if(datasetId !== undefined && cases === undefined) listCases(datasetId) }, [datasetId, cases === undefined, listCases])

  const datasetCases = cases?.filter(scan => scan.datasetId === datasetId) || []

  const [amsFileContent, setAmsFileContent] = useState<string | undefined>(undefined)

  const amsData: DatasetAmsData | undefined = useMemo(() => {
    if(amsFileContent !== undefined){
      const result = Papa.parse<string[]>(amsFileContent, {header: true})
      const header = Object.keys(result.data[0])
      const rows = result.data
      
      return {
        header,
        rows
      }
    } else {
      return undefined
    }
  }, [amsFileContent])



  return (
    <div style={{margin: '20px'}}>
      <DatasetSelector selectedDatasetId={datasetId} setSelectedDatasetId={setDatasetId} datasets={datasetsList} />

      { amsFileContent === undefined &&
      <AmsFileUpload setFileContent={setAmsFileContent} buttonSecondary={false} theme={theme} />
      }
      
      <div style={{marginTop: '20px'}}>
        { dataset !== undefined && amsData !== undefined &&
          <RTPCalculator 
            amsData={amsData} 
            cases={datasetCases} 
            caseInfos={dataset.aggregateInfo.caseInfos} 
          />              
        }        
      </div>

      { amsFileContent !== undefined &&
      <AmsFileUpload setFileContent={setAmsFileContent} buttonSecondary={true}  theme={theme} />
      }
    </div>    
  )
}


const mapStateToProps = (state: RootState) => {
  return  { 
    datasetsList: state.datasets.list,
    cases: state.scans.list,
    allDatasets: state.datasets.all,
    theme: state.user.features.theme
  }
}

const mapDispatchToProps = { 
  fetchDataset: (datasetId: string) => fetchDataset(datasetId, nanoid()),
  listCases: (datasetId: string) => listScans(nanoid(), datasetId),
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InjuryAnalyticsPage)
