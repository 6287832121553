import _ from 'lodash'

import { LoadingSpinner } from "app/components/LoadingSpinner"
import { Dataset, DatasetSeriesInfo } from "uploads/model/Dataset"
import { capLengthIfNeeded } from "utils/TextUtils"
import { InlineEdit } from 'app/components/InLineEdit'
import { CheckBox } from 'app/components/CheckBox'

export type DatasetSeriesInfoProps = {
  dataset: Dataset
  hasWriteAccess: boolean
  hasOwnerAccess: boolean
  toggleShouldAnnotate: (seriesDescription: string) => void
  setSeriesDisplayName: (seriesDescription: string, seriesDisplayName: string) => void
}

export const DatasetSeriesInfoWidget = ({
  dataset, hasWriteAccess, hasOwnerAccess,
  toggleShouldAnnotate, setSeriesDisplayName
}: DatasetSeriesInfoProps) => {

  if(dataset.seriesInfo === undefined || dataset.seriesInfo.length === 0) {
    return <></>
  }

  return (
    <div style={{marginTop: '20px'}}>
      <h2 style={{marginLeft:'10px'}} >Series</h2>
      
      <div style={{maxHeight: '650px', overflowY: 'auto'}}>
        <table className="table table-bordered table-striped">
          <thead>
            <tr>
              <th style={{ width: '300px' }} scope="col">Original Name</th>
              <th style={{ width: '250px' }} scope="col">Display Name</th>
              <th style={{ width: '65px' }}  scope="col"># Files</th>
              <th style={{ width: '80px' }}  scope="col">Annotate</th>
            </tr>
          </thead>
          <TableBody 
            dataset={dataset} 
            hasWriteAccess={hasWriteAccess}
            hasOwnerAccess={hasOwnerAccess}
            toggleShouldAnnotate={toggleShouldAnnotate} 
            setSeriesDisplayName={setSeriesDisplayName}
          />
        </table>   
      </div> 
                   
    </div>
  )
}

type TableBodyProps = {
  dataset: Dataset
  hasWriteAccess: boolean
  hasOwnerAccess: boolean
  toggleShouldAnnotate: (seriesDescription: string) => void 
  setSeriesDisplayName: (seriesDescription: string, seriesDisplayName: string) => void
} 

const TableBody = ({
  dataset, hasWriteAccess, hasOwnerAccess,
  toggleShouldAnnotate, setSeriesDisplayName
}: TableBodyProps) => {
  const rows = Object.entries(_.groupBy(dataset.seriesInfo, (s: DatasetSeriesInfo) => s.seriesDescription))

  return (
    <tbody>
    { _.orderBy(rows, row => row[0]).map(([seriesDescription, seriesInfo]) => 
        <tr key={seriesDescription}>
          <td 
            title={seriesDescription} 
            >{capLengthIfNeeded(seriesDescription, 40)}</td>
          <td>
            <InlineEdit 
              width={240} 
              value={dataset.datasetInfo.seriesDisplayNames[seriesDescription]} 
              setValue={(value) => setSeriesDisplayName(seriesDescription, value)} 
              disabled={!hasOwnerAccess}  
            />
          </td>
          <td>{seriesInfo.reduce((acc, i) => acc + i.filesCount, 0)}</td>
          <td style={{paddingLeft: '30px'}}>
            <CheckBox 
              dataCy={`toggle-annotation-${seriesDescription.replaceAll(' ', '_')}`}
              checked={dataset.datasetInfo.seriesDescriptionsForAnnotation.indexOf(seriesDescription) !== -1}
              onChange={() => toggleShouldAnnotate(seriesDescription)}
              disabled={!hasOwnerAccess}
            />
          </td>         
        </tr>     
    )}                                   
    </tbody>
  )
}