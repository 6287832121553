import Select from 'react-select'
import { useRef, useState } from "react"
import _ from 'lodash'

import { CocoExport } from "uploads/model/Dataset"
import { SelectOption } from "utils/SelectOption"
import { formatDate } from 'utils/DateUtils'

export type CocoExportsProps = {
  datasetId: string
  cocoExports: CocoExport[]
  fetchExports: () => void
  hasWriteAccess: boolean
  downloadCocoAnnotations: (datasetId: string, exportId: string) => Promise<void>
  generateCocoAnnotations: (datasetId: string) => Promise<string | undefined>
}

export const CocoExports = ({
  datasetId, cocoExports, hasWriteAccess,
  downloadCocoAnnotations, generateCocoAnnotations, fetchExports
}: CocoExportsProps) => {

  const orderedExports = _.orderBy(cocoExports, e => e.createdDate, 'desc')

  const [generatingId, setGeneratingId] = useState<string | undefined>(undefined)
  const generatingIdRef = useRef<string>()
  generatingIdRef.current = generatingId
  const [selectedExport, setSelectedExport] = useState<string | undefined>(orderedExports[0]?.exportId)

  const cocoExportsRef = useRef<CocoExport[]>()
  cocoExportsRef.current = cocoExports

  const pollExport = (timeout: number) => {
    if(generatingIdRef.current !== undefined) {
      if(cocoExportsRef.current?.find(e => e.exportId === generatingIdRef.current) === undefined) {
        fetchExports()
        setTimeout(() => pollExport(timeout + 2000), timeout)
      } else  {
        setSelectedExport(generatingIdRef.current)
        setGeneratingId(undefined)
      }
    }
  }

  const onGenerateCocoAnnotations = () => {
    generateCocoAnnotations(datasetId)
      .then((exportId) => {
        setGeneratingId(exportId)
        pollExport(1000)
      })
  }

  const options: SelectOption<string>[] = orderedExports.map((cocoExport) => ({
    value: cocoExport.exportId, 
    label: formatDate(cocoExport.createdDate) 
  }))
  
  const current = selectedExport === undefined ? undefined : { 
    value: selectedExport, 
    label: formatDate(cocoExports.find(e => e.exportId === selectedExport)?.createdDate) 
  }


  return (
    <div style={{marginTop: '20px'}}>
      <h2 style={{marginLeft:'10px'}} >COCO Exports</h2>

      <div style={{ width: '250px' }}>
        <Select 
          classNamePrefix="react-select-coco-export-selector"
          options={options} 
          placeholder="Select export" 
          value={current} 
          onChange={(e) => { if (e !== null){ setSelectedExport(e.value)}}}
        /> 
      </div>

      <button 
        style={{
          minWidth: '180px',
          cursor: 'pointer',
          margin: '10px'
        }}
        data-cy="download-coco-annotations"
        type="button" 
        disabled={selectedExport === undefined}
        onClick={() => {
          if(selectedExport !== undefined) downloadCocoAnnotations(datasetId, selectedExport)
        }}
        className={`btn btn-primary`}
      >
        Download export
      </button>  

      { hasWriteAccess && 
        <button 
          style={{
            minWidth: '180px',
            cursor: 'pointer',
            margin: '10px'
          }}
          data-cy="generate-coco-annotations"
          type="button" 
          disabled={generatingId !== undefined}
          onClick={onGenerateCocoAnnotations}
          className={`btn btn-primary`}
        >
          {generatingId === undefined ? 'Generate new export' : 'Generating...'} 
        </button>  
      }
    </div>

  )
}