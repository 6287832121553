import { Scan, ScanInfo } from "uploads/api/Model"
import { ImageExtractionStatus } from "cases/api/ScansSlice"
import { Dataset } from "uploads/model/Dataset"

export const setSeriesDisplayName: (
  scan: Scan,
  seriesId: string, 
  displayName: string,
  updateScan: (scanId: string, name: string, scanInfo: ScanInfo | undefined, studyDate: string | undefined) => void
) => void = (scan, seriesId, displayName, updateScan) => {
  const currentDisplayNames = scan.scanInfo.seriesDisplayNames

  const updatedDisplayNames = {
    ...currentDisplayNames,
    [seriesId]: displayName
  }

  updateScan(
    scan.scanId, 
    scan.name || '', 
    {        
      ...scan.scanInfo,
      seriesDisplayNames: updatedDisplayNames        
    },
    undefined)
}

export const getSeriesDisplayName: (
  seriesId: string,
  seriesDescription: string | undefined,
  scan: Scan | undefined,
  dataset: Dataset | undefined,
) => string | undefined = (seriesId, seriesDescription, scan, dataset) => {
  if(scan !== undefined && scan.scanInfo.seriesDisplayNames[seriesId] !== undefined) {
    return scan.scanInfo.seriesDisplayNames[seriesId]
  } 
    
  if(seriesDescription !== undefined && 
    dataset?.datasetInfo.seriesDisplayNames !== undefined && 
    dataset?.datasetInfo.seriesDisplayNames[seriesDescription] !== undefined) {
    return dataset.datasetInfo.seriesDisplayNames[seriesDescription]
  }

  return seriesDescription
}

export type SeriesDescriptionsMap = {
  [seriesId: string]: string
}

export const getSeriesDescriptions: (status: ImageExtractionStatus | undefined) => SeriesDescriptionsMap = (status) => {
  const seriesDescriptions = {}
  if(status !== undefined) {
    status.metadata.forEach((metadata) => {
      if(metadata.SeriesInstanceUID !== undefined && metadata.SeriesDescription !== undefined) {
        seriesDescriptions[metadata.SeriesInstanceUID] = metadata.SeriesDescription
      }
    })
  }
  return seriesDescriptions
}
