import html2canvas from 'html2canvas'

import { LabelledCheckBox } from "app/components/LabelledCheckBox"
import { Slider } from "app/components/Slider"
import { AnnotationLabelSelector } from "annotation/components/AnnotationLabelSelector"
import { AnnotationShapeType } from "annotation/model/AnnotationShape"
import { ModelOutput } from "uploads/api/Model"
import { DatasetAnnotationLabel } from "uploads/model/DatasetAnnotationLabel"
import { LabelledTextInput } from 'app/components/LabelledTextInput'
import { useState } from 'react'
import { AnnotationShapeSelector } from 'annotation/components/AnnotationShapeSelector'

export type SeriesControlPanelProps = {
  contrastPercentage: number
  intensityPercentage: number
  showModelOutputs: boolean
  currentAnnotationLabel: string | undefined
  annotateMode: boolean
  annotationLabels?: DatasetAnnotationLabel[]
  annotationShape: AnnotationShapeType | undefined
  hasWriteAccess: boolean
  modelOutputs?: ModelOutput[]
  selectedSeriesId?: string
  showDebugUi: boolean
  labelAllAnnotations: boolean
  toggleLabelAllAnnotations: () => void
  setContrastPercentage: (x: number) => void
  setIntensityPercentage: (x: number) => void
  toggleShowModelOutputs: () => void
  setCurrentAnnotationLabel: (label: string) => void
  setAnnotationShape: (annotationShape: AnnotationShapeType) => void
  saveReportImages: (formData: FormData) => void
}

export const SeriesControlPanel = ({
  contrastPercentage, intensityPercentage, showModelOutputs, annotateMode, currentAnnotationLabel,
  annotationLabels, annotationShape, hasWriteAccess, modelOutputs, selectedSeriesId, 
  showDebugUi, labelAllAnnotations,
  setContrastPercentage, setIntensityPercentage, toggleShowModelOutputs, setAnnotationShape,
  setCurrentAnnotationLabel, saveReportImages, toggleLabelAllAnnotations
}: SeriesControlPanelProps) => {

  const [imageTitle, setImageTitle] = useState<string>('')

  const handleAnnotationLabelClick = (label: string) => {
    setCurrentAnnotationLabel(label)

    const shape = annotationLabels?.find(l => l.label === label)?.shapeId    

    if(shape !== undefined) {
      setAnnotationShape(shape)
    }
  }

  const screenshot = () => {
    if(selectedSeriesId !== undefined) {
      const el = document.getElementById(`series-display-${selectedSeriesId}`)
      if(el !== null) {
        html2canvas(el).then(function(canvas) {
          canvas.toBlob((blob) => {
            if(blob !== null) {
              const formData = new FormData()
              formData.append('user-selected-image', blob, imageTitle)
              saveReportImages(formData)
            }
          })
        })
      }      
    }    
  }

  return (
    <div style={{ maxWidth: '250px', width: '250px', border: '1px solid lightgray', borderRadius: '10px', marginTop: '30px', marginRight: '20px' }}>
      <h5 style={{ fontSize: '1rem', margin: '10px' }}>Image controls</h5>

      <div style={{margin: '10px'}}>
        <Slider width="150px" label="Contrast" min={1} max={200} value={contrastPercentage} onChange={setContrastPercentage} />
        <Slider width="150px" label="Intensity" min={1} max={200} value={intensityPercentage} onChange={setIntensityPercentage} />
        <hr style={{borderTop: 'dotted 1px'}} />
        <LabelledCheckBox
          id="view-model-outputs"
          label={"View model outputs"}
          checked={showModelOutputs}
          disabled={modelOutputs === undefined || modelOutputs.length < 1}
          onChange={toggleShowModelOutputs}
        />
        { showDebugUi && selectedSeriesId !== undefined && hasWriteAccess &&
        <div>
          <hr style={{borderTop: 'dotted 1px'}} />
          <LabelledCheckBox
            id="highlight-all-annotations"
            label={"Label all annotations"}
            checked={labelAllAnnotations}
            onChange={toggleLabelAllAnnotations}
          />
          <button type="button" className="btn btn-primary" onClick={screenshot}>Add current image to report</button>
          <LabelledTextInput value={imageTitle} id={'user-defined-image-title'} label={'Title'} onChange={setImageTitle} />
          
        </div>
        }
      </div>
      { annotateMode &&
      <div style={{margin: '10px'}}>
        <hr style={{borderTop: 'dotted 1px'}} />
        <h5 style={{ fontSize: '0.8rem', margin: '10px' }}>Annotation selectors</h5>
        <AnnotationLabelSelector 
          currentLabel={currentAnnotationLabel} 
          labels={annotationLabels || []} 
          onClick={handleAnnotationLabelClick} />
        
        <AnnotationShapeSelector 
          annotationShape={annotationShape}
          disabled={!hasWriteAccess}
          setAnnotationShape={setAnnotationShape} />                       
      </div>      
      }
    </div>
  )
}
