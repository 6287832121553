import { createSlice } from '@reduxjs/toolkit'
import { DatasetApi } from 'datasets/api/DatasetApi'
import { errorToString } from 'utils/ErrorUtils'
import { PermissionLevel } from 'auth/model/PermissionLevel'
import { Dataset, DatasetInfo, DatasetListingEntry, DefaultDatasetInfo, initialDatasetModel, ListDatasetsResponse } from 'uploads/model/Dataset'
import { AnnotationsImport, AnnotationsImportValidationResult } from '../../uploads/api/Model'
import { Annotation } from 'annotation/model/Annotation'
import { AccessEntry } from 'auth/model/AccessEntry'
import { DatasetAnnotationLabel } from 'uploads/model/DatasetAnnotationLabel'
import { ServiceApi } from 'app/api/ErrorApi'

interface DatasetsState {
  list?: DatasetListingEntry[]
  all?: Dataset[]
  allImports: ImportsMap
  annotations: ImportedAnnotationsMap
  accessEntries: DatasetAccessEntriesMap
  newImports: ImportsMap
  validations: ValidationsMap
  error?: string
}

export type ImportsMap = {
  [datasetId: string]: AnnotationsImport[]
}

export type ImportedAnnotationsMap = {
  [datasetId: string]: Annotation[]
}

export type ValidationsMap = {
  [datasetId: string]: ValidationResultsMap
}
export type ValidationResultsMap = {
  [fileName: string]: AnnotationsImportValidationResult
}
export type DatasetAccessEntriesMap = {
  [datasetId: string]: AccessEntry[]
}


const initialState: DatasetsState = {
  list: undefined,
  all: undefined,
  allImports: {},
  annotations: {},
  accessEntries: {},
  newImports: {},
  validations: {},
  error: undefined
}

const clearError: (state: DatasetsState) => void = (state) => {
  if(window.RuntimeConfig.frontend.autoClearErrors){
    state.error = undefined
  }  
}

const datasetsSlice = createSlice({
  name: 'datasets',
  initialState,
  reducers: {
    listDatasetsSuccess(state, { payload }) {            
      state.list = payload.datasets
      clearError(state)
    },
    fetchDatasetSuccess(state, { payload }) {          
      const dataset: Dataset = payload.dataset  
      state.all = [
        ...(state.all || []).filter(existing => existing.datasetId !== dataset.datasetId),
        dataset
      ]
      clearError(state)
    },
    fetchAccessPermissionsSuccess(state, { payload }) {          
      const {datasetId, entries} = payload
      
      state.accessEntries[datasetId] = entries

      clearError(state)   
    },
    listImportedAnnotationsSuccess(state, { payload }) {          
      const {datasetId, annotationImports, annotations} = payload      

      state.allImports[datasetId] = annotationImports
      state.annotations[datasetId] = annotations      
      clearError(state)
    },
    importedAnnotationsSuccess(state, { payload }) {          
      const {datasetId, annotationImports, annotations} = payload      

      state.newImports[datasetId] = annotationImports
      
      const existing = state.annotations[datasetId] || []

      state.annotations[datasetId] = [
        ...existing,
        ...annotations      
      ]
      clearError(state)
    },
    importedAnnotationsDeleted(state, { payload }) {
      const { datasetId } = payload

      state.annotations[datasetId] = []
      delete state.newImports[datasetId]          
      delete state.allImports[datasetId]
      clearError(state)
    },
    annotationsValidated(state, { payload }) {          
      const { datasetId, fileName, result } = payload

      const validations = state.validations[datasetId]
      
      if(validations === undefined){
        state.validations[datasetId] = {}
      }

      state.validations[datasetId][fileName] = result
      
      clearError(state)  
    },
    datasetsFailure(state, { payload }) {
      state.error = payload
    },
    errorDismissed(state) {
      state.error = undefined
    }
  }
})

const {
  listDatasetsSuccess, 
  fetchDatasetSuccess,
  fetchAccessPermissionsSuccess,
  importedAnnotationsSuccess,
  listImportedAnnotationsSuccess,
  importedAnnotationsDeleted,
  annotationsValidated,
  datasetsFailure,
  errorDismissed
} = datasetsSlice.actions

export default datasetsSlice.reducer

const datasetsApi = new DatasetApi(window.RuntimeConfig.backend)
const errorApi = new ServiceApi(window.RuntimeConfig.backend)

export const listDatasets = (correlationId: string) => async dispatch => {
  try {
    const response: ListDatasetsResponse = await datasetsApi.listDatasets(correlationId)    

    dispatch(listDatasetsSuccess(response))
  } catch (err) {    
    dispatch(reportError('listDatasets', err, correlationId))
  }
}

export const fetchDatasetAccessPermissions = (datasetId: string, correlationId: string) => async dispatch => {
  try {
    const response = await datasetsApi.fetchAccessPermissions(datasetId, correlationId)    

    dispatch(fetchAccessPermissionsSuccess({ entries: response.entries, datasetId }))
  } catch (err) {    
    dispatch(reportError('fetchDatasetAccessPermissions', err, correlationId))
  }
}

export const updateDatasetAccessPermissions = (datasetId: string, userId: string, accessLevel: PermissionLevel, refresh: boolean, correlationId: string) => async dispatch => {
  try {
    await datasetsApi.updateAccessPermissions(datasetId, userId, accessLevel, correlationId)    

    if(refresh){
      dispatch(fetchDatasetAccessPermissions(datasetId, correlationId))
    }    
  } catch (err) {    
    dispatch(reportError('updateDatasetAccessPermissions', err, correlationId))
  }
}

export const createDataset = (datasetId: string, name: string, userId: string | undefined, correlationId: string) => async dispatch => {
  try {    
    dispatch(fetchDatasetSuccess({dataset: initialDatasetModel(datasetId, name, userId)}))
    dispatch(fetchAccessPermissionsSuccess({entries: [{ userId, accessLevel: PermissionLevel.share}], datasetId}))
    const dataset: Dataset = await datasetsApi.createDataset(datasetId, name, DefaultDatasetInfo, correlationId)
    dispatch(fetchDatasetSuccess({dataset}))
  } catch (err) {
    dispatch(reportError('createDataset', err, correlationId))
  }
}

export const fetchDataset = (datasetId: string, correlationId: string) => async dispatch => {
  try {    
    const dataset: Dataset = await datasetsApi.fetchDataset(datasetId, correlationId)
    dispatch(fetchDatasetSuccess({dataset}))
  } catch (err) {
    dispatch(reportError('fetchDataset', err, correlationId))
  }
}

export const updateDataset = (datasetId: string, name: string, datasetInfo: DatasetInfo, correlationId: string) => async dispatch => {
  try {    
    await datasetsApi.updateDataset(datasetId, name, datasetInfo, correlationId)
    await dispatch(fetchDataset(datasetId, correlationId))
  } catch (err) {
    dispatch(reportError('updateDataset', err, correlationId))
  }
}

export const updateAnnotationLabel = (datasetId: string, label: DatasetAnnotationLabel, correlationId: string) => async dispatch => {
  try {    
    await datasetsApi.updateAnnotationLabel(datasetId, label, correlationId)
    await dispatch(fetchDataset(datasetId, correlationId))
  } catch (err) {
    dispatch(reportError('updateAnnotationLabel', err, correlationId))
  }
}

export const deleteAnnotationLabel = (datasetId: string, labelId: string, correlationId: string) => async dispatch => {
  try {    
    await datasetsApi.deleteAnnotationLabel(datasetId, labelId, correlationId)
    await dispatch(fetchDataset(datasetId, correlationId))
  } catch (err) {
    dispatch(reportError('deleteAnnotationLabel', err, correlationId))
  }
}

export const downloadCocoAnnotations = (datasetId: string, exportId: string, correlationId: string) => async dispatch => {
  try {
    window.open(datasetsApi.exportUrl(datasetId, exportId), '_blank')
  } catch (err) {
    dispatch(reportError('downloadCocoAnnotations', err, correlationId))
  }
}

export const generateCocoAnnotations = (datasetId: string,  correlationId: string) => async dispatch => {
  try {    
    const response = await datasetsApi.generateCocoAnnotations(datasetId, correlationId)
    return response.exportId
  } catch (err) {
    dispatch(reportError('generateCocoAnnotations', err, correlationId))
  }
}

export const deleteDatasetCase = (datasetId: string, caseId: string, correlationId: string) => async dispatch => {
  try {    
    await datasetsApi.deleteDatasetCase(datasetId, caseId, correlationId)
    await dispatch(fetchDataset(datasetId, correlationId))
  } catch (err) {
    dispatch(reportError('deleteDatasetCase', err, correlationId))
  }
}

export const uploadFile = (datasetId: string, formData: FormData, correlationId: string) => async dispatch => {
  try {
    await datasetsApi.uploadFile(datasetId, formData, correlationId)
  } catch (err) {
    dispatch(reportError('uploadFile', err, correlationId))
  }  
}

export const listImportedAnnotations = (datasetId: string, correlationId: string) => async dispatch => {
  try {    
    const response = await datasetsApi.listImportedAnnotations(datasetId, correlationId)    
    dispatch(listImportedAnnotationsSuccess({
      datasetId,
      annotationImports: response.imports,
      annotations: response.annotations
    }))
  } catch (err) {
    dispatch(reportError('listImportedAnnotations', err, correlationId))
  }
}

export const validateImport = (datasetId: string, fileName: string, json: any, correlationId: string) => async dispatch => {
  try {    
    const response = await datasetsApi.validateAnnotationsImport(datasetId, json, correlationId)
    dispatch(annotationsValidated({
      datasetId,
      fileName,
      result: response
    }))
  } catch (err) {
    dispatch(reportError('validateImport', err, correlationId))
  }
}

export const importAnnotations = (datasetId: string, formData: FormData, correlationId: string) => async dispatch => {
  try {    
    const response = await datasetsApi.importAnnotations(datasetId, formData, correlationId)
    dispatch(importedAnnotationsSuccess({
      datasetId,
      annotationImports: response.imports,
      annotations: response.annotations
    }))
  } catch (err) {
    dispatch(reportError('importAnnotations', err, correlationId))
  }
}

export const deleteImportedAnnotations = (datasetId: string, importId: string, correlationId: string) => async dispatch => {
  try {
    await datasetsApi.deleteImportedAnnotations(datasetId, importId, correlationId)
    dispatch(importedAnnotationsDeleted({ datasetId }))
    await dispatch(listImportedAnnotations(datasetId, correlationId))
  } catch (err) {
    dispatch(reportError('deleteImportedAnnotations', err, correlationId))
  }
}

export const deleteModelAnnotations = (datasetId: string, correlationId: string) => async dispatch => {
  try {
    await datasetsApi.deleteModelAnnotations(datasetId, correlationId)
  } catch (err) {
    dispatch(reportError('deleteModelAnnotations', err, correlationId))
  }
}

export const deleteModelForms = (datasetId: string, correlationId: string) => async dispatch => {
  try {
    await datasetsApi.deleteModelForms(datasetId, correlationId)
  } catch (err) {
    dispatch(reportError('deleteModelForms', err, correlationId))
  }
}

export const runAllModels = (datasetId: string, correlationId: string) => async dispatch => {
  try {
    await datasetsApi.runAllModels(datasetId, correlationId)
  } catch (err) {
    dispatch(reportError('runAllModels', err, correlationId))
  }
}

export const reprocessUploads = (datasetId: string, correlationId: string) => async dispatch => {
  try {
    await datasetsApi.reprocessUploads(datasetId, correlationId)
  } catch (err) {
    dispatch(reportError('runAllModels', err, correlationId))
  }
}


export const reportError = (method: string, err: any, correlationId: string) => async dispatch => {  
  try {
    console.log({method: err})
    const message = errorToString(err)
    dispatch(datasetsFailure(message))
    await errorApi.reportError(method, message, correlationId)
  } catch (err2) {
    console.log({reportErrorFailure: err2})
  }
}

export const clearDatasetError = () => async dispatch => {
  dispatch(errorDismissed())
}
