import React, { useEffect } from 'react'
import _ from 'lodash'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import { RootState } from 'app/store'

import { RecoveryStatus, ScanListingEntry } from 'uploads/api/Model'
import { DatasetAggregateCaseInfo, DatasetListingEntry } from 'uploads/model/Dataset'
import { nanoid } from 'nanoid'
import { listScans } from 'cases/api/ScansSlice'
import { capLengthIfNeeded } from 'utils/TextUtils'
import { ReportsApi } from 'reports/api/ReportsApi'
import { downloadReportOutput } from 'reports/api/ReportsSlice'
import { AnnotationMode } from 'uploads/model/AnnotationMode'
import { listDatasets } from 'datasets/api/DatasetSlice'

export type DashboardPageProps = {
  datasets?: DatasetListingEntry[] 
  cases?: ScanListingEntry[]
  showExperimentalUi: boolean
  currentUserId: string
  fetchCases: () => void
  downloadReportOutput: (reportId: string, outputId: string) => void
  listDatasets: () => void
}

export const DashboardPage = ({
  datasets, cases, showExperimentalUi,
  fetchCases, downloadReportOutput, listDatasets
}: DashboardPageProps) => {

  useEffect(() => { fetchCases() }, [fetchCases])
  useEffect(() => { if(datasets === undefined) { listDatasets() } }, [fetchCases])

  const datasetsCount = datasets?.length || 0

  return(
    <div style={{margin: '20px'}}>
      {datasets?.map(dataset =>
        <DatasetView 
          key={dataset.datasetId} 
          dataset={dataset} 
          datasetsCount={datasetsCount}
          cases={cases?.filter(c => c.datasetId === dataset.datasetId)} 
          showExperimentalUi={showExperimentalUi}
          downloadReportOutput={downloadReportOutput}
        />  
      )}
    </div>
  )
}

type DatasetViewProps = {
  dataset: DatasetListingEntry
  cases?: ScanListingEntry[]
  showExperimentalUi: boolean
  datasetsCount: number
  downloadReportOutput: (reportId: string, outputId: string) => void
}

const DatasetView = ({
  dataset, cases, showExperimentalUi, datasetsCount,
  downloadReportOutput
}: DatasetViewProps) => {

  const patientsCount = [...new Set(cases?.map(c => c.scanInfo?.patientId))].filter(i => i !== undefined).length

  // const activeCases = cases?.filter(c => c.scanInfo?.recoveryStatus !== RecoveryStatus.Recovered)

  if(cases === undefined || cases.length === 0) {
    return <></>
  }

  const groupByPatient = patientsCount > 0

  return(
    <div style={{ marginBottom: '50px' }}>
      { datasetsCount !== 1 &&
      <h3 style={{textDecoration: 'underline', marginBottom: '10px' }} >
        <Link to={`/datasets/${dataset.datasetId}`} style={{ color: 'black' }}>{dataset.name}</Link>
      </h3>      
      }
      <div style={{ marginLeft: '10px', maxWidth: '820px'}}>
        
        <h5>
          { groupByPatient ? 
          `${patientsCount} ${cases.length === 1 ? 'Player' : 'Players'}, ${cases.length} ${cases.length === 1 ? 'Scans' : 'Scans'}`
          : 
          `${cases.length} ${cases.length === 1 ? 'Scan' : 'Scans'}`           
          }
        </h5>
        <DatasetPatientsTable 
          dataset={dataset} 
          scans={cases || []}
          showExperimentalUi={showExperimentalUi}
          groupByPatient={groupByPatient}
          downloadReportOutput={downloadReportOutput}
        />                                   
      </div>
      
    </div>
  )
}

type CaseRowProps = {
  scan: ScanListingEntry
  datasetCaseInfo?: DatasetAggregateCaseInfo
  showPlayerId: boolean
  groupByPatient: boolean
  downloadReportOutput: (reportId: string, outputId: string) => void
}


const CaseRow = ({
  scan, datasetCaseInfo, showPlayerId, groupByPatient,
  downloadReportOutput
}: CaseRowProps) => {

  const reportsApi = new ReportsApi(window.RuntimeConfig.backend)

  const report = _.orderBy(scan.reportOutputs, o => o.createdDate, 'desc')[0]

  const onDownloadClick = (e) => {
    e.preventDefault()
    if(report !== undefined) {
      downloadReportOutput(report.reportId, report.outputId)
    }    
  }

  const classifications = datasetCaseInfo?.classifications.map((classification) => {
    if(classification === 'intact'){
      return 'Intact'
    } else if(classification === 'torn') {
      return 'Torn'
    } else {
      return classification
    }
  })

  return (
    <tr >
      { groupByPatient &&
      <td>{showPlayerId ? scan.scanInfo?.patientId: ''}</td>
      }
      <td title={scan.name} ><Link to={`/cases/${scan.scanId}`} >{capLengthIfNeeded(scan.name || 'unnamed', 34)}</Link></td>   

      { report !== undefined && 
        <td><a href={reportsApi.reportOutputUrl(report.reportId, report.outputId)} onClick={onDownloadClick} >Available</a></td>              
      } 
      { report === undefined &&
        <td>Pending</td>              
      } 
      <td>{classifications?.join(', ')}</td>    
    </tr>
  )
}

const mapStateToProps = (state: RootState) => {
  const datasets = state.datasets.list

  const caseIds = datasets?.flatMap(d => d.cases) || []
  const cases = state.scans.list?.filter(c => caseIds.includes(c.scanId))
  
  return  { 
    cases,
    datasets,
    showExperimentalUi: state.user.features.showExperimentalUi
  }
}

const mapDispatchToProps = { 
  fetchCases: () => listScans(nanoid(), undefined),
  listDatasets: () => listDatasets(nanoid()),
  downloadReportOutput
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DashboardPage)


export type DatasetPatientsTableProps = {
  dataset: DatasetListingEntry
  scans: ScanListingEntry[]
  showExperimentalUi: boolean
  groupByPatient: boolean
  downloadReportOutput: (reportId: string, outputId: string) => void
}

export const DatasetPatientsTable = ({
  dataset, scans, showExperimentalUi, groupByPatient,
  downloadReportOutput
}: DatasetPatientsTableProps) => { 

  const grouped = _.groupBy(scans, scan => scan.scanInfo?.patientId)

  const patientIds: string[] = _.orderBy([... new Set(scans.map(s => s.scanInfo?.patientId).filter(i => i !== undefined))], i => i) as string[]

  return(
    <table className="table table-bordered table-striped" style={{maxWidth: '800px'}}>
      <thead>
        <tr>
          {groupByPatient &&
          <th style={{ width: '100px' }} scope="col">Player ID</th>    
          }
          <th style={{ width: '250px' }} scope="col">Case Name</th>
          <th style={{ width: '150px' }} scope="col">Report</th>
          <th style={{ width: '130px' }} scope="col">{dataset.datasetInfo?.annotationMode === AnnotationMode.ACL ? 'ACL Impression' : 'BAMIC Grade' }</th>                       
        </tr>
      </thead>
      <tbody>
        { groupByPatient ? 
        <>
         { patientIds.map(patientId => {
          return (
            <>            
            { grouped[patientId]?.map((scan, index) =>
              <CaseRow 
                scan={scan} 
                downloadReportOutput={downloadReportOutput} 
                datasetCaseInfo={dataset.aggregateInfo?.caseInfos.find(c => c.caseId == scan.scanId)} 
                showPlayerId={groupByPatient && index === 0}
                groupByPatient={groupByPatient}
              />  
            )}
            </>
          )
          }
        )}
        </>
        : 
        <>
        { scans.map (scan =>
          <CaseRow 
            scan={scan} 
            downloadReportOutput={downloadReportOutput} 
            datasetCaseInfo={dataset.aggregateInfo?.caseInfos.find(c => c.caseId == scan.scanId)} 
            showPlayerId={groupByPatient}
            groupByPatient={groupByPatient}
          />  
        )}
        </>
        }
      </tbody>
    </table>    
  )
}
