import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { nanoid } from 'nanoid'
import { useNavigate } from "react-router-dom"

import { listDatasets, createDataset, updateDatasetAccessPermissions } from 'datasets/api/DatasetSlice'
import { RootState } from 'app/store'
import { DatasetListingEntry } from 'uploads/model/Dataset'
import { User } from 'auth/model/User'
import { UserFeatures } from 'auth/UserSlice'
import { PermissionLevel } from 'auth/model/PermissionLevel'
import { DatasetCreator } from './components/DatasetCreator'
import { DatasetsTable } from './components/DatasetsTable'

export type DatasetsPageProps = {
  currentUserId: string
  datasets?: DatasetListingEntry[]
  users?: User[]
  features: UserFeatures
  listDatasets: () => void
  createDataset: (datasetId: string, name: string, currentUserId?: string) => void
  updateAccessPermissions: (datasetId: string, userId: string, level: PermissionLevel, refresh: boolean) => Promise<void>
}

const DatasetsPage = ({
  listDatasets, createDataset, updateAccessPermissions,
  datasets, currentUserId, users, features
}: DatasetsPageProps) => {
  
  
  const navigate = useNavigate()
  const navigateToDataset: (datasetId: string) => void = (datasetId) => navigate(`/datasets/${datasetId}`)

  return (
    <div style={{margin: '20px'}}>
      <DatasetCreator createDataset={(datasetId, name) => createDataset(datasetId, name, currentUserId) } navigateToDataset={navigateToDataset} />
      <DatasetsTable 
        titleText="" 
        datasets={datasets} 
        users={users} 
        showRemoveSelf={features.removeSelfPermission} 
        updateAccessPermissions={updateAccessPermissions}
        listDatasets={listDatasets}
        currentUserId={currentUserId}
      />       
    </div>
  )
}

const mapStateToProps = (state: RootState) => { 
  return  { 
    datasets: state.datasets.list,
    users: state.user.users,
    features: state.user.features
  }
}

const mapDispatchToProps = { 
  listDatasets: () => listDatasets(nanoid()),
  createDataset: (datasetId: string, name: string, currentUserId?: string) => 
    createDataset(datasetId, name, currentUserId, nanoid()),
  updateAccessPermissions: (datasetId: string, userId: string, level: PermissionLevel, refresh: boolean) => 
    updateDatasetAccessPermissions(datasetId, userId, level, refresh, nanoid()),

}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DatasetsPage)
