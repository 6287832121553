import { LeftArrow, RightArrow } from "app/components/icons/Arrows"
import { useRef } from "react"

export type ThumbnailScrollbarProps = {
  imageUrls: string[]
  size: number
  displayIndex: number
  width: number
  contrastPercentage: number
  intensityPercentage: number
  setDisplayIndex: (index: number) => void
}


export const ThumbnailScrollbar = ({
  imageUrls, size, displayIndex, width, contrastPercentage, intensityPercentage,
  setDisplayIndex
}: ThumbnailScrollbarProps) => {

  const displayIndexRef = useRef<number>(displayIndex)  
  displayIndexRef.current = displayIndex


  const displayCount = Math.floor(width / size) - 2
  const halfDisplayCount = Math.floor(displayCount / 2)

  const startIndex: number = (() => {
    if(imageUrls.length > displayCount) {
      if(displayIndex < halfDisplayCount)  {
        return 0
      } else if(displayIndex >= (imageUrls.length - halfDisplayCount)) {
        return imageUrls.length - displayCount
      } else {
        return displayIndex - halfDisplayCount
      }
    } else {
      return 0
    }
  })()

  
  const sliceStart = startIndex 
  const sliceEnd = startIndex + displayCount
  const sliceStartRef = useRef<number>()  
  sliceStartRef.current = sliceStart
  const sliceEndRef = useRef<number>()  
  sliceEndRef.current = sliceEnd

  const toDisplay = imageUrls.slice(sliceStart, sliceEnd)

  return (
    <div 
      style={{
        display: 'flex', 
        maxHeight: `${size}px`,
        overflowY: 'hidden',
        overflowX: 'hidden',
        userSelect: 'none'
      }}
    >
      { imageUrls.length > displayCount &&
        <LeftArrow size={size} onClick={() => {
          if(displayIndexRef.current > 0) {
            setDisplayIndex(displayIndexRef.current - 1)
          }
        }} />
      }
      { toDisplay.map((imageUrl, index) =>
        <img 
          key={imageUrl}
          src={imageUrl}
          alt="thumbnail"
          style={{
            display: 'block', 
            width:`${size}px`,
            height: `${size}px`,
            border: (startIndex + index) === displayIndex ? `1px solid red` : '1px solid black',
            cursor: 'pointer',
            filter: `contrast(${contrastPercentage}%)  brightness(${intensityPercentage}%)`,
          }}
          onClick={() => {
            setDisplayIndex(startIndex + index)
          }}
        />
      )}
      { imageUrls.length > displayCount &&
        <RightArrow size={size} onClick={() => {
          if(displayIndexRef.current < (imageUrls.length - 1)) {
            setDisplayIndex(displayIndexRef.current + 1)
          } 
        }} />
      }
    </div>
  )
}
