import _ from 'lodash'
import { connect } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'

import { RootState } from 'app/store'
import { ScanCreator } from 'uploads/component/ScanCreator'
import { createScan } from 'cases/api/ScansSlice'
import { nanoid } from 'nanoid'
import { Theme } from './model/Theme'
import { UserFeatures } from 'auth/UserSlice'


export type HomePageProps = {
  features: UserFeatures
  currentUserId: string
  theme: Theme
  createScan: (scanId: string, currentUserId: string) => void
}

export const HomePage = ({
  features, currentUserId, theme,
  createScan
}: HomePageProps) => {

  const navigate = useNavigate()
  const navigateToScan: (scanId: string) => void = (scanId) => {
    navigate(`/cases/${scanId}`)
  }

  return( 
    <div style={{
      height: '100%', 
      width: '100%',
      backgroundImage: 'url("/Legs1.png")',
      backgroundSize: 'cover',
    }}>
      <div style={{
        color: 'white', 
        height: '100%', 
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        paddingTop: '50px',         
        backgroundColor: theme.darkBackground,
      }}>
        <div style={{
          display: 'flex', 
        }}>
          <div style={{width: '550px', marginLeft: '50px', marginTop: '50px'}}>
            <div style={{fontSize: '20pt'}}>Welcome to</div>
            <div style={{fontSize: '55pt'}}>Elite AI</div>
            <div style={{fontSize: '15pt'}}>Muscle injury and return-to-play analysis for elite athletes</div>
          </div>
          <div style={{
            width: '300px',
            height: '400px',
            marginLeft: '50px',
            paddingBottom: '100px',
            backgroundColor: theme.lightBackground,
            borderRadius: '10px',
            textAlign: 'center',
            justifyContent: 'centre'
          }}>
            <div style={{ marginTop: '100px', fontSize: '20pt', margin: '50px'}}>
              Quick Actions
            </div>
            <div style={{ margin: '25px', display: 'flex', justifyContent: 'center' }}>
              <ScanCreator 
                createScan={(scanId) => createScan(scanId, currentUserId)}
                navigateToScan={navigateToScan} 
                color={theme.buttonColor1} 
                border={theme.buttonBorder1}             
              />            
            </div>
            <div style={{ margin: '25px' }}>
              <Link 
                to={'/dashboard'}
                style={{
                  padding: '8px',
                  borderRadius: '10px',
                  backgroundColor: theme.buttonColor2,
                  border: theme.buttonBorder2,
                  color: 'white',
                  textDecoration: 'none',
                  boxShadow: `0px 5px 7px 0px ${theme.buttonShadowColor}`
                }}
              >Review Current Cases</Link>
            </div>
            { features.injuryAnalytics &&
            <div style={{ margin: '5px', marginTop: '33px' }}>
              <Link
                to={'/injury-analytics'}
                style={{
                  padding: '8px',
                  borderRadius: '10px',
                  backgroundColor: theme.buttonColor3,
                  border: theme.buttonBorder3,
                  color: 'white',
                  textDecoration: 'none',
                  boxShadow: `0px 5px 7px 0px ${theme.buttonShadowColor}`
                }}
              >Injury Analytics</Link>   
            </div>
            }
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state: RootState) => {

  return  { 
    features: state.user.features,
    theme: state.user.features.theme
  }
}

const mapDispatchToProps = { 
  createScan: (scanId: string, currentUserId: string) => createScan(scanId, currentUserId, nanoid()),
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HomePage)
