export type DirectoryUploadProgressProps = {
  filesUploadedCount: number
  filesToUploadCount: number
}

export const DirectoryUploadProgress = ({
  filesToUploadCount, filesUploadedCount
}: DirectoryUploadProgressProps) => {
  const text = `${filesUploadedCount} of ${filesToUploadCount} files uploaded`
  return (
    <div>
      <label 
        htmlFor="directory-upload-progress"
        style={{
          display: 'block', 
          width: '400px', 
          marginLeft: '10px'                
        }}
      >
        <progress 
          id="directory-upload-progress" 
          title={text}
          style={{
            marginLeft: '5px', 
            marginRight: '5px',                 
            verticalAlign: 'middle', 
            position: 'relative', 
            bottom: '1px', 
            width: '150px'
          }}
          max={filesToUploadCount} 
          value={filesUploadedCount}
        >text</progress>
        {text}   
      </label>            
    </div>
  )
}