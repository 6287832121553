import axios from 'axios'
import retry from 'async-retry'

import { currentAuthHeaders } from 'auth/KeyCloak' 
import { BackendConfig } from 'config/ApplicationConfig'

export class ServiceApi  {

  errorsUrl: string
  logLevelUrl: string
  retryCount: number

  constructor(config: BackendConfig) {
    this.retryCount = config.retryCount
    this.errorsUrl = `${config.url}/eliteai/errors`
    this.logLevelUrl = `${config.url}/eliteai/logging/level`
  }

  async reportError(action: string, message: string, correlationId: string) {
    if(window.RuntimeConfig.frontend.reportErrors) {
      const headers = await currentAuthHeaders(correlationId)

      return retry(async (bail) => {    
        await axios.post<void>(this.errorsUrl, { action, message }, { headers })
      }, {
        retries: this.retryCount
      })    
    } else {
      console.log(`Error reporting disabled\naction: ${action}\nmessage: ${message}\ncorrelationId: ${correlationId}`)
    }
  }

  async updateLogLevel(level: string, correlationId: string) {
    const headers = await currentAuthHeaders(correlationId)

    return retry(async (bail) => {    
      await axios.post<void>(this.logLevelUrl, { level }, { headers })
    }, {
      retries: this.retryCount
    })        
  }
}
