import { DatasetAnnotationLabel } from "uploads/model/DatasetAnnotationLabel"
import { Axial, Coronal, Sagittal } from "uploads/component/PlaneSelector"

export const getAnnotationLabels: (
  seriesDisplayName: string | undefined,
  annotationLabels: DatasetAnnotationLabel[] | undefined
) => DatasetAnnotationLabel[] = (seriesDisplayName, annotationLabels) => {

  if(seriesDisplayName === undefined) {
    return annotationLabels || []
  }

  return (annotationLabels || []).reduce ( (acc: DatasetAnnotationLabel[], label: DatasetAnnotationLabel) => {
    if(label.plane === undefined || label.plane.trim() === ""){
      acc.push(label)
    } else {
      const planes = label.plane.split(',')

      if(planes.some(plane => seriesDisplayName.toLowerCase().includes(plane.toLowerCase()))) {
        acc.push(label)
      } else if(planes.includes(Axial) && seriesDisplayName.toLowerCase().indexOf('ax') !== -1) {
        acc.push(label)
      } else if(planes.includes(Coronal) && seriesDisplayName.toLowerCase().indexOf('cor') !== -1) {
        acc.push(label)
      } else if(planes.includes(Sagittal) && seriesDisplayName.toLowerCase().indexOf('sag') !== -1) {
        acc.push(label)
      }
    }
    return acc
  }, [])
}
