import { createSlice } from '@reduxjs/toolkit'
import { Annotation, AnnotationApprovalStatus } from 'annotation/model/Annotation'
import { ExtractedImageMetaData, ImagesForScanResponse, ListScansResponse, newScan, Scan, ScanInfo, ScanListingEntry } from '../../uploads/api/Model'
import { ScansApi } from './ScansApi'
import { v4 } from 'uuid'
import { errorToString } from 'utils/ErrorUtils'
import { ClassificationForm } from 'annotation/model/ClassificationForm'
import { FormValues } from 'uploads/component/HamstringForm'
import { PermissionLevel } from 'auth/model/PermissionLevel'
import { AccessEntry } from 'auth/model/AccessEntry'
import { ReportInfoStatus } from 'uploads/model/ReportInfoStatus'
import { ServiceApi } from 'app/api/ErrorApi'

interface ScansState {
  list?: ScanListingEntry[]
  all?: Scan[]
  annotations: AnnotationsMap
  accessEntries: ScanAccessEntriesMap
  forms: ClassificationFormMap
  reportInfoStatuses: ReportInfoStatusMap
  imagesMetadata: ImagesMap
  error?: string
  deletedAnnotation?: { scanId: string, annotationId: string}
}

export type AnnotationsMap = {
  [scanId: string]: Annotation[]
}

export type ClassificationFormMap = {
  [scanId: string]: ClassificationForm[]
}
export type ScanAccessEntriesMap = {
  [scanId: string]: AccessEntry[]
}
export type ReportInfoStatusMap = {
  [scanId: string]: ReportInfoStatus
}

export type ImagesMap = {
  [scanId: string]: ImageExtractionStatus
}

export type ImageExtractionStatus = {
  metadata: ExtractedImageMetaData[]
  status: string
}

const initialState: ScansState = {
  list: undefined,
  all: undefined,
  annotations: {}, 
  accessEntries: {},
  forms: {},
  imagesMetadata: {},
  reportInfoStatuses: {},
  error: undefined,
  deletedAnnotation: undefined
}

const clearError: (state: ScansState) => void = (state) => {
  if(window.RuntimeConfig.frontend.autoClearErrors){
    state.error = undefined
  }  
}

const scansSlice = createSlice({
  name: 'scans',
  initialState,
  reducers: {
    listScansSuccess(state, { payload }) {            
      state.list = payload.scans

      clearError(state)           
    },
    createScanSuccess(state, { payload }) {          
      const { scan } = payload  
      state.all = [
        ...(state.all || []).filter(existing => existing.scanId !== scan.scanId),
        scan
      ]      
      state.list = [
        ...(state.list || []).filter(existing => existing.scanId !== scan.scanId),
        scan
      ]      
      state.annotations[scan.scanId] = []
      state.forms[scan.scanId] = []
      state.imagesMetadata[scan.scanId] = {
        metadata: [],
        status: 'complete'
      }
      clearError(state)               
    },
    fetchScanSuccess(state, { payload }) {          
      const scan = payload  
      state.all = [
        ...(state.all || []).filter(existing => existing.scanId !== scan.scanId),
        scan
      ]
      clearError(state)     
    },
    fetchAnnotationsSuccess(state, { payload }) {          
      const annotations: Annotation[] = payload.annotations
      const scanId: string = payload.scanId

      state.annotations[scanId] = annotations          
      
      clearError(state)     
    },
    fetchClassificationFormsSuccess(state, { payload }) {          
      const forms: ClassificationForm[] = payload.forms
      const scanId: string = payload.scanId

      state.forms[scanId] = forms          
      
      clearError(state)     
    },
    scansFailure(state, { payload }) {
      state.error = payload
    },
    errorDismissed(state) {
      state.error = undefined
    },
    annotationDeleted(state, { payload }) {
      state.deletedAnnotation = {
        scanId: payload.scanId,
        annotationId: payload.annotationId
      }

      state.annotations[payload.scanId] = state.annotations[payload.scanId]?.filter(a => a.annotationId !== payload.annotationId)
      clearError(state)     
    },
    specificAnnotationDeletedDismissed(state, { payload }) {
      if(state.deletedAnnotation !== undefined && 
        state.deletedAnnotation.annotationId === payload.annotationId && 
        state.deletedAnnotation.scanId === payload.scanId) {
          state.deletedAnnotation = undefined
      }      
      clearError(state)     
    },
    annotationDeletedDismissed(state) {
      state.deletedAnnotation = undefined          
      clearError(state)     
    },
    listImagesForScanSuccess(state, { payload }) {       
      const response: ImagesForScanResponse = payload.response 
      const scanId: string = payload.scanId    

      state.imagesMetadata[scanId] = {
        metadata: response.metadata.flatMap(metadata => metadata.images.map(i => {
          return {
            ...i,
            uploadId: metadata.extraction.uploadId,
            status: metadata.extraction.status,
            width: metadata.extraction.width || i.width,
            height: metadata.extraction.height || i.height
          }
        })).filter(i => i.imageFormat === 'png'),
        status: response.status
      }      
      
      clearError(state)     
    },
    fetchAccessPermissionsSuccess(state, { payload }) {          
      const {caseId, entries} = payload
      
      state.accessEntries[caseId] = entries

      clearError(state)     
    },
    reportInfoStatusFetched(state, { payload }) {
      const { caseId, status } = payload

      state.reportInfoStatuses[caseId] = status
      clearError(state)     
    }
  }
})

const {
  listScansSuccess, 
  createScanSuccess,
  fetchScanSuccess,
  scansFailure,
  errorDismissed,
  annotationDeleted,
  annotationDeletedDismissed,
  specificAnnotationDeletedDismissed,
  fetchAnnotationsSuccess,
  fetchClassificationFormsSuccess,
  listImagesForScanSuccess,
  fetchAccessPermissionsSuccess,
  reportInfoStatusFetched
} = scansSlice.actions

export default scansSlice.reducer

const scansApi = new ScansApi(window.RuntimeConfig.backend)
const serviceApi = new ServiceApi(window.RuntimeConfig.backend)

export const listScans = (correlationId: string, datasetId: string | undefined) => async dispatch => {
  try {
    const response: ListScansResponse = await scansApi.listScans(correlationId, datasetId)    

    dispatch(listScansSuccess(response))
  } catch (err) {    
    dispatch(reportError('listScans', err, correlationId))
  }
}

export const fetchScan = (scanId: string, correlationId: string) => async dispatch => {
  try {
    const response: Scan = await scansApi.fetchScan(scanId, correlationId)    

    dispatch(fetchScanSuccess(response))
  } catch (err) {    
    dispatch(reportError('fetchScan', err, correlationId))
  }
}

export const createScan = (scanId: string, userId: string, correlationId: string) => async dispatch => {
  try {
    const pending: Scan = newScan(scanId, userId)
    dispatch(createScanSuccess({scan: pending}))
    dispatch(fetchAnnotationsSuccess({scanId, annotations: []}))    
    dispatch(fetchAccessPermissionsSuccess({caseId: scanId, entries: [{ userId, accessLevel: PermissionLevel.share}]}))
    dispatch(reportInfoStatusFetched({caseId: scanId, status: ReportInfoStatus.pending}))
    const scan: Scan = await scansApi.createScan(scanId, correlationId)
    dispatch(createScanSuccess({scan}))
  } catch (err) {
    dispatch(reportError('createScan', err, correlationId))
  }
}

export const updateScan = (scanId: string, name: string, scanInfo: ScanInfo | undefined, studyDate: string | undefined, correlationId: string) => async dispatch => {
  try {
    await scansApi.updateScan(scanId, name, scanInfo, studyDate, correlationId)
    await dispatch(fetchScan(scanId, correlationId))
  } catch (err) {
    dispatch(reportError('updateScan', err, correlationId))
  }
}

export const saveAnnotation = (
  scanId: string, annotation: Annotation, correlationId: string = v4()) => async dispatch => {
  try {
    await scansApi.updateAnnotation(scanId, annotation, correlationId)
  } catch (err) {
    dispatch(reportError('saveAnnotation', err, correlationId))
  }
}

export const fetchAnnotations = (
  scanId: string, correlationId: string = v4()) => async dispatch => {
  try {
    const response = await scansApi.fetchAnnotations(scanId, correlationId)
    dispatch(fetchAnnotationsSuccess({scanId, annotations: response.annotations}))
  } catch (err) {
    dispatch(reportError('fetchAnnotations', err, correlationId))
  }
}

export const deleteAnnotation = (scanId: string, annotationId: string, correlationId: string = v4()) => async dispatch => {
  try {
    await scansApi.deleteAnnotation(scanId, annotationId, correlationId)
    dispatch(annotationDeleted({scanId, annotationId}))
    setTimeout(() => dispatch(specificAnnotationDeletedDismissed({scanId, annotationId})), 5 * 1000)
  } catch (err) {
    dispatch(reportError('deleteAnnotation', err, correlationId))
  }
}

export const restoreAnnotation = (scanId: string, annotationId: string, correlationId: string = v4()) => async dispatch => {    
  try {
    await scansApi.restoreAnnotation(scanId, annotationId, correlationId)
    dispatch(fetchScan(scanId, correlationId))
    dispatch(annotationDeletedDismissed())
  } catch (err) {
    dispatch(reportError('restoreAnnotation', err, correlationId))
  }
}

export const updateAnnotationApprovalStatus = (scanId: string, annotationId: string, status: AnnotationApprovalStatus | undefined, correlationId: string = v4()) => async dispatch => {    
  try {
    await scansApi.updateAnnotationApprovalStatus(scanId, annotationId, status, correlationId)
    dispatch(fetchAnnotations(scanId, correlationId))
  } catch (err) {
    dispatch(reportError('updateAnnotationApprovalStatus', err, correlationId))
  }
}


export const saveClassificationForm = (
  scanId: string, formData: FormValues, correlationId: string = v4()) => async dispatch => {
  try {
    await scansApi.saveClassificationForm(scanId, formData, correlationId)
    dispatch(fetchClassificationForms(scanId, correlationId))
    dispatch(fetchReportInfoStatus(scanId, correlationId))
  } catch (err) {
    dispatch(reportError('saveClassificationForm', err, correlationId))
  }
}

export const updateClassificationForm = (
  scanId: string, 
  classificationFormId: string, 
  currentVersion: number, 
  formData: FormValues, 
  correlationId: string) => async dispatch => {

  try {
    await scansApi.updateClassificationForm(scanId, classificationFormId, currentVersion, formData, correlationId)
    dispatch(fetchClassificationForms(scanId, correlationId))
    dispatch(fetchReportInfoStatus(scanId, correlationId))
  } catch (err) {
    dispatch(reportError('updateClassificationForm', err, correlationId))
  }
}

export const deleteClassificationForm = (
  scanId: string, 
  classificationFormId: string, 
  currentVersion: number, 
  correlationId: string) => async dispatch => {

  try {
    await scansApi.deleteClassificationForm(scanId, classificationFormId, currentVersion, correlationId)
    dispatch(fetchClassificationForms(scanId, correlationId))
    dispatch(fetchReportInfoStatus(scanId, correlationId))
  } catch (err) {
    dispatch(reportError('deleteClassificationForm', err, correlationId))
  }
}

export const fetchClassificationForms = (
  scanId: string, correlationId: string = v4()) => async dispatch => {
  try {
    const response = await scansApi.fetchClassificationForms(scanId, correlationId)
    dispatch(fetchClassificationFormsSuccess({scanId, forms: response.forms}))
  } catch (err) {
    dispatch(reportError('fetchClassificationForms', err, correlationId))
  }
}

export const requestModelRun = (scanId: string, correlationId: string) => async dispatch => {
  try {
    return await scansApi.requestModelRun(scanId, correlationId)
  } catch (err) {
    dispatch(reportError('requestModelRun', err, correlationId))
  }
}

export const fetchImagesForScan = (scanId: string, correlationId: string) => async dispatch => {
  try {    
    const response = await scansApi.imagesForScan(scanId, correlationId)     

    dispatch(listImagesForScanSuccess({scanId, response: response}))
  } catch (err) {    
    dispatch(reportError('fetchImagesForScan', err, correlationId))
  }
}

export const fetchCaseAccessPermissions = (caseId: string, correlationId: string) => async dispatch => {
  try {
    const response = await scansApi.fetchAccessPermissions(caseId, correlationId)    

    dispatch(fetchAccessPermissionsSuccess({ entries: response.entries, caseId }))
  } catch (err) {    
    dispatch(reportError('fetchCaseAccessPermissions', err, correlationId))
  }
}

export const updateCaseAccessPermissions = (caseId: string, userId: string, accessLevel: PermissionLevel, refresh: boolean, correlationId: string) => async dispatch => {
  try {
    await scansApi.updateAccessPermissions(caseId, userId, accessLevel, correlationId)    

    if(refresh){
      dispatch(fetchCaseAccessPermissions(caseId, correlationId))
    }    
  } catch (err) {    
    dispatch(reportError('updateCaseAccessPermissions', err, correlationId))
  }
}

export const fetchReportInfoStatus = (caseId: string, correlationId: string) => async dispatch => {
  try {
    const { status } = await scansApi.fetchReportInfoStatus(caseId, correlationId)

    dispatch(reportInfoStatusFetched({caseId, status}))
  } catch (err) {
    dispatch(reportError('fetchReportInfoStatus', err, correlationId))
  }
}

export const saveReportImages = (caseId: string, formData: FormData, correlationId: string) => async dispatch => {
  try {
    await scansApi.saveReportImages(caseId, formData, correlationId)
  } catch (err) {
    dispatch(reportError('saveReportImages', err, correlationId))
  }
}

export const clearAnnotationDeleted = () => async dispatch => {
  dispatch(annotationDeletedDismissed())
}

export const clearScanError = () => async dispatch => {
  dispatch(errorDismissed())
}


export const reportError = (method: string, err: any, correlationId: string) => async dispatch => {  
  try {
    console.log({method: err})
    const message = errorToString(err)
    dispatch(scansFailure(message))
    await serviceApi.reportError(method, message, correlationId)
  } catch (err2) {
    console.log({reportErrorFailure: err2})
  }
}

export const clearDatasetError = () => async dispatch => {
  dispatch(errorDismissed())
}
