import { DeleteX } from "app/components/icons/DeleteX"
import { InlineEdit } from "app/components/InLineEdit"
import _ from "lodash"
import { useEffect, useState } from "react"
import { PlaneSelector } from "uploads/component/PlaneSelector"
import { AnnotationMode } from "uploads/model/AnnotationMode"
import { DatasetAnnotationLabel, defaultAclAnnotationLabels, defaultHamstringAnnotationLabels, labelFromDisplayText, newDatasetAnnotationLabel } from "uploads/model/DatasetAnnotationLabel"
import { AnnotationShapeSelector } from "./AnnotationShapeSelector"

export type AnnotationLabelsTableProps = {
  annotationLabels: DatasetAnnotationLabel[]
  showDebugUi: boolean
  annotationMode: AnnotationMode
  hasWriteAccess: boolean
  hasOwnerAccess: boolean
  updateLabel: (label: DatasetAnnotationLabel) => Promise<void>
  deleteLabel: (labelId: string) => Promise<void>
}

export const AnnotationLabelsTable = ({
  annotationLabels, showDebugUi, annotationMode, hasWriteAccess, hasOwnerAccess,
  updateLabel, deleteLabel
}: AnnotationLabelsTableProps) => {
  const nameWidth = 200
  const shapeWidth = 240
  const planeWidth = 240
  const deleteWidth = 80
  const backendWidth = 250

  const tableWidth = showDebugUi ? nameWidth + shapeWidth + planeWidth + deleteWidth + backendWidth : nameWidth + shapeWidth + planeWidth + deleteWidth

  const [newLabel, setNewLabel] = useState<DatasetAnnotationLabel>(newDatasetAnnotationLabel())

  useEffect(() => {
    if(annotationLabels.find(l => l.labelId === newLabel.labelId)){
      setNewLabel(newDatasetAnnotationLabel())
    }
  }, [annotationLabels, newLabel.labelId])  

  const onAddDefaultAnnotationClick = () => {
    const newLabels: DatasetAnnotationLabel[] = annotationMode === AnnotationMode.Hamstring ? defaultHamstringAnnotationLabels() : defaultAclAnnotationLabels()

    newLabels.forEach(updateLabel)
  }
  
  return (
    <div 
      className="annotation-labels"
      style={{ marginRight: '10px', marginBottom: '10px', marginTop: '30px' }}
      >
      <h2 style={{marginLeft:'10px'}}>Annotations</h2>

      { hasOwnerAccess && annotationLabels.length === 0 &&
        <button 
          className='btn btn-primary' 
          style={{margin: '10px'}} 
          onClick={onAddDefaultAnnotationClick}
        >Add default {annotationMode} labels</button>
      }

      <div style={{maxHeight: '650px', overflowY: 'auto'}}>
        <table className="table table-bordered table-striped" style={{ width: `${tableWidth}px`}}>
          <thead>
            <tr>
              <th style={{ width: `${nameWidth}px` }} scope="col">Name</th>
              <th style={{ width: `${shapeWidth}px` }} scope="col">Shape</th>
              <th style={{ width: `${planeWidth}px` }} scope="col">Planes</th>
              { hasOwnerAccess &&
              <th style={{ width: `${deleteWidth}px` }} scope="col">Remove</th>
              }
              {showDebugUi && 
              <th style={{ width: `${backendWidth}px` }} scope="col">backend_value</th>
              }
            </tr>
          </thead>
          <tbody>
            { _.sortBy(annotationLabels, l => l.displayText).map(annotationLabel => 
              <tr key={annotationLabel.labelId}>
                <td style={{paddingTop: '15px'}}>
                  <InlineEdit 
                    value={annotationLabel.displayText} 
                    setValue={(displayText) => { updateLabel({...annotationLabel, displayText}) }} 
                    width={nameWidth - 20} 
                    disabled={!hasOwnerAccess} 
                  />
                </td>  
                <td>
                  <AnnotationShapeSelector 
                    annotationShape={annotationLabel.shapeId} 
                    setAnnotationShape={(shapeId) => { updateLabel({...annotationLabel, shapeId}) } } 
                    disabled={!hasOwnerAccess} 
                  />
                </td>
                <td style={{paddingTop: '15px'}}>
                  <PlaneSelector 
                    selectedPlanes={annotationLabel.plane?.split(',').map(p => p.trim()) || []} 
                    disabled={!hasOwnerAccess} 
                    setSelectedPlanes={(planes => updateLabel({...annotationLabel, plane: planes.join(',')}))}
                  />
                </td>  
                { hasOwnerAccess &&
                <td>
                  <div style={{ marginLeft: '25px', marginTop: '8px' }}>                    
                    <DeleteX onClick={() => deleteLabel(annotationLabel.labelId)} />                                       
                  </div>                
                </td>  
                }
                {showDebugUi && 
                  <td style={{paddingTop: '15px'}}>
                    <InlineEdit 
                      value={annotationLabel.label} 
                      setValue={(label) => { updateLabel({...annotationLabel, label}) }} 
                      width={backendWidth - 20} 
                      disabled={!hasOwnerAccess} 
                    />
                  </td>  
                }
              </tr>
            )}
            { hasOwnerAccess && 
            <tr key={newLabel.labelId}>
              <td>
                <InlineEdit 
                  value={newLabel.displayText} 
                  setValue={(displayText) => { updateLabel({...newLabel, displayText, label: labelFromDisplayText(displayText) })  }} 
                  width={nameWidth - 20} 
                  disabled={!hasOwnerAccess} 
                />
              </td>  
              <td>
                <AnnotationShapeSelector 
                  annotationShape={newLabel.shapeId}
                  setAnnotationShape={(shapeId) => { updateLabel({...newLabel, shapeId}) }} 
                  disabled={!hasOwnerAccess} 
                />
              </td>
              <td>
                <InlineEdit 
                  value={newLabel.plane} 
                  setValue={(plane) => { updateLabel({...newLabel, plane})  }} 
                  width={planeWidth - 20}  
                  disabled={!hasOwnerAccess} 
                />
              </td>  
              {showDebugUi && 
              <td>
                <InlineEdit 
                  value={newLabel.label} 
                  setValue={(label) => { updateLabel({...newLabel, label}) }} 
                  width={backendWidth - 20} 
                  disabled={!hasOwnerAccess} 
                />
              </td>  
              }
            </tr>
            }
          </tbody>
        </table>  
      </div>  
    </div>
  )
}

