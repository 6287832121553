import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { connect } from 'react-redux'
import { useParams } from 'react-router-dom'
import { useLocation, useNavigate } from 'react-router-dom'
import { fetchAnnotations, fetchClassificationForms, fetchScan, saveClassificationForm, updateClassificationForm, fetchImagesForScan, updateScan, fetchCaseAccessPermissions, deleteClassificationForm, saveReportImages } from 'cases/api/ScansSlice'
import { RootState } from 'app/store'
import { nanoid } from 'nanoid'
import { ExtractedImage } from 'uploads/store/Model'
import { AnnotationMode, getAnnotationMode } from 'uploads/model/AnnotationMode'
import { ExtractedImageMetaData, Scan, ScanInfo } from 'uploads/api/Model'
import SeriesPanel, { SeriesAnnotationIdsMap } from 'uploads/component/SeriesPanel'
import { Dataset, DatasetInfo } from 'uploads/model/Dataset'
import { fetchDataset, fetchDatasetAccessPermissions, updateDataset } from 'datasets/api/DatasetSlice'
import { Annotation, distinctAnnotationCreators } from 'annotation/model/Annotation'
import { LoadingSpinner } from 'app/components/LoadingSpinner'
import { ClassificationForm } from 'annotation/model/ClassificationForm'
import { User } from 'auth/model/User'
import { getSeriesDisplayName, setSeriesDisplayName } from 'uploads/util/SeriesDisplayName'
import { isAnnotateAccess, isWriteAccess, mergePermissionLevels } from 'auth/model/PermissionLevel'
import { AccessEntry } from 'auth/model/AccessEntry'
import { ScansApi } from 'cases/api/ScansApi'
import { getSeriesIds } from 'uploads/model/ScanData'
import { SeriesControlPanel } from 'uploads/component/SeriesControlPanel'
import { DatasetAnnotationLabel, SeriesDatasetAnnotationLabelMap } from 'uploads/model/DatasetAnnotationLabel'
import { AnnotationShapeType } from 'annotation/model/AnnotationShape'
import { getAnnotationLabels } from 'annotation/model/AnnotationLabel'
import { AnnotationForm } from './components/AnnotationForm'
import { requestReport } from 'reports/api/ReportsSlice'
import SeriesViewer from './components/SeriesViewer'
import { FormValues } from 'uploads/component/HamstringForm'
import { useMediaQuery } from 'react-responsive'

type AnnotationPageProps = {
  fetchScan: (scanId: string) => void
  fetchImagesForScan: (scanId: string) => void
  fetchAnnotations: (scanId: string) => void
  fetchDataset: (datasetId: string) => void
  saveClassification: (scanId: string, hamstringFormValues: FormValues) => Promise<void>
  updateClassificationForm: (scanId: string, classificationFormId: string, currentVersion: number, formData: FormValues) => Promise<void>
  deleteClassificationForm: (scanId: string, classificationFormId: string, currentVersion: number) => Promise<void>
  fetchClassificationForms: (scanId: string) => void
  updateScan: (scanId: string, name: string, scanInfo: ScanInfo | undefined, studyDate: string | undefined) => Promise<void>
  updateDataset: (datasetId: string, name: string, datasetInfo: DatasetInfo) => Promise<void>
  loadAccessEntries: (scanId: string) => void
  fetchDatasetAccessPermissions: (datasetId: string) => void
  saveReportImages: (scanId: string, formData: FormData) => void
  requestReport: (scanId: string, selectedUserId: string | undefined) => void,
  scanId: string
  scan?: Scan
  dataset?: Dataset
  images?: ExtractedImage[]
  annotations?: Annotation[]
  annotateMode: boolean
  classificationForms?: ClassificationForm[]
  currentUserId: string
  users?: User[]
  scanAccessEntries?: AccessEntry[]
  datasetAccessEntries?: AccessEntry[]
  showDebugUi: boolean
  showExperimentalUi: boolean
  seriesAnnotationLabels: SeriesDatasetAnnotationLabelMap
}

const AnnotationPage = ({
  fetchScan, fetchImagesForScan, fetchAnnotations, fetchDataset, saveClassification, fetchClassificationForms, 
  updateClassificationForm, deleteClassificationForm, updateScan, loadAccessEntries, 
  fetchDatasetAccessPermissions, saveReportImages, requestReport,
  scanId, scan, images, dataset, annotations, classificationForms, currentUserId, users, annotateMode, 
  scanAccessEntries, datasetAccessEntries,
  showDebugUi, seriesAnnotationLabels, showExperimentalUi
}: AnnotationPageProps) => {

  useEffect(() => {if(scan === undefined) fetchScan(scanId)}, [fetchScan, scan === undefined])
  useEffect(() => {if(annotations === undefined) fetchAnnotations(scanId) }, [fetchAnnotations, scanId, annotations])
  useEffect(() => {if(images === undefined) fetchImagesForScan(scanId)}, [fetchImagesForScan, scanId, images])
  useEffect(() => {if(scan?.datasetId !== undefined && dataset === undefined) {fetchDataset(scan.datasetId)} }, 
    [scan?.datasetId, dataset, fetchDataset])
  useEffect(() => {if(classificationForms === undefined) fetchClassificationForms(scanId)}, [classificationForms, scanId, fetchClassificationForms])
  useEffect(() => {if(scanAccessEntries === undefined) loadAccessEntries(scanId)}, [loadAccessEntries, scanId])
  useEffect(() => {if(dataset !== undefined && datasetAccessEntries === undefined) fetchDatasetAccessPermissions(dataset.datasetId)}, 
    [fetchDatasetAccessPermissions, dataset?.datasetId, datasetAccessEntries])

  const scanIdRef = useRef<string>()
  scanIdRef.current = scanId
 
  const [selectedSeriesId, setSelectedSeriesId] = useState<string | undefined>(undefined)
  const selectedSeriesIdRef = useRef<string>()
  selectedSeriesIdRef.current = selectedSeriesId

  const [labelAllAnnotations, setLabelAllAnnotations] = useState<boolean>(false)

  const { search } = useLocation()
  const navigate = useNavigate()
  const searchParams = useMemo(() => new URLSearchParams(search), [search])
  const zoomedSeriesInstanceUID = searchParams.get("seriesId") || undefined
  const show3d: boolean = searchParams.get("3d") === "true"

  const [editingAnnotation, setEditingAnnotation] = useState<string | undefined>(undefined)

  const [displayModelAnnotations, setDisplayModelAnnotations] = useState<boolean>(false)
  const [displayModelAnnotationsInitialised, setDisplayModelAnnotationsInitialised] = useState<boolean>(false)
  useEffect(() => {
    if(!displayModelAnnotationsInitialised && annotations !== undefined) {
      if(annotations.find(a => a.createdBy === undefined) && !annotations.find(a => a.createdBy !== undefined)) {
        setDisplayModelAnnotations(true)
      }
      setDisplayModelAnnotationsInitialised(true)
    }
  }, [displayModelAnnotations, annotations === undefined])

  const [hiddenAnnotations, setHiddenAnnotations] = useState<string[]>([])

  const [displayedUserAnnotations, setDisplayedUserAnnotations] = useState<string[]>([])
  const [displayedUserAnnotationsInitialised, setDisplayedUserAnnotationsInitialised] = useState<boolean>(false)
  useEffect(() => {
    if(!displayedUserAnnotationsInitialised && annotations !== undefined) {       
      setDisplayedUserAnnotations(distinctAnnotationCreators(annotations)) 
      setDisplayedUserAnnotationsInitialised(true)
    } 
  }, [displayedUserAnnotationsInitialised, annotations])

  const toggleDisplayedUserAnnotation = (userId: string) => {
    if(displayedUserAnnotations.indexOf(userId) === -1) {
      setDisplayedUserAnnotations([...displayedUserAnnotations, userId])
    } else {
      setDisplayedUserAnnotations(displayedUserAnnotations.filter(id => id !== userId))
    }
  } 
  const toggleZoomedSeriesUID = (seriesId: string) => {
    if(zoomedSeriesInstanceUID !== undefined){
      searchParams.delete('seriesId')
    } else {
      searchParams.set('seriesId', seriesId)
    }

    setSelectedSeriesId(seriesId)

    navigate(`/annotations/${scanId}?${searchParams.toString()}`)
  }

  const setZoomedSeriesUID = useCallback((seriesId: string | undefined) => {
    if(seriesId === undefined) {
      searchParams.delete('seriesId')
    } else {
      searchParams.set('seriesId', seriesId)    
    }

    navigate(`/annotations/${scanId}?${searchParams.toString()}`)
  }, [searchParams])

  const enter3D = (seriesId: string) => {
    searchParams.set('seriesId', seriesId)    
    searchParams.set('3d', 'true')    

    setSelectedSeriesId(seriesId)

    navigate(`/annotations/${scanId}?${searchParams.toString()}`)
  }
  const exit3d = () => {
    searchParams.delete('3d')    

    navigate(`/annotations/${scanId}?${searchParams.toString()}`)
  }



  const [hoveredAnnotationMeta, setHoveredAnnotationMeta] = useState<string | undefined>(undefined)
  const [hoveredAnnotation, setHoveredAnnotation] = useState<string | undefined>(undefined)
  const [displayInstanceNumberMap, setDisplayInstanceNumberMap] = useState<DisplayInstanceNumberMap>({})
  

  const [contrastPercentages, setContrastPercentages] = useState<SeriesNumberMap>({})
  const [intensityPercentages, setIntensityPercentages] = useState<SeriesNumberMap>({})
  const [showModelOutputs, setShowModelOutputs] = useState<SeriesBooleanMap>({})
  const [currentAnnotationLabel, setCurrentAnnotationLabel] = useState<string | undefined>(undefined)
  const [annotationShape, setAnnotationShape] = useState<AnnotationShapeType | undefined>(undefined)
  const [savingAnnotationIds, setSavingAnnotationIds] = useState<SeriesAnnotationIdsMap>({})

  const [seriesOrder, setSeriesOrder] = useState<string[] | undefined>(scan?.scanInfo.orderedSeriesIds) 
  useEffect(()  => {
    if(seriesOrder === undefined && scan !== undefined) {
      setSeriesOrder(scan.scanInfo.orderedSeriesIds)
    }
  })
  useEffect(() => {
    if(seriesOrder !== undefined && 
      seriesOrder.length > 0 &&
      (selectedSeriesId === undefined || !seriesOrder.slice(0, 4).includes(selectedSeriesId))) {
        setSelectedSeriesId(seriesOrder[0])
      }
  }, [seriesOrder, selectedSeriesId])
  const displayAnnotation = useCallback((annotation: Annotation) => {
    if(zoomedSeriesInstanceUID !== undefined && zoomedSeriesInstanceUID !== annotation.SeriesInstanceUID) {
      setZoomedSeriesUID(annotation.SeriesInstanceUID)
    }
    if(seriesOrder !== undefined && !seriesOrder.slice(0, 4).includes(annotation.SeriesInstanceUID)) {
      setZoomedSeriesUID(annotation.SeriesInstanceUID)
    }
    setDisplayInstanceNumberMap({...displayInstanceNumberMap, [annotation.SeriesInstanceUID]: annotation.InstanceNumber})
  }, [zoomedSeriesInstanceUID])

  const annotationMode = getAnnotationMode(dataset, scan)

  const scanAccessLevel = scanAccessEntries?.find(e => e.userId === currentUserId)?.accessLevel    
  const datasetAccessLevel = datasetAccessEntries?.find(e => e.userId === currentUserId)?.accessLevel
  const accessLevel = mergePermissionLevels(scanAccessLevel, datasetAccessLevel)

  const hasWriteAccess = isWriteAccess(accessLevel)
  const hasAnnotateAccess = isAnnotateAccess(accessLevel)

  const showSidePanel = useMediaQuery({ query: '(min-width: 1100px)' })

  if(scan === undefined || 
    (scan.datasetId !== undefined && dataset === undefined) ||
    images === undefined){      
    return (
      <div style={{margin: '30px'}}>
        <LoadingSpinner />
      </div>      
    )
  } else {   
    const orderedSeriesIds = zoomedSeriesInstanceUID !== undefined ? [zoomedSeriesInstanceUID] : (seriesOrder || [])

    const allSeriesIds: string[] = getSeriesIds(images)

    return (        
      <div style={{ display: 'flex', marginLeft: '10px' }}>
        <div style={{ flexShrink: 0, position: 'relative', minWidth: showSidePanel ? '250px' : '10px', height: '94vh', overflowY: 'auto', overflowX: 'hidden' }}>          
          { showSidePanel &&
          <>
          <SeriesPanel
            scanId={scanId}
            scan={scan}
            dataset={dataset}
            hasAnnotateAccess={hasAnnotateAccess}
            hasWriteAccess={hasWriteAccess}
            seriesOrderState={seriesOrder}
            savingAnnotationIds={savingAnnotationIds}
            setSeriesOrderState={(seriesOrder) => setSeriesOrder(seriesOrder)}
            seriesIds={allSeriesIds}
            setHoveredAnnotationMeta={(annotationId) => { setHoveredAnnotationMeta(annotationId) } }
            hoveredAnnotationId={hoveredAnnotation}
            displayedUserAnnotations={displayedUserAnnotations}
            toggleDisplayedUserAnnotation={toggleDisplayedUserAnnotation}
            setDisplayModelAnnotations={setDisplayModelAnnotations}
            displayModelAnnotations={displayModelAnnotations}
            displayAnnotation={displayAnnotation}
            annotationApprovers={dataset?.datasetInfo.annotationApprovers || []}
            currentUserId={currentUserId}
            setEditingAnnotation={setEditingAnnotation}
            editingAnnotation={editingAnnotation}
            zoomedSeriesInstanceUID={zoomedSeriesInstanceUID}
            seriesAnnotationLabels={seriesAnnotationLabels}
            setZoomedSeriesInstanceUID={setZoomedSeriesUID}
            onCaseNavigate={() => {
              setSeriesOrder(undefined)
              setContrastPercentages({})
              setIntensityPercentages({})
              setShowModelOutputs({})
            }}
            selectedSeriesId={selectedSeriesId}
            hiddenAnnotations={hiddenAnnotations}
            annotateMode={annotateMode}
            setHiddenAnnotations={setHiddenAnnotations}
          />          
          {selectedSeriesIdRef.current !== undefined && 
          <SeriesControlPanel 
            annotateMode={annotateMode}
            contrastPercentage={contrastPercentages[selectedSeriesIdRef.current] || 100}
            intensityPercentage={intensityPercentages[selectedSeriesIdRef.current] || 100}
            showModelOutputs={showModelOutputs[selectedSeriesIdRef.current] || false}
            currentAnnotationLabel={currentAnnotationLabel}
            annotationLabels={seriesAnnotationLabels[selectedSeriesIdRef.current]}
            annotationShape={annotationShape}
            hasWriteAccess={hasWriteAccess}
            selectedSeriesId={selectedSeriesId}
            showDebugUi={showDebugUi}
            modelOutputs={scan.modelOutputs?.filter(o => o.seriesId === selectedSeriesIdRef.current)}
            labelAllAnnotations={labelAllAnnotations}
            toggleLabelAllAnnotations={() => setLabelAllAnnotations(l => !l)}
            setContrastPercentage={(p) => {
              if (selectedSeriesIdRef.current !== undefined) {
                setContrastPercentages({ ...contrastPercentages, [selectedSeriesIdRef.current]: p })
              }
            } }
            setIntensityPercentage={(p) => {
              if (selectedSeriesIdRef.current !== undefined) {
                setIntensityPercentages({ ...intensityPercentages, [selectedSeriesIdRef.current]: p })
              }
            } }
            toggleShowModelOutputs={() => {
              if (selectedSeriesIdRef.current !== undefined) {
                const current: boolean = showModelOutputs[selectedSeriesIdRef.current] || false
                setShowModelOutputs({ ...showModelOutputs, [selectedSeriesIdRef.current]: !current })
              }
            } }
            setCurrentAnnotationLabel={setCurrentAnnotationLabel}
            setAnnotationShape={setAnnotationShape} 
            saveReportImages={(formData: FormData) => {if(scanIdRef.current !== undefined) { saveReportImages(scanIdRef.current, formData)} }} 
          />
          } 
          </>
          }         
        </div>                 
        <div 
          style={{ 
            width: '1060px', 
            height: '1286px',
            overflowY: 'auto', 
            display: 'grid',
            gridTemplateColumns: 'repeat(auto-fit, minmax(520px, 1fr))',
            // flexWrap: 'wrap',
            // backgroundColor: showExperimentalUi ? 'black' : 'white',
            marginTop: '10px'
          }} 
        >
          { orderedSeriesIds.slice(0, 4).map((seriesId) =>
            <SeriesViewer 
              key={seriesId}
              scanId={scanId}      
              scan={scan}        
              annotateMode={annotateMode}
              seriesId={seriesId}
              labelAllAnnotations={seriesId === selectedSeriesIdRef.current && labelAllAnnotations}
              highlightAnnoation={hoveredAnnotationMeta}
              hiddenAnnotations={hiddenAnnotations}
              currentUserId={currentUserId}
              isMaximised={seriesId === zoomedSeriesInstanceUID || orderedSeriesIds.length <= 1}
              showMaximise={allSeriesIds.length > 1}
              displayedUserAnnotations={displayedUserAnnotations}
              displayModelAnnotations={displayModelAnnotations}
              setHoveredAnnotation={setHoveredAnnotation}
              onMaximiseToggle={() => toggleZoomedSeriesUID(seriesId)} 
              setZoomedSeriesUID={setZoomedSeriesUID}
              onAnnotationDraw={() => {if(currentUserId !== undefined && displayedUserAnnotations.indexOf(currentUserId) === -1) {toggleDisplayedUserAnnotation(currentUserId)}}}
              displayInstanceNumber={displayInstanceNumberMap[seriesId]}
              clearDisplayInstanceNumber={() => setDisplayInstanceNumberMap({...displayInstanceNumberMap, [seriesId]: undefined})} 
              setSeriesDisplayName={(name) => setSeriesDisplayName(scan, seriesId, name, updateScan)}
              editingAnnotation={editingAnnotation}
              hasWriteAccess={hasWriteAccess}
              setDisplayInstanceNumber={(instanceNumber) => setDisplayInstanceNumberMap({...displayInstanceNumberMap, [seriesId]: instanceNumber})}
              setSelectedSeries={() => setSelectedSeriesId(seriesId)}
              isActiveSeries={seriesId === selectedSeriesId}
              contrastPercentage={contrastPercentages[seriesId] || 100}
              intensityPercentage={intensityPercentages[seriesId] || 100}
              showModelOutputs={showModelOutputs[seriesId] || false}
              annotationShape={annotationShape}
              show3d={show3d}
              enter3d={() => enter3D(seriesId)}
              exit3d={exit3d}
              currentAnnotationLabel={currentAnnotationLabel}                    
              setSavingAnnotationIds={(ids) => {
                setSavingAnnotationIds({
                  ...savingAnnotationIds,
                  [seriesId]: ids
                })
              }}
            />
          )}
        </div>

        { classificationForms !== undefined && users !== undefined &&
          <AnnotationForm 
            scanId={scanId}
            scan={scan}
            currentUserId={currentUserId}
            hasAnnotateAccess={hasAnnotateAccess}
            annotationMode={annotationMode}
            saveClassification={(scanId, form) => 
              saveClassification(scanId, form).then(() => 
                requestReport(scanId, currentUserId))
            }
            displayAnnotation={displayAnnotation}
            updateClassificationForm={updateClassificationForm} 
            deleteClassificationForm={deleteClassificationForm}
            showDebugUi={showDebugUi}      
            annotations={annotations || []}   
            forms={classificationForms}
            users={users}
            displayOnly={!annotateMode}
          />
        }  
      </div>
    )
  }
}

const mapStateToProps = (state: RootState, { scanId }) => {
  const scan = state.scans.all?.find(scan => scan.scanId === scanId)

  const annotations = state.scans.annotations[scanId]

  const dataset = state.datasets.all?.find(d => d.datasetId === scan?.datasetId)

  const images: ExtractedImageMetaData[] = state.scans.imagesMetadata[scanId]?.metadata.filter(i => {
    if(scan?.datasetId === undefined) {
      return i.imageFormat === 'png'
    } else {
      return i.imageFormat === 'png' && (dataset?.datasetInfo.seriesDescriptionsForAnnotation || []).indexOf(i.SeriesDescription || '') !== -1
    }    
  })


  const seriesIds: string[] = images?.reduce((acc: string[], image: ExtractedImageMetaData) => {
    if(image.SeriesInstanceUID !== undefined) {
      return [...acc, image.SeriesInstanceUID]
    } else {
      return acc
    }
  }, []) || []


  const seriesAnnotationLabels: SeriesDatasetAnnotationLabelMap = {}

  seriesIds.forEach((seriesId) => {
    const seriesDescription = images.find(i => i.SeriesInstanceUID === seriesId && i.SeriesDescription !== undefined)?.SeriesDescription
    const seriesDisplayName = getSeriesDisplayName(seriesId, seriesDescription, scan, dataset)
    const annotationLabels = getAnnotationLabels(seriesDisplayName, dataset?.annotationLabels)

    seriesAnnotationLabels[seriesId] = annotationLabels
  })

  
  return { 
    scan,
    images: images,
    annotations,
    dataset,
    scanAccessEntries: state.scans.accessEntries[scanId],
    datasetAccessEntries: scan?.datasetId === undefined ? undefined : state.datasets.accessEntries[scan.datasetId],
    classificationForms: state.scans.forms[scanId],
    users: state.user.users,
    seriesAnnotationLabels,
    showDebugUi: state.user.features.showDebugUi,
    showExperimentalUi: state.user.features.showExperimentalUi,
  }
}

const mapDispatchToProps = {
  fetchScan: (scanId: string) => fetchScan(scanId, nanoid()),
  fetchImagesForScan: (scanId: string) => fetchImagesForScan(scanId, nanoid()),
  fetchAnnotations: (scanId: string) => fetchAnnotations(scanId, nanoid()),
  fetchDataset: (datasetId: string) => fetchDataset(datasetId, nanoid()),
  saveClassification: (scanId: string, formData: FormValues) => 
    saveClassificationForm(scanId, formData, nanoid()),
  updateClassificationForm: (scanId: string, classificationFormId: string, currentVersion: number, formData: FormValues) => 
    updateClassificationForm(scanId, classificationFormId, currentVersion, formData, nanoid()),
  deleteClassificationForm: (scanId: string, classificationFormId: string, currentVersion: number) => 
    deleteClassificationForm(scanId, classificationFormId, currentVersion, nanoid()), 
  fetchClassificationForms: (scanId: string) => fetchClassificationForms(scanId, nanoid()),
  updateScan: (scanId: string, name: string, scanInfo: ScanInfo | undefined, studyDate: string | undefined) => updateScan(scanId, name, scanInfo, studyDate, nanoid()),
  updateDataset: (datasetId: string, name: string, datasetInfo: DatasetInfo) => updateDataset(datasetId, name, datasetInfo, nanoid()),
  loadAccessEntries: (scanId: string) => fetchCaseAccessPermissions(scanId, nanoid()),
  fetchDatasetAccessPermissions: (datasetId: string) => fetchDatasetAccessPermissions(datasetId, nanoid()),
  saveReportImages: (scanId: string, formData: FormData) => saveReportImages(scanId, formData, nanoid()),
  requestReport: (scanId: string, selectedUserId: string | undefined) => requestReport(scanId, selectedUserId, nanoid()),
}


const ConnectedAnnotationPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(AnnotationPage)


export default function AnnotationPageWrapper({currentUserId, annotateMode}: {currentUserId: string, annotateMode: boolean}) {
  const { scanId } = useParams()

  return (
    <ConnectedAnnotationPage scanId={scanId} currentUserId={currentUserId} annotateMode={annotateMode}/>
  )
}

type DisplayInstanceNumberMap = {
  [seriesId: string]: number | undefined
}

type SeriesNumberMap = {
  [seriesId: string]: number
}

type SeriesBooleanMap = {
  [seriesId: string]: boolean
}
