import { createSlice } from '@reduxjs/toolkit'
import { UploadsApi } from 'uploads/api/UploadsApi'
import { UploadRecord } from 'uploads/model/UploadRecord'
import { nanoid } from 'nanoid'
import { fetchScan } from '../../cases/api/ScansSlice'
import { errorToString } from 'utils/ErrorUtils'
import { ServiceApi } from 'app/api/ErrorApi'


export interface UploadsState {
  byScan: ScanUploadsMap
  byDataset: DatasetUploadsMap
  error?: string
}

export type ScanUploadsMap = {
  [scanId: string]: UploadRecord[]
}

export type DatasetUploadsMap = {
  [datasetId: string]: UploadRecord[]
}


const initialState: UploadsState = {
  byScan: {},
  byDataset: {},
  error: undefined,
}

const clearError: (state: UploadsState) => void = (state) => {
  if(window.RuntimeConfig.frontend.autoClearErrors){
    state.error = undefined
  }
}

const uploadsSlice = createSlice({
  name: 'uploads',
  initialState,
  reducers: {
    listScanUploadsSuccess(state, { payload }) {            
      state.byScan[payload.scanId] = payload.uploads
      clearError(state)
    },
    listDatasetUploadsSuccess(state, { payload }) {            
      state.byDataset[payload.datasetId] = payload.uploads
      clearError(state)
    },
    uploadsFailure(state, { payload }) {
      state.error = payload
    },
    errorDismissed(state) {
      state.error = undefined
    }
  }
})

const { 
  listScanUploadsSuccess,
  listDatasetUploadsSuccess,
  uploadsFailure,
  errorDismissed
} = uploadsSlice.actions

export default uploadsSlice.reducer

const uploadsApi = new UploadsApi(window.RuntimeConfig.backend)
const errorApi = new ServiceApi(window.RuntimeConfig.backend)

export const fetchScanUploads = (scanId: string, datasetId: string | undefined, correlationId: string = nanoid()) => async dispatch => {
  try {
    const response = await uploadsApi.listScanUploads(scanId, datasetId, correlationId)    
    dispatch(listScanUploadsSuccess({scanId, uploads: response.uploads}))
  } catch (err) {    
    dispatch(reportError('fetchScanUploads', err, correlationId))
  }
}

export const fetchDatasetUploads = (datasetId: string, correlationId: string = nanoid()) => async dispatch => {
  try {
    const response = await uploadsApi.listDatasetUploads(datasetId, correlationId) 
    dispatch(listDatasetUploadsSuccess({datasetId, uploads: response.uploads}))
   
  } catch (err) {    
    dispatch(reportError('fetchDatasetUploads', err, correlationId))
  }
}

export const uploadFiles = (scanId: string, files: File[]) => async dispatch => {  
  const correlationId = nanoid()
  try {
    const formData = new FormData()
    files.forEach(file => formData.append(file.name, file))		

    await uploadsApi.uploadFiles(scanId, formData, correlationId)

    dispatch(fetchScan(scanId, correlationId))    
  } catch (err) {
    dispatch(reportError('uploadFiles', err, correlationId))
  }
} 

export const uploadFile = (scanId: string, formData: FormData) => async dispatch => {  
  const correlationId = nanoid()
  try {
    await uploadsApi.uploadFiles(scanId, formData, correlationId)
    dispatch(fetchScan(scanId, correlationId))    
  } catch (err) {
    dispatch(reportError('uploadFile', err, correlationId))
  }
} 

export const fileUploadsComplete = (scanId: string, correlationId: string) => async dispatch => {  
  try {
    const modelRunId = await uploadsApi.fileUploadsComplete(scanId, correlationId)
    return modelRunId
  } catch (err) {
    dispatch(reportError('fileUploadsComplete', err, correlationId))
  }
} 



export const downloadFile = (uploadId: string) => async dispatch => {
  const correlationId = nanoid()
  try {
    window.open(`${uploadsApi.uploadsUrl}/${uploadId}`, '_blank')
  } catch (err) {
    dispatch(reportError('downloadFile', err, correlationId))
  }
}

export const clearUploadError = () => async dispatch => {
  dispatch(errorDismissed())
}

export const reportError = (method: string, err: any, correlationId: string) => async dispatch => {  
  try {
    console.log({method: err})
    const message = errorToString(err)
    dispatch(uploadsFailure(message))
    await errorApi.reportError(method, message, correlationId)
  } catch (err2) {
    console.log({reportErrorFailure: err2})
  }
}
