import { useEffect, useState } from 'react'
import { connect } from 'react-redux'

import { RootState } from 'app/store'
import { Dataset } from 'uploads/model/Dataset'
import { sortByFileName, UploadRecord } from 'uploads/model/UploadRecord'
import { BackendConfig } from 'config/ApplicationConfig'
import { downloadFile, fetchDatasetUploads } from 'uploads/api/UploadsSlice'
import { capLengthIfNeeded } from 'utils/TextUtils'
import { formatDate } from 'utils/DateUtils'
import { DownloadIcon } from 'app/components/DownloadIcon'
import { uploadsUrl } from 'uploads/api/UploadUrls'

export type DatasetUploadsTableProps = {
  datasetId: string
  dataset?: Dataset
  uploads?: UploadRecord[]
  config: BackendConfig
  listUploads: (datasetId: string) => void
  downloadFile: (uploadId: string) => void
}

export const DatasetUploadsTable = ({
  uploads, config, datasetId, dataset,
  downloadFile, listUploads
}: DatasetUploadsTableProps) => {

  const [showUploads, setShowUploads] = useState<boolean>(false)

  useEffect(() => {
    if(showUploads && dataset !== undefined && dataset.uploads.length > 1 && uploads === undefined) { 
      listUploads(datasetId) 
    }
  }, [showUploads, listUploads, datasetId, dataset?.uploads.length, uploads === undefined])  

  if(!showUploads) {
    return (
      <div style={{
        border: '1px solid lightgrey',
        borderRadius: '1em',
        margin: '5px',
        position: 'relative',
        height: 'max-content',
        blockSize: 'max-content',
        width: 'fit-content', 
        padding: '10px',
        paddingRight: '15px'
      }}>
        <button className="btn btn-primary" style={{ marginLeft: '5px', }} onClick={() => setShowUploads(true)}>Show uploads</button>
      </div>      
    )
  } else {
    return(
      <div style={{marginTop: '20px'}}>
        <div className="uploads-list-component" style={{ width: '900px', maxHeight: '500px', overflowY: 'auto'}}>
          <table className="table table-bordered table-striped" style={{ width: '860px'}}>
            <thead>
              <tr>
                <th style={{ width: '300px' }} scope="col">File Name</th>
                <th style={{ width: '160px' }} scope="col">Uploaded Date</th>
                <th style={{ width: '70px' }} scope="col">Download</th>
              </tr>
            </thead>
            <tbody>
              { uploads?.map(upload => 
                <UploadedFileDetails 
                  key={upload.id} 
                  upload={upload} 
                  config={config} 
                  downloadFile={downloadFile} 
                />
              )}
            </tbody>
          </table>    
        </div>
      </div>
    )
  }
}

type UploadedFileDetailsProps = {
  upload: UploadRecord
  config: BackendConfig
  downloadFile: (uploadId: string) => void
}

const UploadedFileDetails = ({upload, config, downloadFile}: UploadedFileDetailsProps) => {
  const clickHandler = (uploadId) => (e) => {    
    e.preventDefault()
    downloadFile(uploadId)
  }  


  return (
    <tr key={upload.id}>
      <td title={upload.filename}>{capLengthIfNeeded(upload.filename, 60)}</td>       
      <td>{formatDate(upload.uploadedDate)}</td>      
      <td data-cy={`download-original-${upload.filename}`} style={{display: 'flex', justifyContent: 'center'}}>
        <a href={`${uploadsUrl(config)}/${upload.id}`} onClick={clickHandler(upload.id)}  > 
          <DownloadIcon />
        </a>
      </td>
    </tr>
  )
}


const mapStateToProps = (state: RootState, { datasetId }) => {
  const dataset = state.datasets.all?.find(d => d.datasetId === datasetId)

  const uploads = sortByFileName(state.uploads.byDataset[datasetId])
  
  return { 
    uploads,
    config: state.config.backend,
    dataset,
  }
}

const mapDispatchToProps = { 
  downloadFile,
  listUploads: (datasetId: string) => fetchDatasetUploads(datasetId),
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DatasetUploadsTable)
