
export type LoadingSpinnerProps = {
  size?: number
}
export const LoadingSpinner = ({size}: LoadingSpinnerProps) => {
  return (
    <div className="spinner-border" role="status" style={{width: size ? `${size}px`: undefined, height: size ? `${size}px`: undefined}}>
      <span className="visually-hidden">Loading...</span>
    </div>
  )
}