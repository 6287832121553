import { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { connect } from 'react-redux'
import Select from 'react-select'


import { RootState } from 'app/store'
import { ScanListingEntry } from 'uploads/api/Model'
import { uploadFile, fetchDatasetAccessPermissions, updateDatasetAccessPermissions, updateDataset, fetchDataset, downloadCocoAnnotations, updateAnnotationLabel, deleteAnnotationLabel, generateCocoAnnotations, deleteModelAnnotations, deleteModelForms, runAllModels, reprocessUploads } from 'datasets/api/DatasetSlice'
import { fetchAnnotations, listScans } from 'cases/api/ScansSlice'
import { nanoid } from 'nanoid'
import { User } from 'auth/model/User'
import { isOwnerAccess, isWriteAccess, PermissionLevel } from 'auth/model/PermissionLevel'
import { Dataset, DatasetInfo } from 'uploads/model/Dataset'
import { AnnotationMode } from 'uploads/model/AnnotationMode'

import { AccessEntry } from 'auth/model/AccessEntry'
import { DirectoryUpload } from 'uploads/component/DirectoryUpload'
import { MultiUserSelector } from 'app/components/MultiUserSelector'
import { downloadFile, fetchDatasetUploads } from 'uploads/api/UploadsSlice'
import { DatasetAnnotationLabel } from 'uploads/model/DatasetAnnotationLabel'
import { CocoExports } from 'uploads/component/CocoExports'
import { TextArea } from 'app/components/TextArea'
import { DatasetHeader } from './components/DatasetHeader'
import { AnnotationModeSelector } from 'annotation/components/AnnotationModeSelector'
import DatasetPatientsTable from './components/DatasetPatientsTable'
import DatasetScansTable from './components/DatasetScansTable'
import { DatasetSeriesInfoWidget } from './components/DatasetSeriesInfoWidget'
import { AnnotationLabelsTable } from 'annotation/components/AnnotationLabelsTable'
import DatasetUploadsTable from './components/DatasetUploadsTable'
import { Theme } from 'app/model/Theme'


type DatasetPageProps = {
  datasetId: string
  dataset?: Dataset
  cases?: ScanListingEntry[]
  users?: User[]
  currentUserId: string
  accessEntries?: AccessEntry[]
  showDebugUi: boolean
  showExperimentalUi: boolean
  configureDatasetsEnabled: boolean
  theme: Theme
  loadAccessEntries: (datasetId: string) => void
  listCases: (datasetId: string) => void
  updateAccessPermissions: (datasetId: string, userId: string, level: PermissionLevel, refresh: boolean) => void
  fetchDataset: (datasetId: string) => void
  updateDataset: (datasetId: string, name: string, datasetInfo: DatasetInfo) => Promise<void>
  uploadFile: (datasetId: string, formData: FormData) => Promise<void>
  downloadFile: (uploadId: string) => void
  downloadCocoAnnotations: (datasetId: string, exportId: string) => Promise<void>
  generateCocoAnnotations: (datasetId: string) => Promise<string | undefined>
  updateAnnotationLabel: (datasetId: string, label: DatasetAnnotationLabel) => Promise<void>
  deleteAnnotationLabel: (datasetId: string, labelId: string) => Promise<void>    
  deleteModelAnnotations: (datasetId: string) => Promise<void>    
  deleteModelForms: (datasetId: string) => Promise<void>    
  runAllModels: (datasetId: string) => Promise<void>    
  reprocessUploads: (datasetId: string) => Promise<void>
}

const DatasetPage = ({ 
  datasetId, dataset, cases, users, currentUserId, accessEntries, showDebugUi, showExperimentalUi, 
  configureDatasetsEnabled, theme,
  loadAccessEntries, listCases, uploadFile, updateAccessPermissions, updateDataset, fetchDataset, 
  downloadCocoAnnotations, generateCocoAnnotations, updateAnnotationLabel, deleteAnnotationLabel,
  deleteModelAnnotations, deleteModelForms, runAllModels, reprocessUploads
}: DatasetPageProps) => {

  useEffect(() => {if(accessEntries === undefined) loadAccessEntries(datasetId)}, [loadAccessEntries, datasetId])
  useEffect(() => {if(dataset !== undefined && dataset.cases.length > 0 && (cases === undefined || cases.length < 1)) listCases(datasetId) }, 
    [listCases, datasetId, dataset?.cases.length, cases?.length])
  useEffect(() => {if(dataset === undefined || (dataset !== undefined && dataset.seriesInfo === undefined)) fetchDataset(datasetId)}, 
    [fetchDataset, datasetId, dataset === undefined, dataset?.seriesInfo !== undefined])

  const [lastRequested, setLastRequested] = useState<number>(new Date().getTime())
  const lastRequestedRef = useRef<number>(new Date().getTime())
  lastRequestedRef.current = lastRequested

  const minIntervalMs = 2000

  const doCheck = () => {
    const now = new Date().getTime()
    if(lastRequestedRef.current === undefined || now - lastRequestedRef.current > minIntervalMs) {
      listCases(datasetId)
      fetchDataset(datasetId)
      setLastRequested(now)
    }
  }
  useEffect(() => {
    const checkOnCases = (timesCalled: number) => {
      if(dataset !== undefined && dataset.cases.length !== cases?.length) {
        doCheck()              
        setTimeout(() => checkOnCases(timesCalled + 1), minIntervalMs * Math.pow(2, timesCalled))      
      }
    }

    checkOnCases(0)
  }, [dataset?.cases.length, cases?.length])

  const accessLevel = accessEntries?.find(e => e.userId === currentUserId)?.accessLevel || PermissionLevel.none
  const hasOwnerAccess = isOwnerAccess(accessLevel)
  const hasWriteAccess = isWriteAccess(accessLevel)

  const toggleAnnotationMode: () => void = () => {
    if(dataset !== undefined) {      
      const annotationMode = dataset.datasetInfo.annotationMode === AnnotationMode.ACL ? AnnotationMode.Hamstring : AnnotationMode.ACL
      updateDataset(datasetId, dataset.name, {...dataset.datasetInfo, annotationMode })
    }
  }   

  const toggleShouldAnnotate: (seriesDescription: string) => void = (seriesDescription) => {
    if(dataset !== undefined) {      
      const currentForAnnotation = dataset.datasetInfo.seriesDescriptionsForAnnotation

      const updatedForAnnotation = currentForAnnotation.indexOf(seriesDescription) !== -1 ? currentForAnnotation.filter(s => s !== seriesDescription) : [...currentForAnnotation, seriesDescription]

      updateDataset(datasetId, dataset.name, {        
        ...dataset.datasetInfo,
        seriesDescriptionsForAnnotation: updatedForAnnotation        
      })
    }
  }   

  const setSeriesDisplayName: (seriesDescription: string, displayName: string) => void = (seriesDescription, displayName) => {
    if(dataset !== undefined) {      
      const currentDisplayNames = dataset.datasetInfo.seriesDisplayNames

      const updatedDisplayNames = {
        ...currentDisplayNames,
        [seriesDescription]: displayName
      }

      updateDataset(datasetId, dataset.name, {        
        ...dataset.datasetInfo,
        seriesDisplayNames: updatedDisplayNames        
      })
    }
  }

  const setAnnotationApprovers: (annotationApprovers: string[]) => void = (annotationApprovers) => {
    if(dataset !== undefined) {         
      updateDataset(datasetId, dataset.name, {        
        ...dataset.datasetInfo,
        annotationApprovers
      })
    }
  }

  const setAnnotators: (annotationApprovers: string[]) => void = (annotators) => {
    if(dataset !== undefined) {         
      updateDataset(datasetId, dataset.name, {        
        ...dataset.datasetInfo,
        annotators
      })
    }
  }

  const setModelServiceConfig: (modelSerivceConfig: string) => void = (modelSerivceConfig) => {
    if(dataset !== undefined) {         
      updateDataset(datasetId, dataset.name, {        
        ...dataset.datasetInfo,
        modelServiceConfig: modelSerivceConfig
      })
    }
  }

  const showPlayers = false //showExperimentalUi && accessLevel !== PermissionLevel.annotate
  const showCases = !showPlayers

  
  if ( dataset === undefined) {
    return <div style={{margin: '20px'}}>Loading dataset...</div>
  } else {

    return (
      <div className="dataset-page" style={{maxWidth: '800px', margin: '20px'}}>
        <DatasetHeader dataset={dataset} hasWriteAccess={hasWriteAccess} hasSharingAccess={hasOwnerAccess} accessEntries={accessEntries} users={users}
          updateAccessPermissions={updateAccessPermissions} updateDataset={updateDataset} theme={theme} />
        
        <table className="table table-bordered table-striped">
          <tbody>
            <tr>
              <td>Dataset type:</td>
              <td>
                <AnnotationModeSelector 
                  annotationMode={dataset.datasetInfo.annotationMode} 
                  toggleAnnotationMode={toggleAnnotationMode} 
                  disabled={!hasWriteAccess}
                />
              </td>
            </tr>
            <tr>
              <td style={{paddingTop: '16px'}} >Radiologists:</td>
              <td>
                <MultiUserSelector 
                  users={users}
                  disabled={!hasWriteAccess}
                  selectedUserIds={dataset?.datasetInfo?.annotationApprovers} 
                  setSelectedUserIds={setAnnotationApprovers} 
                />
              </td>
            </tr>
            <tr>
              <td style={{paddingTop: '16px'}} >Annotators:</td>
              <td className="annotators-selector-widget">
                <MultiUserSelector 
                  users={users}
                  disabled={!hasOwnerAccess}
                  selectedUserIds={dataset?.datasetInfo?.annotators} 
                  setSelectedUserIds={setAnnotators} 
                />
              </td>
            </tr>
            <tr>
              <td style={{paddingTop: '16px'}} >Anonymised:</td>
              <td>
                <div style={{width: '100px'}}>
                  <Select 
                    options={[{value: true, label: 'Yes'}, {value: false, label: 'No'}]} 
                    isDisabled={!hasWriteAccess}
                    value={dataset.datasetInfo.isAnonymised ? { value: true, label: 'Yes'} : { value: false, label: 'No'} } 
                    onChange={(o) => { if(o !== null) updateDataset(datasetId, dataset.name, {...dataset.datasetInfo, isAnonymised: o.value})}}
                  />
                </div>
              </td>
            </tr>
            { showDebugUi && 
            <tr>
              <td style={{paddingTop: '16px'}} >Model service config:</td>
              <td>
                <TextArea value={dataset.datasetInfo.modelServiceConfig} onChange={setModelServiceConfig} />
              </td>
            </tr>
            }
          </tbody>
        </table>    

        { hasOwnerAccess && cases !== undefined &&        
          <DirectoryUpload 
            uploadFile={(formData) => uploadFile(datasetId, formData)} 
            onComplete={() => {setTimeout(doCheck, minIntervalMs)}}
            hidden={cases.length !== 0}
          />           
        }

        { showPlayers &&
        <div className="scans-list-component" style={{height: '800px', overflowY: 'auto', marginTop: '20px'}}>
          <h2 style={{marginLeft:'10px', paddingTop: '15px'}}>Players</h2>
          
          <DatasetPatientsTable 
            dataset={dataset} 
            scans={cases || []}
            showExperimentalUi={showExperimentalUi}
          />     
        </div>
        }

        { showCases && cases !== undefined && cases.length > 0 &&
        <div className="cases-conatiner" style={{
          width: '1000px', marginTop: '20px'
        }}>
          <h2 style={{marginLeft:'10px'}}>Cases</h2>
                
          <DatasetScansTable 
            datasetId={datasetId} 
            dataset={dataset} 
            scans={cases || []} 
            hasOwnerAccess={hasOwnerAccess}  
            showExperimentalUi={showExperimentalUi}
            showDebugUi={showDebugUi}
          />       
        </div>    
        }     

        { configureDatasetsEnabled &&
        <>
        <DatasetSeriesInfoWidget
          dataset={dataset}
          hasWriteAccess={hasWriteAccess} 
          hasOwnerAccess={hasOwnerAccess}
          toggleShouldAnnotate={toggleShouldAnnotate} 
          setSeriesDisplayName={setSeriesDisplayName} 
        />
          
        <AnnotationLabelsTable
          annotationLabels={dataset.annotationLabels}
          showDebugUi={showDebugUi}
          annotationMode={dataset.datasetInfo.annotationMode}
          updateLabel={(label) => updateAnnotationLabel(datasetId, label)}
          deleteLabel={(labelId => deleteAnnotationLabel(datasetId, labelId))}
          hasWriteAccess={hasWriteAccess}
          hasOwnerAccess={hasOwnerAccess}
        />
        </>
        }

        { hasOwnerAccess && showDebugUi && 
          <div>
            <button 
              style={{ minWidth: '130px', margin: '10px' }}
              type="button" 
              className={'btn btn-primary'}
              onClick={() => deleteModelAnnotations(datasetId)}
            >Delete all model annotations</button>
            <button 
              style={{ minWidth: '130px', margin: '10px' }}
              type="button" 
              className={'btn btn-primary'}
              onClick={() => deleteModelForms(datasetId)}
            >Delete all model forms</button>
            <button 
              style={{ minWidth: '130px', margin: '10px' }}
              type="button" 
              className={'btn btn-primary'}
              onClick={() => runAllModels(datasetId)}
            >Run models for all cases</button>
          </div>
        }

        { configureDatasetsEnabled && hasOwnerAccess &&
        <CocoExports 
          datasetId={datasetId} 
          downloadCocoAnnotations={downloadCocoAnnotations} 
          generateCocoAnnotations={generateCocoAnnotations}
          cocoExports={dataset.cocoExports}
          fetchExports={() => fetchDataset(datasetId)}
          hasWriteAccess={hasWriteAccess}
        />        
        }        
         
        { cases !== undefined && cases.length > 0 && 
        <div style={{marginTop: '20px'}}>
          <h2>Dataset files</h2>
        
          <DatasetUploadsTable datasetId={datasetId} />   

          { showDebugUi &&
          <div style={{
            border: '1px solid lightgrey',
            borderRadius: '1em',
            margin: '5px',
            position: 'relative',
            height: 'max-content',
            blockSize: 'max-content',
            width: 'fit-content', 
            padding: '10px',
            paddingRight: '15px'
          }}>
            <button className="btn btn-primary" style={{ marginLeft: '5px', }} onClick={() => reprocessUploads(datasetId)}>Reprocess uploaded files</button>
          </div>      
          }           

          { hasOwnerAccess && 
            <DirectoryUpload 
              uploadFile={(formData) => uploadFile(datasetId, formData)} 
              onComplete={() => {setTimeout(doCheck, minIntervalMs)}} 
              hidden={cases.length === 0}
            /> 
          }            
        </div>    
        }
      </div>
    )  
  }
}

declare module 'react' {
  interface HTMLAttributes<T> extends AriaAttributes, DOMAttributes<T> {
    webkitdirectory?: string
  }
}

const mapStateToProps = (state: RootState, { datasetId }) => {
  const dataset = state.datasets.all?.find(d => d.datasetId === datasetId)
  const cases = state.scans.list?.filter(scan => scan.datasetId === datasetId)

  const accessEntries = state.datasets.accessEntries[datasetId]

  const users = state.user.users 
  
  return { 
    dataset,
    cases,
    users,
    accessEntries,
    showDebugUi: state.user.features.showDebugUi,
    showExperimentalUi: state.user.features.showExperimentalUi,
    configureDatasetsEnabled: state.user.features.configureDatasets,
    theme: state.user.features.theme
  }
}

const mapDispatchToProps = { 
  uploadFile: (datasetId: string, formData: FormData) => uploadFile(datasetId, formData, nanoid()),
  listCases: (datasetId: string) => listScans(nanoid(), datasetId),
  loadAccessEntries: (datasetId: string) => fetchDatasetAccessPermissions(datasetId, nanoid()),
  updateAccessPermissions: (datasetId: string, userId: string, level: PermissionLevel, refresh: boolean) => updateDatasetAccessPermissions(datasetId, userId, level, refresh, nanoid()),
  updateDataset: (datasetId: string, name: string, datasetInfo: DatasetInfo) => updateDataset(datasetId, name, datasetInfo, nanoid()),
  fetchAnnotations: (scanId: string) => fetchAnnotations(scanId, nanoid()),
  fetchDataset: (datasetId: string) => fetchDataset(datasetId, nanoid()),
  downloadFile,
  listUploads: (datasetId: string) => fetchDatasetUploads(datasetId),
  generateCocoAnnotations: (datasetId: string) => generateCocoAnnotations(datasetId, nanoid()),
  downloadCocoAnnotations: (datasetId: string, exportId: string) => downloadCocoAnnotations(datasetId, exportId, nanoid()),
  updateAnnotationLabel: (datasetId: string, label: DatasetAnnotationLabel) => updateAnnotationLabel(datasetId, label, nanoid()),
  deleteAnnotationLabel: (datasetId: string, labelId: string) => deleteAnnotationLabel(datasetId, labelId, nanoid()),  
  deleteModelAnnotations: (datasetId: string) => deleteModelAnnotations(datasetId, nanoid()),
  deleteModelForms: (datasetId: string) => deleteModelForms(datasetId, nanoid()),
  runAllModels: (datasetId: string) => runAllModels(datasetId, nanoid()),
  reprocessUploads: (datasetId: string) => reprocessUploads(datasetId, nanoid())
}

const ConnectedDatasetPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(DatasetPage)

type DatasetPageWrapperProps = {
  currentUserId: string
}


export default function DatasetPageWrapper({currentUserId}: DatasetPageWrapperProps) {
  const { datasetId } = useParams()
  return (
    <ConnectedDatasetPage datasetId={datasetId} currentUserId={currentUserId} />
  )
}
