
export type VisibilityToggleProps = {
  isVisible: boolean | undefined
  onVisibilityToggle: () => void
}
export const VisibilityToggle = ({
  isVisible, onVisibilityToggle
}: VisibilityToggleProps) => {

  return (
    <i 
      className={`material-icons toggle-maximise ${isVisible ? 'visibility' : 'visibility_off'}`}
      style={{      
        float: 'right',
        position: 'relative',
        cursor: 'pointer',
        fontSize: '20px',
        color: '#9aa0a6'       
      }}
      onClick={onVisibilityToggle}
      title={isVisible ? 'Visible' : 'Hidden'}
    >{isVisible ? 'visibility' : 'visibility_off'}</i>  
    
  )
}
