import { createSlice } from '@reduxjs/toolkit'

import { keycloak } from 'auth/KeyCloak'
import { RootState } from 'app/store'
import { KeycloakProfile } from 'keycloak-js'
import { errorToString } from 'utils/ErrorUtils'
import { CallInfo, User } from './model/User'
import { UsersApi } from './api/UsersApi'
import { ServiceApi } from 'app/api/ErrorApi'
import { nanoid } from 'nanoid'
import { DefaultTheme, Theme } from 'app/model/Theme'
import { UserVisiblilty } from './model/UserVisibility'

interface UserState {
  profile?: KeycloakProfile
  error?: string
  infoMessage?: string
  users?: User[]
  features: UserFeatures
  currentUserId?: string
  userStatuses: { [userId: string]: UserStatus }
  connections: string[]
  incomingCalls: CallInfo[]
  outgoingCalls: string[]
  ongoingCalls: string[]
  remoteNavigate?: Location
  spectateClientId?: string
}

export type UserFeatures = {
  importAnnotations: boolean
  injuryAnalytics: boolean
  createAnnotations: boolean
  editFeatures: boolean
  requestModelRun: boolean
  showUiDebugToggles: boolean
  showDebugUi: boolean
  showExperimentalUi: boolean
  removeSelfPermission: boolean
  configureDatasets: boolean
  alwaysViewDatasets: boolean
  alwaysShowCases: boolean
  theme: Theme
}

export type UserStatus = {
  visibility: UserVisiblilty
  lastUpdated: string
}


const initialState: UserState = {
  profile: undefined,
  error: undefined,
  users: undefined,
  infoMessage: undefined,
  features: {
    importAnnotations: false,
    injuryAnalytics: false,
    createAnnotations: false,
    editFeatures: false,
    requestModelRun: false,
    showUiDebugToggles: localStorage.getItem('showUiDebugToggles') === 'true',
    showDebugUi: localStorage.getItem('showDebugUi') === 'true',
    showExperimentalUi: localStorage.getItem('showExperimentalUi') === 'true',
    removeSelfPermission: false,
    configureDatasets: false,
    alwaysViewDatasets: true,
    alwaysShowCases: true,
    theme: DefaultTheme
  },
  userStatuses: {},
  connections: [],
  incomingCalls: [],
  outgoingCalls: [],
  ongoingCalls: [],
  remoteNavigate: undefined
}

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    loadedUserProfile(state, { payload }) {                  
      state.profile = payload
      state.currentUserId = state.users?.find(u => u.email === state.profile?.email)?.id      

      const anyProfile: any = state.profile 

      if(anyProfile.attributes !== undefined) {
        const features = anyProfile.attributes

        if(features['import-annotations'] !== undefined) {
          state.features.importAnnotations = features['import-annotations'].indexOf('enabled') !== -1
        }
        if(features['create-annotations'] !== undefined) {
          state.features.createAnnotations = features['create-annotations'].indexOf('enabled') !== -1
        }
        if(features['edit-features'] !== undefined) {
          state.features.editFeatures = features['edit-features'].indexOf('enabled') !== -1
        }
        if(features['request-model-run'] !== undefined) {
          state.features.requestModelRun = features['request-model-run'].indexOf('enabled') !== -1
        }
        if(features['remove-self-permission'] !== undefined) {
          state.features.removeSelfPermission = features['remove-self-permission'].indexOf('enabled') !== -1
        }
        if(features['configure-datasets'] !== undefined) {
          state.features.configureDatasets = features['configure-datasets'].indexOf('enabled') !== -1
        }
        if(features['injury-analytics'] !== undefined) {
          state.features.injuryAnalytics = features['injury-analytics'].indexOf('enabled') !== -1
        }

        state.features.alwaysViewDatasets = features['view-datasets']?.includes('enabled') === true     
        state.features.alwaysShowCases = features['always-show-cases']?.includes('enabled') === true     
      }

      if(window.RuntimeConfig.frontend.autoClearErrors){
        state.error = undefined
      }
    },
    usersFailure(state, action) {
      state.error = action.payload
    },
    errorDismissed(state) {
      state.error = undefined
    },
    usersReceived(state, { payload }) {
      if(window.RuntimeConfig.frontend.autoClearErrors){
        state.error = undefined
      }            
      state.users = payload
      state.currentUserId = state.users?.find(u => u.email === state.profile?.email)?.id      
    },
    infoMessageReceived(state, { payload }) {
      state.infoMessage = payload.message
    },
    infoMessageCleared(state) {
      state.infoMessage = undefined
    },
    showUiDebugTogglesToggled(state) {    
      const newValue = !state.features.showUiDebugToggles
      state.features.showUiDebugToggles = newValue
      localStorage.setItem('showUiDebugToggles', newValue.toString())
    },
    showDebugUiToggled(state) {      
      const newValue = !state.features.showDebugUi
      state.features.showDebugUi = newValue
      localStorage.setItem('showDebugUi', newValue.toString())
    },
    showExperimentalUiToggled(state) {      
      const newValue = !state.features.showExperimentalUi
      state.features.showExperimentalUi = newValue
      localStorage.setItem('showExperimentalUi', newValue.toString())
    },
    updateUserStatus(state, { payload }) {
      const { userId, visibility } = payload
      state.userStatuses[userId] = {
        visibility,
        lastUpdated: new Date().toISOString()
      }
    },
    addIncomingCall(state, { payload }) {    
      state.incomingCalls = [...state.incomingCalls, payload]
    },
    removeIncomingCall(state, { payload }) {
      state.incomingCalls = state.incomingCalls.filter(c => c.userId !== payload.userId)
    },
    addOutgoingCall(state, { payload }) {
      const { userId } = payload
      if(!state.outgoingCalls.includes(userId)){
        state.outgoingCalls = [...state.outgoingCalls, userId]
      }      
    },
    removeOutgoingCall(state, { payload }) {
      const { userId } = payload
      state.outgoingCalls = state.outgoingCalls.filter(c => c !== userId)
    },
    addOngoingCall(state, { payload }) {
      const { peerConnectionId } = payload 
      if(!state.ongoingCalls.includes(peerConnectionId)) {
        state.ongoingCalls = [...state.ongoingCalls, peerConnectionId]      
      }      
    },
    removeOngoingCall(state, { payload }) {
      const { connectionId } = payload
      state.ongoingCalls = state.ongoingCalls.filter(c => c !== connectionId)
    },
    removeOngoingCalls(state) {
      state.ongoingCalls = []
    },
    remoteNavigte(state, { payload }) {
      const { location, spectateClientId } = payload
      
      state.remoteNavigate = location
      state.spectateClientId = spectateClientId
    },
    clearRemoteNavigte(state,) {    
      state.remoteNavigate = undefined
    }
  }
})

export const { 
  loadedUserProfile, 
  usersFailure,
  errorDismissed,
  usersReceived,
  infoMessageReceived,
  infoMessageCleared,
  showUiDebugTogglesToggled,
  showExperimentalUiToggled,
  showDebugUiToggled,
  updateUserStatus,
  addIncomingCall,
  removeIncomingCall,
  addOutgoingCall,
  removeOutgoingCall,
  addOngoingCall,
  removeOngoingCalls,
  removeOngoingCall,
  remoteNavigte,
  clearRemoteNavigte
} = userSlice.actions

export default userSlice.reducer

const usersApi = new UsersApi(window.RuntimeConfig.backend)
const errorApi = new ServiceApi(window.RuntimeConfig.backend)

export const loadProfile = () => async dispatch => {
  try {
    const profile = await keycloak.loadUserProfile()
    dispatch(loadedUserProfile(profile))
  } catch (err) {
    dispatch(reportError('loadProfile', err))
  }
}

export const listUsers = (correlationId: string) => async dispatch => {
  try {
    const users = await usersApi.listUsers(correlationId)
    dispatch(usersReceived(users))
  } catch (err) {
    dispatch(reportError('listUsers', err, correlationId))
  }
}

export const updateUserFeature = (userId: string, featureId: string, status: string, correlationId: string) => async dispatch => {
  try {
    await usersApi.updateUserFeature(userId, featureId, status, correlationId)
    await dispatch(loadProfile())
  } catch (err) {
    dispatch(reportError('updateUserFeature', err, correlationId))
  }
}
export const updateUserFeatures = (userId: string, features: any, correlationId: string) => async dispatch => {
  try {
    await usersApi.updateUserFeatures(userId, features, correlationId)
    await dispatch(loadProfile())
  } catch (err) {
    dispatch(reportError('updateUserFeatures', err, correlationId))
  }
}

export const uploadAvatar = (userId: string, formData: FormData, correlationId: string) => async dispatch => {
  try {
    await usersApi.updateUserAvatar(userId, formData, correlationId)
  } catch (err) {
    dispatch(reportError('uploadAvatar', err, correlationId))
  }
}

export const toggleShowUiDebugToggles = () => async dispatch => {
  try {
    await dispatch(showUiDebugTogglesToggled())
  } catch (err) {
    dispatch(reportError('toggleShowUiDebugToggles', err))
  }
}

export const toggleShowDebugUi = () => async dispatch => {
  try {
    await dispatch(showDebugUiToggled())
  } catch (err) {
    dispatch(reportError('toggleShowDebugUi', err))
  }
}

export const toggleShowExperimentalUi = () => async dispatch => {
  try {
    await dispatch(showExperimentalUiToggled())
  } catch (err) {
    dispatch(reportError('toggleShowExperimentalUi', err))
  }
}

export const setCookie = (correlationId: string) => async dispatch => {
  try {
    await usersApi.setCookie(correlationId)
  } catch (err) {
    dispatch(reportError('setCookie', err, correlationId))
  }
}

export const setUserInfoMessage = (message: string) => async dispatch => {
  dispatch(infoMessageReceived({message}))
}

export const clearUserInfoMessage = () => async dispatch => {
  dispatch(infoMessageCleared())
}

export const clearUserError = () => async dispatch => {
  dispatch(errorDismissed())
}

export const selectUserInfo = (state: RootState) => state.user.profile

export const reportError = (method: string, err: any, correlationId?: string) => async dispatch => {  
  try {
    console.log({method: err})
    const message = errorToString(err)    
    dispatch(usersFailure(message))
    await errorApi.reportError(method, message, correlationId || nanoid())
  } catch (err2) {
    console.log({reportErrorFailure: err2})
  }
}
