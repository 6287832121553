import { Annotation } from 'annotation/model/Annotation'
import { Scan } from 'uploads/api/Model'
import { FormValues, getLargestTearAnnotation } from 'uploads/component/HamstringForm'


export type HamstringFormDisplayProps = {
  current?: FormValues
  scan: Scan
  annotations: Annotation[]
  displayAnnotation: (annotation: Annotation) => void
}

const round2 = (number: number) => Math.round((number + Number.EPSILON) * 100) / 100

export const HamstringFormDisplay = ({  
  current, scan, annotations, displayAnnotation
}: HamstringFormDisplayProps) => {

  if(current === undefined) {
    return <></>
  }

  const tearAnnotation = getLargestTearAnnotation(annotations, current)

  const eliteAiMeasurementsExist = scan.additionalBamicInfo?.tearLengthCoronalEccentric !== undefined ||
    scan.additionalBamicInfo?.tearLengthCoronal !== undefined ||
    scan.additionalBamicInfo?.areaOfTearAtLargestExtent !== undefined  ||
    scan.additionalBamicInfo?.areaOfMuscleAtLargestExtent !== undefined  ||
    scan.additionalBamicInfo?.crossSectionalRatioOfMuscleTear !== undefined  ||
    scan.additionalBamicInfo?.tearAreaIntratendinous !== undefined  ||
    scan.additionalBamicInfo?.tearAreaTendonTorn !== undefined  ||
    scan.additionalBamicInfo?.crossSectionalRatioOfTendonTear !== undefined  ||
    scan.additionalBamicInfo?.highestCsaPercent !== undefined  ||
    scan.additionalBamicInfo?.highestIntratendinousPercent !== undefined  ||
    scan.additionalBamicInfo?.distanceToTJunction !== undefined 

  return (
    <div style={{marginTop: '20px', padding: '10px'}}>
      <h5 style={{padding: '10px'}}>BAMIC classification</h5>
      <div>
        { current.grade !== undefined &&
          <div style={{fontWeight: 'bold', fontSize: '1rem', marginBottom: '10px'}}>Grade: {current.grade}</div>
        }
        { current.isThereATear === 'yes' &&
          <div>
            - Tear detected
            { tearAnnotation !== undefined &&
            <span style={{ marginLeft: '20px', cursor: 'pointer', color: '#0d6efd', textDecoration: 'underline' }} onClick={() => displayAnnotation(tearAnnotation)}>
              View tear
            </span>
            }
          </div>
        }
        { current.isThereATear === 'no' &&
          <div>- No tear detected</div>
        }
        { current.inWhichMuscle === 'long_head_biceps_femoris' &&
          <div>- Location: Long head biceps femoris</div>
        }
        { current.inWhichMuscle === 'short_head_biceps_femoris' &&
          <div>- Location: Short head biceps femoris</div>
        }
        { current.inWhichMuscle === 'semitendinosus' &&
          <div>- Location: Semitendinosus</div>
        }
        { current.inWhichMuscle === 'semimembranosus' &&
          <div>- Location: Semimembranosus</div>
        }
        { current.proximalOrDistal === 'proximal' &&
          <div>- Tear is proximal</div>
        }
        { current.proximalOrDistal === 'distal' &&
          <div>- Tear is distal</div>
        }
        { current.tearComplete === 'yes' &&
          <div>- Tear is complete</div>
        }
        { current.tearComplete === 'no' &&
          <div>- Tear is not complete</div>
        }
        { current.locusOfTear === 'muscle' &&
          <div>- Locus of tear: Muscle</div>
        }
        { current.locusOfTear === 'tendon' &&
          <div>- Locus of tear: Tendon</div>
        }
        { current.locusOfTear === 'myofascial' &&
          <div>- Locus of tear: Myofascial</div>
        }
        { current.locusOfTear === 'muscle_belly_mtj' &&
          <div>- Locus of tear: Muscle Belly (MTJ)</div>
        }
        { current.locusOfTear === 'intra-tendinous' &&
          <div>- Locus of tear: Intra-tendinous</div>
        }
        { current.signalChange === 'less_than_ten_percent' &&
          <div>- High signal change into the muscle belly cross sectional area: {`< 10%`}</div>
        }
        { current.signalChange === 'ten_to_fifty_percent' &&
          <div>- High signal change into the muscle belly cross sectional area: {`> 10% and < 50%`}</div>
        }
        { current.signalChange === 'over_fifty_percent' &&
          <div>- High signal change into the muscle belly cross sectional area: {`> 50%`}</div>
        }
        { current.tearCranioCaudalLength === 'less_than_five_cm' &&
          <div>- Tear cranio-caudal length: {`< 5cm`}</div>
        }
        { current.tearCranioCaudalLength === 'five_to_fifteen_cm' &&
          <div>- Tear cranio-caudal length: {`> 5cm and < 15cm`}</div>
        }
        { current.tearCranioCaudalLength === 'over_fifteen_cm' &&
          <div>- Tear cranio-caudal length: {`> 15cm`}</div>
        }
        { current.crossSectionalArea === 'less_than_fifty_percent' &&
          <div>- Cross sectional area of tendon involvement: {`< 50%`}</div>
        }
        { current.crossSectionalArea === 'over_fifty_percent' &&
          <div>- Cross sectional area of tendon involvement: {`> 50%`}</div>
        }
        { current.longitudinalLengthOfTendonInvolvement === 'less_than_five_cm' &&
          <div>- Longitudinal length of tendon involvement: {`< 5cm`}</div>
        }
        { current.longitudinalLengthOfTendonInvolvement === 'over_five_cm' &&
          <div>- Longitudinal length of tendon involvement: {`> 5cm`}</div>
        }
        { current.fibrosisLongHeadBicepsFemoris === true &&
          <div>- Long head biceps femoris fibrosis detected</div>
        }
        { current.fibrosisShortHeadBicepsFemoris === true &&
          <div>- Short head biceps femoris fibrosis detected</div>
        }
        { current.fibrosisSemitendinosus === true &&
          <div>- Semitendinosus fibrosis detected</div>
        }
        { current.fibrosisSemimembranosus === true &&
          <div>- Semimembranosus fibrosis detected</div>
        }
        { current.comments !== undefined && current.comments.trim().length > 0 &&
          <div style={{padding: '15px'}}>
            <div>Additional comments:</div>
            <div>{current.comments}</div>
          </div>
        } 
      </div>
      <div style={{marginTop: '20px'}}>
        { eliteAiMeasurementsExist &&
        <h5 style={{padding: '10px'}}>Elite AI measurements</h5>
        }
        { scan.additionalBamicInfo?.tearLengthCoronalEccentric !== undefined &&
          <div>- Tear eccentric length in coronal plane: {round2(scan.additionalBamicInfo.tearLengthCoronalEccentric / 10)}cm</div>
        }
        { scan.additionalBamicInfo?.tearLengthCoronal !== undefined &&
          <div>- Tear cranio-caudal length: {round2(scan.additionalBamicInfo.tearLengthCoronal / 10)}cm</div>
        }
        { scan.additionalBamicInfo?.areaOfTearAtLargestExtent !== undefined &&
          <div>- Area of tear at largest extent: {round2(scan.additionalBamicInfo.areaOfTearAtLargestExtent / 100)}cm²</div>
        }
        { scan.additionalBamicInfo?.areaOfMuscleAtLargestExtent !== undefined &&
          <div>- Area of muscle at largest tear: {round2(scan.additionalBamicInfo.areaOfMuscleAtLargestExtent)}cm²</div>
        }
        { scan.additionalBamicInfo?.crossSectionalRatioOfMuscleTear !== undefined &&
          <div>- CSA of (largest) tear in muscle: {scan.additionalBamicInfo.crossSectionalRatioOfMuscleTear}%</div>
        }
        { scan.additionalBamicInfo?.tearAreaIntratendinous !== undefined &&
          <div>- Area of tear at largest extent: {round2(scan.additionalBamicInfo.tearAreaIntratendinous)}cm²</div>
        }
        { scan.additionalBamicInfo?.tearAreaTendonTorn !== undefined &&
          <div>- Area of tendon at largest tear extent: {round2(scan.additionalBamicInfo.tearAreaTendonTorn)}cm²</div>
        }
        { scan.additionalBamicInfo?.crossSectionalRatioOfTendonTear !== undefined &&
          <div>- CSA of (largest) tear in tendon: {scan.additionalBamicInfo.crossSectionalRatioOfTendonTear}%</div>
        }
        { scan.additionalBamicInfo?.highestCsaPercent !== undefined &&
          <div>- Largest CSA of tear in muscle: {scan.additionalBamicInfo.highestCsaPercent}%</div>
        }
        { scan.additionalBamicInfo?.highestIntratendinousPercent !== undefined &&
          <div>- Largest CSA of tear in tendon: {scan.additionalBamicInfo.highestIntratendinousPercent}%</div>
        }
        { scan.additionalBamicInfo?.distanceToTJunction !== undefined &&
          <div>- Distance to t-junction: {round2(scan.additionalBamicInfo.distanceToTJunction / 10)}cm</div>
        }
      </div>
    </div>
  )
}
