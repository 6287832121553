import { AnnotationShape } from "./AnnotationShape"

export type Annotation = {
  annotationId: string
  annotationVersion: number
  scanId: string
  SeriesInstanceUID: string
  InstanceNumber?: number
  label: string
  shape: AnnotationShape
  createdBy?: string
  updatedBy?: string
  createdDate: string
  importId?: string
  modelId?: string
  modelName?: string
  approvals: AnnotationApproval[]
  derivedData?: DerivedAnnotationData
  displayMode?: string  // standard, debug 
  featureId?: string
}

export type DerivedAnnotationData = {
  length?: number
  area?: number
  distanceToTJunction?: number
}

export const distinctAnnotationCreators: (annotations: Annotation[] | undefined) => string[] = (annotations) => {
  if(annotations === undefined) {
    return []
  }

  const userIds: string[] = [] 
  annotations.forEach(a => {
    if(a.createdBy !== undefined && !userIds.includes(a.createdBy)) {
      userIds.push(a.createdBy)
    }
  })
  
  return userIds
}

export type AnnotationApproval = {
  annotationId: string
  userId: string
  status: AnnotationApprovalStatus
}

export enum AnnotationApprovalStatus {
  Approved = "approved",
  Rejected = "rejected"
}

