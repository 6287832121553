import axios from 'axios'
import retry from 'async-retry'

import { currentAuthHeaders } from 'auth/KeyCloak' 
import { BackendConfig } from 'config/ApplicationConfig'
import { DefaultScanInfo, ImagesForScanResponse, ListAnnotationsResponse, ListClassificationFormsResponse, ListPermissionsResponse, ListScansResponse, RequestModelRunResponse, Scan, ScanInfo,  } from '../../uploads/api/Model'
import { Annotation, AnnotationApprovalStatus } from 'annotation/model/Annotation'
import { FormValues } from 'uploads/component/HamstringForm'
import { PermissionLevel } from 'auth/model/PermissionLevel'
import { Hamstring } from 'uploads/model/BodyRegion'

const ensureDefaults: (scan: Scan) => void = (scan) => {
  if(scan.scanInfo === undefined) {
    scan.scanInfo = DefaultScanInfo
  }
  if(scan.scanInfo.annotationApprovals === undefined) {
    scan.scanInfo.annotationApprovals = []
  }
  if(scan.scanInfo.seriesDisplayNames === undefined) {
    scan.scanInfo.seriesDisplayNames = {}
  }
  if(scan.datasetId === undefined && scan.scanInfo.bodyRegionId === undefined) {
    scan.scanInfo = {
      ...scan.scanInfo,
      bodyRegionId: Hamstring.id
    }
  }
  if(scan.scanInfo.userAnnotationColors === undefined) {
    scan.scanInfo.userAnnotationColors = {}
  }
  if(scan.scanInfo.orderedSeriesIds === undefined) {
    scan.scanInfo.orderedSeriesIds = []
  } 
  if(scan.scanInfo.amsData !== undefined) {
    scan.scanInfo.amsData = undefined
  }
}

export class ScansApi  {

  scansUrl: string
  scanUrl: (scanId: string) => string
  annotationsUrl: (scanId: string) => string
  annotationUrl: (scanId: string, annotationId: string) => string
  annotationApprovalUrl: (scanId: string, annotationId: string) => string
  classificationFormsUrl: (scanId: string) => string
  classificationFormUrl: (scanId: string, classificationFormId: string) => string
  imagesForScanUrl: (scanId: string) => string
  imageUrl: (imageId: string) => string  
  modelOutputUrl: (outputId: string) => string
  modelRunUrl: (scanId: string) => string
  retryCount: number

  constructor(config: BackendConfig) {
    this.retryCount = config.retryCount
    this.scansUrl = `${config.url}/eliteai/scans`
    this.scanUrl = (scanId) => `${this.scansUrl}/${scanId}`
    this.annotationsUrl = (scanId) => `${this.scanUrl(scanId)}/annotations`
    this.annotationUrl = (scanId, annotationId) => `${this.annotationsUrl(scanId)}/${annotationId}`
    this.annotationApprovalUrl = (scanId: string, annotationId: string) => `${this.annotationUrl(scanId, annotationId)}/approval`
    this.classificationFormsUrl = (scanId) => `${this.scanUrl(scanId)}/classifications`
    this.classificationFormUrl = (scanId, classificationFormId) => `${this.classificationFormsUrl(scanId)}/${classificationFormId}`
    this.imagesForScanUrl = (scanId) => `${this.scanUrl(scanId)}/images`
    this.imageUrl = (imageId) => `${config.url}/eliteai/images/${imageId}`    
    this.modelOutputUrl = (outputId) => `${config.url}/eliteai/images/model-outputs/${outputId}`
  
    this.modelRunUrl = (scanId) => `${this.scanUrl(scanId)}/model-run`
  }

  async createScan(scanId: string, correlationId: string) {
    return retry(async (bail) => {
      const headers = await currentAuthHeaders(correlationId)

      const response = await axios.post<Scan>(this.scansUrl, { scanId }, { headers })
    
      const scan = response.data
      ensureDefaults(scan)
      return scan
    }, {
      retries: this.retryCount
    })    
  }

  async listScans(correlationId: string, datasetId: string | undefined) {
    return retry(async (bail) => {
      const headers = await currentAuthHeaders(correlationId)

      const response = await axios.get<ListScansResponse>(this.scansUrl, { headers, params: { datasetId } })

      return response.data
    }, {
      retries: this.retryCount
    })    
  }

  async fetchScan(scanId: string, correlationId: string) {
    return retry(async (bail) => {
      const headers = await currentAuthHeaders(correlationId)

      const response = await axios.get<Scan>(this.scanUrl(scanId), { headers })

      const scan = response.data
      ensureDefaults(scan)
      return scan
    }, {
      retries: this.retryCount
    })    
  }

  async updateScan(scanId: string, name: string, scanInfo: ScanInfo | undefined, studyDate: string | undefined, correlationId: string) {
    return retry(async (bail) => {
      const headers = await currentAuthHeaders(correlationId)

      await axios.put(this.scanUrl(scanId), { name, scanInfo, studyDate }, { headers })
    }, {
      retries: this.retryCount
    })    
  }

  async fetchAccessPermissions(scanId: string, correlationId: string) {
    return retry(async (bail) => {
      const headers = await currentAuthHeaders(correlationId)

      const response = await axios.get<ListPermissionsResponse>(`${this.scanUrl(scanId)}/permissions`, { headers })

      return response.data
    }, {
      retries: this.retryCount
    })    
  }

  async updateAccessPermissions(scanId: string, userId: string, accessLevel: PermissionLevel, correlationId: string) {
    return retry(async (bail) => {
      const headers = await currentAuthHeaders(correlationId)

      const response = await axios.post(`${this.scanUrl(scanId)}/permissions`, {userId, accessLevel},  { headers })

      return response.data
    }, {
      retries: this.retryCount
    })    
  }

  async updateAnnotation(scanId: string, annotation: Annotation, correlationId: string) {
    return retry(async (bail) => {
      const headers = await currentAuthHeaders(correlationId)

      const url = this.annotationUrl(scanId, annotation.annotationId)
      
      const body = {
        annotationVersion: annotation.annotationVersion,
        SeriesInstanceUID: annotation.SeriesInstanceUID, 
        InstanceNumber: annotation.InstanceNumber, 
        label: annotation.label, 
        shape: annotation.shape
      }

      const response = await axios.put<Scan>(url, body, { headers })

      return response.data
    }, {
      retries: this.retryCount
    })    
  }

  async fetchAnnotations(scanId: string, correlationId: string) {
    return retry(async (bail) => {
      
      const headers = await currentAuthHeaders(correlationId)

      const url = this.annotationsUrl(scanId)

      const response = await axios.get<ListAnnotationsResponse>(url, { headers })

      return response.data
    }, {
      retries: this.retryCount
    })    
  }

  async deleteAnnotation(scanId: string, annotationId: string, correlationId: string) {
    return retry(async (bail) => {
      const headers = await currentAuthHeaders(correlationId)

      const url = this.annotationUrl(scanId, annotationId)

      const response = await axios.delete<void>(url, { headers })

      return response.data
    }, {
      retries: this.retryCount
    })    
  }

  async restoreAnnotation(scanId: string, annotationId: string, correlationId: string) {
    return retry(async (bail) => {
      const headers = await currentAuthHeaders(correlationId)

      const annotationUrl = this.annotationUrl(scanId, annotationId)

      const response = await axios.post<void>(`${annotationUrl}/restore`, null, { headers })

      return response.data
    }, {
      retries: this.retryCount
    })    
  }

  async updateAnnotationApprovalStatus(scanId: string, annotationId: string, status: AnnotationApprovalStatus | undefined, correlationId: string) {
    return retry(async (bail) => {
      const headers = await currentAuthHeaders(correlationId)

      const approvalUrl = this.annotationApprovalUrl(scanId, annotationId)

      await axios.post<void>(approvalUrl, { status }, { headers })
    }, {
      retries: this.retryCount
    })    
  }

  async saveClassificationForm(scanId: string, formData: FormValues, correlationId: string) {    
    return retry(async (bail) => {
      const headers = await currentAuthHeaders(correlationId)

      const url = this.classificationFormsUrl(scanId)
      const body = { formData }

      const response = await axios.post<Scan>(url, body, { headers })

      return response.data
    }, {
      retries: this.retryCount
    })    
  }

  async updateClassificationForm(
    scanId: string,
    classificationFormId: string, 
    currentVersion: number, 
    formData: FormValues,
    correlationId: string) {

      return retry(async (bail) => {
        const headers = await currentAuthHeaders(correlationId)

      const url = this.classificationFormUrl(scanId, classificationFormId)
      const body = {currentVersion, formData}

      const response = await axios.put<Scan>(url, body, { headers })

      return response.data
    }, {
      retries: this.retryCount
    })    
  }

  async deleteClassificationForm(
    scanId: string,
    classificationFormId: string, 
    currentVersion: number, 
    correlationId: string) {

      return retry(async (bail) => {
        const headers = await currentAuthHeaders(correlationId)

      const url = this.classificationFormUrl(scanId, classificationFormId)
      const body = {currentVersion}

      await axios.delete<Scan>(url, { headers, data: body })
    }, {
      retries: this.retryCount
    })    
  }

  async fetchClassificationForms(scanId: string, correlationId: string) {
    return retry(async (bail) => {
      const headers = await currentAuthHeaders(correlationId)

      const url = this.classificationFormsUrl(scanId)

      const response = await axios.get<ListClassificationFormsResponse>(url, { headers })

      return response.data
    }, {
      retries: this.retryCount
    })    
  }

  async imagesForScan(scanId: string, correlationId: string) {
    return retry(async (bail) => {
      const headers = await currentAuthHeaders(correlationId)

      const response = await axios.get<ImagesForScanResponse>(this.imagesForScanUrl(scanId), { headers })

      return response.data
    }, {
      retries: this.retryCount
    })    
  }

  async requestModelRun(scanId: string, correlationId: string) {
    return retry(async (bail) => {
      const headers = await currentAuthHeaders(correlationId)

      const response = await axios.post<RequestModelRunResponse>(this.modelRunUrl(scanId), {}, { headers })

      return response.data.modelRunId
    }, {
      retries: this.retryCount
    })    
  }

  async fetchReportInfoStatus(scanId: string, correlationId: string) {
    return retry(async (bail) => {
      const headers = await currentAuthHeaders(correlationId)

      const response = await axios.get(`${this.scanUrl(scanId)}/report-info/status`, { headers })

      return response.data
    }, {
      retries: this.retryCount
    })    
  }

  async saveReportImages(scanId: string, formData: FormData, correlationId: string) {
    return retry(async (bail) => {
      const headers = await currentAuthHeaders(correlationId)

      const response = await axios.post(`${this.scanUrl(scanId)}/model-outputs`, formData, { headers })

      return response.data
    }, {
      retries: this.retryCount
    })    
  }
}
