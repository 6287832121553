import { InlineEdit } from "app/components/InLineEdit"
import { Theme } from "app/model/Theme"
import SharingWidget from "auth/components/SharingWidget"
import { AccessEntry } from "auth/model/AccessEntry"
import { PermissionLevel } from "auth/model/PermissionLevel"
import { User } from "auth/model/User"
import { useState } from "react"
import { Dataset, DatasetInfo } from "uploads/model/Dataset"
import { capLengthIfNeeded } from "utils/TextUtils"

export type DatasetHeaderProps = {
  dataset: Dataset
  hasWriteAccess: boolean
  hasSharingAccess: boolean
  users?: User[]
  accessEntries?: AccessEntry[]
  theme: Theme
  updateAccessPermissions: (datasetId: string, userId: string, level: PermissionLevel, refresh: boolean) => void  
  updateDataset: (datasetId: string, name: string, datasetInfo: DatasetInfo) => Promise<void>
}
export const DatasetHeader = ({
  dataset, hasWriteAccess, hasSharingAccess, users, accessEntries, theme,
  updateDataset, updateAccessPermissions
}: DatasetHeaderProps) => {


  return (
    <div style={{display: 'flex', marginBottom: '8px', width: ''}}>             
      <div style={{fontSize: '2.5rem', fontWeight: 500}}>
        <InlineEdit 
          value={dataset.name} 
          disabled={!hasWriteAccess}
          width={700} 
          color={theme.sectionHeaderColor}
          setValue={rename => updateDataset(dataset.datasetId, rename, dataset.datasetInfo)} 
        />   
      </div> 

      { hasSharingAccess &&
        <SharingWidget 
          entitiyName={"dataset"}
          accessEntries={accessEntries} 
          users={users}
          addPermission={(userId, level, refresh) => updateAccessPermissions(dataset.datasetId, userId, level, refresh)} 
          theme={theme}
        />   
      }
    </div>
  )
}
