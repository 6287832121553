import _ from 'lodash'

import { ScanListingEntry } from "uploads/api/Model"
import { DatasetAggregateCaseInfo, DatasetAmsData } from "uploads/model/Dataset"
import { parseStudyDate } from "utils/DateUtils"

export type GradedInjury = {
  playerId: string
  injuryId: string
  daysInjured: number
  grade: string
  row: string[]
}

export const filterAmsRows: (amsData: string[][], gradedInjuries: GradedInjury[]) => string[][] = (amsData, gradedInjuries) => {
  return amsData.filter(row => 
    gradedInjuries.find(i => row['InjuryID'] === i.injuryId) !== undefined
  )
}

export const getGradedInjuries: (
  cases: ScanListingEntry[], amsData: string[][], caseInfos: DatasetAggregateCaseInfo[], daysInjuredColumn: string
) => GradedInjury[] = (cases, amsData, caseInfos, daysInjuredColumn) => {

  const is = amsData.reduce((acc: GradedInjury[], row: string[]) => {
    const playerId = row['PlayerID']
    const injuryId = row['InjuryID']
    const dateInjured = row['Date Injured']
    const daysInjured = Number.parseInt(row[daysInjuredColumn], 10)

    if(Number.isInteger(daysInjured)) {
      const matchingPlayer = cases.filter(s => s.scanInfo?.patientId === playerId)
      console.log(`${matchingPlayer.length} matches, playerId: ${playerId}, playerIds: ${cases.map(c => c.scanInfo?.patientId)}`)
      const scans = matchingPlayer.map(s => {
        const studyDate = parseStudyDate(s.studyDate)

        if(studyDate !== undefined) {
          const dateInjuredDate = new Date(dateInjured)

          const diff = Math.abs(dateInjuredDate.getTime() - studyDate.getTime())
          const days = Math.floor(diff / (1000 * 60 * 60 * 24))

          return {
            scan: s,
            days
          }
        } else {
          console.log(`undefined study date: studyDate: ${studyDate}, s.: ${s.studyDate}`)
          return undefined
        }
      })

      const chosen = _.orderBy(scans.filter(s => s !== undefined), s => s?.days)[0]
      const caseInfo = caseInfos.find(i => i.caseId === chosen?.scan.scanId)
      const grades = caseInfo?.classifications
      
      if(grades?.length !== undefined && grades.length >= 1) {
        return [...acc, {
          playerId,
          injuryId,
          daysInjured,
          grade: grades[0],
          row
        }]
      } else {
        return acc
      }
    } else {
      console.log(`Non-integer days injured: ${row[daysInjuredColumn]}, daysInjuredColumn: ${daysInjuredColumn}`);
      
      return acc
    }
  }, [])

  console.log(`${is.length} graded injuries from ${amsData.length} rows`)

  return is
}
