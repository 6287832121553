import {useEffect} from 'react'
import { connect } from 'react-redux'
import { nanoid } from 'nanoid'

import { listScans } from 'cases/api/ScansSlice'
import { RootState } from 'app/store'
import { ScanListingEntry } from 'uploads/api/Model'
import ScansTable from 'uploads/component/ScansTable'
import { DatasetListingEntry } from 'uploads/model/Dataset'
import { LoadingSpinner } from 'app/components/LoadingSpinner'

export type AnnotationsPageProps = {
  listScans: () => void
  scans?: ScanListingEntry[]
  datasets?: DatasetListingEntry[]
  currentUserId: string
}

const AnnotationsPage = ({listScans, scans, datasets}: AnnotationsPageProps) => {
  useEffect(listScans, [listScans])

  return(
    <div style={{margin: '20px'}}>
      { (scans === undefined || datasets === undefined) ? 
        <LoadingSpinner />
        :
        <div style={{height: '1000px', width: '1020px', overflowY: 'auto'}}>
          <ScansTable titleText="Cases available for annotation" scans={scans} datasets={datasets} linkTo={(scanId) => `annotations/${scanId}`} />    
        </div>
      }
    </div>
  )
}

const mapStateToProps = (state: RootState) => {
  const scans = state.scans.list
  const datasets = state.datasets.list

  return  { 
    scans,
    datasets
  }
}

const mapDispatchToProps = { 
  listScans: () => listScans(nanoid(), undefined)
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AnnotationsPage)
