import Select from 'react-select'

import { SelectOption } from "utils/SelectOption"


export type PlaneSelectorProps = {
  selectedPlanes: string[]
  disabled: boolean
  setSelectedPlanes: (planes: string[]) => void
}

export const Axial = 'Axial'
export const Coronal = 'Coronal'
export const Sagittal = 'Sagittal'

const options: SelectOption<string>[] = [Axial, Coronal, Sagittal].map(p => ({value: p, label: p}))

export const PlaneSelector = ({
  selectedPlanes,
  disabled,
  setSelectedPlanes
}: PlaneSelectorProps) => {

  const current = options.filter(o => selectedPlanes.includes(o.value))

  return (
    <div className="plane-selector" style={{ marginRight: '10px' }}>
      <Select 
        classNamePrefix="react-select-plane-selector"
        options={options} 
        isMulti
        isDisabled={disabled}
        placeholder="Select planes" 
        value={current} 
        onChange={(options) => setSelectedPlanes(options.map(o => o.value))}
        /> 
    </div>
  )
  
}