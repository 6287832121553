import { ScanListingEntry } from "uploads/api/Model"
import { Dataset } from "./Dataset"

export enum AnnotationMode {
  ACL = "ACL",
  Hamstring = "Hamstring"
}


export const getAnnotationMode: (dataset: Dataset | undefined, scan: ScanListingEntry | undefined) => AnnotationMode = (dataset, scan) => {
  if(dataset?.datasetInfo?.annotationMode !== undefined){
    return dataset.datasetInfo.annotationMode
  }

  if(scan?.scanInfo?.bodyRegionId === 'knee') {
    return AnnotationMode.ACL
  }

  return AnnotationMode.Hamstring
}