export type Enter3dMaterialProps = {
  isActive: boolean | undefined
  size: number
  onToggle: () => void
}
export const Enter3dMaterial = ({
  isActive, size,
  onToggle
}: Enter3dMaterialProps) => {

  return (
    <i 
      className={`material-icons`}
      style={{
        float: 'right',
        position: 'relative',
        cursor: 'pointer',
        fontSize: `${size}x`,
        color: '#9aa0a6',
      }}
      onClick={(e) => {
        e.stopPropagation()
        onToggle()
      }}
      title={isActive ? 'Exit 3D' : 'Enter 3D'}
    >{'3d_rotation'}</i>    
  )
}
