import { getUserAvatarUrl, UsersApi } from 'auth/api/UsersApi'
import { AccessEntry } from 'auth/model/AccessEntry'
import { permissionLevelDisplayText, permissionLevelToolTip } from 'auth/model/PermissionLevel'
import { User } from 'auth/model/User'
import { Link } from 'react-router-dom'
import { Dataset } from 'uploads/model/Dataset'
import { capLengthIfNeeded } from 'utils/TextUtils'

export type UsersAccessListProps = {
  accessEntries?: AccessEntry[]
  users?: User[]
  caseDataset?: Dataset
}

type UserPermissionRow = User & AccessEntry

export const UsersAccessList = ({accessEntries, users, caseDataset}: UsersAccessListProps) => { 

  const userAvatarUrl = getUserAvatarUrl(window.RuntimeConfig.backend)

  if(accessEntries === undefined || users === undefined) {
    return <div>Loading...</div>
  } else {
    const displayedUsers = accessEntries.reduce((acc: UserPermissionRow[], entry: AccessEntry) => {
      const user = users.find(u => u.id === entry.userId)

      if(user !== undefined) {
        return [...acc, { ...user, ...entry } ]
      } else {
        return acc      
      }
    }, [])

    return (
      <div className="user-access-list" style={{padding: '10px'}}>
        <h5>Currently shared with:</h5>
        {caseDataset !== undefined &&
          <div style={{fontSize: '9pt', marginBottom: '5px'}}>
            This case is also shared as part of the <Link to={`/datasets/${caseDataset.datasetId}`}>{caseDataset?.name}</Link> dataset
          </div>
        } 
        <table className="table table-bordered table-striped">
          <thead>
            <tr>
              <th style={{ width: '250px' }} scope="col">User</th>
              <th style={{ width: '120px' }} scope="col">Access level</th>
            </tr>
          </thead>
          <tbody>
            {displayedUsers.map( entry => 
              <tr key={entry.userId}>
                <td>
                  <div style={{display: 'flex'}}>
                    <div>
                      <div title={`${entry.firstName} ${entry.lastName}`}>{capLengthIfNeeded(`${entry.firstName} ${entry.lastName}`, 25)}</div>
                      <div style={{fontSize: '10pt'}} title={entry.email}>{capLengthIfNeeded(entry.email, 22)}</div>
                    </div>
                    <img style={{maxHeight: '42px', marginLeft: 'auto', borderRadius: '50%'}} src={userAvatarUrl(entry.userId)}></img>                                     
                  </div>                  
                </td> 
                <td title={permissionLevelToolTip(entry.accessLevel, 'dataset')}>
                  {permissionLevelDisplayText(entry.accessLevel)}
                </td>
              </tr>
            )}
          </tbody>
        </table>    
      </div>
    )
  }
}
