import Select from 'react-select'

import { SelectOption } from "utils/SelectOption"
import { LoadingSpinner } from 'app/components/LoadingSpinner'
import { getUserName, User, userName } from 'auth/model/User'

export type UserSelectorProps = {  
  users?: User[]
  selectedUserId?: string
  disabled?: boolean
  setSelectedUserId: (userId: string | undefined) => void
}

export const UserSelector = ({
  users, selectedUserId, disabled,
  setSelectedUserId
}: UserSelectorProps) => {
  const options: SelectOption<string>[] = (users || []).map((user) => { return {value: user.id, label: userName(user) }})
  
  const current = selectedUserId === undefined ? undefined : { value: selectedUserId, label: getUserName(users, selectedUserId) }

  if(users === undefined){
    return <LoadingSpinner />
  } else {
    return (
      <div 
        className="user-selector"
        style={{ display: 'flex', marginRight: '10px' }}
      >
        <div style={{ width: '250px' }}>
          <Select 
            classNamePrefix="react-select-user-selector"
            options={options} 
            isDisabled={disabled}
            placeholder="Select user" 
            value={current} 
            onChange={(o) => { if(o !== null) setSelectedUserId( o.value)}}
            /> 
        </div>
        
      </div>
    )
  }
}
