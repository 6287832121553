import { RootState } from "app/store"
import { nanoid } from "nanoid"
import { useEffect, useRef, useState } from "react"
import { connect } from "react-redux"
import { ReportMetaData } from "reports/api/ReportsApi"
import { downloadReportOutput, requestReport } from "reports/api/ReportsSlice"
import { ReportOutputMetadata, Scan } from "uploads/api/Model"
import { fetchClassificationForms, fetchReportInfoStatus, fetchScan, requestModelRun } from "cases/api/ScansSlice"
import { ReportInfoStatus } from "uploads/model/ReportInfoStatus"
import { CaseButton, RequestReportLink } from "./CaseLink"
import { ClassificationForm } from "annotation/model/ClassificationForm"
import { UserFeatures } from "auth/UserSlice"
import { DownloadReport } from "./DownloadReport"
import { User } from "auth/model/User"
import { Theme } from "app/model/Theme"

export type RequestOrViewReportProps = {
  scan: Scan
  uploadsCount: number
  reportInfoStatus?: ReportInfoStatus
  showDebugUi: boolean
  classificationForms?: ClassificationForm[]
  features: UserFeatures
  hasWriteAccess: boolean
  theme: Theme
  onModelRunRequested: () => void
  fetchClassificationForms: (scanId: string) => void
  fetchScan: (scanId: string) => void
  users?: User[]
  reportUsers: string[]
  requestReport: (scanId: string, selectedUserId: string | undefined) => Promise<ReportMetaData | undefined>
  downloadReportOutput: (reportId: string, outputId: string) => void
  fetchReportInfoStatus: (scanId: string) => void
}

export const RequestOrViewReport = ({
  scan, uploadsCount, reportInfoStatus, showDebugUi, features, classificationForms, hasWriteAccess, users, 
  reportUsers, theme,
  requestReport, downloadReportOutput, fetchScan, fetchReportInfoStatus, onModelRunRequested, 
  fetchClassificationForms
}: RequestOrViewReportProps) => {

  useEffect(() => { if(reportInfoStatus === undefined) fetchReportInfoStatus(scan.scanId) }, [fetchReportInfoStatus, scan.scanId, reportInfoStatus])
  useEffect(() => { if(classificationForms === undefined) fetchClassificationForms(scan.scanId)}, [classificationForms, scan.scanId, fetchClassificationForms])

  const [requestedReportId, setRequestedReportId] = useState<string | undefined>(undefined)
  const requestedReportIdRef = useRef<string | undefined>(requestedReportId)
  requestedReportIdRef.current = requestedReportId
  const [lastPolled, setLastPolled] = useState<number>(new Date().getTime())
  const lastPolledRef = useRef<number>(new Date().getTime())
  lastPolledRef.current = lastPolled
  const reportOutputsRef = useRef<ReportOutputMetadata[] | undefined>(scan.reportOutputs)
  reportOutputsRef.current = scan.reportOutputs

  const [selectedUserId, setSelectedUserId] = useState<string | undefined>(undefined)
  const selectedUserIdRef = useRef<string>()
  selectedUserIdRef.current = selectedUserId

  const scanRef = useRef<Scan>()
  scanRef.current = scan


  const minIntervalMs = 2000

  const checkOnReportOutputs = (timesCalled: number) => {
    const now = new Date().getTime()

    if(requestedReportIdRef.current !== undefined &&
      (reportOutputsRef.current === undefined || 
        reportOutputsRef.current.find(o => o.reportId === requestedReportId) === undefined)
    ) {
      if(now - lastPolledRef.current > minIntervalMs) {
        setLastPolled(now)           
        fetchScan(scan.scanId)        
      }
      setTimeout(() => checkOnReportOutputs(timesCalled + 1), minIntervalMs * Math.pow(2, timesCalled))      
    }
  } 

  const onReportRequestedClick = (selectedUserId: string | undefined) => {
    requestReport(scan.scanId, selectedUserId).then(metaData => {
      setRequestedReportId(metaData?.reportId)
      setTimeout(() => checkOnReportOutputs(0), minIntervalMs)
    })
  }


  return (
    <>          
      { scan.reportOutputs !== undefined && 
        <DownloadReport
          reportOutputs={scan.reportOutputs}
          showDebugUi={showDebugUi}
          downloadReportOutput={(reportId: string, outputId: string) => downloadReportOutput(reportId, outputId)}
          theme={features.theme}
        />
      }
      { showDebugUi && reportInfoStatus === ReportInfoStatus.ready && hasWriteAccess &&
        <RequestReportLink 
          onReportRequestedClick={onReportRequestedClick} 
          requested={requestedReportId !== undefined} 
          output={scan.reportOutputs?.find(o => o.reportId === requestedReportId)}   
          regenerate={(scan.reportOutputs?.length || 0) > 0}
          buttonSecondary={uploadsCount < 1}        
          showDebugUi={showDebugUi}   
          selectedUserId={selectedUserId}
          setSelectedUserId={setSelectedUserId}
          users={users?.filter(u => reportUsers.includes(u.id))}          
        />
      }
      { showDebugUi && features.requestModelRun && hasWriteAccess &&
        <div style={{ display: 'flex', fontSize: '15pt'}}>
          <CaseButton 
            dataCy='request-model-run-button' 
            text='Run models'
            onClick={onModelRunRequested}  
            theme={theme}
          />
        </div>
      }         
    </>
  )
}


const mapStateToProps = (state: RootState, { caseId }) => {
  return  { 
    reportInfoStatus: state.scans.reportInfoStatuses[caseId],
    showDebugUi: state.user.features.showDebugUi,
    classificationForms: state.scans.forms[caseId],
    features: state.user.features,
    users: state.user.users
  }
}

const mapDispatchToProps = { 
  downloadReportOutput: downloadReportOutput,
  requestReport: (scanId: string, selectedUserId: string | undefined) => requestReport(scanId, selectedUserId, nanoid()),
  fetchScan: (scanId: string) => fetchScan(scanId, nanoid()),
  fetchReportInfoStatus: (scanId: string) => fetchReportInfoStatus(scanId, nanoid()),
  requestModelRun: (scanId: string) => requestModelRun(scanId, nanoid()),
  fetchClassificationForms: (scanId: string) => fetchClassificationForms(scanId, nanoid()),
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RequestOrViewReport)
