import { Form, Formik } from 'formik'
import { useRef, useState } from 'react'
import { ClassificationForm } from 'annotation/model/ClassificationForm'
import { DeleteButton, FormSection, LabeledCheckBox, RadioGroupSection, SaveOrEditButton, SectionDescription } from '../../uploads/component/FormElements'
import { Annotation } from 'annotation/model/Annotation'
import { FormValues } from 'uploads/component/HamstringForm'

export type ACLFormProps = {
  saveClassificationForm: (hamstringFormValues: FormValues) => Promise<void>
  updateClassificationForm: (classificationFormId: string, currentVersion: number, formData: FormValues) => void
  deleteClassificationForm: (classificationFormId: string, currentVersion: number) => Promise<void>
  currentUserId: string
  hasAnnotateAccess: boolean
  annotations: Annotation[]
  displayOnly: boolean
  current?: ClassificationForm
  onEditOthersForm: () => void
}

export const ACLForm = ({
  saveClassificationForm, updateClassificationForm, deleteClassificationForm,
  currentUserId, hasAnnotateAccess, displayOnly, current, onEditOthersForm, annotations
}: ACLFormProps) => {

  const [isEditing, setIsEditing] = useState<boolean>(current === undefined)
  const inputDisabled = displayOnly || !hasAnnotateAccess || !isEditing

  const currentRef = useRef<ClassificationForm>()
  currentRef.current = current

  return (
    <div>
      <h2 style={{padding: '10px'}}>ACL classification</h2>
     
      <Formik 
        enableReinitialize={true}
        initialValues={current?.formData || {}}
        onSubmit={(values) => {          
          setIsEditing(false)

          if(currentRef.current !== undefined && currentRef.current.createdBy === currentUserId) {
            updateClassificationForm(currentRef.current.classificationFormId, currentRef.current.classificationFormVersion, values)
          } else {
            saveClassificationForm(values).then(onEditOthersForm)
          }          
        }}
      >
        {({ values }) => (
          <Form>                

            <FormSection>
              <SectionDescription>Data quality check</SectionDescription>
              <LabeledCheckBox name="aclReconstruction" text="ACL Reconstruction" disabled={inputDisabled} />
              <LabeledCheckBox name="lowImageQuality" text="Low image quality" disabled={inputDisabled} />
            </FormSection>

            <RadioGroupSection 
              description="ACL Impression" 
              name="aclImpression" 
              values={[
                {id: "intact", display: "Intact"}, 
                {id: "torn", display: "Torn"}]} 
              disabled={inputDisabled} 
              includeOther={false}              
              radioOptionsWidth={150}
            />            

            {!displayOnly &&
            <SaveOrEditButton 
              hasAnnotateAccess={hasAnnotateAccess}
              inputDisabled={inputDisabled} 
              setIsEditing={() => setIsEditing(true)} 
            />
            }

            { current !== undefined && current.createdBy === currentUserId &&
            <DeleteButton deleteForm={ () => {
              if(currentRef.current !== undefined){
                deleteClassificationForm(currentRef.current.classificationFormId, currentRef.current.classificationFormVersion)
              }
            }} />
            }
          </Form>
        )}

      </Formik>
    </div>
  )
}
