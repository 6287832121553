import Select from 'react-select'

import { SelectOption } from "utils/SelectOption"
import { DatasetListingEntry } from 'uploads/model/Dataset'
import { LoadingSpinner } from 'app/components/LoadingSpinner'

export type DatasetSelectorProps = {
  datasets?: DatasetListingEntry[]
  selectedDatasetId?: string
  setSelectedDatasetId: (datasetId: string | undefined) => void
}

export const DatasetSelector = ({datasets, selectedDatasetId, setSelectedDatasetId}: DatasetSelectorProps) => {
  const options: SelectOption<string>[] = (datasets || []).map((dataset) => { return {value: dataset.datasetId, label: dataset.name }})
  
  const current = { value: selectedDatasetId, label: datasets?.find(d => d.datasetId === selectedDatasetId)?.name || '' }

  if(datasets === undefined){
    return <LoadingSpinner />
  } else {
    return (
      <div 
        className="dataset-selector"
        style={{ display: 'flex', marginRight: '10px' }}
        >
        <div style={{marginTop: '5px', marginLeft:'10px', fontSize: '13pt', width: '200px'}}>Select dataset:</div>

        <div style={{ width: '250px' }}>
          <Select 
            classNamePrefix="react-select-dataset-selector"
            options={options} 
            placeholder="Annotation mode" 
            value={current} 
            onChange={(e) => { if (e !== null) setSelectedDatasetId(e.value)}}
          /> 
        </div>
        
      </div>
    )
  }
}
