import _ from 'lodash'

import { ChevronDown, ChevronUp } from "app/components/icons/Chevrons"
import { User } from "auth/model/User"
import { useCallback, useState } from "react"
import { Annotation, AnnotationApprovalStatus } from "annotation/model/Annotation"
import { Scan } from "uploads/api/Model"
import { Dataset } from "uploads/model/Dataset"
import { DatasetAnnotationLabel, annotationDisplayText } from "uploads/model/DatasetAnnotationLabel"
import { SeriesProps } from "uploads/model/SeriesProps"
import { getSeriesDisplayName } from "uploads/util/SeriesDisplayName"
import { SliceAnnotationsList } from './SliceAnnotationsList'
import { capLengthIfNeeded } from 'utils/TextUtils'
import { MaximiseRestore } from 'app/components/icons/MaximiseRestore'
import { pluralise } from 'utils/Pluralise'


export type SeriesAnnotationsListProps = {
  scanId: string
  scan: Scan
  dataset?: Dataset
  seriesId: string
  users: User[]
  showDebugUi: boolean
  seriesDescription: string | undefined
  seriesProps?: SeriesProps
  annotations: Annotation[]
  hoveredAnnotationId?: string
  annotationApprovers: string[]
  currentUserId: string
  editingAnnotation: string | undefined
  annotationLabels: DatasetAnnotationLabel[]
  savingAnnotationIds: string[]
  zoomedSeriesInstanceUID?: string
  hiddenAnnotations: string[]
  isActiveSeries: boolean
  labels: DatasetAnnotationLabel[]
  setHiddenAnnotations: (hiddenAnnotations: string[]) => void
  setZoomedSeriesInstanceUID: (seriesId: string | undefined) => void
  setHoveredAnnotationMeta: (hoveredAnnotationId: string | undefined) => void
  deleteAnnotation: (scanId: string, annotationId: string) => Promise<void>
  updateAnnotationApproval: (scanId: string, annotationId: string, status: AnnotationApprovalStatus | undefined) => void
  displayAnnotation: (annotation: Annotation) => void
  setEditingAnnotation: (annotationId: string|undefined) => void
  onExpandSeries: () => void
}

export const SeriesAnnotationsList = ({
  scanId, scan, dataset, seriesId, seriesDescription, annotations, hoveredAnnotationId, 
  annotationApprovers, currentUserId, editingAnnotation, annotationLabels, showDebugUi, 
  users, savingAnnotationIds, zoomedSeriesInstanceUID, hiddenAnnotations, isActiveSeries,
  labels,
  setHoveredAnnotationMeta, deleteAnnotation, displayAnnotation, 
  updateAnnotationApproval, setEditingAnnotation, onExpandSeries, setZoomedSeriesInstanceUID,
  setHiddenAnnotations
}: SeriesAnnotationsListProps) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(false)

  const onExpandClick = () =>{
    setIsExpanded(current=> !current)
    onExpandSeries()
  }

  const seriesDisplayName = getSeriesDisplayName(seriesId, seriesDescription, scan, dataset) || ''
  const annotationsBySlice = Object.entries(_.groupBy(annotations.filter(a => a.InstanceNumber !== undefined), a => a.InstanceNumber))
  const seriesAnnotations = annotations.filter(a => a.InstanceNumber === undefined)

  const title = showDebugUi ? seriesDisplayName : `${seriesDisplayName}\nSeries ID: ${seriesId}`

  const onMaximiseToggle = useCallback(() => {
    if(seriesId === zoomedSeriesInstanceUID) {
      setZoomedSeriesInstanceUID(undefined)
    } else {
      setZoomedSeriesInstanceUID(seriesId)
    }
  }, [seriesId, zoomedSeriesInstanceUID, setZoomedSeriesInstanceUID])

  return (
    <div className="series-annotations" title={title} 
      style={{
        border: isActiveSeries ? '1px solid red' : '1px solid lightgray',
        borderRadius: '6px',
        margin:'5px', padding: '5px', width: '240px'}}>
      <div className="series-header" style={{display: 'flex', cursor: 'pointer'}} onClick={onExpandClick} >
        <div style={{marginBottom: '5px'}}>
          {capLengthIfNeeded(seriesDisplayName, 20)}
        </div>

        <div style={{ display: 'flex', marginLeft: 'auto'}}>
          <MaximiseRestore size={25} isMaximised={zoomedSeriesInstanceUID === seriesId} onMaximiseToggle={onMaximiseToggle} />
          <div style={{marginRight: '3px', color: 'grey'}}>
          ({annotations.length})
          </div>
          <div style={{ width: '16px', height: '16px'}}>
            { isExpanded && 
              <ChevronUp />
            }
            { !isExpanded &&
              <ChevronDown />
            }    
          </div>        
        </div>    
      </div>
      { seriesAnnotations.length > 0 &&
      <div>
        { seriesAnnotations.map(seriesAnnotation =>
          <div>{annotationDisplayText(seriesAnnotation, labels)} detected</div>  
        )}
      </div>
      }
      { isExpanded && annotationsBySlice.length < 1 && 
        <div style={{paddingLeft: '10px'}}>No annotations</div>
      }
      <>
      { isExpanded && annotationsBySlice?.length >= 1 && annotationsBySlice.map( ([instanceNumber, sliceAnnotations]) => 
        <SliceAnnotationsList 
          scanId={scanId}       
          key={instanceNumber}
          instanceNumber={instanceNumber}
          annotations={sliceAnnotations}
          seriesDescription={seriesDescription}
          setHoveredAnnotationMeta={setHoveredAnnotationMeta}
          displayAnnotation={displayAnnotation}
          deleteAnnotation={deleteAnnotation}
          updateAnnotationApproval={updateAnnotationApproval}
          hoveredAnnotationId={hoveredAnnotationId}
          annotationApprovers={annotationApprovers}
          currentUserId={currentUserId}
          setEditingAnnotation={setEditingAnnotation}
          editingAnnotation={editingAnnotation}
          annotationLabels={annotationLabels}
          users={users}
          showDebugUi={showDebugUi} 
          savingAnnotationIds={savingAnnotationIds}
          hiddenAnnotations={hiddenAnnotations}
          setHiddenAnnotations={setHiddenAnnotations}
        />
      )}
      </>
    </div>
  )
}
