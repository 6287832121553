import { useState } from "react"

export type CheckBoxProps = {
  id?: string
  dataCy?: string
  checked: boolean
  disabled?: boolean
  onChange: () => void
}

export const CheckBox = ({id, dataCy, checked, disabled, onChange}: CheckBoxProps) => {

  const [isChecked, setIsChecked] = useState<boolean | undefined>(undefined)

  const handler = () => {
    setIsChecked(current => current === undefined ? !checked : !current )
    onChange()
  }

  return (
    <input 
      id={id}
      type="checkbox" 
      data-cy={ dataCy }
      checked={ isChecked !== undefined ? isChecked : checked } 
      disabled={disabled}
      onChange={ handler }
    />
  )
}