import React from 'react'
import { Provider } from 'react-redux'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { store } from 'app/store'
import { ServiceApi } from 'app/api/ErrorApi'
import { nanoid } from 'nanoid'
import ConfigEditor from 'app/components/ConfigEditor'

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
)

document.cookie = `elite_ai_auth_token=loggedin; expires=${new Date().toISOString()}; Domain=medicineserver.ucd.ie; Path=/eliteai/; Secure; HttpOnly; SameSite=None`

if(document.location.pathname === '/config-editor') {
  root.render(
    <Provider store={store}>
      <React.StrictMode>
        <ConfigEditor />
      </React.StrictMode>
    </Provider>
  )
} else {
  root.render(
    <Provider store={store}>
      <React.StrictMode>
        <App />
      </React.StrictMode>
    </Provider>
  )
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

window.onerror = (message, source, lineno, colno, error) => {
  const errorApi = new ServiceApi(window.RuntimeConfig.backend)

  const messages = [
    `message: ${message}`,
    `source: ${source}:${lineno}:${colno}`,
    `error.message: ${error?.message}`,
    `error: ${error}`
  ]

  if(error?.stack !== undefined) {
    messages.push(`stack: ${error.stack}`)
  }

  errorApi.reportError(
    'window.onerror', 
    messages.join('\n'), 
    nanoid())
}
