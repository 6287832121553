import { AccessEntry } from 'auth/model/AccessEntry'
import { PermissionLevel, permissionLevelDisplayText } from 'auth/model/PermissionLevel'
import { User } from 'auth/model/User'
import { useState } from 'react'
import { useNavigate } from "react-router-dom"
import Select from 'react-select'
import { SelectOption } from 'utils/SelectOption'


type AddPeopleComponentProps = {
  users: User[] 
  accessEntries: AccessEntry[]
  currentUserId?: string
  entitiyName: string
  addPermission: (userId: string, accessLevel: PermissionLevel, refresh: boolean) => void
  setInfoMessage: (message: string) => void
}

export const AddPeopleComponent = ({
  users, accessEntries, currentUserId, entitiyName,
  addPermission, setInfoMessage
}: AddPeopleComponentProps) => {

  const [selectedUser, setSelectedUser] = useState<SelectOption<string> | null>(null)
  const [selectedAccessLevel, setSelectedAccessLevel] = useState<SelectOption<string> | null>(null)
  const navigate = useNavigate()


  const userOptions: SelectOption<string>[] = users.map(user => { return { value: user.id, label: user.email } })
  const accessLevels: SelectOption<string>[] = [
    PermissionLevel.read, 
    PermissionLevel.write, 
    PermissionLevel.share, 
    PermissionLevel.none
  ].map(value => { 
    return { value, label: permissionLevelDisplayText(value) }
  })

  const onAddPermission = () => {
    if(selectedUser !== null && selectedAccessLevel !== null) {          
      const selfRevoke = selectedUser.value === currentUserId && selectedAccessLevel.value === PermissionLevel.none

      addPermission(selectedUser.value, selectedAccessLevel.value as PermissionLevel, !selfRevoke)

      if(selfRevoke) {
        const path = entitiyName === 'dataset' ? '/datasets' : '/scans'
        navigate(path)
        setInfoMessage(`${entitiyName} access revoked`)
      }
    }
  }

  const userPermissionAlreadyExists: Boolean = accessEntries.find(e => e.userId === selectedUser?.value) === undefined 

  return (
    <div className="add-people-component">
      <div style={{display: 'flex'}}>
        <div style={{ flexGrow: 4}} data-cy="user-selector">
          <Select 
            classNamePrefix="react-select-user-selector"
            options={userOptions} 
            placeholder="Select user" 
            value={selectedUser} 
            onChange={(e) => setSelectedUser(e)}        
            />
        </div>      
        <div style={{ flexGrow: 1}} data-cy="access-level-selector">
          <Select 
            classNamePrefix="react-select-access-level-selector"
            options={accessLevels} 
            placeholder="Access" 
            value={selectedAccessLevel} 
            onChange={(e) => setSelectedAccessLevel(e)} 
            />
        </div>
      </div>
      <div>
        <button 
          type="button" 
          className="btn btn-primary"
          style={{marginTop: '10px', position: 'relative', left: userPermissionAlreadyExists ? '323px' : '300px'}}
          onClick={onAddPermission} 
          data-cy="share-with-selected-user"
          >{ userPermissionAlreadyExists ? "Add" : "Update"}</button>
      </div>
    </div>
  )
}