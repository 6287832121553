import { Scan } from 'uploads/api/Model'
import { FormValues } from 'uploads/component/HamstringForm'


export type HamstringClassificationProps = {
  current: FormValues
  scan: Scan
}

const round2 = (number: number) => Math.round((number + Number.EPSILON) * 100) / 100

export const HamstringClassification = ({  
  current, scan
}: HamstringClassificationProps) => {

  const eliteAiMeasurementsExist = scan.additionalBamicInfo?.tearLengthCoronalEccentric !== undefined ||
    scan.additionalBamicInfo?.tearLengthCoronal !== undefined ||
    scan.additionalBamicInfo?.areaOfTearAtLargestExtent !== undefined  ||
    scan.additionalBamicInfo?.areaOfMuscleAtLargestExtent !== undefined  ||
    scan.additionalBamicInfo?.crossSectionalRatioOfMuscleTear !== undefined  ||
    scan.additionalBamicInfo?.tearAreaIntratendinous !== undefined  ||
    scan.additionalBamicInfo?.tearAreaTendonTorn !== undefined  ||
    scan.additionalBamicInfo?.crossSectionalRatioOfTendonTear !== undefined  ||
    scan.additionalBamicInfo?.highestCsaPercent !== undefined  ||
    scan.additionalBamicInfo?.highestIntratendinousPercent !== undefined  ||
    scan.additionalBamicInfo?.distanceToTJunction !== undefined 

  return (
    <div>
      <div className='elite-ai-measurements'>
        { eliteAiMeasurementsExist &&
        <h5 style={{paddingLeft: '10px'}}>Elite AI measurements</h5>
        }
        <table><tbody>
        { scan.additionalBamicInfo?.tearLengthCoronalEccentric !== undefined &&
          <tr><td style={{width: '250px'}}>Tear eccentric length in coronal plane</td><td>{round2(scan.additionalBamicInfo.tearLengthCoronalEccentric / 10)} cm</td></tr>
        }
        { scan.additionalBamicInfo?.tearLengthCoronal !== undefined &&
          <tr><td>Tear cranio-caudal length</td><td>{round2(scan.additionalBamicInfo.tearLengthCoronal / 10)} cm</td></tr>
        }
        { scan.additionalBamicInfo?.areaOfTearAtLargestExtent !== undefined &&
          <tr><td>Area of tear at largest extent</td><td>{round2(scan.additionalBamicInfo.areaOfTearAtLargestExtent / 100) } cm²</td></tr>
        }
        { scan.additionalBamicInfo?.areaOfMuscleAtLargestExtent !== undefined &&
          <tr><td>Area of muscle at largest tear</td><td>{round2(scan.additionalBamicInfo.areaOfMuscleAtLargestExtent)} cm²</td></tr>
        }
        { scan.additionalBamicInfo?.crossSectionalRatioOfMuscleTear !== undefined &&
          <tr><td>CSA of (largest) tear in muscle</td><td>{scan.additionalBamicInfo.crossSectionalRatioOfMuscleTear}%</td></tr>
        }
        { scan.additionalBamicInfo?.tearAreaIntratendinous !== undefined &&
          <tr><td>Area of tear at largest extent</td><td>{round2(scan.additionalBamicInfo.tearAreaIntratendinous)} cm²</td></tr>
        }
        { scan.additionalBamicInfo?.tearAreaTendonTorn !== undefined &&
          <tr><td>Area of tendon at largest tear extent</td><td>{round2(scan.additionalBamicInfo.tearAreaTendonTorn)} cm²</td></tr>
        }
        { scan.additionalBamicInfo?.crossSectionalRatioOfTendonTear !== undefined &&
          <tr><td>CSA of (largest) tear in tendon</td><td>{scan.additionalBamicInfo.crossSectionalRatioOfTendonTear}%</td></tr>
        }
        { scan.additionalBamicInfo?.highestCsaPercent !== undefined &&
          <tr><td>Largest CSA of tear in muscle</td><td>{scan.additionalBamicInfo.highestCsaPercent}%</td></tr>
        }
        { scan.additionalBamicInfo?.highestIntratendinousPercent !== undefined &&
          <tr><td>Largest CSA of tear in tendon</td><td>{scan.additionalBamicInfo.highestIntratendinousPercent}%</td></tr>
        }
        { scan.additionalBamicInfo?.distanceToTJunction !== undefined &&
          <tr><td>Distance to t-junction</td><td>{round2(scan.additionalBamicInfo.distanceToTJunction / 10)} cm</td></tr>
        }
        </tbody></table>
      </div>
      <div className="bamic-grading" style={{marginTop: '20px'}}>
        <h5 style={{paddingLeft: '10px'}}>BAMIC Grading</h5>
        <table><tbody>
          { current.grade !== undefined &&
            <tr><td style={{width: '250px'}}>BAMIC Grade</td><td>{current.grade}</td></tr>
          }
          { current.isThereATear === 'no' &&
            <tr><td>No tear detected</td></tr>
          }
          { current.tearComplete === 'yes' &&
            <tr><td>Tear</td><td>Complete</td></tr>
          }
          { current.tearComplete === 'no' &&
            <tr><td>Tear</td><td>Incomplete</td></tr>
          }
          { current.inWhichMuscle === 'long_head_biceps_femoris' &&
            <tr><td>Location</td><td>Long head biceps femoris</td></tr>
          }
          { current.inWhichMuscle === 'short_head_biceps_femoris' &&
            <tr><td>Location</td><td>Short head biceps femoris</td></tr>
          }
          { current.inWhichMuscle === 'semitendinosus' &&
            <tr><td>Location</td><td>Semitendinosus</td></tr>
          }
          { current.inWhichMuscle === 'semimembranosus' &&
            <tr><td>Location</td><td>Semimembranosus</td></tr>
          }
          { current.proximalOrDistal === 'proximal' &&
            <tr><td>Region of muscle</td><td>Proximal</td></tr>
          }
          { current.proximalOrDistal === 'distal' &&
            <tr><td>Region of muscle</td><td>Distal</td></tr>
          }
          { current.locusOfTear === 'muscle' &&
            <tr><td>Locus of tear</td><td>Muscle</td></tr>
          }
          { current.locusOfTear === 'tendon' &&
            <tr><td>Locus of tear</td><td>Tendon</td></tr>
          }
          { current.locusOfTear === 'myofascial' &&
            <tr><td>Locus of tear</td><td>Myofascial</td></tr>
          }
          { current.locusOfTear === 'muscle_belly_mtj' &&
            <tr><td>Locus of tear</td><td>Muscle Belly (MTJ)</td></tr>
          }
          { current.locusOfTear === 'intra-tendinous' &&
            <tr><td>Locus of tear</td><td>Intra-tendinous</td></tr>
          }
          { current.signalChange === 'less_than_ten_percent' &&
            <tr><td>Signal Change into CSA</td><td>{`< 10%`}</td></tr>
          }
          { current.signalChange === 'ten_to_fifty_percent' &&
            <tr><td>Signal Change into CSA</td><td>{`> 10% and < 50%`}</td></tr>
          }
          { current.signalChange === 'over_fifty_percent' &&
            <tr><td>Signal Change into CSA</td><td>{`> 50%`}</td></tr>
          }
          { current.tearCranioCaudalLength === 'less_than_five_cm' &&
            <tr><td>Tear cranio-caudal length</td><td>{`< 5cm`}</td></tr>
          }
          { current.tearCranioCaudalLength === 'five_to_fifteen_cm' &&
            <tr><td>Tear cranio-caudal length</td><td>{`> 5cm and < 15cm`}</td></tr>
          }
          { current.tearCranioCaudalLength === 'over_fifteen_cm' &&
            <tr><td>Tear cranio-caudal length</td><td>{`> 15cm`}</td></tr>
          }
          { current.crossSectionalArea === 'less_than_fifty_percent' &&
            <tr><td>Cross sectional area of tendon involvement</td><td>{`< 50%`}</td></tr>
          }
          { current.crossSectionalArea === 'over_fifty_percent' &&
            <tr><td>Cross sectional area of tendon involvement</td><td>{`> 50%`}</td></tr>
          }
          { current.longitudinalLengthOfTendonInvolvement === 'less_than_five_cm' &&
            <tr><td>Longitudinal length of tendon involvement</td><td>{`< 5cm`}</td></tr>
          }
          { current.longitudinalLengthOfTendonInvolvement === 'over_five_cm' &&
            <tr><td>Longitudinal length of tendon involvement</td><td>{`> 5cm`}</td></tr>
          }
          { current.fibrosisLongHeadBicepsFemoris === true &&
            <tr><td>Fibrosis</td><td>Long head biceps femoris</td></tr>
          }
          { current.fibrosisShortHeadBicepsFemoris === true &&
            <tr><td>Fibrosis</td><td>Short head biceps femoris</td></tr>
          }
          { current.fibrosisSemitendinosus === true &&
            <tr><td>Fibrosis</td><td>Semitendinosus</td></tr>
          }
          { current.fibrosisSemimembranosus === true &&
            <tr><td>Fibrosis</td><td>Semimembranosus</td></tr>
          }
        </tbody></table>
        { current.comments !== undefined && current.comments.trim().length > 0 &&
        <div style={{marginTop: '20px'}}>
          <h5>Additional comments:</h5>
          <div>{current.comments}</div>
        </div>
        } 
      </div>
    </div>
  )
}
