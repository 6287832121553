import Select from 'react-select'

import { AnnotationMode } from "uploads/model/AnnotationMode"
import { SelectOption } from "utils/SelectOption"

export type AnnotationModeSelectorProps = {
  annotationMode: AnnotationMode
  disabled: boolean
  toggleAnnotationMode: () => void
}
export const AnnotationModeSelector = ({
  annotationMode, disabled,
  toggleAnnotationMode
}: AnnotationModeSelectorProps) => {

  const options: SelectOption<string>[] = [AnnotationMode.ACL, AnnotationMode.Hamstring].map(label => { return { value: label.toString(), label: label.toString() } })
  
  const current = { value: annotationMode.toString(), label: annotationMode.toString() }

  return (
    <div style={{ width: '250px' }} className="annotation-mode-selection">
      <Select 
        classNamePrefix="react-select-annotation-mode-selector"
        options={options} 
        placeholder="Annotation mode" 
        value={current} 
        isDisabled={disabled}
        onChange={toggleAnnotationMode}        
        /> 
    </div>
  )
}
