import { connect } from 'react-redux'
import _ from 'lodash'

import { RootState } from 'app/store'
import { ExtractedImage } from 'uploads/store/Model'
import { Scan } from 'uploads/api/Model'
import { InlineEdit } from 'app/components/InLineEdit'
import { classNameForSeries } from './ImageScrollerFunctions'
import { AnnotationShapeType } from 'annotation/model/AnnotationShape'
import { MaximiseRestore } from 'app/components/icons/MaximiseRestore'
import { getSeriesDisplayName } from 'uploads/util/SeriesDisplayName'
import ImageScroller from './ImageScroller'
import { Enter3dMaterial } from 'app/components/icons/Enter3dMaterial'
import { Suspense, useCallback } from 'react'
import { GltfScene } from 'threeD/GltfScene'
import { broadcastVrSceneState } from 'auth/Rtc'
import { VrSceneState } from 'threeD/model/VrSceneState'
import { ScansApi } from 'cases/api/ScansApi'

export type SeriesViewerProps = {
  scan: Scan
  annotateMode: boolean
  seriesId: string
  seriesDisplayName?: string
  images?: ExtractedImage[]
  highlightAnnoation?: string
  labelAllAnnotations: boolean
  isMaximised?: boolean
  showMaximise: boolean
  currentUserId: string
  displayedUserAnnotations?: string[]
  displayModelAnnotations: boolean
  displayInstanceNumber?: number
  showDebugUi: boolean
  showExperimentalUi: boolean
  editingAnnotation: string | undefined
  hasWriteAccess: boolean  
  isActiveSeries: boolean
  contrastPercentage: number
  intensityPercentage: number
  showModelOutputs: boolean
  annotationShape?: AnnotationShapeType
  currentAnnotationLabel?: string 
  hiddenAnnotations: string[]
  show3d: boolean
  spectateClientId?: string
  hideTitleBar?: boolean
  setSelectedSeries: () => void
  clearDisplayInstanceNumber: () => void
  setDisplayInstanceNumber: (instanceNumber: number) => void
  setHoveredAnnotation?: (annotationId: string| undefined) => void
  onMaximiseToggle?: () => void
  setZoomedSeriesUID?: (seriesId: string | undefined) => void 
  onAnnotationDraw?: () => void
  setSeriesDisplayName: (displayName: string) => void
  setSavingAnnotationIds: (annotationIds: string[]) => void
  broadcastVrSceneState: (state: VrSceneState) => void
  enter3d: () => void
  exit3d: () => void
}

const scansApi = new ScansApi(window.RuntimeConfig.backend)


export const SeriesViewer = ({ 
  scan, annotateMode, seriesDisplayName, images, highlightAnnoation, labelAllAnnotations,
  isMaximised, showMaximise, currentUserId, displayedUserAnnotations, displayInstanceNumber, seriesId, displayModelAnnotations, 
  showDebugUi, showExperimentalUi, editingAnnotation, hasWriteAccess,
  isActiveSeries, contrastPercentage, intensityPercentage, showModelOutputs, annotationShape, currentAnnotationLabel,
  hiddenAnnotations, show3d, spectateClientId, hideTitleBar,
  setHoveredAnnotation, onMaximiseToggle, setZoomedSeriesUID, onAnnotationDraw, clearDisplayInstanceNumber, 
  setSeriesDisplayName, setDisplayInstanceNumber, setSelectedSeries,
  setSavingAnnotationIds, broadcastVrSceneState, enter3d, exit3d
}: SeriesViewerProps) => {  

  const on3DToggle = useCallback(() => {
    if(show3d) {
      exit3d()
    } else {
      if(setZoomedSeriesUID !== undefined) {
        setZoomedSeriesUID(show3d ? undefined : seriesId)
      } 
      enter3d()
    }
  }, [show3d])

  if(images === undefined || images.length < 1) {
    return (<div/>)
  }

  return (
    <div 
      className={classNameForSeries(seriesId) } 
      style={{ 
        position: 'relative', 
        width: isMaximised ? `1040px` : '520px',
        height: isMaximised ? '1170px' : '650px',
        padding: '0px'
      }}
      onClick={setSelectedSeries}
    > 
      { hideTitleBar !== true && 
      <div style={{ display: 'flex', marginBottom: '-9px' }}>
        <h5 style={{paddingLeft: '5px'}}>
          <InlineEdit 
            value={seriesDisplayName} 
            setValue={setSeriesDisplayName} 
            disabled={!hasWriteAccess} 
            width={440} 
            // color={showExperimentalUi ? 'lightgrey' : undefined}
            // backgroundHoverColor={showExperimentalUi ? 'black' : '#d3d3d3'}
          />
        </h5>
        { onMaximiseToggle !== undefined && showMaximise &&
          <div style={{position: 'absolute', right: '5px'}}>
            <div style={{display: 'flex'}}>
              <div style={{margin: '3px'}}>
                { showExperimentalUi &&
                <Enter3dMaterial isActive={show3d} onToggle={on3DToggle} size={35} />
                }                
              </div>
              <div style={{margin: '3px'}}>
                <MaximiseRestore isMaximised={isMaximised} onMaximiseToggle={onMaximiseToggle} size={35}  />                      
              </div>
            </div>
          </div>
        }
      </div>
      }

      {show3d &&
        <div style={{height: isMaximised ? '1024px' : '512px', marginTop: '5px'}}>
          <Suspense>
            <GltfScene spectateClientId={spectateClientId} broadcastVrSceneState={broadcastVrSceneState} showDebugUi={showDebugUi} />
          </Suspense>
        </div>
      }

      { images === undefined &&
      <div>Images undefined</div>
      }

      { !show3d && images !== undefined &&
        <ImageScroller 
          key={seriesId}
          scanId={scan.scanId}      
          scan={scan}        
          annotateMode={annotateMode}
          seriesId={seriesId}
          labelAllAnnotations={labelAllAnnotations}
          highlightAnnoation={highlightAnnoation}
          hiddenAnnotations={hiddenAnnotations}
          currentUserId={currentUserId}
          isMaximised={isMaximised}
          displayedUserAnnotations={displayedUserAnnotations}
          displayModelAnnotations={displayModelAnnotations}
          setHoveredAnnotation={setHoveredAnnotation}
          onMaximiseToggle={onMaximiseToggle}
          onAnnotationDraw={onAnnotationDraw}
          displayInstanceNumber={displayInstanceNumber}
          clearDisplayInstanceNumber={clearDisplayInstanceNumber} 
          setSeriesDisplayName={setSeriesDisplayName}
          editingAnnotation={editingAnnotation}
          hasWriteAccess={hasWriteAccess}
          imageUrl={scansApi.imageUrl}
          modelOutputUrl={scansApi.modelOutputUrl}
          setDisplayInstanceNumber={setDisplayInstanceNumber}
          isActiveSeries={isActiveSeries}
          contrastPercentage={contrastPercentage}
          intensityPercentage={intensityPercentage}
          showModelOutputs={showModelOutputs}
          annotationShape={annotationShape}
          currentAnnotationLabel={currentAnnotationLabel}                    
          setSavingAnnotationIds={setSavingAnnotationIds}
          images={images}
        />
      }
      
    </div>
  )  
}

const mapStateToProps = (state: RootState, { scanId, seriesId }: {scanId: string, seriesId: string}) => { 
  const images = state.scans.imagesMetadata[scanId]?.metadata.filter(i => i.SeriesInstanceUID === seriesId)

  const sortedImages = images === undefined ? undefined : _.sortBy(images, ['InstanceNumber', 'PatientID'])

  const scan = state.scans.all?.find(scan => scan.scanId === scanId)
  const dataset = state.datasets.all?.find(d => d.datasetId === scan?.datasetId)
  const seriesDescription = images === undefined ? undefined : images.find(i => i.SeriesDescription !== undefined)?.SeriesDescription

  const seriesDisplayName = getSeriesDisplayName(seriesId, seriesDescription, scan, dataset)

  const showDebugUi = state.user.features.showDebugUi

  return {
    images: sortedImages,
    profile: state.user.profile,
    seriesDisplayName,
    showDebugUi,
    showExperimentalUi: state.user.features.showExperimentalUi,
    spectateClientId: state.user.spectateClientId
  }
}

const mapDispatchToProps = {
  broadcastVrSceneState: broadcastVrSceneState
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SeriesViewer)
