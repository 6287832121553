import { Scan } from "uploads/api/Model"
import { Dataset } from "uploads/model/Dataset"
import { capLengthIfNeeded } from "utils/TextUtils"
import { Link } from 'react-router-dom'
import { CaseNavigationLinks } from "./CaseNavigationLinks"

export type CaseInfoProps = {
  scan: Scan
  dataset?: Dataset
  previousCase?: string
  nextCase?: string
  caseProgressDescription?: string
  annotateMode: boolean
  onCaseNavigate: () => void
}

export const CaseInfo = ({
  scan, dataset, previousCase, nextCase, caseProgressDescription, annotateMode,
  onCaseNavigate
}: CaseInfoProps) => {
  return (
    <div 
      style={{ 
        maxWidth: '250px',  
        border: '1px solid lightgray', 
        borderRadius: '10px', 
        marginTop: '10px', 
        marginRight: '20px',
        marginBottom: '10px',
        padding: '5px'
      }}
    >
      <div>
        Case: <Link title={scan.name} style={{color: 'black'}} to={`/cases/${scan.scanId}`}>{capLengthIfNeeded(scan.name, 24)}</Link>        
      </div>
      {dataset !== undefined && scan.scanInfo.patientId !== undefined &&
      <div>
        Player: <Link title={dataset.name} style={{color: 'black'}} to={`/datasets/${dataset.datasetId}/patient/${scan.scanInfo.patientId}`}>{scan.scanInfo.patientId}</Link>
      </div>
      }
      {dataset !== undefined &&
      <div>
        Dataset: <Link title={dataset.name} style={{color: 'black'}} to={`/datasets/${dataset.datasetId}`}>{capLengthIfNeeded(dataset.name, 21)}</Link>
      </div>
      }

      <CaseNavigationLinks 
        previousCase={previousCase} 
        nextCase={nextCase} 
        caseProgressDescription={caseProgressDescription}
        annotateMode={annotateMode}
        onClick={onCaseNavigate} 
      />
      
    </div>
  )
}