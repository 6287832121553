export type SeriesProgressBarProps = {
  sliceCount: number
  displayIndex: number
  width: number
}

export const SeriesProgressBar = ({
  sliceCount, displayIndex, width
}: SeriesProgressBarProps) => {
  return (
    <div style={{ display: 'flex'}}>
      { Array(sliceCount).fill(true).map((_, index) =>
        <div
          key={index}
          style={{
            width: `${width / sliceCount}px`,
            height: '4px',
            paddingLeft: `1px`,
            paddingRight: `1px`,
            backgroundColor: 'black'
          }}
        >
          <div style={{
            width: `3px`,
            height: `4px`,
            backgroundColor: index === displayIndex ? 'red' : 'green'
          }}/>
        </div>
      )}
    </div>
  )
}