
export enum UserVisiblilty {
  Online = 'online',
  Away = 'away',
  Offline = 'offline',
  OnACall = 'on_a_call'
}

export const isOnlineVisiblilty: (visibility: UserVisiblilty) => boolean = (visibility) =>
  visibility === UserVisiblilty.Online ||
  visibility === UserVisiblilty.Away ||
  visibility === UserVisiblilty.OnACall
