import { CallInfo } from "auth/model/User"
import { UserVisiblilty } from "auth/model/UserVisibility"

export type AsyncWebSocketProps = {
  url: string
  onmessage: (event: IncomingWebSocketMessage) => any
}

export type IncomingWebSocketMessage = {
  sender: string
  clientInstanceId: string
  recipientInstanceId?: string
  recipientUserId?: string
  payload: WebSocketMessagePayload
}

export type OutgoingWebSocketMessage = {
  recipientInstanceId?: string
  recipientUserId?: string
  payload: WebSocketMessagePayload
}

export type WebSocketMessagePayload = UserVisibilityPayload | NewIceCandidatePayload | StartCallingPayload | 
  StopCallingPayload | CallAnsweredPayload | IceAnswerPayload | OfferPayload | CallRejectedPayload | CallEndedPayload

export type UserVisibilityPayload = {
  event: WebSocketMessagePayloadEvent.UserVisibility
  data: { visibility: UserVisiblilty }
}

export type NewIceCandidatePayload = {
  event: WebSocketMessagePayloadEvent.NewIceCandidate
  data: { candidate: RTCIceCandidate }
}

export type IceAnswerPayload = {
  event: WebSocketMessagePayloadEvent.IceAnswer
  data: { answer: RTCSessionDescriptionInit }
}

export type OfferPayload = {
  event: WebSocketMessagePayloadEvent.Offer
  data: { offer: RTCSessionDescriptionInit }
}

export type StartCallingPayload = {
  event: WebSocketMessagePayloadEvent.StartCalling
  data: CallInfo
}

export type StopCallingPayload = {
  event: WebSocketMessagePayloadEvent.StopCalling
  data: { userId: string }
}

export type CallAnsweredPayload = {
  event: WebSocketMessagePayloadEvent.CallAnswered
  data: { connectionId: string }
}

export type CallRejectedPayload = {
  event: WebSocketMessagePayloadEvent.CallRejected
  data: CallInfo
}

export type CallEndedPayload = {
  event: WebSocketMessagePayloadEvent.CallEnded
}

export enum WebSocketMessagePayloadEvent {
  UserVisibility = 'user_visibility',
  StartCalling = 'start_calling',
  StopCalling = 'stop_calling',
  CallAnswered = 'call_answered',
  CallRejected = 'call_rejected',
  CallEnded = 'call_ended',
  NewIceCandidate = 'new_ice_candidate',
  IceAnswer = 'ice_answer',
  Offer = 'offer'
}

export const AsyncWebSocket = ({url, onmessage}: AsyncWebSocketProps) => {

  return new Promise(function(resolve, reject) {
    const socket = new WebSocket(url)

    socket.onopen = () => resolve(socket)          

    socket.onerror = (err) => reject(err)        

    socket.onclose = () => {
      console.log(`socket closed`)     
    }

    socket.onmessage = (event) => {
      onmessage(JSON.parse(event.data))
    }    
  })
}
