import React from 'react'
import { Link } from 'react-router-dom'
import { ScanListingEntry } from 'uploads/api/Model'
import { formatDate } from 'utils/DateUtils'
import _ from 'lodash'
import { DatasetListingEntry } from 'uploads/model/Dataset'
import { capLengthIfNeeded } from 'utils/TextUtils'
import { ReportsApi } from 'reports/api/ReportsApi'
import { connect } from 'react-redux'
import { downloadReportOutput } from 'reports/api/ReportsSlice'


export type ScansTableProps = {
  scans: ScanListingEntry[]
  datasets?: DatasetListingEntry[]
  linkTo: (caseId: string) => string
  titleText: string
  downloadReportOutput: (reportId: string, outputId: string) => void
}


export const ScansTable = ({scans, datasets, linkTo, titleText, downloadReportOutput}: ScansTableProps) => {
  const sortedOrder = datasets === undefined ? ['name'] : ['datasetId', 'name']  

  return(
    <div className="scans-list-component">
      <h2>{titleText}</h2>
      <table className="table table-bordered table-striped" style={{ width: '1000px'}}>
        <thead>
          <tr>
            <th style={{ width: '250px' }} scope="col">Case Name</th>
            { datasets !== undefined && 
            <th style={{ width: '250px' }} scope="col">Dataset</th>
            }
            <th style={{ width: '150px' }} scope="col">Report</th>
            <th style={{ width: '200px' }} scope="col">Created Date</th>
          </tr>
        </thead>
        <tbody>
          { _.sortBy(scans, sortedOrder).map((scan: ScanListingEntry) => 
            <ScanRow key={scan.scanId} scan={scan} datasets={datasets} linkTo={linkTo} downloadReportOutput={downloadReportOutput} />
          )}
        </tbody>
      </table>    
    </div>
  )
}

type ScanRowProps = {
  scan: ScanListingEntry
  datasets?: DatasetListingEntry[]
  linkTo: (caseId: string) => string
  downloadReportOutput: (reportId: string, outputId: string) => void
}

const ScanRow = ({scan, datasets, linkTo, downloadReportOutput}: ScanRowProps) => {

  const reportsApi = new ReportsApi(window.RuntimeConfig.backend)

  const report = _.orderBy(scan.reportOutputs, o => o.createdDate, 'desc')[0]

  const onDownloadClick = (e) => {
    e.preventDefault()
    if(report !== undefined) {
      downloadReportOutput(report.reportId, report.outputId)
    }    
  }

  return (
    <tr>
      <td title={scan.name}><Link to={linkTo(scan.scanId)}  >{capLengthIfNeeded(scan.name || 'unnamed', 32)}</Link></td>   
      { datasets !== undefined && 
      <td>{capLengthIfNeeded(datasets.find(d => d.datasetId === scan.datasetId)?.name || '', 34)}</td>              
      }
      { report !== undefined && 
        <td><a href={reportsApi.reportOutputUrl(report.reportId, report.outputId)} onClick={onDownloadClick} >Available</a></td>              
      }

      { report === undefined &&
        <td>Pending</td>              
      } 
      <td>{formatDate(scan.createdDate)}</td>              
    </tr>
  )
}


const mapStateToProps = () => {
  return {

  }
}

const mapDispatchToProps = { 
  downloadReportOutput
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ScansTable)