import React from 'react'
import { Link } from 'react-router-dom'
import { formatDate } from 'utils/DateUtils'
import _ from 'lodash'
import { DatasetListingEntry } from 'uploads/model/Dataset'
import { getUserName, User } from 'auth/model/User'
import { capLengthIfNeeded } from 'utils/TextUtils'
import { PermissionLevel } from 'auth/model/PermissionLevel'
import { DeleteX } from 'app/components/icons/DeleteX'


export type DatasetsTableProps = {
  datasets?: DatasetListingEntry[]  
  titleText: string
  users?: User[]
  showRemoveSelf: boolean
  currentUserId: string
  updateAccessPermissions: (datasetId: string, userId: string, level: PermissionLevel, refresh: boolean) => Promise<void>
  listDatasets: () => void
}

export const DatasetsTable = ({
  datasets, titleText, users, showRemoveSelf, currentUserId,
  updateAccessPermissions, listDatasets
}: DatasetsTableProps) => {

  return(
    <div className="datasets-list-component">
      <h2>{titleText}</h2>
      <table className="table table-bordered table-striped" style={{ width: '1000px'}}>
        <thead>
          <tr>
            <th style={{ width: '270px' }} scope="col">Dataset Name</th>
            <th style={{ width: '80px' }} scope="col"># cases</th>
            <th style={{ width: '200px' }} scope="col">Created By</th>
            <th style={{ width: '150px' }} scope="col">Created Date</th>
            {showRemoveSelf && 
            <th style={{ width: '80px' }} scope="col">Remove self</th>
            }
          </tr>
        </thead>
        <tbody>
          { _.sortBy(datasets, ['name']).map(dataset => 
            <tr key={dataset.datasetId}>
              <td><Link to={`/datasets/${dataset.datasetId}`} >{capLengthIfNeeded(dataset.name, '32')}</Link></td>     
              <td>{dataset.cases !== undefined ? `${dataset.cases.length}` : ''}</td>  
              <td>{getUserName(users, dataset.createdBy)}</td>  
              <td>{formatDate(dataset.createdDate)}</td>             
              {showRemoveSelf && 
              <td>
                <DeleteX title={"Remove self"} onClick={() => 
                  updateAccessPermissions(dataset.datasetId, currentUserId, PermissionLevel.none, false).then(listDatasets)
                } />
              </td>
              }
            </tr>
          )}
        </tbody>
      </table>    
    </div>
  )
}
