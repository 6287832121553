export type Theme = {
  headerBackground: string
  headerLosenge: string
  buttonColor1: string
  buttonBorder1: string
  buttonColor2: string
  buttonBorder2: string
  buttonColor3: string
  buttonBorder3: string
  buttonShadowColor: string
  lightButton: string
  lightButtonBorder: string
  lightButtonText: string
  lightBackground: string
  darkBackground: string
  sectionHeaderColor: string
}

export const DefaultTheme: Theme = {
  headerBackground: '#38457D',
  headerLosenge: 'rgb(79, 97, 176)',
  buttonColor1: 'rgb(151, 63, 145)',
  buttonBorder1: '1px solid rgb(177, 54, 125)',
  buttonColor2: 'rgb(98, 127, 97)',
  buttonBorder2: '1px solid rgb(108, 138, 82)',
  buttonColor3: 'rgb(182, 128, 115)',
  buttonBorder3: '1px solid rgb(218, 140, 99)',
  buttonShadowColor: 'rgb(55, 68, 124)',
  lightButton: 'rgb(89, 128, 199)',
  lightButtonBorder: '0px',
  lightButtonText: 'white',
  lightBackground: 'rgba(73, 91, 165, 0.85)',
  darkBackground: 'rgba(42, 52, 94, 0.95)',
  sectionHeaderColor: 'rgb(68, 68, 68)'
}
