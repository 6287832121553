
import { RootState } from "app/store"
import { updateLogLevel } from "config/ConfigSlice"
import { nanoid } from "nanoid"
import { useState } from "react"
import { connect } from "react-redux"

const sectionStyle = {
  border: '1px solid lightgray',
  borderRadius: '10px',
  padding: '10px',
  margin: '20px',
  width: '300px'
}

const inputStyle = {display: 'block', width: '250px'}

export type ConfigEditorProps = {
  setLogLevel: (level: string) => void
}

export const ConfigEditor = ({setLogLevel}: ConfigEditorProps) => {
  
  const [keyCloakUrl, setKeyCloakUrl] = useState<string>(() => window.RuntimeConfig.keyCloak.url )
  const [keyCloakRealm, setKeyCloakRealm] = useState<string>(() => window.RuntimeConfig.keyCloak.realm)
  const [keyCloakClientId, setKeyCloakClientId] = useState<string>(() => window.RuntimeConfig.keyCloak.clientId)
  const [backendUrl, setBackendUrl] = useState<string>(() => window.RuntimeConfig.backend.url)
  const [autoClearErrors, setAutoClearErrors] = useState<boolean>(() => window.RuntimeConfig.frontend.autoClearErrors)
  const [useFrontendCanary, setUseFrontendCanary] = useState<boolean>(() => window.RuntimeConfig.frontend.canary === 'frontend-canary')
  
  const saveChanges = () => {
    window.localStorage.setItem("keyCloakUrl", keyCloakUrl)
    window.localStorage.setItem("keyCloakRealm", keyCloakRealm)
    window.localStorage.setItem("keyCloakClientId", keyCloakClientId)
    window.localStorage.setItem("backendUrl", backendUrl)
    window.localStorage.setItem("autoClearErrors", autoClearErrors.toString())

    window.localStorage.setItem("frontendCanary", useFrontendCanary ? 'frontend-canary' : '' )
    document.cookie = `elite_ai_frontend_canary=${useFrontendCanary ? 'frontend-canary' : ''}`

    window.location.reload()
  }

  const discardChanges = () => {
    setKeyCloakUrl(window.RuntimeConfig.keyCloak.url)
    setKeyCloakRealm(window.RuntimeConfig.keyCloak.realm)
    setKeyCloakClientId(window.RuntimeConfig.keyCloak.clientId)
    setBackendUrl(window.RuntimeConfig.backend.url)
    setUseFrontendCanary(window.RuntimeConfig.frontend.canary === 'frontend-canary')
    setAutoClearErrors(window.RuntimeConfig.frontend.autoClearErrors)
  }

  const setLocalDefaults = () => {
    window.localStorage.setItem("keyCloakUrl", 'http://localhost:7000/auth/')
    window.localStorage.setItem("keyCloakRealm", 'eliteai')
    window.localStorage.setItem("keyCloakClientId", 'frontend')
    window.localStorage.setItem("backendUrl", 'http://localhost:7000')
    window.localStorage.setItem("autoClearErrors", 'false')

    window.localStorage.setItem("frontendCanary", useFrontendCanary ? 'frontend-canary' : '' )
    document.cookie = `elite_ai_frontend_canary=${useFrontendCanary ? 'frontend-canary' : ''}`

    window.location.reload()
  }

  const setProductionDefaults = () => {
    window.localStorage.setItem("keyCloakUrl", 'https://medicineserver.ucd.ie/auth/')
    window.localStorage.setItem("keyCloakRealm", 'eliteai')
    window.localStorage.setItem("keyCloakClientId", 'frontend')
    window.localStorage.setItem("backendUrl", 'https://medicineserver.ucd.ie')
    window.localStorage.setItem("autoClearErrors", 'true')

    window.localStorage.setItem("frontendCanary", useFrontendCanary ? 'frontend-canary' : '' )
    document.cookie = `elite_ai_frontend_canary=${useFrontendCanary ? 'frontend-canary' : ''}`

    window.location.reload()
  }

  const configLines = JSON.stringify(window.RuntimeConfig, null, 2).split('\n')

  return (
    <div>
      <div style={{marginLeft: '10px'}}>
        <h1>Config editor</h1>
        <div style={sectionStyle}>
          <label>
            KeyCloak URL: 
            <input type="text" style={inputStyle} value={keyCloakUrl} onChange={(e) => setKeyCloakUrl(e.target.value)}></input>
          </label>
        </div>
        <div style={sectionStyle}>
          <label>
            KeyCloak Realm: 
            <input type="text" style={inputStyle} value={keyCloakRealm} onChange={(e) => setKeyCloakRealm(e.target.value)}></input>
          </label>
        </div>
        <div style={sectionStyle}>
          <label>
            KeyCloak Client ID: 
            <input type="text" style={inputStyle} value={keyCloakClientId} onChange={(e) => setKeyCloakClientId(e.target.value)}></input>
          </label>
        </div>
        <div style={sectionStyle}>
          <label>
            Backend URL: 
            <input type="text" style={inputStyle} value={backendUrl} onChange={(e) => setBackendUrl(e.target.value)}></input>
          </label>
        </div>
        <div style={sectionStyle}>
          <label>
            <span style={{marginTop: '-2px'}} >Frontend Canary: </span>
            <input type="checkbox" checked={useFrontendCanary} onChange={() => setUseFrontendCanary(!useFrontendCanary)} ></input>
          </label>
        </div>
        <div style={sectionStyle}>
          <label>
            <span style={{marginTop: '-2px'}} >Auto-clear errors: </span>            
            <input type="checkbox" checked={autoClearErrors} onChange={() => setAutoClearErrors(!autoClearErrors)}></input>
          </label>
        </div>

        <div style={{margin:'5px'}}>
          <button className="btn btn-primary" style={{marginLeft: '5px'}} onClick={saveChanges}>Save changes</button>
          <button className="btn btn-primary" style={{marginLeft: '5px'}} onClick={discardChanges}>Discard changes</button>
        </div>

        <div style={{margin:'5px'}}>
          <button className="btn btn-primary" style={{marginLeft: '5px'}} onClick={setLocalDefaults}>Local defaults</button>
          <button className="btn btn-primary" style={{marginLeft: '5px'}} onClick={setProductionDefaults}>Production defaults</button>
        </div>

        <div style={{margin:'5px'}}>
        <button className="btn btn-primary" style={{marginLeft: '5px'}} onClick={() => setLogLevel('info')}>Backend info logging</button>
        <button className="btn btn-primary" style={{marginLeft: '5px'}} onClick={() => setLogLevel('debug')}>Backend debug logging</button>
        </div>

      </div>


      <div style={{ margin: '20px'}}>
        <ul>
          {configLines.map((line, index) =>
            <li key={index} style={{ whiteSpace: 'pre'}}>{line}</li>
          )}
        </ul>
      </div>
    </div>
  )
}


const mapStateToProps = (state: RootState) => {
  return {}
}

const mapDispatchToProps = { 
  setLogLevel: (level: string) => updateLogLevel(level, nanoid())
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConfigEditor)