import axios from 'axios'
import retry from 'async-retry'

import { currentAuthHeaders } from 'auth/KeyCloak' 
import { BackendConfig } from 'config/ApplicationConfig'
import { User } from 'auth/model/User'
import { AsyncWebSocket, IncomingWebSocketMessage } from 'utils/AsyncWebSocket'

export const getUserAvatarUrl: (config: BackendConfig) => (userId: string) => string = (config) => (userId) =>
 `${config.url}/eliteai/avatars/user/${userId}`


const HideUserIds = ['96b94b1c-2317-4ebc-a2ab-6825c6958a29']

export class UsersApi  {

  usersUrl: string
  cookieUrl: string
  userUrl: (userId: string) => string
  userFeatureUrl: (userId: string, featureId: string) => string
  userFeaturesUrl: (userId: string) => string
  userAvatarUrl: (userId: string) => string
  retryCount: number

  constructor(config: BackendConfig) {
    this.retryCount = config.retryCount
    this.cookieUrl = `${config.url}/eliteai/cookie`
    this.usersUrl = `${config.url}/eliteai/users`
    this.userUrl = (userId) => `${this.usersUrl}/${userId}`
    this.userFeatureUrl = (userId, featureId) => `${this.userUrl(userId)}/${featureId}`
    this.userFeaturesUrl = (userId) => `${this.userUrl(userId)}/features`
    this.userAvatarUrl = (userId) => getUserAvatarUrl(config)(userId)
  }

  async listUsers(correlationId: string) {
    return retry(async (bail) => {
      const headers = await currentAuthHeaders(correlationId)

      const response = await axios.get<User[]>(this.usersUrl, { headers })

      return response.data.filter(u => !HideUserIds.includes(u.id))
    }, {
      retries: this.retryCount
    })    
  }

  async updateUserFeature(userId: string, featureId: string, status: string, correlationId: string) {
    return retry(async (bail) => {
      const headers = await currentAuthHeaders(correlationId)

      await axios.put(this.userFeatureUrl(userId, featureId), { status }, { headers })
    }, {
      retries: this.retryCount
    })    
  }

  async updateUserFeatures(userId: string, features: any, correlationId: string) {
    return retry(async (bail) => {
      const headers = await currentAuthHeaders(correlationId)

      await axios.post(this.userFeaturesUrl(userId), { features }, { headers })
    }, {
      retries: this.retryCount
    })    
  }

  async updateUserAvatar(userId: string, formData: FormData, correlationId: string) {
    return retry(async (bail) => {
      const headers = await currentAuthHeaders(correlationId)

      await axios.post(this.userAvatarUrl(userId), formData, { headers })
    }, {
      retries: this.retryCount
    })    
  }

  async setCookie(correlationId: string) {
    return retry(async (bail) => {
      const headers = await currentAuthHeaders(correlationId)

      const response = await axios.post<void>(this.cookieUrl, { }, { headers })
    
      return response.data
    }, {
      retries: this.retryCount
    })    
  }

}

