import SeriesViewer from "annotation/components/SeriesViewer"
import { RootState } from "app/store"
import { useEffect, useState } from "react"
import { connect } from "react-redux"
import { Scan } from "uploads/api/Model"
import { SeriesDescriptionsMap, getSeriesDescriptions } from "uploads/util/SeriesDisplayName"
import { SeriesSelector } from "./SeriesSelector"
import { Theme } from "app/model/Theme"
import { Dataset } from "uploads/model/Dataset"
import { UserFeatures } from "auth/UserSlice"
import { CaseLink } from "uploads/component/CaseLink"

export type CaseDetailsSeriesViewProps = {
  scan: Scan
  dataset?: Dataset 
  features: UserFeatures
  currentUserId: string
  annotationsCreatedBy: string[]
  seriesDescriptions: SeriesDescriptionsMap
  theme: Theme
  hasWriteAccess: Boolean
}

export const CaseDetailsSeriesView = ({
  scan, currentUserId, annotationsCreatedBy, seriesDescriptions, theme, dataset, features, hasWriteAccess
}: CaseDetailsSeriesViewProps) => {

  const [seriesId, setSeriesId] = useState<string>()

  useEffect(() => {
    if(seriesId === undefined && scan.scanInfo.orderedSeriesIds.length > 0) {
      setSeriesId(scan.scanInfo.orderedSeriesIds[0])
    }
  }, [seriesId, scan.scanInfo.orderedSeriesIds.length])

  const [displayInstanceNumber, setDisplayInstanceNumber] = useState<number>()

  return (
    <div>
      <div style={{display: 'flex'}}>
        <SeriesSelector series={scan.scanInfo.orderedSeriesIds} selectedSeriesId={seriesId} seriesDescriptions={seriesDescriptions} setSelectedSeriesId={setSeriesId} />

        { (
            (scan.datasetId === undefined || (dataset !== undefined && dataset.datasetInfo.annotators.includes(currentUserId))) && 
            hasWriteAccess && features.createAnnotations
          ) ?
          <CaseLink to={`/annotations/${scan.scanId}`} text="Annotate" dataCy="annotations-link" theme={theme} /> 
          : 
          <CaseLink to={`/cases/${scan.scanId}/view`} text="View all series" dataCy="view-case-link" theme={theme} minWidth={150} />
        }

      </div>

      { seriesId !== undefined &&
        <SeriesViewer 
          scan={scan} 
          scanId={scan.scanId} 
          currentUserId={currentUserId} 
          hasWriteAccess={false} 
          hideTitleBar={true}
          seriesId={seriesId} 
          annotateMode={false} 
          labelAllAnnotations={true} 
          showMaximise={false} 
          displayModelAnnotations={false} 
          displayedUserAnnotations={annotationsCreatedBy}
          editingAnnotation={undefined} 
          isActiveSeries={false} 
          contrastPercentage={100} 
          intensityPercentage={100} 
          showModelOutputs={false} 
          hiddenAnnotations={[]} 
          show3d={false} 
          clearDisplayInstanceNumber={()  => {} }
          displayInstanceNumber={displayInstanceNumber} 
          setDisplayInstanceNumber={setDisplayInstanceNumber} 
          setSelectedSeries={()  => {}} 
          setSavingAnnotationIds={()  => {}} 
          enter3d={()  => {}} 
          exit3d={()  => {}} 
          setSeriesDisplayName={()  => {}} 
        />
      }
    </div>
  )
}


const mapStateToProps = (state: RootState, { scanId }) => {
  const seriesDescriptions = getSeriesDescriptions(state.scans.imagesMetadata[scanId])
  
  return { 
    seriesDescriptions
  }
}

const mapDispatchToProps = {
  
}


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CaseDetailsSeriesView)
