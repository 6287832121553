import { useEffect, useState } from "react"

export type LabelledCheckBoxProps = {
  id: string
  label: string
  dataCy?: string
  checked: boolean
  disabled?: boolean
  width?: number
  labelFirst?: boolean
  onChange: () => void
}

export const LabelledCheckBox = ({
  id, dataCy, checked, disabled, label, width, labelFirst,
  onChange
}: LabelledCheckBoxProps) => {

  const [isChecked, setIsChecked] = useState<boolean>(checked)
  useEffect(() => {setIsChecked(checked)}, [checked])

  const handler = () => {    
    setIsChecked(current => current === undefined ? !checked : !current )
    onChange()
  }

  return (
    <label 
      htmlFor={id} 
      style={{
        display: 'block', 
        width: width !== undefined ? `${width}px` : '200px', 
        height: '25px'
      }}
    >
      { (labelFirst === true) &&
        label
      }
      <input 
        type="checkbox" 
        id={id} 
        disabled={disabled}
        data-cy={ dataCy }
        name={`show_debug_ui_toggle`} 
        checked={ isChecked !== undefined ? isChecked : checked } 
        onChange={ handler }
        style={{marginRight: '5px', marginLeft: '5px', verticalAlign: 'middle', position: 'relative', bottom: '1px'}}
      />
      {!(labelFirst === true) &&
        label
      }        
    </label>
  )
}