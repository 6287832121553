import Keycloak from 'keycloak-js'

export const keycloak = new Keycloak(window.RuntimeConfig.keyCloak)

keycloak.onAuthRefreshError = () => {
  console.log(`onAuthRefreshError`)
  keycloak.logout()
}

keycloak.onTokenExpired = () => keycloak.updateToken(5 * 60)

window.GlobalKeyCloak = keycloak

export type AuthHeaders = {
  Authorization: string
  "X-Correlation-ID": string
}

const refreshToken = async (minValidity: number |undefined) => {
  await keycloak.updateToken(minValidity || 5)
}

export const getToken = async (minValidity: number | undefined) => {
  await refreshToken(minValidity)

  return keycloak.token
}

export const getTokenParsed = async (minValidity: number) => {
  await refreshToken(minValidity)

  return keycloak.tokenParsed
}

export const currentAuthHeaders: (correlationId: string, minValidity?: number) => Promise<AuthHeaders> = async (correlationId, minValidity) => {
  const token = await getToken(minValidity)  

  return { 
    Authorization: `Bearer ${token}`,
    "X-Correlation-ID": correlationId
  }
}
