import { AccessEntry } from 'auth/model/AccessEntry'
import { PermissionLevel } from 'auth/model/PermissionLevel'
import { User } from 'auth/model/User'
import { useEffect, useState } from 'react'
import { Dataset } from 'uploads/model/Dataset'
import { RootState } from 'app/store'
import { connect } from 'react-redux'
import { setUserInfoMessage } from 'auth/UserSlice'
import { AddPeopleComponent } from './AddPeopleComponent'
import { UsersAccessList } from './UsersAccessList'
import { nanoid } from 'nanoid'
import { Theme } from 'app/model/Theme'
import { Button } from 'app/components/Button'

export type SharingWidgetProps = {
  accessEntries?: AccessEntry[]
  users?: User[] 
  entitiyName: string
  caseDataset?: Dataset
  currentUserId?: string
  theme: Theme
  addPermission: (userId: string, level: PermissionLevel, refresh: boolean) => void
  setUserInfoMessage: (message: string) => void
}

export const SharingWidget = ({
  accessEntries, entitiyName, users, caseDataset, currentUserId, theme,
  addPermission, setUserInfoMessage
}: SharingWidgetProps) => {

  const [isExpanded, setIsExpanded] = useState<boolean>(false)

  return (
    <div className="sharing-widget" style={{
      marginLeft: 'auto',
      marginRight:'0px',
      position: 'relative', 
      marginTop: '15px'
      }}
    >
      <div style={{paddingTop: '0px', fontSize: '15pt'}}>
        <Button 
          className="toggle-sharing-widget" 
          onClick={() => setIsExpanded(!isExpanded)} 
          dataCy="toggle-sharing-widget"
          text="Share"
          theme={theme}
        />
      </div>

      { isExpanded && 
        <div 
          style={{
          position: 'absolute', 
          zIndex: 10,
          width: '420px',
          height: `${Math.min(400 + (accessEntries?.length || 0) * 50, 500) }px`,
          resize: 'vertical',
          overflow: 'auto',
          top: '43px',
          right: '1px',
          border: '1px solid grey',
          borderRadius: '10px',
          padding: '10px',
          background: 'white',
          boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'
        }}>
          <h5>Share this {entitiyName} with other users</h5>                   
          <AddPeopleComponent 
            users={users || []} 
            addPermission={addPermission} 
            accessEntries={accessEntries || []} 
            currentUserId={currentUserId}
            setInfoMessage={setUserInfoMessage}
            entitiyName={entitiyName} />
          <UsersAccessList 
            accessEntries={accessEntries} 
            users={users} 
            caseDataset={caseDataset}             
          />
        </div>
      }
    </div>
  )    
}

const mapDispatchToProps = {
  setUserInfoMessage
}

const mapStateToProps = (state: RootState) => {
  return {
    currentUserId: state.user.currentUserId
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SharingWidget)

