import { CallInfo, getUserName, User } from "auth/model/User"
import { isOnlineVisiblilty, UserVisiblilty } from "auth/model/UserVisibility"
import { UserStatus } from "auth/UserSlice"
import { useCallback, useEffect, useState } from "react"
import { formatDate } from "utils/DateUtils"
import { capLengthIfNeeded } from "utils/TextUtils"
import { ChevronDown, ChevronUp } from "./icons/Chevrons"
import { userAgentSupportsVR } from "utils/UserAgent"



export type ChatBoxProps = {
  users: User[]
  statuses: { [userId: string]: UserStatus}
  currentUserId: string
  incomingCalls: CallInfo[]
  outgoingCalls: string[]
  ongoingCalls: string[]
  defaultExpanded?: boolean
  openSocket: (currentUserId: string, visibility: UserVisiblilty) => void
  callUser: (userId: string, currentUserId: string, sendVideo: boolean) => void
  stopCallingUser: (userId: string, currentUserId: string) => void
  answerCall: (callInfo: CallInfo, currentUserId: string, sendVideo: boolean) => void
  rejectCall: (callInfo: CallInfo, currentUserId: string) => void
  endCalls: (connectionIds: string[], currentUserId: string) => void
  updateVisibility: (currentUserId: string, visibility: UserVisiblilty) => void
  heartbeat: (visibility: UserVisiblilty, currentUserId: string) => void
  startScreenShare: () => void
}

export const ChatBox = ({
  users, statuses, currentUserId, incomingCalls, outgoingCalls, ongoingCalls, defaultExpanded,
  openSocket, callUser, stopCallingUser, answerCall, updateVisibility, heartbeat, startScreenShare,
  rejectCall, endCalls
}: ChatBoxProps) => {

  useEffect(() => { openSocket(currentUserId, statuses[currentUserId]?.visibility || UserVisiblilty.Online) }, [openSocket])

  const updateStatus = useCallback(() => {    
    const status = statuses[currentUserId]
    if(status !== undefined) {
      heartbeat(status.visibility, currentUserId)
    }
  }, [statuses[currentUserId]])

  useEffect(() => {
    const interval = setInterval(updateStatus, 20 * 1000)
    return () => clearInterval(interval)    
  }, [updateStatus])

  const [expanded, setExpanded] = useState<boolean>(defaultExpanded || false)

  const isVR = userAgentSupportsVR()
  const sendVideo = !isVR
  
  return (
    <div style={{ 
      backgroundColor: 'white', 
      zIndex: '1000',
      position: 'fixed', 
      width: '200px',
      height: 'auto',
      right: '10px', 
      bottom: '10px',
      border: '1px solid lightgrey',
      borderRadius: '5px'
    }}>
      <div style={{margin: '5px 5px 5px 15px', cursor: 'pointer'}} onClick={() => setExpanded(e => !e)}>
        <div style={{display: 'inline-block', marginRight: '5px'}}>{expanded ? <ChevronDown /> : <ChevronUp/>}</div>
        
        {pluraliseUsers(Object.values(statuses).filter(s => isOnlineVisiblilty(s.visibility)).length)} online
      </div>
      { expanded && 
      <ul style={{paddingLeft: '10px', marginTop: '5px'}}>        
        {Object.entries(statuses).map(([userId, status]) =>
          <UserRow 
            key={userId} 
            userId={userId} 
            isSelf={userId === currentUserId} 
            users={users} 
            status={status} 
            incomingCalls={incomingCalls}
            outgoingCalls={outgoingCalls}
            callUser={() => callUser(userId, currentUserId, sendVideo)} 
            stopCallingUser={() => stopCallingUser(userId, currentUserId)}
            answerCall={(callInfo) => answerCall(callInfo, currentUserId, sendVideo)}
            updateVisibility={(visibility) => {
              updateVisibility(currentUserId, visibility)
              heartbeat(visibility, currentUserId)
            }}
          />  
        )}              
      </ul>
      }
      { (incomingCalls.length > 0 || outgoingCalls.length > 0 || ongoingCalls.length > 0) &&         
      <div style={{padding: '5px'}}>
        <hr style={{borderTop: 'dotted 1px', margin: '10px'}} />
        <div>
        { incomingCalls.map(callInfo =>
          <div key={callInfo.connectionId}>
            <div key={callInfo.connectionId} onClick={() => answerCall(callInfo, currentUserId, sendVideo)}>{getUserName(users, callInfo.userId)} calling</div>
            <div style={{display: 'flex', marginLeft: '30px'}}>
              <img
                src={'/icons/phone_call.png'}
                alt={'Answer'}
                title={'Answer'}
                height={35}
                width={35}
                onClick={() => answerCall(callInfo, currentUserId, sendVideo)}
                style={{cursor: 'pointer', margin: '10px'}}
              />
              <img 
                src={'/icons/hang_up.png'} 
                alt={'Reject'} 
                title={'Reject'} 
                height={35}
                width={35}
                onClick={() => rejectCall(callInfo, currentUserId)} 
                style={{cursor: 'pointer', margin: '10px'}}
              />
            </div>
          </div>
        )}
        </div>
        <div>
        { outgoingCalls.map(userId =>
          <span key={userId} onClick={() => stopCallingUser(userId, currentUserId)}>
            Calling {getUserName(users,  userId)}
            <img 
              src={'/icons/hang_up.png'} 
              alt={'Stop calling'} 
              title={'Stop calling'} 
              height={35}
              width={35}
              onClick={() => endCalls(ongoingCalls, currentUserId)} 
              style={{cursor: 'pointer', marginLeft: '10px'}}
            />
          </span>
        )}
        </div>
        <div>
        { ongoingCalls.map( ongoingCall =>
          <div key={ongoingCall} >
            <video id={`video-element-${ongoingCall}`} autoPlay playsInline controls width={180} height={180} />            
          </div>
        )}
        </div>
        {ongoingCalls.length > 0 &&
        <div style={{display: 'flex'}}>
          <div style={{marginLeft: '30px'}}>
            <img 
              src="/icons/screen_share.png" 
              width={35} height={35} 
              style={{cursor: 'pointer', marginLeft: '5px'}} 
              alt="Start screen share" 
              title="Start screen share" 
              onClick={startScreenShare}
            />
            <div>Present</div>
          </div>
          <div style={{marginLeft: '20px'}}>
            <img 
              src={'/icons/hang_up.png'} 
              alt={'End call'} 
              title={'End call'} 
              height={35}
              width={35}
              onClick={() => endCalls(ongoingCalls, currentUserId)} 
              style={{cursor: 'pointer', marginLeft: '5px'}}
            />
            <div>End call</div>
          </div>
        </div>
        }
      </div>
      }    
    </div>
  )  
}

const pluraliseUsers = (count: number) => count === 1 ? '1 user' : `${count} users`

type UserRowProps = {
  userId: string
  users: User[]
  status: UserStatus
  isSelf: boolean
  incomingCalls: CallInfo[]
  outgoingCalls: string[]
  callUser: () => void
  stopCallingUser: () => void
  answerCall: (callInfo: CallInfo) => void
  updateVisibility: (visibility: UserVisiblilty) => void
}

const UserRow = ({
  userId, users, status, isSelf, incomingCalls, outgoingCalls,
  callUser, stopCallingUser, answerCall, updateVisibility
}: UserRowProps) => {

  const onStatusClick = useCallback(() => {
    if(isSelf) { 
      if(status.visibility !== UserVisiblilty.Offline) {
        updateVisibility(UserVisiblilty.Offline)
      } else {
        updateVisibility(UserVisiblilty.Online)
      }
    }
  }, [status.visibility])

  const now = new Date().getTime()
  const lastUpdated = new Date(status.lastUpdated).getTime()
  const age = (now - lastUpdated) / 1000

  const visibility = (status.visibility === UserVisiblilty.Online && age > 30) ? UserVisiblilty.Away : status.visibility

  const color: string = (() => {
    switch(visibility) {
      case UserVisiblilty.Online: 
      case UserVisiblilty.OnACall:
        return 'green'
      case UserVisiblilty.Offline:
        return 'red'
      case UserVisiblilty.Away:
        return 'yellow'
      default:
        return 'red'
    }
  })()

  const callFunction = (() => {
    const incomingCall = incomingCalls.find(c => c.userId === userId)
    if(incomingCall !== undefined) {
      return {
        text: 'Answer call', 
        icon: '/icons/phone_call.png', 
        f: () => answerCall(incomingCall)
      }
    } else if(outgoingCalls.find(c => c === userId)) {
      return {
        text: 'Stop calling', 
        icon: '/icons/hang_up.png', 
        f: stopCallingUser
      }
    } else if(visibility === UserVisiblilty.Online && !isSelf) {
      return {
        text: 'Call', 
        icon: '/icons/phone_call.png', 
        f: callUser
      }
    } else {
      return undefined
    }
  })()


  return (
    <li title={`Last seen ${formatDate(status.lastUpdated)}`}>
      <span 
        style={{fontSize: '12pt', color, marginRight: '3px', cursor: isSelf ? 'pointer': 'default'}}
        onClick={onStatusClick}
      >●</span>
      <span style={{minWidth: '20px', display: 'inline-block'}}>
        { callFunction !== undefined &&
        <img 
          src={callFunction.icon} 
          alt={callFunction.text} 
          title={callFunction.text} 
          height={18}
          width={18}
          onClick={callFunction.f} 
          style={{cursor: 'pointer', marginBottom: '2px'}}
        />
        }</span>
      <span>{capLengthIfNeeded(getUserName(users, userId), 17)}</span> 
    </li>
  )
}
