import { FormValues } from 'uploads/component/HamstringForm'


export type ACLFormDisplayProps = {
  current?: FormValues
}

export const ACLFormDisplay = ({  
  current
}: ACLFormDisplayProps) => {

  if(current === undefined) {
    return <></>
  }

  return (
    <div>
      <h2 style={{padding: '10px'}}>ACL classification</h2>

      <ul>
      { current.aclImpression === 'intact' &&
        <li>- Intact ACL</li>
      }
      { current.aclImpression === 'torn' &&
        <li>- Torn ACL</li>
      }
      { current.aclReconstruction === true &&
        <li>- ACL Reconstruction detected</li>
      }
      { current.aclReconstruction === false &&
        <li>- No ACL Reconstruction detected</li>
      }
      { current.lowImageQuality === true &&
        <li>- Low quality image detected</li>
      }    
      </ul>    
    </div>
  )
}
