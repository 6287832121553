import axios from 'axios'
import retry from 'async-retry'

import { currentAuthHeaders } from 'auth/KeyCloak' 
import { BackendConfig } from 'config/ApplicationConfig'
import { AnnotationsImportValidationResult, ListPermissionsResponse, ImportedAnnotationsResponse,  } from '../../uploads/api/Model'
import { PermissionLevel } from 'auth/model/PermissionLevel'
import { Dataset, DatasetInfo, DefaultDatasetInfo, ListDatasetsResponse } from 'uploads/model/Dataset'
import { DatasetAnnotationLabel } from 'uploads/model/DatasetAnnotationLabel'
import { GenerateCocoExportResponse } from './DatasetResponses'

const ensureDefaults: (dataset: Dataset) => void = (dataset) => {
  if(dataset.datasetInfo === undefined) {
    dataset.datasetInfo = DefaultDatasetInfo
  }
  if(dataset.datasetInfo.annotationMode === undefined) {
    dataset.datasetInfo.annotationMode = DefaultDatasetInfo.annotationMode
  }
  if(dataset.datasetInfo.seriesDisplayNames === undefined) {
    dataset.datasetInfo.seriesDisplayNames = {}
  }
  if(dataset.datasetInfo.seriesDescriptionsForAnnotation === undefined) {
    dataset.datasetInfo.seriesDescriptionsForAnnotation = []
  }
  if(dataset.datasetInfo.annotationApprovers === undefined) {
    dataset.datasetInfo.annotationApprovers = []
  }
  if(dataset.datasetInfo.userAnnotationColors === undefined) {
    dataset.datasetInfo.userAnnotationColors = {}
  }
  if(dataset.datasetInfo.orderedSeriesIds === undefined) {
    dataset.datasetInfo.orderedSeriesIds = []
  }
  if(dataset.datasetInfo.modelServiceConfig === undefined) {
    dataset.datasetInfo.modelServiceConfig = ''
  }
  if(dataset.datasetInfo.isAnonymised === undefined) {
    dataset.datasetInfo.isAnonymised = true
  }  
  if(dataset.datasetInfo.amsData !== undefined) {
    dataset.datasetInfo.amsData = undefined
  }  
  if(dataset.datasetInfo.annotators === undefined) {
    dataset.datasetInfo.annotators = []
  }
}

export class DatasetApi  {

  datasetsUrl: string
  datasetUrl: (datasetId: string) => string
  datasetAnnotationsUrl: (datasetId: string) => string
  exportUrl: (datasetId: string, exportId: string) => string
  retryCount: number

  constructor(config: BackendConfig) {
    this.retryCount = config.retryCount
    this.datasetsUrl = `${config.url}/eliteai/datasets`
    this.datasetUrl = (datasetId) => `${this.datasetsUrl}/${datasetId}`
    this.datasetAnnotationsUrl = (datasetId) => `${this.datasetUrl(datasetId)}/annotations`
    this.exportUrl = (datasetId, exportId) => `${this.datasetUrl(datasetId)}/exports/${exportId}`
  }

  async createDataset(datasetId: string, name: string, datasetInfo: DatasetInfo, correlationId: string) {
    return retry(async (bail) => {
      const headers = await currentAuthHeaders(correlationId)

      const response = await axios.post<Dataset>(this.datasetsUrl, { datasetId, name, datasetInfo }, { headers })
    
      return response.data
    }, {
      retries: this.retryCount
    })    
  }

  async fetchDataset(datasetId: string, correlationId: string) {
    return retry(async (bail) => {
      const headers = await currentAuthHeaders(correlationId)

      const response = await axios.get<Dataset>(this.datasetUrl(datasetId), { headers })
    
      const dataset = response.data
      ensureDefaults(dataset)
      return dataset
    }, {
      retries: this.retryCount
    })    
  }

  async updateDataset(datasetId: string, name: string, datasetInfo: DatasetInfo, correlationId: string) {
    return retry(async (bail) => {
      const headers = await currentAuthHeaders(correlationId)

      const response = await axios.put<void>(this.datasetUrl(datasetId), { name, datasetInfo }, { headers })
    
      return response.data
    }, {
      retries: this.retryCount
    })    
  }

  async generateCocoAnnotations(datasetId: string, correlationId: string) {
    return retry(async (bail) => {
      const headers = await currentAuthHeaders(correlationId)

      const response = await axios.post<GenerateCocoExportResponse>(`${this.datasetUrl(datasetId)}/exports`, { }, { headers })
    
      return response.data
    }, {
      retries: this.retryCount
    })    
  }

  async listDatasets(correlationId: string) {
    return retry(async (bail) => {
      const headers = await currentAuthHeaders(correlationId)

      const response = await axios.get<ListDatasetsResponse>(this.datasetsUrl, { headers })

      return response.data
    }, {
      retries: this.retryCount
    })    
  }

  async fetchAccessPermissions(datasetId: string, correlationId: string) {
    return retry(async (bail) => {
      const headers = await currentAuthHeaders(correlationId)

      const response = await axios.get<ListPermissionsResponse>(`${this.datasetUrl(datasetId)}/permissions`, { headers })

      return response.data
    }, {
      retries: this.retryCount
    })    
  }

  async updateAccessPermissions(datasetId: string, userId: string, accessLevel: PermissionLevel, correlationId: string) {
    return retry(async (bail) => {
      const headers = await currentAuthHeaders(correlationId)

      const response = await axios.post(`${this.datasetUrl(datasetId)}/permissions`, {userId, accessLevel},  { headers })

      return response.data
    }, {
      retries: this.retryCount
    })    
  }

  async uploadFile(datasetId: string, formData: FormData, correlationId: string) {
    return retry(async (bail) => {
      const headers = await currentAuthHeaders(correlationId)

      const response = await axios.post(`${this.datasetUrl(datasetId)}/uploads`, formData, { headers })

      return response.data
    }, {
      retries: this.retryCount
    })    
  }

  async validateAnnotationsImport(datasetId: string, annotationsImport: any, correlationId: string) {
    return retry(async (bail) => {
      const headers = await currentAuthHeaders(correlationId)

      const response = await axios.post<AnnotationsImportValidationResult>(`${this.datasetUrl(datasetId)}/annotation-imports/validate`, annotationsImport, { headers })

      return response.data
    }, {
      retries: this.retryCount
    })    
  }

  async importAnnotations(datasetId: string, formData: FormData, correlationId: string) {
    return retry(async (bail) => {
      const headers = await currentAuthHeaders(correlationId)

      const response = await axios.post<ImportedAnnotationsResponse>(`${this.datasetUrl(datasetId)}/annotation-imports`, formData, { headers })

      return response.data
    }, {
      retries: this.retryCount
    })    
  }

  async listImportedAnnotations(datasetId: string, correlationId: string) {
    return retry(async (bail) => {
      const headers = await currentAuthHeaders(correlationId)

      const response = await axios.get<ImportedAnnotationsResponse>(`${this.datasetUrl(datasetId)}/annotation-imports`, { headers })

      return response.data
    }, {
      retries: this.retryCount
    })    
  }

  async deleteImportedAnnotations(datasetId: string, importId: string, correlationId: string) {
    return retry(async (bail) => {
      const headers = await currentAuthHeaders(correlationId)

      const response = await axios.delete(`${this.datasetUrl(datasetId)}/annotation-imports/${importId}`, { headers })

      return response.data
    }, {
      retries: this.retryCount
    })    
  }

  async deleteDatasetCase(datasetId: string, caseId: string, correlationId: string) {
    return retry(async (bail) => {
      const headers = await currentAuthHeaders(correlationId)

      await axios.delete(`${this.datasetUrl(datasetId)}/cases/${caseId}`, { headers })
    }, {
      retries: this.retryCount
    })    
  }

  async updateAnnotationLabel(datasetId: string, label: DatasetAnnotationLabel, correlationId: string) {
    return retry(async (bail) => {
      const headers = await currentAuthHeaders(correlationId)

      const response = await axios.put<void>(`${this.datasetUrl(datasetId)}/annotation-labels`, label, { headers })

      return response.data
    }, {
      retries: this.retryCount
    })    
  }

  async deleteAnnotationLabel(datasetId: string, labelId: string, correlationId: string) {
    return retry(async (bail) => {
      const headers = await currentAuthHeaders(correlationId)

      const response = await axios.delete<void>(`${this.datasetUrl(datasetId)}/annotation-labels/${labelId}`, { headers })

      return response.data
    }, {
      retries: this.retryCount
    })    
  }

  async deleteModelAnnotations(datasetId: string, correlationId: string) {
    return retry(async (bail) => {
      const headers = await currentAuthHeaders(correlationId)

      await axios.delete<void>(`${this.datasetUrl(datasetId)}/model-annotations`, { headers })
    }, {
      retries: this.retryCount
    })    
  }

  async deleteModelForms(datasetId: string, correlationId: string) {
    return retry(async (bail) => {
      const headers = await currentAuthHeaders(correlationId)

      await axios.delete<void>(`${this.datasetUrl(datasetId)}/model-forms`, { headers })
    }, {
      retries: this.retryCount
    })    
  }

  async runAllModels(datasetId: string, correlationId: string) {
    return retry(async (bail) => {
      const headers = await currentAuthHeaders(correlationId)

      await axios.post(`${this.datasetUrl(datasetId)}/model-runs`, {}, { headers })
    }, {
      retries: this.retryCount
    })    
  }

  async reprocessUploads(datasetId: string, correlationId: string) {
    return retry(async (bail) => {
      const headers = await currentAuthHeaders(correlationId)

      await axios.post(`${this.datasetUrl(datasetId)}/reprocess-uploads`, {}, { headers })
    }, {
      retries: this.retryCount
    })    
  }
}
