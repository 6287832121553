
export type User = {
  id: string
  firstName: string
  lastName: string
  email: string
}


export type CallInfo = {
  userId: string
  connectionId: string
}

export const getUserName: (users: User[] | undefined, id: string) => string = (users, id) => {
  const user = users?.find(u => u.id === id)

  if(user !== undefined) {
    return userName(user)
  } else {
    return id
  }
}


export const userName: (user: User) => string = (user) => `${user.firstName} ${user.lastName}`