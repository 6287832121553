
export enum PermissionLevel {
  none = "none",
  read = "read",
  annotate = "annotate",
  write = "write",
  share = "share"
}

export const mergePermissionLevels: (p1: PermissionLevel | undefined, p2: PermissionLevel | undefined) => PermissionLevel = (p1, p2) => {
  if(p1 === PermissionLevel.share || p2 === PermissionLevel.share) {
    return PermissionLevel.share
  } else if(p1 === PermissionLevel.write || p2 === PermissionLevel.write) {
    return PermissionLevel.write
  } else if(p1 === PermissionLevel.annotate || p2 === PermissionLevel.annotate) {
    return PermissionLevel.annotate
  } else if(p1 === PermissionLevel.read || p2 === PermissionLevel.read) {
    return PermissionLevel.read
  } else {
    return PermissionLevel.none
  }
}

export const permissionLevelDisplayText: (level: PermissionLevel) => string = (level) => {
  switch(level) {
    case PermissionLevel.none: 
      return `Revoked`
    case PermissionLevel.read: 
      return `Viewer`
    case PermissionLevel.annotate: 
      return `Annotator`
    case PermissionLevel.write: 
      return `Editor`
    case PermissionLevel.share: 
      return `Owner`
  }
}

export const permissionLevelToolTip: (level: PermissionLevel, documentType: string) => string = (level, documentType) => {
  switch(level) {
    case PermissionLevel.none: 
      return `This user does not have access to this ${documentType}`
    case PermissionLevel.read: 
      return `This user has permission to view the contents of this ${documentType}`
    case PermissionLevel.annotate: 
      return `This user has permission to create annotations on this ${documentType}`
    case PermissionLevel.write: 
      return `This user has permission to modify the contents of this ${documentType}`
    case PermissionLevel.share: 
      return `This user has permission to modify the contents of this ${documentType} and share it with other users`
  }
}


export const isOwnerAccess: (accessLevel: PermissionLevel) => boolean = 
  (accessLevel) => accessLevel === PermissionLevel.share

export const isWriteAccess: (accessLevel: PermissionLevel) => boolean = 
  (accessLevel) => accessLevel === PermissionLevel.share || accessLevel === PermissionLevel.write

export const isAnnotateAccess: (accessLevel: PermissionLevel) => boolean = 
  (accessLevel) => accessLevel === PermissionLevel.share || accessLevel === PermissionLevel.write || accessLevel === PermissionLevel.annotate

  