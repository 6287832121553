import { LeftArrow, RightArrow } from 'app/components/icons/Arrows'
import { Link } from 'react-router-dom'

export type CaseNavigationLinksProps = {
  previousCase?: string
  nextCase?: string
  caseProgressDescription?: string
  annotateMode: boolean
  onClick: () => void
}

export const CaseNavigationLinks = ({
  previousCase,
  nextCase,
  caseProgressDescription,
  annotateMode,
  onClick
}: CaseNavigationLinksProps) => {
  const caseLink = (caseId: string) => annotateMode ? `/annotations/${caseId}` : `/cases/${caseId}/view` 
  return (
    <div className="case-navigation-links" style={{ display: 'flex', marginTop: '10px', marginLeft: '15px' }}>
      <div style={{width: '60px'}}>
      { previousCase !== undefined &&
        <Link to={caseLink(previousCase)} onClick={onClick} style={{ color: 'black' }} >
          <div>
            <LeftArrow size={30} />
          </div>
          <div>
            Previous
          </div>
        </Link>
      }
      </div>
      
      <div style={{width: '90px'}}>
      { caseProgressDescription !== undefined &&
        <div style={{marginTop: '10px'}}>{caseProgressDescription}</div>
      }
      </div>
      <div style={{width: '60px'}}>
      { nextCase !== undefined &&
        <Link to={caseLink(nextCase)} onClick={onClick} style={{ color: 'black'}}>
          <div>
            <RightArrow size={30} />
          </div>
          <div>
            Next
          </div>
        </Link>
      }
      </div>
    </div>
  )
}