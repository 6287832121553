import { AnnotationsPalette } from "app/ColorPalettes"
import _ from "lodash"
import { Annotation } from "annotation/model/Annotation"
import { AnnotationShape, AnnotationShapeType, findCentrePoint, getIthPoint, shapeDescription } from "annotation/model/AnnotationShape"
import { PixelSpacing } from "annotation/model/PixelSpacing"
import { Point } from "annotation/model/Point"
import { UserAnnotationColorMap, UserAnnotationColorMapModelId } from "uploads/model/Dataset"
import { annotationDisplayText, DatasetAnnotationLabel } from "uploads/model/DatasetAnnotationLabel"

export type DrawParams = {
  context: CanvasRenderingContext2D
  canvas: HTMLCanvasElement
  annotations: Annotation[]
  highlightAnnoation: string | undefined
  labelAllAnnotations: boolean
  pixelSpacing: PixelSpacing | undefined
  scaleFunction: (n: number) => number
  editingAnnotationId: string | undefined
  editingAnnotationPointIndex: number | undefined
  userAnnotationColors: UserAnnotationColorMap
  labels: DatasetAnnotationLabel[]
  displayIndex?: number
  displayTotal?: number
  hiddenAnnotations: string[]
}

export const draw = ({
  context, canvas, annotations, highlightAnnoation, labelAllAnnotations, pixelSpacing, editingAnnotationId, 
  editingAnnotationPointIndex, userAnnotationColors, labels, displayIndex, displayTotal, hiddenAnnotations,
  scaleFunction
}: DrawParams) => {  
  context.clearRect(0, 0, canvas.width, canvas.height)

  context.lineWidth = 1

  const distinctAnnotations: Annotation[] = Object.entries(_.groupBy(annotations, a => a.annotationId)).map(([annotationId, duplicateAnnotations]) => (
    _.maxBy(duplicateAnnotations, a => a.annotationVersion)
  )) as Annotation[]

  const count = distinctAnnotations.length 
  const halfCount = Math.ceil(count / 2)
  const labelHeight = 60

  distinctAnnotations.filter(a => !hiddenAnnotations.includes(a.annotationId)).forEach((annotation, index) => {
    const userId = annotation.createdBy || UserAnnotationColorMapModelId
    const labelAllAnnotationsColor = AnnotationsPalette[index % AnnotationsPalette.length]

    const userColor = labelAllAnnotations ? labelAllAnnotationsColor : userAnnotationColors[userId]
    
    let shapeColour: string = userColor !== undefined ? userColor : "red"  
    
    context.strokeStyle = shapeColour

    const hoverHighlight = annotation.annotationId === highlightAnnoation
    const editHighlight = annotation.annotationId === editingAnnotationId

    let textColour = 'white'

    if(editHighlight) {
      shapeColour = '#4DFF00' // Bright green
      textColour = 'black'
    } else if(labelAllAnnotations) {
      shapeColour = labelAllAnnotationsColor
      textColour = 'black'
    } else if(hoverHighlight) {
      shapeColour = 'yellow'
      textColour = 'black'
    }
    
    drawShape(context, annotation.shape, shapeColour, scaleFunction)

    if(editHighlight && editingAnnotationPointIndex !== undefined) {
      const point = getIthPoint(annotation.shape, editingAnnotationPointIndex)
      if(point !== undefined){
        context.strokeStyle = 'black'

        context.beginPath()
        context.arc(scaleFunction(point.x), scaleFunction(point.y), 2, 0, 2 * Math.PI)
        context.stroke()
      }
    } else if(labelAllAnnotations || hoverHighlight) {
      const yOffset = labelAllAnnotations ? (index - halfCount) * labelHeight : 0 
      drawLabel(context, annotation, shapeColour, textColour, scaleFunction, labels, yOffset)
    }
  })

  drawInfo(context, displayIndex, displayTotal, 'red', pixelSpacing)
}

const drawOval = (con: CanvasRenderingContext2D, centerX: number, centerY: number, width: number, height: number) => {
  con.beginPath()
  con.moveTo(centerX, centerY - height / 2)

  con.bezierCurveTo(
      centerX + width / 2, centerY - height / 2,
      centerX + width / 2, centerY + height / 2,
      centerX, centerY + height / 2
  )
  con.bezierCurveTo(
      centerX - width / 2, centerY + height / 2,
      centerX - width / 2, centerY - height / 2,
      centerX, centerY - height / 2
  )

  con.stroke()
  con.closePath()
}

const drawShape = (context: CanvasRenderingContext2D, shape: AnnotationShape, strokeStyle: string, scaleFunction: (n: number) => number) => {
  context.strokeStyle = strokeStyle

  const drawLine: (from: Point, to: Point) => void = (from, to) => {
    context.beginPath()
    context.moveTo(scaleFunction(from.x), scaleFunction(from.y))
    context.lineTo(scaleFunction(to.x), scaleFunction(to.y))
    context.stroke()
  } 

  const drawLines: (points: Point[]) => void = (points) => {
    points.forEach((point, i) => {
      if(i !== 0) {
        drawLine(points[i - 1], point)
      }
    })
  }

  const drawPoint: (point: Point) => void = (point) => {
    context.beginPath()
    context.arc(scaleFunction(point.x), scaleFunction(point.y), 1, 0, 2 * Math.PI)
    context.stroke()
  }

  switch (shape.type) {
    case AnnotationShapeType.Box:
      context.beginPath()
      context.rect(
        scaleFunction(shape.topLeft.x),
        scaleFunction(shape.topLeft.y),
        scaleFunction(shape.bottomRight.x - shape.topLeft.x),
        scaleFunction(shape.bottomRight.y - shape.topLeft.y))
      context.stroke()
      break
    case AnnotationShapeType.Line:
      drawLine(shape.topLeft, shape.bottomRight)
      break
    case AnnotationShapeType.FreeHand:
      drawLines(shape.points)          
      break      
    case AnnotationShapeType.Circle:
      context.beginPath()
      context.arc(scaleFunction(shape.centre.x), scaleFunction(shape.centre.y), scaleFunction(shape.radius), 0, 2 * Math.PI)
      context.stroke()
      break    
    case AnnotationShapeType.Oval:
      const centreX = scaleFunction(shape.bottomRight.x + (shape.topLeft.x - shape.bottomRight.x) / 2)
      const centreY = scaleFunction(shape.bottomRight.y + (shape.topLeft.y - shape.bottomRight.y) / 2)
      drawOval(context, centreX, centreY, scaleFunction(shape.bottomRight.x - shape.topLeft.x), scaleFunction(shape.bottomRight.y - shape.topLeft.y))
      break
    case AnnotationShapeType.Point:
      drawPoint(shape.point)
      break
    case AnnotationShapeType.OpenPolygon:
      if(shape.points.length === 1) {
        drawPoint(shape.points[0])
      }
      drawLines(shape.points)          
      break
    case AnnotationShapeType.ClosedPolygon:
      if(shape.points.length === 1) {
        drawPoint(shape.points[0])
      } else if(shape.points.length > 2){
        drawLines([...shape.points, shape.points[0]])          
      } else {
        drawLines(shape.points)          
      }      
      break
    case AnnotationShapeType.WholeImage:
      break
    default:
      break
  }    
}

const drawLabel = (
  context: CanvasRenderingContext2D, 
  annotation: Annotation, 
  labelFillStyle: string, 
  labeTextColour: string, 
  scaleFunction: (n: number) => number,
  labels: DatasetAnnotationLabel[],
  yOffset: number
) => {
  
  const centrePoint = findCentrePoint(annotation.shape)
  const additionalTexts = shapeDescription(annotation)
  
  context.beginPath()
  context.fillStyle = labelFillStyle  
  context.arc(scaleFunction(centrePoint.x), scaleFunction(centrePoint.y), 2, 0, 2 * Math.PI)
  context.closePath()
  context.fill()
  
  context.textBaseline = 'top'
  const label = annotationDisplayText(annotation, labels)
  const text = ` ${label} `
  const textWidth = context.measureText(text).width
  const additionalTextWidth = Math.max(...additionalTexts.map(t => context.measureText(t).width), 0)
  const width = Math.max(textWidth, additionalTextWidth) + 2

  const widest = Math.max(textWidth, additionalTextWidth)
  const textToTheLeft = widest > (context.canvas.width - centrePoint.x)

  const labelX = textToTheLeft ? centrePoint.x + 5 - widest : centrePoint.x + 5

  const lineHeight = 17

  context.fillStyle = labelFillStyle
  context.font = "15px Georgia"
  context.fillRect(scaleFunction(labelX), yOffset + scaleFunction(centrePoint.y) - 7, width, lineHeight)
  context.fillStyle = labeTextColour
  context.fillText(text, scaleFunction(labelX), yOffset + scaleFunction(centrePoint.y) - 5)

  if(additionalTexts.length > 0) {
    context.fillStyle = labelFillStyle
    context.font = "15px Georgia"
    context.fillRect(scaleFunction(labelX), yOffset + scaleFunction(centrePoint.y) + 9, width, (lineHeight * additionalTexts.length) + 2)
  }

  additionalTexts.forEach((additionalText, index) => {
    const lineNumber = index + 1

    context.fillStyle = labeTextColour
    const y = yOffset + scaleFunction(centrePoint.y) + (17 * lineNumber) - 5

    context.fillText(additionalText, scaleFunction(labelX), y)
  })
}

const drawInfo = (
  context: CanvasRenderingContext2D, 
  displayIndex: number | undefined, 
  displayTotal: number | undefined,
  labeTextColour: string,
  pixelSpacing: PixelSpacing | undefined
  ) => {
  if(displayIndex !== undefined && displayTotal !== undefined) {
    context.font = "bold 12px Courier New"
    context.fillStyle = labeTextColour
    
    const indexText = `Slice ${displayIndex + 1} of ${displayTotal}`
    context.fillText(indexText, 10, 20)


    if(pixelSpacing !== undefined && pixelSpacing.width !== undefined && pixelSpacing.height !== undefined) {
      const width = Math.round(pixelSpacing.x * pixelSpacing.width) / 10
      const height = Math.round(pixelSpacing.y * pixelSpacing.height) / 10
      const dimensionsText = `${width}cm x ${height}cm `
      context.fillText(dimensionsText, 10, 32)
    }
  }  
}

export const shapeContainsPoint: (shape: AnnotationShape, point: Point) => boolean = (shape, point) => {
  let contains = false
  switch (shape.type) {
    case AnnotationShapeType.Box:
      if(point.x < Math.max(shape.bottomRight.x, shape.topLeft.x) && 
        point.x > Math.min(shape.bottomRight.x, shape.topLeft.x) && 
        point.y < Math.max(shape.bottomRight.y, shape.topLeft.y) && 
        point.y > Math.min(shape.bottomRight.y, shape.topLeft.y)) {
        contains = true
      }
      break
    case AnnotationShapeType.Line:
      if(point.x < Math.max(shape.bottomRight.x, shape.topLeft.x) && 
        point.x > Math.min(shape.bottomRight.x, shape.topLeft.x) && 
        point.y < Math.max(shape.bottomRight.y, shape.topLeft.y) && 
        point.y > Math.min(shape.bottomRight.y, shape.topLeft.y)) {
        contains = true
      }

      break
    case AnnotationShapeType.FreeHand:      
      if(pointsContainPoint(shape.points, point)){
        contains = true
      }      
      break      
    case AnnotationShapeType.Circle:
      const dXCircle = point.x - shape.centre.x
      const dYCircle = point.y - shape.centre.y
      if(Math.sqrt((dXCircle * dXCircle) + (dYCircle * dYCircle)) < shape.radius){
        contains = true
      }
      break
    case AnnotationShapeType.Oval:
      if(point.x < Math.max(shape.bottomRight.x, shape.topLeft.x) && 
        point.x > Math.min(shape.bottomRight.x, shape.topLeft.x) && 
        point.y < Math.max(shape.bottomRight.y, shape.topLeft.y) && 
        point.y > Math.min(shape.bottomRight.y, shape.topLeft.y)) {
        contains = true
      }
      break
    case AnnotationShapeType.Point:
      const dXPoint = point.x - shape.point.x
      const dYPoint = point.y - shape.point.y
      if(Math.sqrt((dXPoint * dXPoint) + (dYPoint * dYPoint)) < 1){
        contains = true
      }
      break
    case AnnotationShapeType.OpenPolygon:
      if(pointsContainPoint(shape.points, point)){
        contains = true
      } 
      break
    case AnnotationShapeType.ClosedPolygon:
      if(pointsContainPoint(shape.points, point)){
        contains = true
      } 
      break
    case AnnotationShapeType.WholeImage:
      break
    default:
      break
  }

  return contains
} 

const pointsContainPoint: (points: Point[], point: Point) => boolean = (points, point) => {
  if(
    point.x <  Math.max(...points.map(p => p.x)) && 
    point.x > Math.min(...points.map(p => p.x)) && 
    point.y < Math.max(...points.map(p => p.y)) && 
    point.y > Math.min(...points.map(p => p.y))
  ) {
    return true
  } else {
    return false
  }
}
