export type Point = {
  x: number
  y: number
}

export const pointsDistance: (p1: Point, p2: Point) => number = (p1, p2) =>{
  const dX = p1.x - p2.x
  const dY = p1.y - p2.y

  return Math.sqrt(
    (dX * dX) + (dY * dY)
  )
}