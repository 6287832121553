import { Theme } from "app/model/Theme"

export type ButtonProps = {
  text: string  
  theme: Theme
  dataCy: string
  className?: string
  onClick?: () => void  
}

export const Button = ({
  text, theme, dataCy, className,
  onClick
}: ButtonProps) => 
  <div 
    style={{
      backgroundColor: theme.lightButton, 
      color: theme.lightButtonText,
      width: 'fit-content',
      height: 'fit-content',
      paddingTop: '3px',
      paddingBottom: '3px',
      paddingLeft: '10px',
      paddingRight: '10px',
      cursor: 'pointer',
      borderRadius: '5px'
    }} 
    className={className}
    data-cy={dataCy}
    onClick={onClick}
  >
    {text}
  </div>