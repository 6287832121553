import { Euler, Group, Vector3, Matrix4 } from 'three'

export type VrSceneState = {
  camera?: Location
  model?: Location
  extra?: Location[]
}

export type Location = {
  position?: Vector3
  rotation?: Vector3
  scale?: Vector3
}

export const serialiseLocation = (object) => {
  const position = absoluteLocation(object)

  const rotMat = new Matrix4()
  rotMat.extractRotation(object.matrixWorld)
  const rotation = new Euler()
  rotation.setFromRotationMatrix(rotMat)

  return {
    position: {
      x: position.x,
      y: position.y,
      z: position.z
    },
    rotation: {
      x: rotation.x,
      y: rotation.y,
      z: rotation.z
    },
    scale: {
      x: object.scale.x,
      y: object.scale.y,
      z: object.scale.z
    }
  }
}

export const absoluteLocation = (object) => {
  object.updateMatrixWorld()

  const position = new Vector3()    
  object.getWorldPosition(position)
  return position
}

export const updateLocation = (target, desired) => {
  target.position.x = desired.position.x
  target.position.y = desired.position.y
  target.position.z = desired.position.z

  target.rotation.x = desired.rotation.x
  target.rotation.y = desired.rotation.y
  target.rotation.z = desired.rotation.z    

  if(desired.scale) {
    target.scale.x = desired.scale.x
    target.scale.y = desired.scale.y
    target.scale.z = desired.scale.z
  }
}
