import { configureStore } from '@reduxjs/toolkit'
import uploadsReducer from 'uploads/api/UploadsSlice'
import userReducer from 'auth/UserSlice'
import configReducer from 'config/ConfigSlice'
import reportsReducer from 'reports/api/ReportsSlice'
import scansReducer from 'cases/api/ScansSlice'
import datasetsReducer from 'datasets/api/DatasetSlice'

export const store = configureStore({
  reducer: {
    uploads: uploadsReducer,
    user: userReducer,
    config: configReducer,
    reports: reportsReducer,
    scans: scansReducer,
    datasets: datasetsReducer
  },
  devTools: true
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

