import { Theme } from "app/model/Theme"
import { RootState } from "app/store"
import { nanoid } from "nanoid"
import { connect } from 'react-redux'
import { UserFeaturesComponent } from "./components/UserFeaturesComponent"
import UserInfoComponent from "./components/UserInfoComponent"
import { toggleShowUiDebugToggles, updateUserFeature, updateUserFeatures, UserFeatures } from "./UserSlice"

export type UserProfilePageProps = {
  currentUserId: string
  features: UserFeatures
  theme: Theme
  updateUserFeature: (userId: string, featureId: string, status: string) => Promise<void>
  updateUserFeatures: (userId: string, features: any) => Promise<void>
  toggleShowUiDebug: () => Promise<void>
}

export const UserProfilePage = ({
  features, currentUserId, theme,
  updateUserFeature, toggleShowUiDebug, updateUserFeatures
}: UserProfilePageProps) => {
  
  return (
    <div style={{margin: '20px'}}>
      <UserInfoComponent />
      { features.editFeatures &&
        <UserFeaturesComponent 
          features={features} 
          currentUserId={currentUserId} 
          updateUserFeature={updateUserFeature}
          updateUserFeatures={updateUserFeatures}
          toggleShowUiDebug={toggleShowUiDebug}
          theme={theme}
        />
      }
    </div>
  )
}


const mapStateToProps = (state: RootState) => {    
  return { 
    features: state.user.features,
    theme: state.user.features.theme
  }
}

const mapDispatchToProps = {
  updateUserFeature: (userId: string, featureId: string, status: string) => updateUserFeature(userId, featureId, status, nanoid()),
  updateUserFeatures: (userId: string, features: any) => updateUserFeatures(userId, features, nanoid()),
  toggleShowUiDebug: toggleShowUiDebugToggles
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserProfilePage)
