import Select from 'react-select'

import { SelectOption } from "utils/SelectOption"
import { LoadingSpinner } from 'app/components/LoadingSpinner'
import { SeriesDescriptionsMap } from 'uploads/util/SeriesDisplayName'

export type SeriesSelectorProps = {
  series: string[]
  selectedSeriesId?: string
  seriesDescriptions: SeriesDescriptionsMap
  setSelectedSeriesId: (seriesId: string | undefined) => void
}

export const SeriesSelector = ({series, selectedSeriesId, seriesDescriptions, setSelectedSeriesId}: SeriesSelectorProps) => {
  const options: SelectOption<string>[] = (series || []).map((series) => { return {value: series, label: seriesDescriptions[series] }})
  
  const current = { value: selectedSeriesId, label: selectedSeriesId === undefined ? '' : seriesDescriptions[selectedSeriesId] || '' }

  if(series === undefined){
    return <LoadingSpinner />
  } else {
    return (
      <div 
        className="series-selector"
        style={{ display: 'flex', marginRight: '10px' }}
        >
        <div style={{marginTop: '5px', marginLeft:'10px', fontSize: '13pt', width: '70px'}}>Series:</div>

        <div style={{ width: '250px' }}>
          <Select 
            classNamePrefix="react-select-series-selector"
            options={options} 
            placeholder="Annotation mode" 
            value={current} 
            onChange={(e) => { if (e !== null) setSelectedSeriesId(e.value)}}
          /> 
        </div>
        
      </div>
    )
  }
}
