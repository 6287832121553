import { AnnotationMode } from './AnnotationMode'
import { DatasetAnnotationLabel } from './DatasetAnnotationLabel'

export type Dataset = {
  datasetId: string
  name: string
  createdBy: string
  createdDate: string
  datasetInfo: DatasetInfo
  cases: string[]
  uploads: string[]
  seriesInfo?: DatasetSeriesInfo[]
  annotationLabels: DatasetAnnotationLabel[]
  cocoExports: CocoExport[]
  aggregateInfo: DatasetAggregateInfo
}


export type DatasetInfo = {
  annotationMode: AnnotationMode
  seriesDisplayNames: SeriesDisplayNameMap
  seriesDescriptionsForAnnotation: string[]
  annotationApprovers: string[]
  annotators: string[]
  userAnnotationColors: UserAnnotationColorMap
  orderedSeriesIds: string[]
  modelServiceConfig: string,
  amsData?: DatasetAmsData
  isAnonymised: boolean
}

export type DatasetAggregateInfo = {
  caseInfos: DatasetAggregateCaseInfo[]
}

export type DatasetAmsData = {
  header: any
  rows: any[]
}

export type DatasetAggregateCaseInfo = {
  caseId: string,
  annotationsCount: number,
  formsCount: number,
  classifications: string[]
}

export type CocoExport = {
  exportId: string,
  datasetId: string,
  createdDate: string
}

export type SeriesDisplayNameMap = {
  [seriesDescription: string]: string
}

export type UserAnnotationColorMap = {
  [userId: string]: string
}
export const UserAnnotationColorMapModelId = 'model'


export const DefaultDatasetInfo: DatasetInfo = {
  annotationMode: AnnotationMode.Hamstring,
  seriesDisplayNames: {},
  seriesDescriptionsForAnnotation: [],
  annotationApprovers: [],
  annotators: [],
  userAnnotationColors: {},
  orderedSeriesIds: [],
  modelServiceConfig: '',
  isAnonymised: true
}

export type DatasetSeriesInfo = {
  seriesInstanceUID: string
  seriesDescription: string
  filesCount: number
}

export const initialDatasetModel: (datasetId: string, name: string, userId?: string) => Dataset = (datasetId, name, userId) => {
  return {
    datasetId, 
    name, 
    createdBy: userId || '', 
    createdDate: new Date().toString(), 
    cases: [], 
    uploads: [], 
    datasetInfo: DefaultDatasetInfo,
    seriesInfo: [],
    annotationLabels: [],
    userAnnotationColors: {},
    cocoExports: [],
    aggregateInfo: {
      caseInfos: []
    }
  }
}

export type ListDatasetsResponse = {
  datasets: DatasetListingEntry[]
}

export type DatasetListingEntry = {
  datasetId: string
  name: string
  createdBy: string
  createdDate: string
  cases: string[]
  datasetInfo?: DatasetInfo
  aggregateInfo?: DatasetAggregateInfo
}
