import { useEffect } from 'react'
import { useParams, Link } from 'react-router-dom'
import { connect } from 'react-redux'

import { RootState } from 'app/store'
import { ScanListingEntry } from 'uploads/api/Model'
import { fetchDatasetAccessPermissions, updateDataset, fetchDataset } from 'datasets/api/DatasetSlice'
import { fetchAnnotations, listScans } from 'cases/api/ScansSlice'
import { nanoid } from 'nanoid'
import { User } from 'auth/model/User'
import { Dataset, DatasetAmsData, DatasetInfo } from 'uploads/model/Dataset'

import { AccessEntry } from 'auth/model/AccessEntry'
import { fetchDatasetUploads } from 'uploads/api/UploadsSlice'
import PatientScansTable from 'uploads/component/PatientScansTable'
import { RTPCalculator } from 'app/components/RTPCalculator'
import { DatasetAmsDataTable } from './components/DatasetAmsDataTable'


type DatasetPatientPageProps = {
  datasetId: string
  patientId: string
  dataset?: Dataset
  cases: ScanListingEntry[]
  users?: User[]
  currentUserId: string
  accessEntries?: AccessEntry[]
  showDebugUi: boolean
  showExperimentalUi: boolean
  loadAccessEntries: (datasetId: string) => void
  listCases: (datasetId: string) => void
  fetchDataset: (datasetId: string) => void
  updateDataset: (datasetId: string, name: string, datasetInfo: DatasetInfo) => Promise<void>
}

const DatasetPatientPage = ({ 
  datasetId, patientId, dataset, cases, users, currentUserId, accessEntries, showDebugUi, showExperimentalUi,
  loadAccessEntries, listCases, updateDataset, fetchDataset
}: DatasetPatientPageProps) => {

  useEffect(() => {if(accessEntries === undefined) loadAccessEntries(datasetId)}, [loadAccessEntries, datasetId])
  useEffect(() => {if(dataset !== undefined && dataset.cases.length > 0 && cases.length < 1) listCases(datasetId) }, 
    [listCases, datasetId, dataset?.cases.length, cases.length])
  useEffect(() => {if(dataset === undefined || (dataset !== undefined && dataset.seriesInfo === undefined)) fetchDataset(datasetId)}, 
    [fetchDataset, datasetId, dataset === undefined, dataset?.seriesInfo !== undefined])

  const amsData = patientAmsData(dataset?.datasetInfo.amsData, patientId)

  if ( dataset === undefined) {
    return <div>Loading...</div>
  } else {
    return (
      <div className="dataset-patient-page" style={{maxWidth: '800px'}}>
        <div>
          <h2 style={{marginLeft:'10px', paddingTop: '15px'}}>Patient {patientId}</h2>
          <table style={{width: '450px'}} className="table table-bordered table-striped">            
            <tbody>   
              { dataset !== undefined &&
              <tr>
                <td>Dataset:</td>
                <td><Link style={{color: 'black'}} to={`/datasets/${dataset.datasetId}`}>{dataset.name}</Link></td>
              </tr>
              }              
            </tbody>
          </table> 
        </div>
         
        <div className="cases-conatiner" >
          <h2 style={{marginLeft:'10px', paddingTop: '15px'}}>Scans</h2>
                
          <PatientScansTable 
            dataset={dataset} 
            scans={cases} 
            linkTo={(caseId) => `/cases/${caseId}/view`} 
          />       
        </div>
        
        <div style={{marginTop: '20px'}}>               
          {amsData !== undefined &&          
          <div className="ams-data-viewer">
            <h3>AMS Data</h3>
      
            <DatasetAmsDataTable header={amsData.header} rows={amsData.rows} />  
          </div>
          }
        </div>    

        {dataset.datasetInfo.amsData !== undefined &&        
        <RTPCalculator
          amsData={dataset.datasetInfo.amsData}
          cases={[]}
          caseInfos={[]}
        />
        }
      </div>
    )  
  }
}

const patientAmsData: (amsData: DatasetAmsData | undefined, patientId: string) => DatasetAmsData | undefined = (amsData, patientId) => {
  if(amsData === undefined) {
    return undefined
  }  
  
  return {
    ...amsData,
    rows: amsData?.rows.filter(row => row['PlayerID'] === patientId)
  }
}


const mapStateToProps = (state: RootState, { datasetId, patientId }) => {
  const dataset = state.datasets.all?.find(d => d.datasetId === datasetId)
  const cases = state.scans.list?.filter(scan => scan.datasetId === datasetId && scan.scanInfo?.patientId === patientId) || []

  const accessEntries = state.datasets.accessEntries[datasetId]

  const users = state.user.users
  
  return { 
    dataset,
    cases,
    users,
    accessEntries,
    showDebugUi: state.user.features.showDebugUi,
    showExperimentalUi: state.user.features.showExperimentalUi
  }
}

const mapDispatchToProps = { 
  listCases: (datasetId: string) => listScans(nanoid(), datasetId),
  loadAccessEntries: (datasetId: string) => fetchDatasetAccessPermissions(datasetId, nanoid()),
  updateDataset: (datasetId: string, name: string, datasetInfo: DatasetInfo) => updateDataset(datasetId, name, datasetInfo, nanoid()),
  fetchAnnotations: (scanId: string) => fetchAnnotations(scanId, nanoid()),
  fetchDataset: (datasetId: string) => fetchDataset(datasetId, nanoid()),
  listUploads: (datasetId: string) => fetchDatasetUploads(datasetId),
}

const ConnectedDatasetPatientPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(DatasetPatientPage)

type DatasetPatientPageWrapperProps = {
  currentUserId: string
}
export default function DatasetPatientPageWrapper({currentUserId}: DatasetPatientPageWrapperProps) {
  const { datasetId, patientId } = useParams()
  return (
    <ConnectedDatasetPatientPage datasetId={datasetId} patientId={patientId} currentUserId={currentUserId} />
  )
}
