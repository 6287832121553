import _ from 'lodash'
// import "@carbon/styles/css/styles.css"
// import "@carbon/styles/css/"?
import "@carbon/charts/styles.css"
import { BoxplotChart, HistogramChart } from "@carbon/charts-react"
import {interfaces} from '@carbon/charts'

// IBM Plex should either be imported in your project by using Carbon
// or consumed manually through an import
import "./ibm-plex-font.css"
import { GradedInjury } from 'app/model/GradedInjury'

const boxOptions: (groupBy: string) => any = (groupBy) => ({
  "title": "Return to Play (days)",
  "axes": {
    "bottom": {
      "title": "Days injured",
      "mapsTo": "value"
    },
    "left": {
      "title": groupBy,
      "scaleType": interfaces.ScaleTypes.LABELS,
      "mapsTo": "group"
    }
  },
  "height": "400px"
})

const histoOptions = {
  "axes": {
    "bottom": {
      "title": "Days injured",
      "mapsTo": "value",
      "bins": 10,
      "limitDomainToBins": true
    },
    "left": {
      "title": "No. of injuries",
      "scaleType": interfaces.ScaleTypes.LINEAR,
      "stacked": true,
      "binned": true
    }
  },
  "height": "400px"
}

export type ChartProps = {
  gradedInjuries: GradedInjury[]
  useEliteAiGrading: boolean
  groupByInjuryGrade: boolean
  groupByColumn: string | undefined
  daysInjuredColumn: string | undefined
  gradingColumn: string | undefined
}

type DataItem = {
  value: number
  group: string
  key: string
}

export const Chart = ({
  gradedInjuries, useEliteAiGrading, groupByInjuryGrade, groupByColumn, daysInjuredColumn, gradingColumn
}: ChartProps) => {
  const numbers: DataItem[] = (!useEliteAiGrading || !groupByInjuryGrade) ? (gradedInjuries.reduce((acc: DataItem[], gradedInjury: GradedInjury) => {
    const groupColumn = groupByInjuryGrade ? gradingColumn : groupByColumn

    const group = groupByInjuryGrade ? (
      useEliteAiGrading ? gradedInjury.grade : (gradingColumn === undefined ? undefined : gradedInjury.row[gradingColumn])
    ) :(
      groupColumn === undefined ? undefined : gradedInjury.row[groupColumn]    
    )    
    
    return (daysInjuredColumn === undefined || group === undefined) ? acc : [...acc, {
      value: Number.parseInt(gradedInjury.row[daysInjuredColumn], 10),
      group,
      key: "k"
    }]
  }, []).filter(d => Number.isInteger(d.value) && d.group !== 'Not Applicable' && d.group !== 'None')
  ) : (
    gradedInjuries.map(gradedInjury => {
      return {
        value: gradedInjury.daysInjured,
        group: gradedInjury.grade,
        key: gradedInjury.playerId
      }
    })
  )

  const data = _.sortBy(numbers, n => {
    const parsed = Number.parseFloat(n.group)

    if(Number.isFinite(parsed)) {
      return parsed
    } else {
      return n.group
    }
  })

  return (
    <div className="rtp-charts" style={{display: 'flex', height: '420px'}}>
      {numbers.length > 0 &&
        <>
          <div style={{margin: '10px', width: '500px'}}>
            <BoxplotChart
              data={data}
              options={boxOptions(groupByInjuryGrade ? 'Injury grade' : (groupByColumn || ''))} 
            />
          </div>
          <div style={{margin: '10px', width: '500px'}}>
            <HistogramChart
              data={data}
              options={histoOptions} />
          </div>
        </>
      }        
    </div>
  )
  
}

  