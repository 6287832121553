import { Annotation } from 'annotation/model/Annotation'
import { Field, FieldHookConfig, useField } from 'formik'
import { dataCySafe } from 'utils/CypressUtils'

export type RadioGroupValue = {
  id: string
  display: string
}

export type RadioGroupSectionProps = {
  description: string
  name: string
  values: RadioGroupValue[]
  disabled: boolean
  includeOther: boolean
  annotation?: Annotation
  radioOptionsWidth: number 
  displayAnnotation?: (annotation: Annotation) => void
}

export const RadioGroupSection = ({
  description, name, values, disabled, includeOther, annotation, radioOptionsWidth,
  displayAnnotation
}: RadioGroupSectionProps) => 
  <FormSection >            
    <SectionDescription >{description}</SectionDescription>
    <div style={{display: 'flex'}}>
      <div role="group" style={{width: `${radioOptionsWidth}px`}}>
        <>
          {values.map(value => 
            <LabelledRadioButton key={value.id} name={name} value={value} disabled={disabled} />)
          }
          {includeOther &&
            <LabelledRadioButtonOther name={name} disabled={disabled} />
          }
        </>
      </div>
      { displayAnnotation !== undefined && annotation !== undefined &&
      <div>
        <span style={{ cursor: 'pointer', color: '#0d6efd', textDecoration: 'underline' }} onClick={() => displayAnnotation(annotation)}>
          View 
        </span>
      </div>
      }
    </div>
  </FormSection>

export const LabeledCheckBox = ({name, text, disabled}) => 
  <label style={{display: 'block'}}>
    <Field
      style={{marginRight: '5px'}}
      name={name}
      type="checkbox"
      disabled={disabled}
      data-cy={dataCySafe(`${name}-check-box`)}
    />
    {text}
  </label> 

export type LabelledRadioButtonProps = {
  name: string
  value: RadioGroupValue
  disabled: boolean
}
export const LabelledRadioButton = ({name, value, disabled}: LabelledRadioButtonProps) =>
  <label style={{ display: 'block' }}>
    <Field 
      type="radio" 
      name={name} 
      value={value.id} 
      style={{marginRight: '5px'}} 
      disabled={disabled} 
      data-cy={dataCySafe(`${name}-${value.id}-radio-button`)} 
    />
    {value.display}
  </label>

export const LabelledRadioButtonOther = ({name, disabled}) =>
  <label style={{ display: 'block' }}>
    <Field 
      type="radio" 
      name={name} 
      value="other" 
      style={{marginRight: '5px'}} 
      disabled={disabled} 
      data-cy={dataCySafe(`${name}-other-radio-button`)} 
    />
    Other: <Field name={dataCySafe(`${name}-other-text`)} type="text" disabled={disabled} data-cy={dataCySafe(`${name}-other-text`)} />
  </label>

export const SectionDescription = ({children}) => 
  <div style={{ fontSize: '14px', padding: '5px' }}>{children}</div>

export const FormSection = ({children}) => 
  <div 
    style={{
      border: '1px solid lightgray',
      borderRadius: '10px',
      paddingTop: '2px',
      paddingBottom: '5px',
      paddingLeft: '10px',
      paddingRight: '10px',
      margin: '6px',
    }}
  >
    { children }
  </div>    

export type SaveOrEditButtonProps = {
  inputDisabled: boolean
  hasAnnotateAccess: boolean
  setIsEditing: (isEditing: boolean) => void
}

export type FormTextAreaProps = FieldHookConfig<string> & {
  disabled: boolean
  columns: number
  rows: number
  dataCy: string
}

export const FormTextArea = (props: FormTextAreaProps) => {
  const {disabled, columns, rows, dataCy} = props
  
  const [field] = useField(props)

  return (
    <textarea disabled={disabled} {...field} placeholder={props.placeholder} rows={rows} cols={columns} data-cy={dataCy} />    
  )
}

export const SaveOrEditButton = ({inputDisabled, hasAnnotateAccess, setIsEditing}: SaveOrEditButtonProps) => {
  if(!hasAnnotateAccess) {
    return <></>
  }

  if(inputDisabled) {
    return (
      <button className="btn btn-primary" onClick={(e) => {e.preventDefault(); setIsEditing(true)}} style={{marginLeft: '10px'}} data-cy="edit-classification-form">
        Edit
      </button>
    )
  } else {
    return (
      <button 
        className="btn btn-primary" 
        type="submit" 
        style={{marginLeft: '10px'}} 
        data-cy="save-classification-form"        
      >
        Save
      </button>
    )
  }
}

export type DeleteButtonProps = {
  deleteForm: () => void
}
export const DeleteButton = ({deleteForm}: DeleteButtonProps) => {
  return (
    <button className="btn btn-danger" onClick={(e) => {e.preventDefault(); deleteForm()}} style={{marginLeft: '10px'}} data-cy="delete-classification-form">
      Delete
    </button>
  )
}