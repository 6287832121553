import { Theme } from 'app/model/Theme'
import { RootState } from 'app/store'
import { getUserAvatarUrl, UsersApi } from 'auth/api/UsersApi'
import { uploadAvatar } from 'auth/UserSlice'
import { KeycloakProfile } from 'keycloak-js'
import { nanoid } from 'nanoid'
import { useState } from 'react'
import { connect } from 'react-redux'

export type UserInfoProps = {
  currentUserId?: string
  profile?: KeycloakProfile
  theme: Theme
  uploadAvatar: (userId: string, formData: FormData) => Promise<void>
}

export const UserInfoComponent = ({
  currentUserId, profile, theme,
  uploadAvatar
}: UserInfoProps) => {

  return (
    <div style={{display: 'flex'}} >
      <div style={{marginBottom: '40px'}}>
        <h2>User Information</h2>          
        <div className="user-info" style={{marginLeft: '40px', marginTop: '20px'}}>
          <p>Name: {profile?.firstName} {profile?.lastName}</p>
          <p>Email: {profile?.email}</p>
        </div>
      </div>
      {currentUserId !== undefined &&
        <AvatarComponent 
          currentUserId={currentUserId}
          uploadAvatar={uploadAvatar} 
          theme={theme}
        />
      }
      
    </div>
  )
}

type AvatarComponentProps = {
  currentUserId: string
  theme: Theme
  uploadAvatar: (userId: string, formData: FormData) => Promise<void>
}

export const AvatarComponent = ({
  currentUserId, theme,
  uploadAvatar
}: AvatarComponentProps) => {

  const userAvatarUrl = getUserAvatarUrl(window.RuntimeConfig.backend)

  const [avatarUploaded, setAvatarUploaded] = useState<string | undefined>(undefined)
  const imageUrl = avatarUploaded === undefined ? userAvatarUrl(currentUserId) : `${userAvatarUrl(currentUserId)}?t=${avatarUploaded}`

	const changeHandler = (event) => {        
    const files: File[] = Array.from(event.target.files)
    
    if(files.length > 0 && currentUserId !== undefined){
      const file = files[0]
      
      const formData = new FormData()
      formData.append(file.name, file)
      uploadAvatar(currentUserId, formData).then(() => setAvatarUploaded(Date.now().toString()))
    }    
	}

  return (
    <div style={{border: '1px solid gray', borderRadius: '10px', width: '220px', marginLeft: '250px', paddingLeft: '10px', paddingTop: '10px'}}>
      <div style={{height: '200px'}}>
        <img src={imageUrl} style={{borderRadius: '50%'}} />           
      </div>
      <div style={{padding: '10px', paddingLeft: '12px'}} >
        <input type="file" id="avatarElem" name="file" onChange={changeHandler} className="visually-hidden" />
        <label htmlFor="avatarElem" style={{backgroundColor: theme.lightButton, color: theme.lightButtonText}} className={`btn`}>Upload a new image</label>
      </div>              
    </div>
  )
}

const mapStateToProps = (state: RootState) => {    
  return { 
    currentUserId: state.user.currentUserId,
    profile: state.user.profile,
    theme: state.user.features.theme
  }
}

const mapDispatchToProps = {
  uploadAvatar: (userId: string, formData: FormData) => uploadAvatar(userId, formData, nanoid()),
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserInfoComponent)
