import { RootState } from "app/store"
import { nanoid } from "nanoid"
import { connect } from 'react-redux'
import _ from 'lodash'

import { Annotation, AnnotationApprovalStatus, distinctAnnotationCreators } from "annotation/model/Annotation"
import { deleteAnnotation, listScans, updateAnnotationApprovalStatus, updateScan } from "cases/api/ScansSlice"
import { User } from "auth/model/User"
import { Dispatch, SetStateAction, useEffect} from "react"
import { Scan, ScanInfo, ScanListingEntry } from "uploads/api/Model"
import { Dataset, DatasetInfo } from "uploads/model/Dataset"
import { updateDataset } from "datasets/api/DatasetSlice"
import { AnnotationsPalette } from "app/ColorPalettes"
import { getSeriesDescriptions, SeriesDescriptionsMap } from "uploads/util/SeriesDisplayName"
import { ReorderableSeriesList } from "./ReorderableSeriesList"
import { CaseInfo } from "./CaseInfo"
import { AnnotationUsersPanel } from "annotation/components/AnnotationUsersPanel"
import { SeriesDatasetAnnotationLabelMap } from "uploads/model/DatasetAnnotationLabel"


export type SeriesPanelProps = {
  scanId: string
  scan: Scan
  dataset?: Dataset
  annotations?: Annotation[]
  seriesIds: string[] 
  seriesDescriptions: SeriesDescriptionsMap
  hoveredAnnotationId?: string
  users: User[] 
  displayedUserAnnotations: string[]
  displayModelAnnotations: boolean
  annotationApprovers: string[]
  currentUserId: string
  editingAnnotation: string | undefined
  showDebugUi: boolean
  showExperimentalUi: boolean
  seriesOrderState?: string[]
  hasAnnotateAccess: boolean
  hasWriteAccess: boolean
  previousCase?: string
  nextCase?: string
  caseProgressDescription?: string
  datasetCases: ScanListingEntry[]
  savingAnnotationIds: SeriesAnnotationIdsMap
  zoomedSeriesInstanceUID?: string
  hiddenAnnotations: string[]
  selectedSeriesId?: string
  annotateMode: boolean
  seriesAnnotationLabels: SeriesDatasetAnnotationLabelMap
  setHiddenAnnotations: (hiddenAnnotations: string[]) => void
  setZoomedSeriesInstanceUID: (seriesId: string | undefined) => void
  listCases: (datasetId: string) => void
  onCaseNavigate: () => void
  setSeriesOrderState: (seriesOrderState: string[]) => void
  setHoveredAnnotationMeta: (hoveredAnnotationId: string | undefined) => void
  deleteAnnotation: (scanId: string, annotationId: string) => Promise<void>
  updateAnnotationApproval: (scanId: string, annotationId: string, status: AnnotationApprovalStatus | undefined) => void
  toggleDisplayedUserAnnotation: (userId: string) => void
  displayAnnotation: (annotation: Annotation) => void
  setDisplayModelAnnotations?: Dispatch<SetStateAction<boolean>>
  setEditingAnnotation: (annotationId: string|undefined) => void
  updateDataset: (datasetId: string, name: string, datasetInfo: DatasetInfo) => Promise<void>
  updateScan: (scanId: string, name: string, scanInfo: ScanInfo) => Promise<void>
}

export const SeriesPanel = ({
  scanId, scan, dataset, annotations, seriesIds, seriesDescriptions, hoveredAnnotationId, users, displayedUserAnnotations, 
  annotationApprovers, currentUserId, displayModelAnnotations, editingAnnotation, showDebugUi, showExperimentalUi, 
  seriesOrderState, hasAnnotateAccess, hasWriteAccess, previousCase, nextCase, caseProgressDescription, datasetCases, 
  savingAnnotationIds, zoomedSeriesInstanceUID, hiddenAnnotations, selectedSeriesId, annotateMode, seriesAnnotationLabels,
  setHoveredAnnotationMeta, deleteAnnotation, toggleDisplayedUserAnnotation, displayAnnotation, 
  updateAnnotationApproval, setDisplayModelAnnotations, setEditingAnnotation, updateDataset, updateScan, setSeriesOrderState,
  onCaseNavigate, listCases, setZoomedSeriesInstanceUID, setHiddenAnnotations
}: SeriesPanelProps) => {

  const userIds = distinctAnnotationCreators(annotations)

  useEffect(() => {if(dataset !== undefined && dataset.cases.length > 0 && datasetCases.length < 1) listCases(dataset.datasetId) }, 
    [listCases, dataset?.datasetId, dataset?.cases.length, datasetCases.length])


  useEffect(() => {
    if(userIds.length > 0 && dataset !== undefined && Object.keys(dataset.datasetInfo.userAnnotationColors).length === 0) {
      const userAnnotationColors = {}

      userIds.forEach((userId, index) =>{
        userAnnotationColors[userId] = AnnotationsPalette[index % AnnotationsPalette.length]
      })

      updateDataset(
        dataset.datasetId, 
        dataset.name, {
          ...dataset.datasetInfo,
          userAnnotationColors
        })
    }
  }, [userIds.length, dataset?.datasetInfo.userAnnotationColors])

  const updateUserColor: (userId: string, color: string) => Promise<void> = (userId, color) => {
    if(dataset !== undefined) {
      return updateDataset(
        dataset.datasetId, 
        dataset.name, 
        {
          ...dataset.datasetInfo,
          userAnnotationColors: {
            ...dataset.datasetInfo.userAnnotationColors,
            [userId]: color
          }
        })
    } else {
      return updateScan(
        scan.scanId,
        scan.name || '',
        {
          ...scan.scanInfo,
          userAnnotationColors: {
            ...scan.scanInfo.userAnnotationColors,
            [userId]: color
          }
        }
      )
    }
  }

  useEffect(() => {
    if(seriesOrderState !== undefined){
      const newSeriesIds = seriesIds.filter(seriesId => !seriesOrderState.includes(seriesId) )
      if(newSeriesIds.length > 0) {
        setSeriesOrderState([...seriesOrderState, ...newSeriesIds])
      }
    }    
  }, [seriesIds, seriesOrderState, setSeriesOrderState])

  return (
    <div 
      className="series-panel"
      style={{
        display: 'inline-block',
        verticalAlign: 'text-top',
        overflowY: 'auto'
      }}
    >
      <CaseInfo 
        scan={scan} 
        dataset={dataset} 
        previousCase={previousCase} 
        nextCase={nextCase} 
        caseProgressDescription={caseProgressDescription} 
        annotateMode={annotateMode}
        onCaseNavigate={onCaseNavigate} />
      
      
      <AnnotationUsersPanel 
        userIds={userIds} 
        users={users}
        userAnnotationColors={dataset?.datasetInfo.userAnnotationColors || {}}
        displayModelAnnotationsCheckbox={annotations?.find(a => a.modelName !== undefined) !== undefined}
        displayedUserAnnotations={displayedUserAnnotations}
        displayModelAnnotations={displayModelAnnotations}
        updateUserColor={updateUserColor}
        hasAnnotateAccess={hasAnnotateAccess}
        toggleDisplayedUserAnnotation={toggleDisplayedUserAnnotation}
        setDisplayModelAnnotations={setDisplayModelAnnotations}
      />       

      <ReorderableSeriesList 
        scanId={scanId} 
        seriesIds={seriesIds} 
        hasWriteAccess={hasWriteAccess} 
        scan={scan} 
        seriesDescriptions={seriesDescriptions} 
        users={users} 
        annotations={annotations}
        dataset={dataset}
        seriesOrderState={seriesOrderState}
        hoveredAnnotationId={hoveredAnnotationId}
        displayedUserAnnotations={displayedUserAnnotations} 
        displayModelAnnotations={displayModelAnnotations} 
        annotationApprovers={annotationApprovers} 
        currentUserId={currentUserId} 
        editingAnnotation={editingAnnotation} 
        showDebugUi={showDebugUi} 
        seriesAnnotationLabels={seriesAnnotationLabels}
        savingAnnotationIds={savingAnnotationIds}
        zoomedSeriesInstanceUID={zoomedSeriesInstanceUID}
        hiddenAnnotations={hiddenAnnotations}
        setHiddenAnnotations={setHiddenAnnotations}
        setZoomedSeriesInstanceUID={setZoomedSeriesInstanceUID}
        setSeriesOrderState={setSeriesOrderState} 
        updateScan={updateScan} 
        setHoveredAnnotationMeta={setHoveredAnnotationMeta} 
        deleteAnnotation={deleteAnnotation} 
        updateAnnotationApproval={updateAnnotationApproval} 
        displayAnnotation={displayAnnotation} 
        setEditingAnnotation={setEditingAnnotation} 
        updateDataset={updateDataset}     
        selectedSeriesId={selectedSeriesId}  
      />                 
    </div>
  )
}

export type SeriesAnnotationIdsMap = {
  [seriesId: string]: string[]
}

const mapStateToProps = (state: RootState, { scanId }) => {
  const showDebugUi = state.user.features.showDebugUi
  const annotations = _.sortBy(state.scans.annotations[scanId], ['SeriesInstanceUID', 'InstanceNumber']).filter(a => 
    showDebugUi || a.displayMode !== "debug"
  )
    
  const users = state.user.users || []

  const seriesDescriptions = getSeriesDescriptions(state.scans.imagesMetadata[scanId])

  const scan = state.scans.all?.find(scan => scan.scanId === scanId)
  const isDatasetCase = scan?.datasetId !== undefined
  const datasetCases: ScanListingEntry[] = scan?.datasetId === undefined ? [] : 
  _.sortBy(state.scans.list?.filter(s => s.datasetId === scan?.datasetId) || [], ['name']) 

  const currentIndex = datasetCases.findIndex(s => s.scanId === scanId)
  const previousCase = datasetCases[currentIndex - 1]?.scanId
  const nextCase = datasetCases[currentIndex + 1]?.scanId
  
  return { 
    annotations,
    users,
    seriesDescriptions,
    showDebugUi,
    showExperimentalUi: state.user.features.showExperimentalUi,
    previousCase,
    nextCase,
    caseProgressDescription: (!isDatasetCase || currentIndex === undefined) ? undefined : `Case ${currentIndex + 1} of ${datasetCases.length}`,
    datasetCases
  }
}

const mapDispatchToProps = {
  deleteAnnotation: (scanId: string, annotationId: string) => deleteAnnotation(scanId, annotationId, nanoid()),
  updateAnnotationApproval: (scanId: string, annotationId: string, status: AnnotationApprovalStatus | undefined) => 
    updateAnnotationApprovalStatus(scanId, annotationId, status, nanoid()),
  updateDataset: (datasetId: string, name: string, datasetInfo: DatasetInfo) => updateDataset(datasetId, name, datasetInfo, nanoid()),
  updateScan: (scanId: string, name: string, scanInfo: ScanInfo) => updateScan(scanId, name, scanInfo, undefined, nanoid()),
  listCases: (datasetId: string) => listScans(nanoid(), datasetId),
}


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SeriesPanel)
