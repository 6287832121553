import Select from 'react-select'

import { getUserName, User, userName } from 'auth/model/User'
import { ClassificationForm } from "annotation/model/ClassificationForm"
import { SelectOption } from "utils/SelectOption"
import { useEffect, useState } from 'react'

export type FormSelectorProps = {
  forms: ClassificationForm[]
  users: User[]
  selectedUserId?: string
  setSelectedUserId: (userId: string | undefined) => void
}

export const FormSelector = ({forms, users, selectedUserId, setSelectedUserId}: FormSelectorProps) => {

  const [selectedUserIdInitialised, setSelectedUserIdInitialised] = useState<boolean>(false)
  useEffect(() => {
    if(!selectedUserIdInitialised) {
      if(forms.length > 0) {
        setSelectedUserId(forms[0].createdBy || 'model-service')
      }
      setSelectedUserIdInitialised(true)
    }
  }, [selectedUserIdInitialised, forms.length, setSelectedUserId])

  const userIds: string[] = forms.map(f => f.createdBy).filter(u => u !== undefined) as string[]
  const distinctUserIds = [...new Set(userIds)]

  const options: SelectOption<string>[] = distinctUserIds.reduce((acc: SelectOption<string>[], userId: string) => {     
    const user = users?.find(u => u.id === userId)
    
    return user === undefined ? acc : [...acc, {value: user.id, label: userName(user) }]
  }, [])

  if(forms.find(f => f.createdBy === undefined) !== undefined) {
    options.push({
      value: 'model-service', label: 'Auto-detected'
    })
  }
  
  const current = selectedUserId === undefined ? undefined : { 
    value: selectedUserId, 
    label: selectedUserId === 'model-service' ? 'Auto-detected' : getUserName(users, selectedUserId) 
  }

  if((forms.length) <= 0){
    return <></>
  } else {
    return (
      <div 
        className="classification-form-user-selector"
        style={{ display: 'flex', marginRight: '10px' }}
        >
        <div style={{marginTop: '5px', marginLeft:'10px', fontSize: '13pt', width: '200px'}}>Select user:</div>

        <div style={{ width: '250px' }}>
          <Select 
            classNamePrefix="react-select-form-selector"
            options={options} 
            placeholder="Select form" 
            value={current} 
            onChange={(e) => { if (e !== null) {setSelectedUserId(e.value)}}}
          /> 
        </div>
        
      </div>
    )
  }
}
