
export const formatDate: (dateString: string | undefined) => string = (dateString) => { 
  if(dateString === undefined) {
    return ''
  }
  
  try {
    if(dateString.endsWith('Z')) {
      return new Date(dateString).toLocaleString()
    } else {
      return new Date(`${dateString}Z`).toLocaleString()
    }    
  } catch (err) {
    console.log(`Error parsing date: ${dateString}`) 
    return dateString
  }
}

export const formatDateOnly: (dateString: string | undefined) => string = (dateString) => { 
  if(dateString === undefined) {
    return ''
  }
  
  try {
    return new Date(`${dateString}Z`).toDateString()
  } catch (err) {
    console.log(`Error parsing date: ${dateString}`) 
    return dateString
  }
}

export const parseStudyDate: (studyDate: string | undefined) => Date | undefined = (studyDate) => {
  if(studyDate === undefined || studyDate.trim() === '') {
    console.log(`empty: ${studyDate}`)

    return undefined
  }

  const split = studyDate.trim().split('.')
  if(split.length === 3) {

    const day = Number.parseInt(split[0], 10)
    const month = Number.parseInt(split[1], 10)
    const year = Number.parseInt(split[2].length === 5 ? split[2].substring(0, 4) : split[2], 10)
    if(!isNaN(day) && !isNaN(month) && !isNaN(year)) {
      return new Date(year, month - 1, day)
    } else {
      console.log(`NaNs: ${studyDate}`)
      return undefined
    }
  } else {
    return new Date(studyDate)
  }
}