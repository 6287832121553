import { ExtractedImage } from "uploads/store/Model"

export type PixelSpacing = {
  x: number
  y: number
  width?: number
  height?: number
}

export const extractPixelSpacing: (image: ExtractedImage | undefined) => PixelSpacing | undefined = (image) => {
  if(image !== undefined && 
    image.pixelSpacingX !== undefined && 
    image.pixelSpacingY !== undefined && 
    image.pixelSpacingX !== null && 
    image.pixelSpacingY !== null) {

      const x = Number.parseFloat(image.pixelSpacingX)
      const y = Number.parseFloat(image.pixelSpacingY)

      if (Number.isNaN(x) || Number.isNaN(y)) {
        return undefined
      } else {
        return { 
          x, 
          y,
          width: image.width,
          height: image.height
        }
      }
  }
}