import _ from 'lodash'

import { ImageExtraction } from "uploads/api/Model"
import { ExtractedImage } from "uploads/store/Model"

export interface UploadRecord {
  id: string
  filename: string
  uploadedBy: string
  uploadedDate: string
  imageExtraction?: ImageExtraction
  images?: ExtractedImage[]
  imagesLoading?: boolean
}

export interface UploadReport {
  reportId: string
  status: ReportGenerationStatus
}

export enum ReportGenerationStatus {
  requested,
  complete,
  failed
}

export const imagesFromUploads = (uploads: UploadRecord[]): ExtractedImage[] => {

  const isDefined = (item: ExtractedImage | undefined): item is ExtractedImage => { return !!item }
  const reducer: (images: ExtractedImage[], upload: UploadRecord) => ExtractedImage[] = (images, upload) => {    
    const newImages = (
      upload.images?.map(i => {
        if(i.imageFormat === 'png') {          
          return i
        } else {
          return undefined
        }
      }) || []
    ).filter(isDefined)
    
    return [ ...images, ...newImages]      
  }

  return uploads.reduce(reducer, [])
}

export const sortByFileName = (uploads: UploadRecord[] | undefined): UploadRecord[] | undefined => {
  if (uploads === undefined) {
    return undefined
  }
  
  return _.sortBy(
    uploads, 
    u => u.filename)
}