import { getUserName, User } from "auth/model/User"
import { useState } from "react"
import { ClassificationForm } from "annotation/model/ClassificationForm"
import { AnnotationMode } from "uploads/model/AnnotationMode"
import { ACLForm } from "./ACLForm"
import { FormValues, HamstringForm } from "uploads/component/HamstringForm"
import { FormSelector } from "uploads/component/FormSelector"
import { Annotation } from "annotation/model/Annotation"
import { ACLFormDisplay } from "./ACLFormDisplay"
import { HamstringFormDisplay } from "./HamstringFormDisplay"
import { Scan } from "uploads/api/Model"

export type AnnotationFormProps = {
  scanId: string
  scan: Scan
  forms: ClassificationForm[]
  users: User[]
  currentUserId: string
  hasAnnotateAccess: boolean
  annotations: Annotation[]
  annotationMode: AnnotationMode
  showDebugUi: boolean  
  displayOnly: boolean
  saveClassification: (scanId: string, form: FormValues) => Promise<void>
  updateClassificationForm: (scanId: string, formId: string, currentVersion: number, form: FormValues) => Promise<void>
  deleteClassificationForm: (scanId: string, formId: string, currentVersion: number) => Promise<void>
  displayAnnotation: (annotation: Annotation) => void
}

export const AnnotationForm = ({
  scanId, scan, forms, users, currentUserId, hasAnnotateAccess, annotationMode, showDebugUi, displayOnly, annotations,
  saveClassification, updateClassificationForm, deleteClassificationForm, displayAnnotation
}: AnnotationFormProps) => {

  const [selectedUserId, setSelectedUserId] = useState<string | undefined>(
    forms.find(f => f.createdBy === currentUserId) !== undefined ? currentUserId : forms[0]?.createdBy)

  const current: ClassificationForm | undefined = selectedUserId === 'model-service' ? forms?.find(f => f.createdBy === undefined) : forms?.find(f => f.createdBy === selectedUserId)

  const lines: string[] | undefined = current === undefined ? 
    undefined : JSON.stringify({
      ...current,
      createdByUser: getUserName(users, current.createdBy || '')
    }, null, 2).split('\n')

  return (
    <div className="annotation-form" style={{
        width: '350px', 
        marginTop: '10px', 
        flexShrink: 0
      }}>

      <FormSelector 
        forms={forms} 
        users={users}
        selectedUserId={selectedUserId} 
        setSelectedUserId={setSelectedUserId} 
      />

      { annotationMode === AnnotationMode.ACL &&
      <>
        { displayOnly ? <ACLFormDisplay current={current?.formData} /> :
          <ACLForm
            saveClassificationForm={(values) => saveClassification(scanId, values)} 
            updateClassificationForm={(classificationFormId, currentVersion, formData) => 
              updateClassificationForm(scanId, classificationFormId, currentVersion, formData)} 
            currentUserId={currentUserId}
            hasAnnotateAccess={hasAnnotateAccess}
            displayOnly={displayOnly}
            current={current}
            annotations={annotations}
            onEditOthersForm={() => setSelectedUserId(currentUserId)}
            deleteClassificationForm={(classificationFormId, currentVersion) => 
              deleteClassificationForm(scanId, classificationFormId, currentVersion)}
        />  
        }
          </>
      }
      { annotationMode === AnnotationMode.Hamstring &&
      <>
        { displayOnly ? 
          <HamstringFormDisplay 
            current={current?.formData} 
            scan={scan} 
            displayAnnotation={displayAnnotation}
            annotations={annotations}
          /> 
          :
          <HamstringForm 
            saveClassificationForm={(values) => saveClassification(scanId, values)} 
            updateClassificationForm={(classificationFormId, currentVersion, formData) => 
              updateClassificationForm(scanId, classificationFormId, currentVersion, formData)} 
            currentUserId={currentUserId}
            hasAnnotateAccess={hasAnnotateAccess}
            displayOnly={displayOnly}
            current={current}
            displayAnnotation={displayAnnotation}
            annotations={annotations}
            onEditOthersForm={() => setSelectedUserId(currentUserId)}
            deleteClassificationForm={(classificationFormId, currentVersion) => 
              deleteClassificationForm(scanId, classificationFormId, currentVersion)}
          />
        }
      </>
      }
      { showDebugUi &&
        <div style={{ margin: '20px'}}>
          <ul>
            {lines?.map((line, index) =>
              <li key={index} style={{ whiteSpace: 'pre'}}>{line}</li>
            )}
          </ul>
        </div>
      }
    </div>
  ) 
}