import { RootState } from "app/store"
import { connect } from 'react-redux'
import { useEffect, useState } from "react"
import { Link } from 'react-router-dom'


import { ImportedAnnotationsMap, deleteImportedAnnotations, importAnnotations, ImportsMap, listImportedAnnotations, validateImport, ValidationResultsMap, ValidationsMap } from "datasets/api/DatasetSlice"
import { nanoid } from "nanoid"
import { DatasetListingEntry } from "uploads/model/Dataset"
import { errorToString } from "utils/ErrorUtils"
import { LoadingSpinner } from "app/components/LoadingSpinner"
import { AnnotationsImport } from "uploads/api/Model"
import { Annotation } from "annotation/model/Annotation"
import _ from "lodash"
import { DatasetSelector } from "datasets/components/DatasetSelector"
import { pluralise } from "utils/Pluralise"

export type ImportAnnotationsPageProps = {
  datasets?: DatasetListingEntry[]
  allImports: ImportsMap
  annotations: ImportedAnnotationsMap
  newImports: ImportsMap 
  validations: ValidationsMap
  currentUserId: string
  importAnnotations: (datasetId: string, formData: FormData) => void
  validateImport: (datasetId: string, fileName: string, json: JSON) => Promise<void>
  listImportedAnnotations: (datasetId: string) => void 
  deleteImportedAnnotations: (datasetId: string, importId: string) => void
}

type FileJsonMap = {
  [fileName: string]: any
}

export const ImportAnnotationsPage = ({
  datasets, allImports, annotations, newImports, validations,
  importAnnotations, validateImport, listImportedAnnotations, deleteImportedAnnotations
}: ImportAnnotationsPageProps) => {
  const [datasetId, setDatasetId] = useState<string | undefined>(undefined)
  const [fileJson, setFileJson] = useState<FileJsonMap>({})
  const [error, setError] = useState<string | undefined>(undefined)
  const [validating, setValidating] = useState<boolean>(false)

  const validateFiles = () => {
    if(datasetId !== undefined) {
      setValidating(true)

      const validations = Object.entries(fileJson).map(([fileName, json]) => {
        return validateImport(datasetId, fileName, json)
      })

      Promise.all(validations).then(() => setValidating(false))
    }
  }

  useEffect(() => { if(datasetId !== undefined && allImports[datasetId] === undefined) {listImportedAnnotations(datasetId)}}, [datasetId, listImportedAnnotations] )
  useEffect(validateFiles, [datasetId])

  const [selectedFiles, setSelectedFiles] = useState<File[] | undefined>(undefined)

  const handleSubmit = (e) => {
    e.preventDefault()   

    if(datasetId !== undefined && selectedFiles !== undefined && selectedFiles.length > 0){
      const formData = new FormData()
      selectedFiles.forEach(file =>{
        formData.append(file.name, file)		
      })      
  
      importAnnotations(datasetId, formData)
    }    
  }

  const storeParsed = (fileName: string) => (event) => {
    const str = event.target.result

    let json: any | undefined = undefined

    try {
      json = JSON.parse(str)
    } catch (err) {
      setError(errorToString(err))
    }

    if(json !== undefined) {
      setFileJson({
        ...fileJson,
        [fileName]: json
      })

      if(datasetId !== undefined){
        setValidating(true)
        validateImport(datasetId, fileName, json).then(() => setValidating(false))
      }      
    }
  }

  const changeHandler = (event) => {   
    const files: File[] = Array.from(event.target.files)
    
    if(files.length > 0){      
      setSelectedFiles(files)

      files.forEach(file => {
        const reader = new FileReader()
        reader.onload = storeParsed(file.name)
        reader.readAsText(file)
      })      
    }    
	}

  const imports = datasetId !== undefined ? newImports[datasetId] : undefined
 
  const importIds = imports?.map(prev => prev.annotationImportId) || []
  const datasetAnnotations = datasetId !== undefined ? annotations[datasetId] : undefined
  const datasetValidations: ValidationResultsMap | undefined = datasetId !== undefined ? validations[datasetId] : undefined

  const previousImports = datasetId !== undefined ? allImports[datasetId]?.filter(i => importIds.indexOf(i.annotationImportId) === -1) : undefined  

  return (
    <div style={{margin: '20px'}}>

      <DatasetSelector datasets={datasets} selectedDatasetId={datasetId} setSelectedDatasetId={setDatasetId} /> 

      { error !== undefined &&
        <div>Error parsing annotations: {error}</div>
      }

      { validating && 
        <LoadingSpinner />
      }

      { !validating && datasetValidations !== undefined && imports === undefined &&
        <ValidationDetails validations={datasetValidations} />
      }

      { imports !== undefined && datasetId !== undefined && 
        <div>
          {imports.map((i) => 
            <ImportDetails 
              key={i.annotationImportId} 
              annImport={i} 
              annotations={datasetAnnotations?.filter(a => a.importId === i.annotationImportId)} 
              deleteImportedAnnotations={() => deleteImportedAnnotations(datasetId, i.annotationImportId)}
            />
          )}          
        </div>
      }

      <form id="upload" onSubmit={handleSubmit} style={{marginTop:'10px'}}>
        <input type="file" id="file" accept=".json" onChange={changeHandler} className="visually-hidden" multiple />
        <label htmlFor="file" className={`btn btn-primary`}>Open file</label>

        <button className={`btn ${(selectedFiles === undefined || datasetId === undefined ) ? "btn-secondary" : "btn-primary"}`} style={{marginLeft:'10px'}}>Upload</button>
      </form>

      { previousImports !== undefined && 
        <div style={{ marginTop: '10px' }}>
          <h4>{pluralise('previous import', previousImports.length)}</h4>
          { previousImports.map((i) => 
            <ImportDetails 
              key={i.annotationImportId} 
              annImport={i} 
              annotations={datasetAnnotations?.filter(a => a.importId === i.annotationImportId)} 
              deleteImportedAnnotations={() => deleteImportedAnnotations(i.datasetId, i.annotationImportId)}
            />)}          
        </div>
      }
    </div>
  )
}

type ImportDetailsProps = {
  annImport: AnnotationsImport
  annotations?: Annotation[]
  deleteImportedAnnotations: () => void
}

const ImportDetails = ({annImport, annotations, deleteImportedAnnotations}: ImportDetailsProps) => {
  return (
    <div style={{border: '1px solid lightgrey', maxWidth: '500px', borderRadius: '10px', margin: '10px', padding: '10px'}}>
      <div className="remove-annotation-button" 
        style={{
          top: '2px',
          marginRight: '-6px',
          float: 'right',
          cursor: 'pointer'
        }}
        onClick={deleteImportedAnnotations} 
        title="Delete imported annotation"
        >
        <svg fill="#000000" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24px" height="24px">
          <path fill="none" stroke="#000000" strokeMiterlimit="10" strokeWidth="2" d="M4 4L20 20M20 4L4 20"/>
        </svg>
      </div>
      <div style={{marginBottom: '10px'}}>{annImport.annotationsCount} annotations imported from {annImport.fileName}</div>
      { annotations !== undefined &&
        <ul>
          {Object.entries(_.groupBy(annotations, a => a.scanId)).map(([scanId, annotations]) => 
            <li key={scanId}>{annotations.length} annotations on case <Link to={`/annotations/${scanId}`}>{scanId}</Link> </li>
          )}
        </ul>
      }
    </div>
  )
}

type ValidationDetailsProps = {
  validations: ValidationResultsMap
}



const ValidationDetails = ({validations}: ValidationDetailsProps) => {
  return (
    <div>
      <h5>Validations:</h5>
      <ul>
        {Object.entries(validations).map(([fileName, result]) => 
          <li key={fileName}>
            <div>File: {fileName}</div>
            <div>{pluralise('valid annotation', Object.keys(result.annotations).length)}</div>
            <div>{pluralise('valid user', result.users.length)}: {result.users.join(', ')}</div>
            <div>{pluralise('error', Object.keys(result.errors).length)}</div>
            <div>
              {Object.entries(result.errors).map(([annotationId, errors]) => 
                <div key={annotationId}>
                  Annotation Id: {annotationId}
                  <ul>
                    {errors.map(e => <li key={e}>{e}</li>)}
                  </ul>
                </div>
              )}
            </div>
          </li>          
        )}
      </ul>
    </div>
  )
}


const mapStateToProps = (state: RootState) => {   
  return { 
    datasets: state.datasets.list,
    allImports: state.datasets.allImports,
    annotations: state.datasets.annotations,
    newImports: state.datasets.newImports,
    validations: state.datasets.validations
  }
}

const mapDispatchToProps = { 
  importAnnotations: (datasetId: string, formData: FormData) => importAnnotations(datasetId, formData, nanoid()),
  validateImport: (datasetId: string, fileName: string, json: JSON) => validateImport(datasetId, fileName, json, nanoid()),
  listImportedAnnotations: (datasetId: string) => listImportedAnnotations(datasetId, nanoid()),
  deleteImportedAnnotations: (datasetId: string, importId: string) => deleteImportedAnnotations(datasetId, importId, nanoid())
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ImportAnnotationsPage)

