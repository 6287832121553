import { nanoid } from 'nanoid'
import { VrSceneState } from 'threeD/model/VrSceneState'

import { IceAnswerPayload, IncomingWebSocketMessage, NewIceCandidatePayload, OfferPayload, OutgoingWebSocketMessage, WebSocketMessagePayloadEvent } from 'utils/AsyncWebSocket'
import { SocketApi } from './api/SocketApi'
import { CallInfo } from './model/User'
import { UserVisiblilty } from './model/UserVisibility'
import { updateUserStatus, reportError, addIncomingCall, removeIncomingCall, addOutgoingCall, removeOutgoingCall, addOngoingCall, remoteNavigte, removeOngoingCalls, removeOngoingCall } from './UserSlice'

const instanceId = nanoid()
let webSocket: WebSocket | undefined = undefined
let opening: boolean = false 
let sendVideoVar: boolean = false

const socketApi = new SocketApi(window.RuntimeConfig.backend)

type RtcChannel = {
  peerConnection: RTCPeerConnection
  dataChannel: RTCDataChannel
  latestState?: VrSceneState
}

export const connections: {[connectionId: string]: RtcChannel} = {}


export const webSocketSend: (message: OutgoingWebSocketMessage, currentUserId: string) => Promise<void> = async (message, currentUserId) => {  
  if(webSocket !== undefined) {
    if(webSocket.readyState === webSocket.CLOSED || webSocket.readyState === webSocket.CLOSING) {
      console.log(`webSocket.readyState = ${webSocket.readyState}, openSocket`)
      await openSocket(currentUserId, undefined)
    }
    try {
      webSocket.send(JSON.stringify(message))
    } catch (err) {
      console.log(err)      
    }
  }
}

const mediaConstraints = (sendVideo: boolean) => ({ audio: true, video: sendVideo })

const setPeerConnection = (peerConnectionId, currentUserId, isInitiator, dispatch) => {
  const peerConnection = new RTCPeerConnection(servers)

  peerConnection.addEventListener("icecandidate", (event) => {
    if (event.candidate) {
      webSocketSend({
        recipientInstanceId: peerConnectionId,
        payload: {
          event: WebSocketMessagePayloadEvent.NewIceCandidate,
          data: { candidate: event.candidate }
        }
      }, currentUserId)
    }
  })

  peerConnection.onnegotiationneeded = async () => {
    if(isInitiator) {
      const offer = await peerConnection.createOffer()
      await peerConnection.setLocalDescription(offer)

      webSocketSend({
        recipientInstanceId: peerConnectionId,
        payload: {
          event: WebSocketMessagePayloadEvent.Offer,
          data: { offer }
        }
      }, currentUserId)
    }    
  }

  const dataChannelOptions: RTCDataChannelInit = {
    ordered: false,
    maxPacketLifeTime: 10 * 1000,
    negotiated: true,
    id: 0
  }
  const dataChannel = peerConnection.createDataChannel("test-channel", dataChannelOptions)

  connections[peerConnectionId] = { peerConnection, dataChannel } 

  dataChannel.onmessage = (event) => {
    dataChannelReceive(peerConnectionId, JSON.parse(event.data), dispatch)
  }

  dataChannel.onopen = () => {
    console.log("datachannel open")
  }

  dataChannel.onclose = () => {
    console.log("datachannel close")
  }

  peerConnection.addEventListener('connectionstatechange', () => {
    console.log(`event=connectionstatechange connectionState=${peerConnection.connectionState}`)
  }) 

  peerConnection.addEventListener('track', async (event) => {
    console.log(`incoming track, count: ${event.streams.length}`)
    const videoElement = document.getElementById(`video-element-${peerConnectionId}`)
    if(videoElement !== null && videoElement instanceof HTMLVideoElement) {
      videoElement.srcObject = event.streams[0]
    } else {
      console.log(`no video element: ${videoElement}`)
    }
  })

  navigator.mediaDevices.getUserMedia(mediaConstraints(sendVideoVar))
    .then((localStream) => {
      console.log(`local stream: ${localStream}`)
      localStream.getTracks().forEach((track) => {
        console.log(`track: ${track}`)
        peerConnection.addTrack(track, localStream)
      })
    })
    .catch((err) => {
      console.log(`getUserMedia error: ${err}`)
    })

}

const webSocketRecieve = (event: IncomingWebSocketMessage, currentUserId: string) => async dispatch => {
  switch(event.payload.event) {
    case WebSocketMessagePayloadEvent.UserVisibility:
      dispatch(updateUserStatus({userId: event.sender, visibility: event.payload.data.visibility}))
      break
    case WebSocketMessagePayloadEvent.StartCalling:      
      dispatch(addIncomingCall(event.payload.data))
      break
    case WebSocketMessagePayloadEvent.StopCalling:
      dispatch(removeIncomingCall(event.payload.data))
      break
    case WebSocketMessagePayloadEvent.CallAnswered:
      dispatch(removeOutgoingCall({userId: event.sender}))
      await dispatch(addOngoingCall({peerConnectionId: event.payload.data.connectionId}))
      setPeerConnection(event.payload.data.connectionId, currentUserId, false, dispatch)
      break
    case WebSocketMessagePayloadEvent.CallRejected:
      dispatch(removeOutgoingCall({userId: event.sender}))
      break
    case WebSocketMessagePayloadEvent.NewIceCandidate:
      receiveIceCandidate(event.payload, event, currentUserId)
      break
    case WebSocketMessagePayloadEvent.IceAnswer:
      receiveIceAnswer(event.payload, event)
      break
    case WebSocketMessagePayloadEvent.Offer:
      receiveOffer(event.payload, event, currentUserId)
      break
    case WebSocketMessagePayloadEvent.CallEnded:
      dispatch(removeOngoingCall({connectionId: event.clientInstanceId}))
      break
    default:
      break
  }
} 

const receiveIceCandidate: (
  payload: NewIceCandidatePayload, 
  event: IncomingWebSocketMessage,
  currentUserId: string
) => void = async (payload, event, currentUserId) => {
  const peerConnection = connections[event.clientInstanceId]?.peerConnection
  if(peerConnection !== undefined) {
    peerConnection.addIceCandidate(payload.data.candidate)
    try{
      const answer = await peerConnection.createAnswer()
      webSocketSend({
        recipientInstanceId: event.clientInstanceId,
        payload: {
          event: WebSocketMessagePayloadEvent.IceAnswer,
          data: { answer }
        }
      }, currentUserId) 
    } catch (err) {
      console.log(`failed to create answer on receiveIceCandidate`)
      throw err
    }    
  } else {
    console.log(`received ice candidate for no connection. event: ${JSON.stringify(event)}`)
  }
}

const receiveIceAnswer: (
  payload: IceAnswerPayload, 
  event: IncomingWebSocketMessage
) => void = async (payload, event) => {
  const peerConnection = connections[event.clientInstanceId]?.peerConnection
  if(peerConnection !== undefined) {
    try { 
      await peerConnection.setRemoteDescription(payload.data.answer)    
    } catch (err) {
      console.log(`failed to set remote description from ice answer. ${JSON.stringify(event)}`)
      throw err
    }
  } else {
    console.log(`received ice answer for no connection. event: ${JSON.stringify(event)}`)
  }
}

const receiveOffer: (
  payload: OfferPayload,
  event: IncomingWebSocketMessage,
  currentUserId: string
) => void = async (payload, event, currentUserId) => {
  const peerConnection = connections[event.clientInstanceId]?.peerConnection
  if(peerConnection !== undefined) {
    try {
      await peerConnection.setRemoteDescription(payload.data.offer)   
      try{
        const answer = await peerConnection.createAnswer()
        await peerConnection.setLocalDescription(answer)
        webSocketSend({
          recipientInstanceId: event.clientInstanceId,
          payload: {
            event: WebSocketMessagePayloadEvent.IceAnswer,
            data: { answer }
          }
        }, currentUserId) 
      } catch (err) {
        console.log(`failed to create answer on receiveOffer`)
        throw err
      }
    } catch (err) {
      console.log(`failed to set remote description from receive offer. ${JSON.stringify(event)}`)
      throw err
    }
  } else {
    console.log(`received offer for no connection. event: ${JSON.stringify(event)}`)
  }
}

export const openSocket = (currentUserId: string, visibility: UserVisiblilty | undefined) => async dispatch => {
  try {
    if(webSocket === undefined && !opening) {
      opening = true
      webSocket = await socketApi.openSocket(instanceId, event => dispatch(webSocketRecieve(event, currentUserId)))
      opening = false

      if(visibility !== undefined) {
        webSocketSend({payload: { event: WebSocketMessagePayloadEvent.UserVisibility, data: { visibility } }}, currentUserId)
        dispatch(updateUserStatus({userId: currentUserId, visibility}))
      }      
    }
  } catch (err) {
    dispatch(reportError('openSocket', err))
  }
}

export const updateStatus = (currentUserId: string, visibility: UserVisiblilty) => async dispatch => {
  try {
    dispatch(updateUserStatus({userId: currentUserId, visibility}))
    webSocketSend({payload: { event: WebSocketMessagePayloadEvent.UserVisibility, data: { visibility } }}, currentUserId)    
  } catch (err) {
    dispatch(reportError('updateStatus', err))
  }
}

export const heartbeat = (visibility: UserVisiblilty, currentUserId: string) => async dispatch => {
  try {
    if(webSocket !== undefined && visibility !== UserVisiblilty.Offline) {
      webSocketSend({payload: { event: WebSocketMessagePayloadEvent.UserVisibility, data: { visibility } }}, currentUserId)
      dispatch(updateUserStatus({userId: currentUserId, visibility}))
    }
  } catch (err) {
    dispatch(reportError('heartbeat', err))
  }
}

const servers: RTCConfiguration = {
  iceServers: [{ urls: [ 
    "stun:stun1.l.google.com:19302", 
    "stun:stun2.l.google.com:19302",
    "stun:stun.stunprotocol.org"
  ] }],
  iceCandidatePoolSize: 10,
}

export const callUser = (userId: string, currentUserId: string, sendVideo: boolean) => async dispatch => {
  try {    
    webSocketSend({
      recipientUserId: userId,
      payload: {
        event: WebSocketMessagePayloadEvent.StartCalling,
        data: { userId: currentUserId, connectionId: instanceId }
      }
    }, currentUserId)
    dispatch(addOutgoingCall({userId}))
  } catch (err) {
    dispatch(reportError('callUser', err))
  }
}

export const stopCallingUser = (userId: string, currentUserId: string) => async dispatch => {
  try {
    webSocketSend({
      recipientUserId: userId,
      payload: {
        event: WebSocketMessagePayloadEvent.StopCalling,
        data: { userId: currentUserId }
      }
    }, currentUserId)
    dispatch(removeOutgoingCall({userId}))
  } catch (err) {
    dispatch(reportError('stopCallingUser', err))
  }
}

export const answerCall = (call: CallInfo, currentUserId: string, sendVideo: boolean) => async dispatch => {
  sendVideoVar = sendVideo
  try {
    dispatch(removeIncomingCall({userId: call.userId}))
    await dispatch(addOngoingCall({peerConnectionId: call.connectionId}))
    setPeerConnection(call.connectionId, currentUserId, true, dispatch)
    webSocketSend({
      recipientInstanceId: call.connectionId,
      payload: {
        event: WebSocketMessagePayloadEvent.CallAnswered,
        data: { connectionId: instanceId }
      }
    }, currentUserId)
  } catch (err) {
    dispatch(reportError('answerCall', err))
  }
}

export const rejectCall = (call: CallInfo, currentUserId: string) => async dispatch => {
  try {
    dispatch(removeIncomingCall({userId: call.userId}))
    webSocketSend({
      recipientInstanceId: call.connectionId,
      payload: {
        event: WebSocketMessagePayloadEvent.CallRejected,
        data: call
      }
    }, currentUserId)
  } catch (err) {
    dispatch(reportError('rejectCall', err))
  }
}

export const endCalls = (calls: string[], currentUserId: string) => async dispatch => {
  try {
    calls.forEach( connectionId => {
      webSocketSend({
        recipientInstanceId: connectionId,
        payload: {
          event: WebSocketMessagePayloadEvent.CallEnded
        }
      }, currentUserId)
    })  

    Object.values(connections).map(connection => {
      connection.dataChannel.close()
      connection.peerConnection.close()
    })
    dispatch(removeOngoingCalls())
    calls.forEach(connectionId => delete connections[connectionId])
  } catch (err) {
    dispatch(reportError('endCall', err))
  }
}

export const startScreenShare = () => async dispatch => {
  try {
    Object.values(connections).map(channels => {
      dataChannelSend(
        channels.dataChannel, { 
          event: DataChannelMessageType.UrlNavigate, 
          data: { 
            location: { 
              pathname: document.location.pathname, 
              search: document.location.search } }})
    })
  } catch (err) {
    dispatch(reportError('startScreenShare', err))
  }
}

export const broadcastVrSceneState = (state: VrSceneState) => async dispatch => {
  try {
    Object.values(connections).map(channels => {
      dataChannelSend(
        channels.dataChannel, { 
          event: DataChannelMessageType.VrSceneState, 
          data: { state } })
    })
  } catch (err) {
    dispatch(reportError('broadcastVrSceneState', err))
  }
}

type DataChannelMessage = UrlNavigateMessage | VrSceneStateMessage

export enum DataChannelMessageType {
  UrlNavigate = 'url_navigate',
  VrSceneState = 'vr_scene_state'
}

export type UrlNavigateMessage = {
  event: DataChannelMessageType.UrlNavigate
  data: { location: RemoteNavigateLoation }
}

export type VrSceneStateMessage = {
  event: DataChannelMessageType.VrSceneState
  data: { state: VrSceneState }
}

export type RemoteNavigateLoation = {
  pathname: string
  search: string
}

const dataChannelReceive = (peerConnectionId: string, message: DataChannelMessage, dispatch) => {
  switch(message.event) {
    case DataChannelMessageType.UrlNavigate:
      dispatch(remoteNavigte({ location: message.data.location, spectateClientId: peerConnectionId }))
      break
    case DataChannelMessageType.VrSceneState:
      // console.log(`received vr scene state: ${JSON.stringify(message.data.state)}`)    
      const channel = connections[peerConnectionId]
      if(channel !== undefined) {
        channel.latestState = message.data.state
      } else {
        console.log(`event=received_state_from_unknown_connection connection_id=${peerConnectionId}`)
      }      
      break
  }
}


const dataChannelSend: (dataChannel: RTCDataChannel, message: DataChannelMessage) => void = (dataChannel, message) => {
  if(dataChannel.readyState === "open") {
    dataChannel.send(JSON.stringify(message))
  }  
}