import { AccessEntry } from "auth/model/AccessEntry"
import { Annotation, AnnotationApproval } from "annotation/model/Annotation"
import { ClassificationForm } from "annotation/model/ClassificationForm"
import { UserAnnotationColorMap } from "uploads/model/Dataset"

export type Scan = {
  scanId: string
  name?: string
  createdBy: string
  createdDate: string
  datasetId?: string
  uploadIds: string[]
  reportIds: string[]
  reportOutputs: ReportOutputMetadata[]
  scanInfo: ScanInfo
  studyDate?: string
  modelOutputs: ModelOutput[]
  modelRuns: ModelRun[]
  additionalBamicInfo?: AdditionalBacicStatistics
}

export type AdditionalBacicStatistics = {
  tearLengthCoronalEccentric?: number
  tearLengthCoronal?: number
  areaOfTearAtLargestExtent?: number
  areaOfMuscleAtLargestExtent?: number
  crossSectionalRatioOfMuscleTear?: number
  tearAreaIntratendinous?: number
  tearAreaTendonTorn?: number
  crossSectionalRatioOfTendonTear?: number
  highestCsaPercent?: number
  highestIntratendinousPercent?: number
  distanceToTJunction?: number
}

export const newScan: (scanId: string, createdBy: string) => Scan = (scanId, createdBy) => ({
  scanId, 
  name: undefined,
  createdBy, 
  createdDate: new Date().toString(), 
  reportOutputs: [], 
  uploadIds: [], 
  reportIds: [],
  scanInfo: DefaultScanInfo,
  modelOutputs: [],
  modelRuns: []
})

export type ScanInfo = {
  annotationApprovals: AnnotationApproval[]
  seriesDisplayNames: SeriesDisplayNameMap
  amsData?: AmsData
  bodyRegionId?: string
  userAnnotationColors: UserAnnotationColorMap
  orderedSeriesIds:  string[]
  patientId?: string
  patientName?: string
  recoveryStatus?: RecoveryStatus
}

export enum RecoveryStatus {
  Injured = "Injured",
  Recovered = "Recovered"
}


export type AmsData = {
  [columnName: string]: string
}

type SeriesDisplayNameMap = {
  [seriesId: string]: string
}

export type ModelOutput = {
  modelOutputId: string
  scanId: string
  seriesId?: string
  instanceNumber?: number
  name?: string
  title?: string
}

export type ModelRun = {
  modelRunId: string
  scanId: string
  status?: ModelRunStatus
  message?: string
  createdDate: string
  updatedDate?: string
}

export enum ModelRunStatus {
  started = "started",
  complete = "complete",
  failed = "failed"
}


export const DefaultScanInfo: ScanInfo = {
  annotationApprovals: [],
  seriesDisplayNames: {},
  amsData: undefined,
  bodyRegionId: undefined,
  userAnnotationColors: {},
  orderedSeriesIds: []
}

export type ReportOutputMetadata = {
  outputId: string,
  reportId: string,
  reportType: string,
  createdDate: string
}

export type ListPermissionsResponse = {
  entries: AccessEntry[]
}

export type UserUploadMetaData = {
  id: string
  filename: string
  uploadedBy: string
  uploadedDate: string
}

export type ImageExtraction = {
  imageExtractionId: string
  uploadId: string
  status: string
  instanceNumber?: number
  createdDate: string
  width?: number
  height?: number
}

export type ExtractedImageMetaData = {
  imageId: string
  imageFormat: string
  uploadId: string
  status: string
  SeriesDescription?: string
  InstanceNumber?: number
  SeriesInstanceUID?: string
  width?: number
  height?: number
}


export type ListUploadsResponse = {
  uploads: UserUploadMetaData[]
}

export type UploadFilesResponse = {
  ids: string[] 
}

export type ImagesForUploadResponse = {
  extraction: ImageExtraction
  images: ExtractedImageMetaData[]
}

export type ImagesForScanResponse = {
  metadata: ImagesForUploadResponse[]
  status: string
}

export type RequestModelRunResponse = {
  modelRunId: string
}

export type ListScansResponse = {
  scans: ScanListingEntry[]
}

export type ScanListingEntry = {
  scanId: string,
  studyInstanceUid?: string,
  name?: string,
  datasetId?: string,
  createdBy: string,
  createdDate: string,
  scanInfo?: ScanInfo,
  reportOutputs: ReportOutputMetadata[]
  studyDate?: string
}

export type ListAnnotationsResponse = {
  annotations: Annotation[]
}

export type ListClassificationFormsResponse = {
  forms: ClassificationForm[]
}

export type AnnotationsImport = {
  annotationImportId: string
  datasetId: string
  fileName: string
  blobSha256: string
  annotationsCount: number
  createdBy: string
  createdDate: string
}

export type ImportedAnnotationsResponse = {
  imports: AnnotationsImport[]
  annotations: Annotation[]  
}

export type AnnotationsImportValidationResult = {
  errors: AnnotationErrorsMap
  annotations: AnnotationDescriptionsMap
  users: string[]
}

export type AnnotationErrorsMap = {
  [annotationId: string]: string[]
}
export type AnnotationDescriptionsMap = {
  [annotationId: string]: string
}
