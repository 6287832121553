import { Link } from 'react-router-dom'
import { ScanListingEntry } from 'uploads/api/Model'
import _ from 'lodash'
import { capLengthIfNeeded } from 'utils/TextUtils'
import { ReportsApi } from 'reports/api/ReportsApi'
import { connect } from 'react-redux'
import { downloadReportOutput } from 'reports/api/ReportsSlice'
import { RootState } from 'app/store'
import { nanoid } from 'nanoid'
import { deleteDatasetCase } from 'datasets/api/DatasetSlice'
import { DeleteX } from 'app/components/icons/DeleteX'
import { Dataset, DatasetAggregateCaseInfo } from 'uploads/model/Dataset'
import { AnnotationMode } from 'uploads/model/AnnotationMode'

export type DatasetScansTableProps = {
  datasetId: string
  dataset: Dataset
  scans: ScanListingEntry[]
  hasOwnerAccess: boolean
  showExperimentalUi: boolean
  showDebugUi: boolean
  downloadReportOutput: (reportId: string, outputId: string) => void
  deleteDatasetCase: (datasetId: string, caseId: string) => void
}

export const DatasetScansTable = ({
  datasetId, dataset, scans, hasOwnerAccess, showExperimentalUi, showDebugUi,
  downloadReportOutput, deleteDatasetCase
}: DatasetScansTableProps) => {

  const showRemoveCase = hasOwnerAccess && showDebugUi

  return(
    <div className="scans-list-component" style={{maxHeight: '650px', overflowY: 'auto'}}>
      <table className="table table-bordered table-striped" style={{maxWidth: '800px'}}>
        <thead>
          <tr>
            <th style={{ width: '260px' }} scope="col">Case Name</th>
            {!showExperimentalUi &&
            <th style={{ width: '120px' }} scope="col">Patient ID</th>          
            }
            <th style={{ width: '100px' }} scope="col">Report</th>
            <th style={{ width: '130px' }} scope="col">Scan date</th>
            <th style={{ width: '110px' }} scope="col">Annotations</th>
            <th style={{ width: '60px' }} scope="col">Forms</th>           
            <th style={{ width: '130px' }} scope="col">{dataset.datasetInfo.annotationMode === AnnotationMode.ACL ? 'ACL Impression' : 'BAMIC Grade' }</th>           
            { showRemoveCase &&
            <th style={{ width: '70px' }} scope="col">Remove</th>           
            }
          </tr>
        </thead>
        <tbody>
          { _.sortBy(scans, ['name']).map((scan: ScanListingEntry) => 
            <ScanRow 
              key={scan.scanId}               
              scan={scan} 
              downloadReportOutput={downloadReportOutput} 
              showRemoveCase={showRemoveCase}
              deleteCase={() => deleteDatasetCase(datasetId, scan.scanId)}
              datasetCaseInfo={dataset.aggregateInfo.caseInfos.find(c => c.caseId === scan.scanId)}
              showExperimentalUi={showExperimentalUi}
            />
          )}
        </tbody>
      </table>    
    </div>
  )
}

type ScanRowProps = {
  scan: ScanListingEntry
  showRemoveCase: boolean
  datasetCaseInfo?: DatasetAggregateCaseInfo
  showExperimentalUi: boolean
  downloadReportOutput: (reportId: string, outputId: string) => void
  deleteCase: () => void
}

const ScanRow = ({
  scan, showRemoveCase, datasetCaseInfo, showExperimentalUi,
  downloadReportOutput, deleteCase
}: ScanRowProps) => {

  const reportsApi = new ReportsApi(window.RuntimeConfig.backend)

  const report = _.orderBy(scan.reportOutputs, o => o.createdDate, 'desc')[0]

  const onDownloadClick = (e: { preventDefault: () => void }) => {
    e.preventDefault()
    if(report !== undefined) {
      downloadReportOutput(report.reportId, report.outputId)
    }    
  }

  const classifications = datasetCaseInfo?.classifications.map((classification) => {
    if(classification === 'intact'){
      return 'Intact'
    } else if(classification === 'torn') {
      return 'Torn'
    } else {
      return classification
    }
  })

  return (
    <tr>
      <td title={scan.name}><Link to={`/cases/${scan.scanId}`}  >{capLengthIfNeeded(scan.name || 'unnamed', 27)}</Link></td>  
      {!showExperimentalUi &&
      <td title={scan.scanInfo?.patientId || ''}>{capLengthIfNeeded(scan.scanInfo?.patientId || '', 11)}</td>       
      }
      { report !== undefined && 
        <td><a href={reportsApi.reportOutputUrl(report.reportId, report.outputId)} onClick={onDownloadClick} >Available</a></td>              
      }

      { report === undefined &&
        <td>Pending</td>              
      } 
      <td>{scan.studyDate}</td>       
      <td>{datasetCaseInfo?.annotationsCount}</td>       
      <td>{datasetCaseInfo?.formsCount}</td>    
      <td>{classifications?.join(', ')}</td>    
      { showRemoveCase &&
      <td style={{paddingLeft: '24px'}}>
        <DeleteX onClick={deleteCase} />
      </td>    
      } 
    </tr>
  )
}

const mapStateToProps = (state: RootState, {  }) => {
  return {
    annotations: state.scans.annotations,
    forms: state.scans.forms,
  }
}

const mapDispatchToProps = { 
  downloadReportOutput,
  deleteDatasetCase: (datasetId: string, caseId: string) => deleteDatasetCase(datasetId, caseId, nanoid())
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DatasetScansTable)
