import React from 'react'

import { DownloadIcon } from 'app/components/DownloadIcon'
import { UploadRecord } from 'uploads/model/UploadRecord'
import { BackendConfig } from 'config/ApplicationConfig'
import { uploadsUrl } from 'uploads/api/UploadUrls'
import { formatDate } from 'utils/DateUtils'
import { capLengthIfNeeded } from 'utils/TextUtils'
import { ExtractedImage } from 'uploads/store/Model'

const imageStatus = (upload: UploadRecord, images: ExtractedImage[] | undefined): string => {
  if(images !== undefined && images.find(i => i.status !== 'complete') === undefined) {
    const count = images.length
    
    if(count === 1) {
      return `DICOM image processed`
    } else {
      return `${count} DICOM images processed`
    }
  } else if (upload.imageExtraction?.status === 'requested') {
    return 'processing'
  } else {
    return 'not started'
  } 
}


export type UploadedFileDetailsProps = {
  upload: UploadRecord
  config: BackendConfig
  images?: ExtractedImage[]
  downloadFile: (uploadId: string) => void
}

const trimDirectoryFromFileName: (full: string) => string = (full) => {
  if(!full.includes('/') || full.lastIndexOf('/') === full.length) {
    return full
  } else {
    return full.substring(full.lastIndexOf('/') + 1, full.length)
  }
}

export const UploadedFileDetails = ({upload, images, config, downloadFile}: UploadedFileDetailsProps) => {
  const clickHandler = (uploadId) => (e) => {    
    e.preventDefault()
    downloadFile(uploadId)
  }  


  return (
    <tr key={upload.id} >
      <td title={upload.filename}>{capLengthIfNeeded(trimDirectoryFromFileName(upload.filename), 44)}</td>       
      <td>{formatDate(upload.uploadedDate)}</td>      
      <td data-cy={`image-status-${upload.filename}`}>{imageStatus(upload, images)}</td>      
      <td data-cy={`download-original-${upload.filename}`} style={{display: 'flex', justifyContent: 'center'}}>
        <a href={`${uploadsUrl(config)}/${upload.id}`} onClick={clickHandler(upload.id)}  > 
          <DownloadIcon />
        </a>
      </td>
    </tr>
  )
}
