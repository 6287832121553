import LoggedInApp from "app/LoggedInApp"
import { useEffect, useState } from "react"
import { BrowserRouter } from 'react-router-dom'
import { keycloak } from 'auth/KeyCloak'

import './app/App.css'


function App() {

  const [keyCloakInitialised, setKeyCloakInitialised] = useState<boolean>(false)
  const [authenticated, setAuthenticated] = useState<boolean>(false)
  const [delayed, setDelayed] = useState<boolean>(false)

  useEffect(() => { setTimeout(() => {
    if(!delayed && keyCloakInitialised) {
      setDelayed(true)
    }    
  }, 1000) }, [delayed, keyCloakInitialised])

  useEffect(() => {
    if(keyCloakInitialised && keycloak.authenticated === false && delayed) {
      keycloak.login()
    }
  }, [keyCloakInitialised, authenticated, delayed])

  useEffect(() => {
    if(!keyCloakInitialised) {      
      keycloak.init({}).then((authenticated) => {
        setAuthenticated(authenticated)        
      })
    }    
    setKeyCloakInitialised(true)
  }, [keyCloakInitialised])


  if(keyCloakInitialised && authenticated) {  
    return (
      <BrowserRouter>
        <LoggedInApp />
      </BrowserRouter>        
    )    
  } else {
    return (
      <div>Loading...</div>          
    )
  } 
}

export default App

