import _ from 'lodash'
import { Form, Formik } from 'formik'
import { useRef, useState } from 'react'
import { ClassificationForm } from 'annotation/model/ClassificationForm'
import { DeleteButton, FormSection, FormTextArea, LabeledCheckBox, RadioGroupSection, SaveOrEditButton, SectionDescription } from 'uploads/component/FormElements'
import { Annotation } from 'annotation/model/Annotation'

// See docs/Hamstring Classification Decision Tree.png

export type FormValues = {
  comments?: string
  fibrosisLongHeadBicepsFemoris?: boolean
  fibrosisShortHeadBicepsFemoris?: boolean
  fibrosisSemitendinosus?: boolean
  fibrosisSemimembranosus?: boolean
  isThereATear?: string
  inWhichMuscle?: string
  proximalOrDistal?: string
  tearComplete?: string
  locusOfTear?: string
  signalChange?: string
  tearCranioCaudalLength?: string
  crossSectionalArea?: string
  longitudinalLengthOfTendonInvolvement?: string

  grade?: string

  // ACL Form Values
  aclImpression?: string
  aclReconstruction?: boolean
  lowImageQuality?: boolean 
}

export type HamstringFormProps = {
  saveClassificationForm: (hamstringFormValues: FormValues) => Promise<void>
  updateClassificationForm: (classificationFormId: string, currentVersion: number, formData: FormValues) => Promise<void>
  deleteClassificationForm: (classificationFormId: string, currentVersion: number) => Promise<void>
  displayAnnotation: (annotation: Annotation) => void
  currentUserId: string
  hasAnnotateAccess: boolean
  displayOnly: boolean
  current?: ClassificationForm
  onEditOthersForm: () => void
  annotations: Annotation[]
}

export const HamstringForm = ({
  saveClassificationForm, updateClassificationForm, deleteClassificationForm, displayAnnotation,
  currentUserId, hasAnnotateAccess, current, displayOnly, onEditOthersForm, annotations
}: HamstringFormProps) => {

  const [isEditing, setIsEditing] = useState<boolean>(current === undefined)
  const inputDisabled = displayOnly || !hasAnnotateAccess || !isEditing

  const currentRef = useRef<ClassificationForm | undefined>(current)
  currentRef.current = current

  return (
    <div>                     
      <Formik 
        enableReinitialize={true}
        initialValues={current?.formData || {
          comments: '',
          fibrosisLongHeadBicepsFemoris: false, 
          fibrosisShortHeadBicepsFemoris: false,
          fibrosisSemitendinosus: false,
          fibrosisSemimembranosus: false,
          isThereATear: '',
          inWhichMuscle: '',
          proximalOrDistal: '',
          tearComplete: '',
          locusOfTear: '',
          signalChange: '',
          tearCranioCaudalLength: '',
          crossSectionalArea: '',
          longitudinalLengthOfTendonInvolvement: ''
        }}
        onSubmit={(values) => {          
          setIsEditing(false)

          const grade = calculateGrade(values)         
           
          const filteredValues = filteredValuesForGrade(values, grade)                
          if(current !== undefined && current.createdBy === currentUserId) {
            updateClassificationForm(current.classificationFormId, current.classificationFormVersion, filteredValues)
          } else {
            saveClassificationForm(filteredValues).then(onEditOthersForm)
          }          
        }}
      >
        {({ values }) => {
          const grade = calculateGrade(values)

          return (<Form>          
            { grade !== undefined &&
              <Grade values={values} />  
            }            
            { grade === undefined &&
              <h2 style={{padding: '10px'}}>BAMIC form</h2>
            }

            <RadioGroupSection 
              description="Is there a tear?" 
              name="isThereATear" 
              values={[
                {id: "yes", display: "Yes"}, 
                {id: "no", display: "No"}]} 
              disabled={inputDisabled}
              includeOther={false}
              annotation={getLargestTearAnnotation(annotations, values)}
              displayAnnotation={displayAnnotation}
              radioOptionsWidth={100}
            />

            { values.isThereATear === "yes" &&
              <>
                <RadioGroupSection 
                  description="In which muscle is the tear?" 
                  name="inWhichMuscle" 
                  values={[
                    { id:"long_head_biceps_femoris", display: "Long head biceps femoris" },
                    { id: "short_head_biceps_femoris", display: "Short head biceps femoris" }, 
                    { id: "semitendinosus", display: "Semitendinosus" }, 
                    { id: "semimembranosus", display: "Semimembranosus" }
                  ]}
                  disabled={inputDisabled}
                  includeOther={false} 
                  radioOptionsWidth={250}
                />
                <RadioGroupSection 
                  description="Is it a proximal or distal tear?" 
                  name="proximalOrDistal" 
                  values={[
                    {id: "proximal", display: "Proximal"}, 
                    {id: "distal", display: "Distal"}]} 
                  disabled={inputDisabled}
                  includeOther={false} 
                  radioOptionsWidth={150}
                />
                <RadioGroupSection 
                  description="Is the tear complete?"  
                  name="tearComplete" 
                  values={[
                    {id: "yes", display: "Yes"}, 
                    {id: "no", display: "No"}]} 
                  disabled={inputDisabled}
                  includeOther={false} 
                  radioOptionsWidth={100}
                />
              </>
            }

            { values.isThereATear === "yes" && values.tearComplete === "yes" &&
              <RadioGroupSection 
                description="Locus of tear?" 
                name="locusOfTear" 
                values={[
                  {id: "muscle", display: "Muscle"}, 
                  {id: "tendon", display: "Tendon"}]} 
                disabled={inputDisabled}
                includeOther={false} 
                radioOptionsWidth={150}
              />
            }

            { values.isThereATear === "yes" && values.tearComplete === "no" &&
              <RadioGroupSection 
                description="Locus of tear?" 
                name="locusOfTear" 
                values={[
                  { id: "myofascial", display: "Myofascial" }, 
                  { id: "muscle_belly_mtj", display: "Muscle Belly (MTJ)" }, 
                  { id: "intra-tendinous", display: "Intra-tendinous" }
                ]} 
                disabled={inputDisabled}
                includeOther={false} 
                radioOptionsWidth={150}
              />
            }

            { values.isThereATear === "yes" && values.tearComplete === "no" && (values.locusOfTear === "myofascial" || values.locusOfTear === "muscle_belly_mtj") &&
              <>
                <RadioGroupSection 
                  description="High signal change into the muscle belly cross sectional area" 
                  name="signalChange" 
                  values={[
                    { id: "less_than_ten_percent", display: "< 10%" }, 
                    { id: "ten_to_fifty_percent", display: "> 10% and < 50%" }, 
                    { id: "over_fifty_percent", display: "> 50%" }
                  ]} 
                  disabled={inputDisabled}
                  includeOther={false} 
                  radioOptionsWidth={150}
                />
                <RadioGroupSection 
                  description="Tear cranio-caudal length" 
                  name="tearCranioCaudalLength" 
                  values={[
                    { id: "less_than_five_cm", display: "< 5cm" }, 
                    { id: "five_to_fifteen_cm", display: "> 5cm and < 15cm" }, 
                    { id: "over_fifteen_cm", display: "> 15cm" }
                  ]} 
                  disabled={inputDisabled}
                  includeOther={false}
                  radioOptionsWidth={150}
                />
              </>
            }

            { values.isThereATear === "yes" && values.tearComplete === "no" && values.locusOfTear === "intra-tendinous" &&
              <>
                <RadioGroupSection 
                  description="Cross sectional area of tendon involvement" 
                  name="crossSectionalArea" 
                  values={[
                    {id: "less_than_fifty_percent", display: "< 50%"}, 
                    {id: "over_fifty_percent", display: "> 50%"}
                  ]} 
                  disabled={inputDisabled} 
                  includeOther={false}
                  radioOptionsWidth={150}
                />
                <RadioGroupSection 
                  description="Longitudinal length of tendon involvement"
                  name="longitudinalLengthOfTendonInvolvement" 
                  values={[
                    {id: "less_than_five_cm", display: "< 5cm"}, 
                    {id: "over_five_cm", display: "> 5cm"}
                  ]} 
                  disabled={inputDisabled}
                  includeOther={false}
                  radioOptionsWidth={150}
                />
              </>
            }

            <FormSection>
              <SectionDescription>If fibrosis is present, which muscle(s) is it present in?</SectionDescription>
              <LabeledCheckBox name="fibrosisLongHeadBicepsFemoris" text="Long head biceps femoris"  disabled={inputDisabled} />
              <LabeledCheckBox name="fibrosisShortHeadBicepsFemoris" text="Short head biceps femoris" disabled={inputDisabled} />
              <LabeledCheckBox name="fibrosisSemitendinosus" text="Semitendinosus" disabled={inputDisabled} />
              <LabeledCheckBox name="fibrosisSemimembranosus" text="Semimembranosus" disabled={inputDisabled} />
            </FormSection>     

            <FormSection >
              <SectionDescription >Comments on MRI</SectionDescription>
              <FormTextArea name="comments" columns={33} rows={4} type="text" disabled={inputDisabled} dataCy="mri-comments-text-field" />
            </FormSection>                        

            { !displayOnly &&                        
            <SaveOrEditButton 
              inputDisabled={inputDisabled} 
              setIsEditing={setIsEditing} 
              hasAnnotateAccess={hasAnnotateAccess}
            />
            }

            { current !== undefined && current.createdBy === currentUserId &&
            <DeleteButton deleteForm={ () => {
              if(currentRef.current !== undefined){
                deleteClassificationForm(currentRef.current.classificationFormId, currentRef.current.classificationFormVersion)
              }
            }} />
            }
          </Form>)
        }}
      </Formik>
    </div>
  )
}

type GradeProps = {
  values: FormValues
}

const Grade = ({values}: GradeProps) => {
  const grade = calculateGrade(values)

  if(grade === undefined) {
    return <></>  
  } else {
    return (
      <div style={{fontSize: '15pt', margin: '10px'}}>      
        BAMIC Grade: {grade}        
      </div>
    )
  }
}

const calculateGrade: (values: FormValues) => string | undefined = (values) => {
  if(values.isThereATear === "no") {
    return "0"
  }

  if(values.isThereATear === "yes" && values.tearComplete === "no") {
    if(values.locusOfTear === "myofascial") { 
      if(values.signalChange === "over_fifty_percent" || values.tearCranioCaudalLength === "over_fifteen_cm") {
        return "3a"
      } else if(values.signalChange === "ten_to_fifty_percent" || values.tearCranioCaudalLength === "five_to_fifteen_cm") {
        return "2a"
      } else  if(values.signalChange === "less_than_ten_percent" || values.tearCranioCaudalLength === "less_than_five_cm") {
        return "1a"
      }
    } 

    if(values.locusOfTear === "muscle_belly_mtj") { 
      if(values.signalChange === "over_fifty_percent" || values.tearCranioCaudalLength === "over_fifteen_cm") {
        return "3b"
      } else if(values.signalChange === "ten_to_fifty_percent" || values.tearCranioCaudalLength === "five_to_fifteen_cm") {
        return "2b"
      } else if(values.signalChange === "less_than_ten_percent" || values.tearCranioCaudalLength === "less_than_five_cm") {
        return "1b"
      }
    } 

    if(values.locusOfTear === "intra-tendinous") { 
      if(values.crossSectionalArea === "over_fifty_percent" || values.longitudinalLengthOfTendonInvolvement === "over_five_cm") {
        return "3c"
      } else {
        return "2c"
      }
    }
  }

  if(values.isThereATear === "yes" && values.tearComplete === "yes" && values.locusOfTear === "muscle") {
    return "4"
  }
  if(values.isThereATear === "yes" && values.tearComplete === "yes" && values.locusOfTear === "tendon") {
    return "4c"
  }

  return undefined
}

const filteredValuesForGrade: (values: FormValues, grade: string | undefined) => FormValues = (values, grade) => {
  if(grade === undefined) {
    return values
  }

  const pickedValues: FormValues = (() => {
    switch(grade) {
      case "0":
        return _.pick(values, 
          'isThereATear', 'comments', 
          'fibrosisLongHeadBicepsFemoris', 'fibrosisShortHeadBicepsFemoris', 'fibrosisSemitendinosus', 'fibrosisSemimembranosus')
  
      case "1a":
      case "2a":
      case "3a":
      case "1b":
      case "2b":
      case "3b":
        return _.pick(values, 
          'isThereATear', 'comments',
          'fibrosisLongHeadBicepsFemoris', 'fibrosisShortHeadBicepsFemoris', 'fibrosisSemitendinosus', 'fibrosisSemimembranosus', 
          'inWhichMuscle',
          'proximalOrDistal',
          'tearComplete', 
          'locusOfTear', 
          'signalChange', 'tearCranioCaudalLength')

      case "2c":
      case "3c":
        return _.pick(values, 
          'isThereATear', 'comments', 
          'fibrosisLongHeadBicepsFemoris', 'fibrosisShortHeadBicepsFemoris', 'fibrosisSemitendinosus', 'fibrosisSemimembranosus', 
          'inWhichMuscle',
          'proximalOrDistal',
          'tearComplete', 
          'locusOfTear', 
          'crossSectionalArea', 'longitudinalLengthOfTendonInvolvement')

      case "4":
      case "4c":
        return _.pick(values, 
          'isThereATear', 'comments', 
          'fibrosisLongHeadBicepsFemoris', 'fibrosisShortHeadBicepsFemoris', 'fibrosisSemitendinosus', 'fibrosisSemimembranosus', 
          'inWhichMuscle',
          'proximalOrDistal',
          'tearComplete', 
          'locusOfTear')

      default: 
        throw new Error(`Non-exhaustive match on possible grades. Grade: ${grade}`)
    }
  })()

  return {
    ...pickedValues,
    grade
  }
}


export const getLargestTearAnnotation: (annotations: Annotation[], values: FormValues) => Annotation | undefined = (annotations, values) => {
  const tears: Annotation[] = annotations.filter(a => values.isThereATear === "yes" && a.label.toLowerCase().indexOf('tear') !== -1)

  return _.maxBy(tears, tear => tear.derivedData?.area)
}
