import _ from 'lodash'

import { InlineEdit } from "app/components/InLineEdit"
import { Annotation } from "annotation/model/Annotation"
import { Scan } from "uploads/api/Model"
import { Dataset } from "uploads/model/Dataset"
import { ExtractedImage } from "uploads/store/Model"
import { capLengthIfNeeded } from 'utils/TextUtils'
import { getSeriesDisplayName } from 'uploads/util/SeriesDisplayName'
import { useState } from 'react'

export type CaseSeriesTableProps = {
  images?: ExtractedImage[]
  scan: Scan
  annotations?: Annotation[]
  hasWriteAccess: boolean
  setSeriesDisplayName: (seriesDescription: string, displayName: string) => void
} 

export const CaseSeriesTable = ({ scan, images, annotations, hasWriteAccess, setSeriesDisplayName }: CaseSeriesTableProps) => {

  const [isExpanded, setIsExpanded] = useState<boolean>(false)
 
  return (
    <div>
      <h2 onClick={() => setIsExpanded(e => !e)} style={{cursor: 'pointer'}} data-cy='expand-series-details' >Series Info</h2>

      { isExpanded && 
      <table className="table table-bordered table-striped">
        <thead>
          <tr>
            <th style={{ width: '100px' }} scope="col">Series Id</th>
            <th style={{ width: '300px' }} scope="col">Series Name</th>
            <th style={{ width: '300px' }} scope="col">Display Name</th>
            <th style={{ width: '100px' }} scope="col"># Files</th>
            <th style={{ width: '120px' }} scope="col"># Annotations</th>
          </tr>
        </thead>
        { images !== undefined && 
          <TableBody scan={scan} images={images} annotations={annotations} hasWriteAccess={hasWriteAccess} setSeriesDisplayName={setSeriesDisplayName} />
        }
      </table>
      }
      </div>          
  )
}

type TableBodyProps = {
  scan: Scan
  dataset?: Dataset
  annotations?: Annotation[]
  images: ExtractedImage[]
  hasWriteAccess: boolean
  setSeriesDisplayName: (seriesDescription: string, displayName: string) => void
}

const TableBody = ({scan, dataset, images, annotations, hasWriteAccess, setSeriesDisplayName}: TableBodyProps) => {
  const rows = Object.entries(_.groupBy(images, (i: ExtractedImage) => i.SeriesInstanceUID))

  return (
    <tbody>
      { _.orderBy(rows, row => row[0]).map(([seriesId, images]) =>       
        <SeriesRow 
          key={seriesId} 
          seriesId={seriesId}
          images={images.filter(i => i.SeriesInstanceUID === seriesId)} 
          dataset={dataset}
          scan={scan} 
          hasWriteAccess={hasWriteAccess}
          setSeriesDisplayName={setSeriesDisplayName}
          annotations={annotations}
        />
      )}            
    </tbody>
  )
}

type SeriesRowProps = {
  seriesId: string
  images: ExtractedImage[]
  annotations?: Annotation[]
  dataset: Dataset | undefined
  scan: Scan
  hasWriteAccess: boolean
  setSeriesDisplayName: (seriesDescription: string, seriesDisplayName: string) => void
}

const SeriesRow = ({
  seriesId, images, annotations, scan, dataset, hasWriteAccess,
  setSeriesDisplayName
}: SeriesRowProps) => {
  const filesCount = images.length
  const seriesIds = [...new Set(images.map(i => i.SeriesInstanceUID))]
  const annotationsCount = annotations?.filter(a => seriesIds.indexOf(a.SeriesInstanceUID) !== -1).length

  const seriesDescription = getSeriesDisplayName(seriesId, images[0]?.SeriesDescription, scan, dataset)

  return(
    <tr>
      <td title={seriesId}>{capLengthIfNeeded(seriesId, 10)}</td>            
      <td>{images[0]?.SeriesDescription}</td>            
      <td>
        { hasWriteAccess &&
          <InlineEdit width={240} value={seriesDescription} setValue={(value) => setSeriesDisplayName(seriesId, value)} disabled={!hasWriteAccess}  />
        }
        { !hasWriteAccess &&
          seriesDescription
        }
      </td>            
      <td>{filesCount}</td>            
      <td>{annotationsCount}</td>            
    </tr>
  )
}
