import { Link } from 'react-router-dom'
import { ScanListingEntry } from 'uploads/api/Model'
import _ from 'lodash'
import { ReportsApi } from 'reports/api/ReportsApi'
import { connect } from 'react-redux'
import { downloadReportOutput } from 'reports/api/ReportsSlice'
import { RootState } from 'app/store'
import { Dataset } from 'uploads/model/Dataset'
import { AnnotationMode } from 'uploads/model/AnnotationMode'
import { parseStudyDate } from 'utils/DateUtils'

export type DatasetPatientsTableProps = {
  dataset: Dataset
  scans: ScanListingEntry[]
  showExperimentalUi: boolean
  downloadReportOutput: (reportId: string, outputId: string) => void
}

export const DatasetPatientsTable = ({
  dataset, scans, showExperimentalUi,
  downloadReportOutput
}: DatasetPatientsTableProps) => {

  const patientIds: string[] = _.orderBy([... new Set(scans.map(s => s.scanInfo?.patientId).filter(i => i !== undefined))], i => i) as string[]

  return(
    <table className="table table-bordered table-striped" style={{maxWidth: '800px'}}>
      <thead>
        <tr>
          <th style={{ width: '100px' }} scope="col">Player ID</th>                      
          <th style={{ width: '100px' }} scope="col">Report</th>
          <th style={{ width: '140px' }} scope="col">Most recent scan date</th>
          <th style={{ width: '110px' }} scope="col">Annotations</th>
          <th style={{ width: '60px' }} scope="col">Forms</th>           
          <th style={{ width: '130px' }} scope="col">{dataset.datasetInfo.annotationMode === AnnotationMode.ACL ? 'ACL Impression' : 'BAMIC Grade' }</th>                       
        </tr>
      </thead>
      <tbody>            
        { patientIds.map(patientId => 
          <PlayerRow 
            patientId={patientId}
            dataset={dataset}
            key={patientId}               
            scans={scans.filter(s => s.scanInfo?.patientId === patientId)} 
            downloadReportOutput={downloadReportOutput} 
          />
        )}
      </tbody>
    </table>    
  )
}

type PlayerRowProps = {
  patientId: string
  dataset: Dataset
  scans: ScanListingEntry[]
  downloadReportOutput: (reportId: string, outputId: string) => void
}

const PlayerRow = ({
  patientId, dataset,
  scans,
  downloadReportOutput
}: PlayerRowProps) => {

  const reportsApi = new ReportsApi(window.RuntimeConfig.backend)

  const scan = _.orderBy(scans, s => parseStudyDate(s.studyDate), 'desc')[0]

  const report = _.orderBy(scan.reportOutputs, o => o.createdDate, 'desc')[0]

  const onDownloadClick = (e: { preventDefault: () => void }) => {
    e.preventDefault()
    if(report !== undefined) {
      downloadReportOutput(report.reportId, report.outputId)
    }    
  }

  const datasetCaseInfo = dataset.aggregateInfo.caseInfos.find(i => i.caseId == scan.scanId)

  const classifications = datasetCaseInfo?.classifications.map((classification) => {
    if(classification === 'intact'){
      return 'Intact'
    } else if(classification === 'torn') {
      return 'Torn'
    } else {
      return classification
    }
  })


  return (
    <tr>
      <td title={`Player ${patientId}`}><Link title={patientId} to={`/datasets/${dataset.datasetId}/patient/${patientId}`}>{patientId}</Link> </td>  
      { report !== undefined && 
        <td><a href={reportsApi.reportOutputUrl(report.reportId, report.outputId)} onClick={onDownloadClick} >Available</a></td>              
      }

      { report === undefined &&
        <td>Pending</td>              
      } 
      <td>{scan.studyDate}</td>       
      <td>{datasetCaseInfo?.annotationsCount}</td>       
      <td>{datasetCaseInfo?.formsCount}</td>    
      <td>{classifications?.join(', ')}</td>          
    </tr>
  )
}

const mapStateToProps = (state: RootState, {  }) => {
  return {
    
  }
}

const mapDispatchToProps = { 
  downloadReportOutput,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DatasetPatientsTable)
