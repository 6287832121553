import axios from 'axios'
import retry from 'async-retry'

import { currentAuthHeaders } from 'auth/KeyCloak' 
import { BackendConfig } from 'config/ApplicationConfig'

export type ReportMetaData = {
  reportId: string,
  scanId: string,
  createdBy: string,
  createdDate: string
}

export interface ListReportsResponse {
  reports: ReportMetaData[]
}

export class ReportsApi {
  
  reportsUrl: string
  reportUrl: (reportId: string) => string
  reportOutputUrl: (reportId: string, outputId: string) => string
  retryCount: number

  constructor(config: BackendConfig) {
    this.retryCount = config.retryCount
    this.reportsUrl = `${config.url}/eliteai/reports`
    this.reportUrl = (reportId) => `${this.reportsUrl}/${reportId}`
    this.reportOutputUrl = (reportId, outputId) => `${this.reportUrl(reportId)}/outputs/${outputId}`
  }

  async requestReport(scanId: string, selectedUserId: string | undefined, correlationId: string) {
    return retry(async (bail) => {
      const headers = await currentAuthHeaders(correlationId)

      const response = await axios.post<ReportMetaData>(this.reportsUrl, { scanId, selectedUserId }, { headers })

      return response.data
    }, {
      retries: this.retryCount
    })    
  }

  async listReports(correlationId: string) {
    return retry(async (bail) => {
      const headers = await currentAuthHeaders(correlationId)

      const response = await axios.get(this.reportsUrl, { headers })

      return response.data
    }, {
      retries: this.retryCount
    })    
  }
}
