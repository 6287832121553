import Select from 'react-select'
import _, { isFinite, isNumber } from 'lodash'
import CreatableSelect from 'react-select/creatable'


import { DatasetAggregateCaseInfo, DatasetAmsData } from "uploads/model/Dataset"
import { useMemo, useRef, useState } from 'react'
import { Chart } from './Chart'
import { ScanListingEntry } from 'uploads/api/Model'
import { filterAmsRows, getGradedInjuries, GradedInjury } from 'app/model/GradedInjury'
import { nanoid } from 'nanoid'
import { LabelledCheckBox } from './LabelledCheckBox'
import { ReactSelectDeleteX } from './icons/ReactSelectDeleteX'
import { DatasetAmsDataTable } from 'datasets/components/DatasetAmsDataTable'

export type RTPCalculatorProps = {
  amsData: DatasetAmsData
  cases: ScanListingEntry[]
  caseInfos: DatasetAggregateCaseInfo[]
}

type RtpCriteriaFilter = {
  id: string
  column?: string
  filterType: RtpFilterType
  values?: string[]
  min?: string
  max?: string
}

type GradeCriteriaFilter = {
  column?: string
  filterType: RtpFilterType
  values?: string[]
  min?: string
  max?: string
}

const newCriteria: () => RtpCriteriaFilter = () => ({ id: nanoid(), filterType: RtpFilterType.EqualTo})

export enum RtpFilterType {
  Contains = "contains",
  EqualTo = "equal to",
  Between = "between"
}

const applyFilters: (
  amsData: DatasetAmsData, 
  filters: RtpCriteriaFilter[], 
  gradeFilter: GradeCriteriaFilter, 
  useEliteAiGrading: boolean,
  gradingColumn?: string 
) => any[] = (amsData, filters, gradeFilter, useEliteAiGrading, gradingColumn) => 
  amsData.rows.filter( row => 
    !filters.map(filter => passes(row, filter.column, filter.filterType, filter.values, filter.min, filter.max)).some(passes => !passes) && 
      gradePasses(row, useEliteAiGrading, gradingColumn, gradeFilter)
  )

const gradePasses: (
  row: any, 
  useEliteAiGrading: boolean,
  gradingColumn: string | undefined,
  gradeFilter: GradeCriteriaFilter
) => boolean = (row, useEliteAiGrading, gradingColumn, gradeFilter) => {
  if(useEliteAiGrading) {
    return true
  } else {
    return passes(row, gradingColumn, gradeFilter.filterType, gradeFilter.values, gradeFilter.min, gradeFilter.max)
  }
}

const passes: (
  row: any, 
  column: string | undefined,
  filterType: RtpFilterType,
  values?: string[],
  min?: string,
  max?: string
) => boolean = (row, column, filterType, values, min, max) => {
  switch(filterType) {
    case RtpFilterType.EqualTo:
      if(values === undefined || 
        values.length === 0 || 
        column === undefined) {
        return true
      } else {
        const cell = row[column]

        return cell !== undefined && values.includes(cell)
      }

    case RtpFilterType.Contains:
      if(values === undefined || 
        values.length === 0 || 
        column === undefined) {
        return true
      } else {
        const cell = row[column]

        return cell !== undefined && values.findIndex(v => cell.includes(v)) !== -1
      }

    case RtpFilterType.Between:
      if(column === undefined) {
        return true
      } else {
        const cell = row[column]
        
        const satisfiesMin = min === undefined || Number.parseInt(min, 10) <= Number.parseInt(cell, 10)
        const satisfiesMax = max === undefined || Number.parseInt(max, 10) >= Number.parseInt(cell, 10)

        return satisfiesMin && satisfiesMax
      }
  } 
}

export const RTPCalculator = ({
  amsData, cases, caseInfos
}: RTPCalculatorProps) => {  

  const [useEliteAiGrading, setUseEliteAiGrading] = useState<boolean>(true)
  const [groupByInjuryGrade, setGroupByInjuryGrade] = useState<boolean>(true)
  const [groupByColumn, setGroupByColumn] = useState<string | undefined>(undefined)

  const [rtpCriteriaFilters, setRtpCriteriaFilters] = useState<RtpCriteriaFilter[]>([newCriteria()])
  const rtpCriteriaFiltersRef = useRef<RtpCriteriaFilter[]>(rtpCriteriaFilters)
  rtpCriteriaFiltersRef.current = rtpCriteriaFilters

  const [gradeFilter, setGradeFilter] = useState<GradeCriteriaFilter>({filterType: RtpFilterType.EqualTo})

  const [daysInjuredColumn, setDaysInjuredColumn] = useState<string | undefined>(
    amsData.header['Days Injured'] !== undefined ? 'Days Injured' : undefined)
  const [gradingColumn, setGradingColumn] = useState<string | undefined>(
    amsData.header['Injury Grade'] !== undefined ? 'Injury Grade' : undefined)

  const validRows = useMemo(
    () => applyFilters(amsData, rtpCriteriaFilters, gradeFilter, useEliteAiGrading, gradingColumn), 
    [amsData.rows.length, rtpCriteriaFilters, gradeFilter, useEliteAiGrading, gradingColumn])

  const gradedInjuries: GradedInjury[] = useMemo(
    () => getGradedInjuries(cases, validRows, caseInfos, daysInjuredColumn || 'Days Injured'), 
    [cases.length, validRows.length, caseInfos.length, daysInjuredColumn])
    
  const filteredGradedInjuries = useMemo(() => {
    if(!useEliteAiGrading) {
      return gradedInjuries
    } else {
      const values = gradeFilter.values

      switch(gradeFilter.filterType) {
        case RtpFilterType.EqualTo:
          if(values === undefined || values.length === 0) {
            return gradedInjuries
          } else {
            return gradedInjuries.filter(i => values.some(v => i.grade.includes(v)))
          }
    
        case RtpFilterType.Contains:
          if(values === undefined || values.length === 0) {
            return gradedInjuries
          } else {
            return gradedInjuries.filter(i => values.findIndex(v => i.grade.includes(v)) !== -1)
          }
    
        case RtpFilterType.Between:
          return []                  
      } 
    }
  }, [gradedInjuries.length, useEliteAiGrading, gradeFilter])

  return (
    <div style={{display: 'flex'}}>
      <div className="rtp-calculator" style={{ border: '1px solid lightgrey', width: '1020px', padding: '10px'}}>
        <div className="rtp-controls" style={{ width: '1020px' }}>
          <div className='days-injured-column-selector' style={{display: 'flex', margin: '3px'}}>
            <div style={{width: '150px', marginTop: '7px', marginLeft: '5px'}}>
              Days injured column:
            </div>
            <div style={{width: '220px'}}>
              <Select
                options={amsData.header.map(h => ({ value: h, label: h }) )} 
                placeholder={`Select column`}         
                isClearable   
                value={daysInjuredColumn === undefined ? undefined : { value: daysInjuredColumn, label: daysInjuredColumn }} 
                onChange={(o) => setDaysInjuredColumn(o === null ? undefined : o.value)}
              />
            </div>
          </div>

          <div className='use-ei-grading-selector' style={{display: 'flex', margin: '3px'}}>
            <div style={{marginTop: '9px', marginLeft: '5px', width: '200px'}}>
              <LabelledCheckBox 
                id={'elite-ai-injury-grading'} 
                label={'Use Elite AI injury grading'} 
                checked={useEliteAiGrading} 
                labelFirst={true}
                width={190}
                onChange={() => setUseEliteAiGrading(checked => !checked)} 
              />
            </div>          

            { !useEliteAiGrading &&
              <div style={{display: 'flex'}}>
                <div style={{marginTop: '9px'}}>Grading column: </div>
                <div style={{width: '170px', marginLeft: '5px'}}>
                  <Select
                    options={amsData.header.map(h => ({ value: h, label: h }) )} 
                    placeholder={`Select column`}         
                    isClearable   
                    value={gradingColumn === undefined ? undefined : { value: gradingColumn, label: gradingColumn }} 
                    onChange={(o) => setGradingColumn(o === null ? undefined : o.value)}
                  />
                </div>
              </div>            
            }        
          </div>

          <div className='grade-filter-selector' style={{marginTop: '10px'}}>
            <GradeFilter
              amsData={amsData}
              criteria={gradeFilter}
              updateCriteria={setGradeFilter}
              useEliteAiGrading={useEliteAiGrading}
              gradingColumn={gradingColumn}
              gradedInjuries={gradedInjuries}
            />
            
          </div>

          <div className='injury-filters' style={{marginTop: '10px'}}>
            <div style={{marginLeft: '8px', marginBottom: '3px'}}>
              Filter injuries:
            </div>
            { rtpCriteriaFilters.map((rtpCriteriaFilter, index) =>
              <ColumnFilter
                key={rtpCriteriaFilter.id}
                header={amsData.header}
                rows={gradedInjuries.map(i => i.row)}
                criteria={rtpCriteriaFilter}
                updateCriteria={(criteria) => {
                  const copy = [...rtpCriteriaFiltersRef.current ]
                  copy[index] = criteria
                  setRtpCriteriaFilters(copy)
                }}
                removeCriteria={() => {
                  setRtpCriteriaFilters(rtpCriteriaFiltersRef.current.filter(c => c.id !== rtpCriteriaFilter.id))
                }}
              />
            )}
          </div>

          <div className='add-filter' style={{margin: '3px'}}>
            <button className='btn btn-primary' onClick={() => setRtpCriteriaFilters([...rtpCriteriaFiltersRef.current, newCriteria() ])}>+ Filter</button>
          </div>              

          <div className='group-by-grade-selector' style={{display: 'flex', margin: '3px'}}>
            <div style={{marginTop: '9px', marginLeft: '5px', width: '190px'}}>
              <LabelledCheckBox 
                id={'group-by-injury-grading'} 
                label={'Group by injury grade'} 
                checked={groupByInjuryGrade} 
                labelFirst={true}
                width={180}
                onChange={() => setGroupByInjuryGrade(checked => !checked)} 
              />
            </div>          

            { !groupByInjuryGrade &&
              <div style={{display: 'flex'}}>
                <div style={{marginTop: '9px'}}>Group by: </div>
                <div style={{width: '170px', marginLeft: '5px'}}>
                  <Select
                    options={amsData.header.map(h => ({ value: h, label: h }) )} 
                    placeholder={`Select column`}         
                    isClearable   
                    value={groupByColumn === undefined ? undefined : { value: groupByColumn, label: groupByColumn }} 
                    onChange={(o) => setGroupByColumn(o === null ? undefined : o.value)}
                  />
                </div>
              </div>            
            }        
          </div>
        </div>

        <Chart 
          gradedInjuries={filteredGradedInjuries} 
          useEliteAiGrading={useEliteAiGrading}
          groupByInjuryGrade={groupByInjuryGrade}
          daysInjuredColumn={daysInjuredColumn}
          groupByColumn={groupByColumn}
          gradingColumn={gradingColumn}
        />         
      </div>
      <div style={{ marginTop: '-60px', marginLeft: '20px'}}>
        <h4>Athlete Data</h4>
        <DatasetAmsDataTable header={amsData.header} rows={filteredGradedInjuries.map(i => i.row)} />  
      </div>   
    </div>    
  )
}

type ColumnFilterProps = {
  header: any
  rows: string[][]
  criteria: RtpCriteriaFilter
  updateCriteria: (criteria: RtpCriteriaFilter) => void
  removeCriteria: () => void
}

const ColumnFilter = ({
  header, rows, criteria,
  updateCriteria, removeCriteria
}: ColumnFilterProps) => {

  const values = useMemo(() => {
    const column = criteria?.column
    if(column === undefined) return undefined 
    else return _.sortBy([... new Set(rows.map(row => row[column]))], e => e)
  }, [criteria?.column, rows.length])
  
  return (
    <div style={{display: 'flex'}}>
      <div style={{width: '180px', margin: '3px'}}>
        <Select 
          options={header.map(h => ({ value: h, label: h }) )} 
          placeholder={`Select column`}         
          isClearable   
          value={criteria?.column === undefined ? undefined : { value: criteria.column, label: criteria.column }} 
          onChange={(o) => updateCriteria({...criteria, column: o === null ? undefined : o.value})}
        />
      </div>
      <div style={{width: '110px', margin: '3px'}}>        
        <Select
          options={[RtpFilterType.Between, RtpFilterType.Contains, RtpFilterType.EqualTo].map(h => ({ value: h, label: h }) )} 
          placeholder={`Filter`}                      
          value={criteria.filterType === undefined ? undefined : {value: criteria.filterType, label: criteria.filterType}} 
          onChange={(o) => { if (o !== null) updateCriteria({...criteria, filterType: o.value})}}
        /> 
      </div>
      { criteria.filterType === RtpFilterType.Between &&
      <>
      <div style={{width: '110px', margin: '3px'}}>
        <Select 
          options={values?.map(n => ({ value: n, label: n }) )} 
          placeholder={`Min`}         
          isClearable   
          value={criteria.min === undefined ? undefined : { value: criteria.min, label: criteria.min }} 
          onChange={(o) => updateCriteria({...criteria, min: o === null ? undefined : o.value})}
        /> 
      </div>
      <div style={{width: '110px', margin: '3px'}}>
        <Select 
          options={values?.map(n => ({ value: n, label: n }) )} 
          placeholder={`Max`}
          isClearable
          value={criteria.max === undefined ? undefined : { value: criteria.max, label: criteria.max }} 
          onChange={(o) => updateCriteria({...criteria, max: o === null ? undefined : o.value})}
        /> 
      </div>        
      </>
      }
      { criteria.filterType === RtpFilterType.EqualTo &&
      <div style={{width: '206px', margin: '3px'}}>
        <Select 
          options={values?.map(value => ({ value, label: value }) )} 
          placeholder="Select values" 
          isClearable
          isMulti
          value={criteria.values?.map(value => ({ value, label: value }))} 
          onChange={(options) => updateCriteria({...criteria, values: options.map(o => o.value)})}
        />  
      </div>     
      } 
      { criteria.filterType === RtpFilterType.Contains &&
      <div style={{width: '206px', margin: '3px'}}>
        <CreatableSelect 
          options={values?.map(value => ({ value, label: value }) )} 
          placeholder="Select values" 
          isClearable
          isMulti
          value={criteria.values?.map(value => ({ value, label: value }))} 
          onChange={(options) => updateCriteria({...criteria, values: options.map(o => o.value)})}
          formatCreateLabel={(inputValue: string) => `Contains "${inputValue}"`}
        />  
      </div>     
      } 
      <div style={{marginTop: '10px', marginLeft: '1px'}}>
        <ReactSelectDeleteX onClick={removeCriteria} />
      </div>      
    </div>
  )
}


type GradeFilterProps = {
  amsData: DatasetAmsData
  criteria: GradeCriteriaFilter
  gradingColumn?: string
  useEliteAiGrading: boolean
  gradedInjuries: GradedInjury[]
  updateCriteria: (criteria: GradeCriteriaFilter) => void
}

const GradeFilter = ({
  amsData, criteria, gradingColumn, useEliteAiGrading, gradedInjuries,
  updateCriteria
}: GradeFilterProps) => {

  const values = useMemo(() => {
    if(useEliteAiGrading) {
      return _.sortBy([... new Set(gradedInjuries.map(g => g.grade))], g => g)
    } else {      
      if(gradingColumn === undefined) return undefined 
      else return _.sortBy([... new Set(amsData.rows.map(row => row[gradingColumn]))], e => e)
    }    
  }, [criteria?.column, amsData.rows.length, useEliteAiGrading, gradedInjuries.length])
  
  return (
    <div style={{display: 'flex'}}>
      <div style={{width: '90px', marginTop: '10px', marginLeft: '8px'}}>
        Filter grades:
      </div>
      <div style={{width: '110px', margin: '3px'}}>        
        <Select
          options={[RtpFilterType.Between, RtpFilterType.Contains, RtpFilterType.EqualTo].map(h => ({ value: h, label: h }) )} 
          placeholder={`Filter`}                      
          value={criteria.filterType === undefined ? undefined : {value: criteria.filterType, label: criteria.filterType}} 
          onChange={(o) => { if(o !== null) updateCriteria({...criteria, filterType: o.value})}}
        /> 
      </div>
      { criteria.filterType === RtpFilterType.Between &&
      <>
      <div style={{width: '110px', margin: '3px'}}>
        <Select 
          options={values?.map(n => ({ value: n, label: n }) )} 
          placeholder={`Min`}         
          isClearable   
          value={criteria.min === undefined ? undefined : { value: criteria.min, label: criteria.min }} 
          onChange={(o) => updateCriteria({...criteria, min: o === null ? undefined : o.value})}
        /> 
      </div>
      <div style={{width: '110px', margin: '3px'}}>
        <Select 
          options={values?.map(n => ({ value: n, label: n }) )} 
          placeholder={`Max`}
          isClearable
          value={criteria.max === undefined ? undefined : { value: criteria.max, label: criteria.max }} 
          onChange={(o) => updateCriteria({...criteria, max: o === null ? undefined : o.value})}
        /> 
      </div>        
      </>
      }
      { criteria.filterType === RtpFilterType.EqualTo &&
      <div style={{width: '206px', margin: '3px'}}>
        <Select 
          options={values?.map(value => ({ value, label: value }) )} 
          placeholder="Values" 
          isClearable
          isMulti
          value={criteria.values?.map(value => ({ value, label: value }))} 
          onChange={(options) => updateCriteria({...criteria, values: options.map(o => o.value)})}
        />  
      </div>     
      } 
      { criteria.filterType === RtpFilterType.Contains &&
      <div style={{width: '206px', margin: '3px'}}>
        <CreatableSelect 
          options={values?.map(value => ({ value, label: value }) )} 
          placeholder="values" 
          isClearable
          isMulti
          value={criteria.values?.map(value => ({ value, label: value }))} 
          onChange={(options) => updateCriteria({...criteria, values: options.map(o => o.value)})}
          formatCreateLabel={(inputValue: string) => `Contains "${inputValue}"`}
        />  
      </div>     
      }    
    </div>
  )
}
