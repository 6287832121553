import { nanoid } from 'nanoid'

export type ScanCreatorProps = {
  color: string
  border: string
  createScan: (scanId: string) => void
  navigateToScan: (scanId: string) => void
}

export function ScanCreator({
  color, border,
  createScan, navigateToScan
}: ScanCreatorProps){

	const handleSubmit = async (e) => {    
    e.preventDefault()
    
    const scanId = `sc-${nanoid()}`
    createScan(scanId)
    navigateToScan(scanId)
	}

	return(
    <div 
      style={{
        padding: '8px',
        width: 'fit-content',
        borderRadius: '10px',
        border,
        backgroundColor: color, 
        color: 'white',
        cursor: 'pointer',
        boxShadow: '0px 5px 7px 0px rgb(55, 68, 124)'
      }} 
      onClick={handleSubmit}
      data-cy="create-new-case"
    >Upload New Case</div>
  )
}
