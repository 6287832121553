import Select from 'react-select'

import { SelectOption } from "utils/SelectOption"
import { LoadingSpinner } from 'app/components/LoadingSpinner'
import { getUserName, User, userName } from 'auth/model/User'

export type MultiUserSelectorProps = {  
  users?: User[]
  selectedUserIds?: string[]
  disabled?: boolean
  setSelectedUserIds: (userId: string[]) => void
}

export const MultiUserSelector = ({
  users, selectedUserIds, disabled,
  setSelectedUserIds
}: MultiUserSelectorProps) => {
  const options: SelectOption<string>[] = (users || []).map((user) => { return {value: user.id, label: userName(user) }})
  
  const current = (selectedUserIds || []).map((selectedUserId) => (
    { value: selectedUserId, label: getUserName(users, selectedUserId) }
  )) 

  if(users === undefined){
    return <LoadingSpinner />
  } else {
    return (
      <div 
        className="user-selector"
        style={{ display: 'flex', marginRight: '10px' }}
      >
        <div style={{ width: '250px' }}>
          <Select 
            classNamePrefix="react-select-user-selector"
            options={options} 
            isMulti
            isDisabled={disabled}
            placeholder="Select users" 
            value={current} 
            onChange={(options) => setSelectedUserIds(options.map(o => o.value))}
          /> 
        </div>
        
      </div>
    )
  }
}
