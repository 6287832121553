import { Annotation } from "./Annotation"
import { Point, pointsDistance } from "./Point"


export enum AnnotationShapeType {
  Box = "Box",
  Line = "Line",
  FreeHand = "FreeHand",
  Circle = "Circle",
  Oval = "Oval",
  Point = "Point",
  OpenPolygon = "Open Polygon",
  ClosedPolygon = "Closed Polygon",
  WholeImage = "Whole Image"
}


export const isSingularShape: (shape: AnnotationShapeType) => boolean = (shape) => {
  return shape === AnnotationShapeType.OpenPolygon ||
    shape === AnnotationShapeType.ClosedPolygon
}


export type AnnotationShape = BoxAnnotation | LineAnnotation | FreeHandAnnotation | CircleAnnotation | 
  OvalAnnotation | PointAnnotation | OpenPolygonAnnotation | ClosedPolygonAnnotation | WholeImageAnnotation

export type BoxAnnotation = {
  type: AnnotationShapeType.Box
  topLeft: Point
  bottomRight: Point
}

export type LineAnnotation = {
  type: AnnotationShapeType.Line
  topLeft: Point
  bottomRight: Point
}

export type FreeHandAnnotation = {
  type: AnnotationShapeType.FreeHand
  points: Point[]
}

export type CircleAnnotation = {
  type: AnnotationShapeType.Circle
  centre: Point
  radius: number
}

export type OvalAnnotation = {
  type: AnnotationShapeType.Oval
  topLeft: Point
  bottomRight: Point
}

export type PointAnnotation = {
  type: AnnotationShapeType.Point
  point: Point
}

export type WholeImageAnnotation = {
  type: AnnotationShapeType.WholeImage
}

export type OpenPolygonAnnotation = {
  type: AnnotationShapeType.OpenPolygon
  points: Point[]
}

export type ClosedPolygonAnnotation = {
  type: AnnotationShapeType.ClosedPolygon
  points: Point[]
}


export const shapeDescription: (annotation: Annotation) => string[] = (annotation) => {  
  let strings: string[] = []

  if(annotation.derivedData?.area !== undefined) strings.push(` Area: ${describeArea(annotation.derivedData.area)}`)
  if(annotation.derivedData?.length !== undefined) strings.push(` Length: ${describeLength(annotation.derivedData.length)}`)
  if(annotation.derivedData?.distanceToTJunction !== undefined) strings.push(` Distance to T-Junction: ${describeLength(annotation.derivedData.distanceToTJunction)}`)

  return strings
}

export const describeLength: (n: number) => string = (n) => {
  return `${Math.round(n) / 10} cm`  
}

export const describeArea: (n: number) => string = (n) => {
  return `${Math.round(n) / 100} cm²`  
}

export const findCentrePoint: (shape: AnnotationShape) => Point = (shape) => {
  const centreOfPoints: (points: Point[]) => Point = (points) => {
    const maxX = Math.max(...points.map(p => p.x))
    const minX = Math.min(...points.map(p => p.x))
    const maxY = Math.max(...points.map(p => p.y))
    const minY = Math.min(...points.map(p => p.y))
      
    return {
      x: minX + ((maxX - minX) / 2),
      y: minY + ((maxY - minY) / 2)
    }          
  }


  switch (shape.type) {
    case AnnotationShapeType.Box:
      return {
        x: shape.bottomRight.x + ((shape.topLeft.x - shape.bottomRight.x) / 2),
        y: shape.bottomRight.y + ((shape.topLeft.y - shape.bottomRight.y) / 2)
      }            
    case AnnotationShapeType.Line:
      return {
        x: shape.bottomRight.x + ((shape.topLeft.x - shape.bottomRight.x) / 2),
        y: shape.bottomRight.y + ((shape.topLeft.y - shape.bottomRight.y) / 2)
      } 
    case AnnotationShapeType.FreeHand:
      return centreOfPoints(shape.points)
         
    case AnnotationShapeType.Circle:
      return shape.centre      
    case AnnotationShapeType.Oval:
      return {
        x: shape.bottomRight.x + ((shape.topLeft.x - shape.bottomRight.x) / 2),
        y: shape.bottomRight.y + ((shape.topLeft.y - shape.bottomRight.y) / 2)
      }         
    case AnnotationShapeType.Point:
      return shape.point      
    case AnnotationShapeType.OpenPolygon:
      return centreOfPoints(shape.points)
    case AnnotationShapeType.ClosedPolygon:
      return centreOfPoints(shape.points)
    case AnnotationShapeType.WholeImage:
      return { x: 0, y: 0 }
  }
}

export const shapePointsDescription: (shape: AnnotationShape) => string = (shape) => {
  switch(shape.type) {
    case AnnotationShapeType.FreeHand:
    case AnnotationShapeType.OpenPolygon:
    case AnnotationShapeType.ClosedPolygon: 
      return pointsToString(shape.points)
    case AnnotationShapeType.Box:
    case AnnotationShapeType.Line: 
    case AnnotationShapeType.Oval: 
      return `Top left: ${pointToString(shape.topLeft)}) \nBottom right: ${pointToString(shape.bottomRight)})`
    case AnnotationShapeType.Circle: 
      return `Centre: ${pointToString(shape.centre)} \nRadius: ${shape.radius}`
    case AnnotationShapeType.Point: 
      return pointToString(shape.point)
    case AnnotationShapeType.WholeImage: 
      return ""
  }
}

const pointsToString: (points: Point[]) => string = (points) =>
  points.map((point) => pointToString(point)).join('\n')

const pointToString: (point: Point) => string = (point) => 
  `(x: ${point.x}, y: ${point.y})`

export const updateAnnotationPoint: (shape: AnnotationShape, pointIndex: number, point: Point) => AnnotationShape = (shape, pointIndex, point) => {
  switch (shape.type) {
    case AnnotationShapeType.OpenPolygon:
    case AnnotationShapeType.FreeHand:
    case AnnotationShapeType.ClosedPolygon:
      const newPoints = [...shape.points]
      newPoints[pointIndex] = point
      return {
        ...shape,
        points: newPoints
      }
    case AnnotationShapeType.Box:
    case AnnotationShapeType.Line:
    case AnnotationShapeType.Oval:
      if(pointIndex === 0) {
        return {
          ...shape,
          topLeft: point
        }
      } else if(pointIndex === 1) {
        return {
          ...shape,
          bottomRight: point
        }
      } else {
        return shape 
      }
    case AnnotationShapeType.Circle:
      return {
        ...shape,
        centre: point
      }
      
    case AnnotationShapeType.Point:
      return {
        ...shape,
        point
      }
    case AnnotationShapeType.WholeImage: 
      return shape     
  }
}

export const getIthPoint: (shape: AnnotationShape, i: number) => Point | undefined = (shape, i) => {
  switch (shape.type) {
    case AnnotationShapeType.Box:
      if(i === 0){
        return shape.topLeft
      } else if (i === 1) {
        return shape.bottomRight
      } else {
        return undefined
      }
    case AnnotationShapeType.Line:
      if(i === 0){
        return shape.topLeft
      } else if (i === 1) {
        return shape.bottomRight
      } else {
        return undefined
      }
    case AnnotationShapeType.FreeHand:
      return shape.points[i]
    case AnnotationShapeType.Circle:
      if(i === 0) {
        return shape.centre
      } else {
        return undefined
      }
    case AnnotationShapeType.Oval:
      if(i === 0){
        return shape.topLeft
      } else if (i === 1) {
        return shape.bottomRight
      } else {
        return undefined
      }
    case AnnotationShapeType.Point:
      if(i === 0) {
        return shape.point
      } else {
        return undefined
      }  
    case AnnotationShapeType.OpenPolygon:
      return shape.points[i]
    case AnnotationShapeType.ClosedPolygon:
      return shape.points[i]
    case AnnotationShapeType.WholeImage:
      return undefined
  }
}

export const shapeIndexClosestToPoint: (shape: AnnotationShape, point: Point) => number | undefined = (shape, point) => {
  switch (shape.type) {
    case AnnotationShapeType.Box:
      return pointsIndexClosestToPoint([shape.topLeft, shape.bottomRight], point)
    case AnnotationShapeType.Line:
      return pointsIndexClosestToPoint([shape.topLeft, shape.bottomRight], point)
    case AnnotationShapeType.FreeHand:
      return pointsIndexClosestToPoint(shape.points, point)
    case AnnotationShapeType.Circle:
      return 0
    case AnnotationShapeType.Oval:
      return pointsIndexClosestToPoint([shape.topLeft, shape.bottomRight], point)      
    case AnnotationShapeType.Point:
      return 0
    case AnnotationShapeType.OpenPolygon:
      return pointsIndexClosestToPoint(shape.points, point)
    case AnnotationShapeType.ClosedPolygon:
      return pointsIndexClosestToPoint(shape.points, point)
    case AnnotationShapeType.WholeImage:
      return undefined
  }
}

const pointsIndexClosestToPoint: (points: Point[], point: Point) => number | undefined = (points, point) => {
  if(points.length < 1) {
    return undefined
  }

  let currentClosestIndex = 0
  let currentClosestDistance = pointsDistance(points[0], point)

  points.forEach((p, i) => {
    const d = pointsDistance(p, point)
    if(d < currentClosestDistance) {
      currentClosestIndex = i
      currentClosestDistance = d
    }
  })

  return currentClosestIndex
}