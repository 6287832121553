import { RootState } from "app/store"
import { CallInfo } from "auth/model/User"
import { UserVisiblilty } from "auth/model/UserVisibility"
import { answerCall, callUser, endCalls, heartbeat, openSocket, rejectCall, startScreenShare, stopCallingUser, updateStatus } from "auth/Rtc"
import { connect } from "react-redux"
import { ChatBox } from "./ChatBoxComponent"


const mapDispatchToProps = {
  openSocket: (currentUserId: string, visibility: UserVisiblilty) => openSocket(currentUserId, visibility),
  callUser: (userId: string, currentUserId: string, sendVideo: boolean) => callUser(userId, currentUserId, sendVideo),
  stopCallingUser: (userId: string, currentUserId: string) => stopCallingUser(userId, currentUserId),
  updateVisibility: (currentUserId: string, visibility: UserVisiblilty) => updateStatus(currentUserId, visibility),
  heartbeat: (visibility: UserVisiblilty, currentUserId: string) => heartbeat(visibility, currentUserId),
  answerCall: (callInfo: CallInfo, currentUserId: string, sendVideo: boolean) => answerCall(callInfo, currentUserId, sendVideo),
  rejectCall: (callInfo: CallInfo, currentUserId: string) => rejectCall(callInfo, currentUserId),
  endCalls: (connectionIds: string[], currentUserId: string) => endCalls(connectionIds, currentUserId),
  startScreenShare: () => startScreenShare()
}

const mapStateToProps = (state: RootState) => {
  return {
    users: state.user.users || [],
    statuses: state.user.userStatuses,
    incomingCalls: state.user.incomingCalls,
    outgoingCalls: state.user.outgoingCalls,
    ongoingCalls: state.user.ongoingCalls
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChatBox)

