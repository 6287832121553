import { Theme } from 'app/model/Theme'


export type AmsFileUploadProps = {
  setFileContent: (content: string) => void
  buttonSecondary: boolean
  theme: Theme
}



export function AmsFileUpload({setFileContent, buttonSecondary, theme}: AmsFileUploadProps){


  const storeParsed = (event) => {
    setFileContent(event.target.result)
  }

	const changeHandler = (event) => {        
    const files: File[] = Array.from(event.target.files)
    
    if(files.length > 0){
      const file = files[0]

      const reader = new FileReader()
      reader.onload = storeParsed
      reader.readAsText(file)
    }    
	}

	return(
    <div style={{display: 'flex'}}>
      <div style={{
        margin: '20px',
        backgroundColor: theme.lightButton,
        padding: '8px',
        width: 'fit-content',
        borderRadius: '10px',
        color: 'white',
        cursor: 'pointer',
        boxShadow: '0px 5px 7px 0px rgb(55, 68, 124)'
      }}>
        <input type="file" id="amsFileElem" name="file" onChange={changeHandler} className="visually-hidden"  />
        <label htmlFor="amsFileElem" style={{cursor: 'pointer'}}>
          Select an AMS file
        </label>
      </div>
      <div style={{marginTop: '27px'}}>
        All AMS data is processed locally and does not leave your computer
      </div>
    </div>
	)
}
