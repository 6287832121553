import {useEffect, useState} from 'react'
import { connect } from 'react-redux'
import { nanoid } from 'nanoid'
import { useNavigate } from "react-router-dom"

import { listScans, createScan } from 'cases/api/ScansSlice'
import { RootState } from 'app/store'
import { ScanListingEntry } from 'uploads/api/Model'
import ScansTable from 'uploads/component/ScansTable'
import { ScanCreator } from 'uploads/component/ScanCreator'
import { DatasetListingEntry } from 'uploads/model/Dataset'
import { LoadingSpinner } from 'app/components/LoadingSpinner'
import { LabelledCheckBox } from 'app/components/LabelledCheckBox'
import { Theme } from 'app/model/Theme'

export type ScansPageProps = {
  listScans: () => void
  createScan: (scanId: string, currentUserId: string) => void
  scans?: ScanListingEntry[]
  datasets?: DatasetListingEntry[]
  currentUserId: string
  theme: Theme
}

const ScansPage = ({
  listScans, createScan,
  scans, datasets, currentUserId, theme
}: ScansPageProps) => {

  useEffect(() => {listScans()}, [listScans])

  const navigate = useNavigate()
  const navigateToScan: (scanId: string) => void = (scanId) => {
    navigate(`/cases/${scanId}`)
  }

  const [showDatasetCases, setShowDatasetCases] = useState<boolean>(true)

  if(scans === undefined){
    return (
      <div style={{margin: '30px'}}>
        <LoadingSpinner />
      </div> 
    )
  } else {
    return (
      <div style={{margin: '10px'}}>
        <div style={{ display: 'flex'}}>
          <ScanCreator 
            createScan={(scanId) => createScan(scanId, currentUserId)}
            navigateToScan={navigateToScan} 
            color={theme.lightButton} 
            border={theme.lightButtonBorder}          
          />
          <div style={{marginLeft: '20px', marginTop: '3px', paddingTop: '8px'}}>
            <LabelledCheckBox 
              id="filter-dataset-cases" 
              label="Include cases from datasets" 
              checked={showDatasetCases} 
              width={250}
              onChange={() => setShowDatasetCases(f => !f)} 
            />
          </div>
        </div>
        <div style={{height: '1000px', width: '1020px', overflowY: 'auto'}}>
          <ScansTable titleText="" scans={showDatasetCases ? scans : scans.filter(s => s.datasetId === undefined)} datasets={datasets} linkTo={(scanId) => `/cases/${scanId}` } />       
        </div>        
      </div>
    )
  }
}

const mapStateToProps = (state: RootState) => {
  const scans = state.scans.list
  const datasets = state.datasets.list
  
  return { 
    scans,
    datasets,
    theme: state.user.features.theme
  }
}

const mapDispatchToProps = { 
  listScans: () => listScans(nanoid(), undefined),
  createScan: (scanId: string, currentUserId: string) => createScan(scanId, currentUserId, nanoid()),
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ScansPage)
