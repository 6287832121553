import { connect } from 'react-redux'
import React from 'react'

import { RootState } from 'app/store'
import { clearUploadError } from 'uploads/api/UploadsSlice'
import { clearScanError, restoreAnnotation, clearAnnotationDeleted } from 'cases/api/ScansSlice'
import { clearReportError } from 'reports/api/ReportsSlice'
import { clearUserError, clearUserInfoMessage } from 'auth/UserSlice'
import { clearDatasetError } from 'datasets/api/DatasetSlice'

type ErrorNotificationProps = {
  message: string
  dismissError: () => void
}
const ErrorNotification = ({message, dismissError}: ErrorNotificationProps) => {  
  return (
    <div className="alert alert-danger alert-dismissible fade show error-notification" role="alert" style={{ position: 'absolute', right: '10px'}}>
      { message }
      <button type="button" className="btn-close" onClick={dismissError} aria-label="Close"></button>
    </div>
  )
}

type InfoNotificationProps = {
  message: string
  clickableMessage?: string
  onClick?: () => void
  dismissInfo?: () => void
}
const InfoNotification = ({message, clickableMessage, onClick, dismissInfo}: InfoNotificationProps) => {
  return (
    <div className="alert alert-info alert-dismissible fade show info-notification" role="alert" style={{}}>
      <span>{ message }</span>
      { clickableMessage !== undefined && 
        <span style={{cursor: 'pointer'}} onClick={onClick}>{ clickableMessage }</span>
      }      
      <button type="button" className="btn-close" onClick={dismissInfo} aria-label="Close"></button>
    </div>
  )
}

type NotificationBarProps = {
  scanError?: string
  uploadError?: string
  reportError?: string
  userInfo?: string
  userError?: string
  datasetError?: string
  deletedAnnotation?: { annotationId: string, scanId: string}  
  clearScanError: () => void
  clearUploadError: () => void
  clearReportError: () => void
  clearUserInfoMessage: () => void
  clearUserError: () => void
  clearDatasetError: () => void
  clearAnnotationDeleted: () => void
  restoreAnnotation: (annotationId: string, scanId: string) => void
}

const NotificationBar = ({
  scanError, uploadError, reportError, userInfo, userError, datasetError, deletedAnnotation,
  clearScanError, clearUploadError, clearReportError, clearUserInfoMessage, clearUserError, clearAnnotationDeleted, clearDatasetError, restoreAnnotation
}: NotificationBarProps) => {
  const messageExists = 
    scanError !== undefined || 
    uploadError !== undefined || 
    reportError !== undefined || 
    userError !== undefined || 
    datasetError !== undefined || 
    deletedAnnotation !== undefined ||
    userInfo !== undefined

  return (
    <section 
      className='notification-bar' 
      style={{
        height: messageExists ? '70px' : '0px', 
        position: 'fixed', 
        width: '500px', 
        bottom: '0px', 
        right: '500px' 
      }}
    >
      { scanError !== undefined && (
        <ErrorNotification message={scanError} dismissError={clearScanError} />
      )}
      { uploadError !== undefined && (
        <ErrorNotification message={uploadError} dismissError={clearUploadError} />
      )}
      { reportError !== undefined && (
        <ErrorNotification message={reportError} dismissError={clearReportError} />
      )}
      { userError !== undefined && (
        <ErrorNotification message={userError} dismissError={clearUserError} />
      )}
      { datasetError !== undefined && (
        <ErrorNotification message={datasetError} dismissError={clearDatasetError} />
      )}
      { deletedAnnotation !== undefined && (
        <InfoNotification 
          message="Annotation deleted. " 
          clickableMessage=" Undo"
          onClick={() => restoreAnnotation(deletedAnnotation.scanId, deletedAnnotation.annotationId)} 
          dismissInfo={clearAnnotationDeleted}
          />
      )}
      { userInfo !== undefined && (
        <InfoNotification 
          message={userInfo}
          dismissInfo={clearUserInfoMessage}
          />
      )}
    </section>  
  )
}

const mapDispatchToProps = {
  clearUploadError,
  clearScanError,
  clearReportError,
  clearUserInfoMessage,
  clearUserError,
  clearDatasetError,
  clearAnnotationDeleted,
  restoreAnnotation
}

const mapStateToProps = (state: RootState) => {
  return {
    scanError: state.scans.error,
    uploadError: state.uploads.error,
    reportError: state.reports.error,
    userInfo: state.user.infoMessage,
    userError: state.user.error,
    datasetError: state.datasets.error,
    deletedAnnotation: state.scans.deletedAnnotation
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NotificationBar)

