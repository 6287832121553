import _ from 'lodash'

import Select from 'react-select'
import { InlineEdit } from "app/components/InLineEdit"
import { Link } from "react-router-dom"
import { ModelRun, RecoveryStatus, ReportOutputMetadata, Scan, ScanInfo } from "uploads/api/Model"
import { BodyRegionSelector } from "uploads/component/BodyRegionSelector"
import { Dataset } from "uploads/model/Dataset"
import { ModelRunStatusView } from "./ModelRunStatusView"
import { AllBodyRegions } from 'uploads/model/BodyRegion'
import { Button } from 'app/components/Button'
import { Theme } from 'app/model/Theme'

export type CaseInfoProps = {
  updateScan: (scanId: string, name: string, scanInfo: ScanInfo | undefined, studyDate: string | undefined) => Promise<void>
  updateBodyRegion: (bodyRegionId: string | undefined) => void
  downloadReportOutput: (reportId: string, outputId: string) => void
  scan: Scan
  dataset?: Dataset
  hasWriteAccess: Boolean
  radiologists?: string[]
  latestModelRun?: ModelRun
  theme: Theme
}

export const CaseInfo = ({
  updateScan, updateBodyRegion, downloadReportOutput,
  scan, dataset, hasWriteAccess, radiologists, latestModelRun, theme
}: CaseInfoProps) => {

  const report: ReportOutputMetadata | undefined = scan.reportOutputs === undefined ? undefined : 
    _.orderBy(scan.reportOutputs, o => o.createdDate, 'desc')[0]


  return (
    <div className='case-info'>
      <table style={{width: '450px'}} className="table table-bordered table-striped">            
        <tbody>
          <tr>
            <td>Patient ID</td>
            <td><InlineEdit 
              value={scan.scanInfo.patientId} 
              setValue={(patientId) => updateScan(scan.scanId, scan.name || '', {...scan.scanInfo, patientId}, scan.studyDate )} 
              width={200} 
              disabled={!hasWriteAccess} 
            /></td>
          </tr>
          {dataset?.datasetInfo.isAnonymised === false &&
          <tr>
            <td>Patient name</td>
            <td><InlineEdit 
              value={scan.scanInfo.patientName} 
              setValue={(patientName) => updateScan(scan.scanId, scan.name || '', {...scan.scanInfo, patientName}, scan.studyDate )} 
              width={200} 
              disabled={!hasWriteAccess} 
            /></td>
          </tr>
          }
          <tr>
            <td>Scan date</td>
            <td><InlineEdit 
              value={scan.studyDate} 
              setValue={(studyDate) => updateScan(scan.scanId, scan.name || '', scan.scanInfo, studyDate )} 
              width={200} 
              disabled={!hasWriteAccess} 
            /></td>
          </tr>
          <tr>
            <td style={{paddingTop: '15px'}}>Injury status:</td>
            <td>
              <div style={{width: '200px'}}>
                <Select 
                  options={[RecoveryStatus.Injured, RecoveryStatus.Recovered].map(s => ({value: s, label: s}))} 
                  isDisabled={!hasWriteAccess}
                  placeholder={'Injury status'}
                  isClearable
                  value={scan.scanInfo.recoveryStatus === undefined ? undefined : {value: scan.scanInfo.recoveryStatus, label: scan.scanInfo.recoveryStatus} } 
                  onChange={(o) => updateScan(scan.scanId, scan.name || '', {...scan.scanInfo, recoveryStatus: o === null ? undefined : o.value}, scan.studyDate)}
                />
              </div>
            </td>
          </tr>
          { scan.datasetId !== undefined &&
          <>
          <tr>
            <td>Dataset:</td>
            <td><Link style={{color: 'black'}} to={`/datasets/${dataset?.datasetId}`}>{dataset?.name}</Link></td>
          </tr>
          <tr>
            <td>Radiologists</td>
            <td>{radiologists?.join(', ')}</td>
          </tr>
          </>
          }              
          { scan.datasetId === undefined &&
          <tr>
            <td style={{ paddingTop: '16px'}}>Body region</td>
            <td >
              <BodyRegionSelector 
                bodyRegions={ AllBodyRegions } 
                selectedBodyRegionId={scan?.scanInfo?.bodyRegionId}
                setSelectedBodyRegionId={ updateBodyRegion } 
              />
            </td>
          </tr>
          }    
          { latestModelRun !== undefined &&       
          <tr>
            <td>Analysis</td>
            <td><ModelRunStatusView run={latestModelRun} /></td>
          </tr>    
          }
          { report !== undefined && 
          <tr>
            <td>Structured report</td>
            <td>
              <a 
                href={ `/eliteai/reports/${report.reportId}/outputs/${report.outputId}`}      
                data-cy="download-report-link"
                style={{textDecoration: 'none'}}
                onClick={(e) => { 
                  debugger
                  e.preventDefault()
                  downloadReportOutput(report.reportId, report.outputId)
                }}
              >
                Download 
                <img src='/pdf_icon.png' width='20' height='20' style={{marginLeft: '5px'}} />
              </a>
            </td>
          </tr>
          }
        </tbody>
      </table>
    </div> 
  )
}