import { ModelRun, ModelRunStatus } from "uploads/api/Model"
import { formatDate } from "utils/DateUtils"

export type ModelRunStatusViewProps = {
  run?: ModelRun
}

export const ModelRunStatusView = ({
  run
}: ModelRunStatusViewProps) => {
  if(run === undefined) {
    return <></>
  }

  switch(run.status) {
    case undefined:
      return <></>
    case ModelRunStatus.started:
      return <span>Started — {formatDate(run.createdDate)}</span>
    case ModelRunStatus.failed:
      return <span>Failed — {formatDate(run.updatedDate)}</span>
    case ModelRunStatus.complete:
      return <span>Complete — {formatDate(run.updatedDate)}</span>
  }
}