import { useEffect } from "react"
import { Annotation } from "annotation/model/Annotation"
import { Scan } from "uploads/api/Model"
import { Dataset } from "uploads/model/Dataset"
import { ExtractedImage } from "uploads/store/Model"
import { draw } from "./CanvasFunctions"

export type AnnotationThumbnailScrollbarProps = {
  seriesId: string
  images: ExtractedImage[]
  size: number
  displayIndex: number
  annotations: Annotation[]
  displayModelAnnotations: boolean
  displayedUserAnnotations: string[]
  dataset?: Dataset
  scan: Scan
  contrastPercentage: number
  intensityPercentage: number
  setDisplayInstanceNumber: (instanceNumber: number) => void
  imageUrl: (imageId: string) => string
  scale: (number: number) => number
}


export const AnnotationThumbnailScrollbar = ({
  seriesId, images, size, displayIndex, annotations, displayModelAnnotations, displayedUserAnnotations,
  dataset, scan, contrastPercentage, intensityPercentage,
  imageUrl, setDisplayInstanceNumber, scale
}: AnnotationThumbnailScrollbarProps) => {

  const displayedAnnotations = annotations.filter(a => 
      (a.createdBy !== undefined && displayedUserAnnotations.includes(a.createdBy)) ||
      (displayModelAnnotations && a.modelName !== undefined))       

  const displayedInstanceNumbers: number[] = displayedAnnotations.reduce((acc: number[], annotation) => 
    annotation.InstanceNumber !== undefined ? [...acc, annotation.InstanceNumber] : acc
  , [])

  const displayedImages = images.filter(i => 
    i.InstanceNumber !== undefined && displayedInstanceNumbers.includes(i.InstanceNumber)
  )

  const canvasClassNameForImage = (image: ExtractedImage) => `annotation-thumbnail-scroller-canvas-${seriesId}-${image.InstanceNumber}`

  useEffect(() => {
    displayedImages.forEach((image, index) => {
      const element = document.getElementsByClassName(canvasClassNameForImage(image))[0]
      if(element !== null) {
        const canvas = element as HTMLCanvasElement
        const context = canvas.getContext('2d')  
        if(context !== null) {        
          draw({
            context, 
            canvas, 
            annotations: displayedAnnotations.filter(a => a.InstanceNumber === image.InstanceNumber), 
            highlightAnnoation: undefined, 
            labelAllAnnotations: false,
            pixelSpacing: undefined, 
            hiddenAnnotations: [],
            scaleFunction: (n) => ((n * size) / 512),
            editingAnnotationId: undefined,
            editingAnnotationPointIndex: undefined,
            userAnnotationColors: dataset?.datasetInfo.userAnnotationColors || scan.scanInfo.userAnnotationColors,
            labels: dataset?.annotationLabels || []
          })
        }
      }    
    })    
  }, [draw, displayedAnnotations, canvasClassNameForImage, dataset?.annotationLabels, 
    dataset?.datasetInfo.userAnnotationColors, displayedImages, scan.scanInfo.userAnnotationColors, size])

  return (
    <div 
      style={{
        display: 'flex', 
        height: `${size + 20}px`,
        overflowY: 'hidden',
        overflowX: 'auto',
        borderTop: '1px solid black',
        width: `${scale(512)}px`
      }}
    >
      { displayedImages.map((image, index) =>
        <div 
          key={image.imageId}
          style={{
            display: 'block',
            width: `${size}px`, 
            height: `${size}px`,
            backgroundImage: `url(${imageUrl(image.imageId)})`,
            filter: `contrast(${contrastPercentage}%)  brightness(${intensityPercentage}%)`,
            backgroundSize: 'cover',
            border: displayedImages[index] === images[displayIndex] ? `1px solid red` : '1px solid black',
            cursor: 'pointer',
            flex: 'none'
          }} 
          onClick={() => {if(image.InstanceNumber !== undefined) setDisplayInstanceNumber(image.InstanceNumber)}}
        >
          <canvas 
            className={canvasClassNameForImage(image)}
            style={{
              position: 'relative',
              overflow: 'auto'
            }}
            height={`${size}px`}
            width={`${size}px`}
          />
        </div>      
      )}
      { displayedImages.length < 1 && 
        <div 
        style={{
          display: 'block',
          width: `${size}px`, 
          height: `${size}px`,
          flex: 'none'
        }} 
      />       
      }
    </div>
  )
}