import { Link, useLocation} from 'react-router-dom'
import { dataCySafe } from 'utils/CypressUtils'

import { RootState } from './store'
import { connect } from 'react-redux'
import { toggleShowDebugUi, toggleShowExperimentalUi, UserFeatures } from 'auth/UserSlice'
import { LabelledCheckBox } from './components/LabelledCheckBox'
import { Theme } from './model/Theme'
import { useEffect, useState } from 'react'
import { keycloak } from 'auth/KeyCloak'
import { listDatasets } from 'datasets/api/DatasetSlice'
import { nanoid } from '@reduxjs/toolkit'
import { listScans } from 'cases/api/ScansSlice'
import { ScanListingEntry } from 'uploads/api/Model'

export type HeaderProps = {
  showExperimentalUi: boolean
  features: UserFeatures
  theme: Theme
  datasetsCount?: number
  scans?: ScanListingEntry[]
  toggleShowDebugUi: () => void
  toggleShowExperimentalUi: () => void
  listDatasets: () => void
  listScans: () => void
}

export const Header = ({
  showExperimentalUi, features, theme, datasetsCount, scans,
  toggleShowDebugUi, toggleShowExperimentalUi, listDatasets, listScans
}: HeaderProps) => {

  useEffect(() => { if(datasetsCount === undefined) { listDatasets() }}, [listDatasets, datasetsCount])
  useEffect(() => { if(scans === undefined) { listScans() }}, [listScans, scans === undefined])

  const logout = () => {
    document.cookie = `elite_ai_auth_token=loggedout; expires=${new Date().toISOString()}`
    keycloak.logout()
  }

  return (
    <header 
      className="navbar sticky-top shadow navbar-expand"
      style={{
        backgroundColor: theme.headerBackground,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        paddingTop: '0px',
        paddingBottom: '0px',
        paddingLeft: '50px',
        zIndex: '900'
      }}
    >

      <NavItem path={'/home'} text="Home" theme={theme} />      
      <NavItem path={'/dashboard'} text="Dashboard" theme={theme} />
      { (features.alwaysViewDatasets || (datasetsCount !== undefined && datasetsCount > 1)) &&
      <NavItem path={'/datasets'} text="Datasets" theme={theme} />
      }
      { (features.alwaysShowCases || (scans?.find(s => s.datasetId === undefined) !== undefined)) &&
      <NavItem path={'/scans'} text="Cases" theme={theme} />
      }
      {features.injuryAnalytics &&
      <NavItem path={'/injury-analytics'} text="Injury Analytics" theme={theme} />                
      }

      { features.importAnnotations && 
        <NavItem path={'/annotations/import'} text="Imports" theme={theme} />
      }      
      { features.showUiDebugToggles && 
        <>     
          <div style={{color: 'white'}}>            
            <LabelledCheckBox 
              id="show_debug_ui_toggle" 
              label={'Debug UI'} 
              checked={features.showDebugUi} 
              onChange={toggleShowDebugUi} 
              width={100} 
            />
          </div>        
          <div style={{color: 'white'}}>            
            <LabelledCheckBox 
              id="show_experimental_ui_toggle" 
              label={'Experimental UI'} 
              checked={features.showExperimentalUi} 
              onChange={toggleShowExperimentalUi} 
              width={130}
            />       
          </div>                                
        </>
      }
      
      <ul className="navbar-nav mb-2 mb-lg-0 ms-auto">
        <li className="nav-item text-nowrap">
          <Link className="nav-link" to={`/profile`} style={{color: 'white'}}>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" style={{marginBottom: "3px"}} fill="currentColor" className="bi bi-person-circle" viewBox="0 0 16 16">
              <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"/>
              <path fillRule="evenodd" d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z"/>
            </svg>  
            <span style={{marginLeft: '3px'}}>Profile</span>
          </Link>          
        </li>
        <li className="nav-item text-nowrap navbar-text" style={{color: 'white'}}>|</li>
        <li className="nav-item">
          <a className="nav-link" data-cy="logout" href="#" style={{color: 'white'}} onClick={logout}>Sign out</a>
        </li>
      </ul>

    </header>
  )
} 

export type NavItemProps = {
  path: string,
  text: string,
  theme: Theme
}

export const NavItem = ({path, text, theme}: NavItemProps) => {

  const [isHover, setIsHover] = useState(false)
  const location = useLocation()

  const active = location.pathname === path  

  return (
    <div 
      className={`nav-link`} 
      style={{
        margin: '5px',         
      }}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
    >
      <Link 
        className={`navbar-brand col-md-1 col-lg-1 me-0 px-3`}
        to={path} 
        data-cy={`${dataCySafe(text)}-sidebar-link`}
        style={{ 
          color: 'white',
          padding: '8px',
          borderRadius: '10px',
          backgroundColor: (active || isHover) ? theme.headerLosenge : theme.headerBackground,          
          boxShadow: isHover ? `0px 2px 2px 0px rgb(60, 60, 60)` : ''
        }}
      >{text}</Link>   
    </div>
  )
}


const mapStateToProps = (state: RootState) => {
  return  { 
    showExperimentalUi: state.user.features.showExperimentalUi,
    features: state.user.features,
    theme: state.user.features.theme,
    datasetsCount: state.datasets.list?.length,
    scans: state.scans.list
  }
}

const mapDispatchToProps = { 
  toggleShowDebugUi,
  toggleShowExperimentalUi,
  listDatasets: () => listDatasets(nanoid()),
  listScans: () => listScans(nanoid(), undefined)
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Header)
