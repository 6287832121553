export type MaximiseRestoreProps = {
  isMaximised: boolean | undefined
  size: number
  onMaximiseToggle: () => void
}
export const MaximiseRestore = ({
  isMaximised, size,
  onMaximiseToggle
}: MaximiseRestoreProps) => {

  return (
    <i 
      className={`material-icons toggle-maximise ${isMaximised ? 'restore' : 'maximise'}`}
      style={{
        float: 'right',
        position: 'relative',
        cursor: 'pointer',
        fontSize: `${size}x`,
        color: '#9aa0a6',
      }}
      onClick={(e) => {
        e.stopPropagation()
        onMaximiseToggle()
      }}
      title={isMaximised ? 'Restore' : 'Maximise'}
    >{isMaximised ? 'fullscreen_exit' : 'fullscreen'}</i>    
  )
}