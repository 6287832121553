import { nanoid } from "nanoid"
import { Annotation } from "annotation/model/Annotation"
import { AnnotationShapeType } from "annotation/model/AnnotationShape"

export type DatasetAnnotationLabel = {
  labelId: string,
  label: string,
  displayText: string,
  shapeId: AnnotationShapeType
  plane?: string
}

export const annotationDisplayText: (annotation: Annotation, labels: DatasetAnnotationLabel[]) => string = (annotation, labels) => 
  labels.find(l => l.label === annotation.label.toString())?.displayText || annotation.label.toString()    

export const newDatasetAnnotationLabel = () => ({
  labelId: `al-${nanoid()}`,
  label: '',
  displayText: '',
  shapeId: AnnotationShapeType.OpenPolygon
})

export const labelFromDisplayText: (displayText: string) => string = (displayText) => {
  return displayText
    .replace(/[^\w\s]/gi, '_')
    .replaceAll(' ', '_')
    .replaceAll('_____', '_')
    .replaceAll('____', '_')
    .replaceAll('___', '_')
    .replaceAll('__', '_')
    .toLowerCase()
}

export const defaultAclAnnotationLabels: () => DatasetAnnotationLabel[] = () => [{
  labelId: `al-${nanoid()}`,
  label: 'acl',
  displayText: 'Entire ACL',
  plane: 'Sagittal',
  shapeId: AnnotationShapeType.ClosedPolygon
},{
  labelId: `al-${nanoid()}`,
  label: 'bone_bruising',
  displayText: 'Bone Bruising',
  plane: 'Sagittal, Coronal',
  shapeId: AnnotationShapeType.ClosedPolygon
},{
  labelId: `al-${nanoid()}`,
  label: 'pcl_angle',
  displayText: 'PCL Angle',
  plane: 'Sagittal',
  shapeId: AnnotationShapeType.ClosedPolygon
},{
  labelId: `al-${nanoid()}`,
  label: 'anterior_tibial_subluxation',
  displayText: 'Anterior Tibial Subluxation',
  plane: 'Sagittal',
  shapeId: AnnotationShapeType.ClosedPolygon
},{
  labelId: `al-${nanoid()}`,
  label: 'segond_fracture',
  displayText: 'Segond Fracture',
  plane: 'Sagittal',
  shapeId: AnnotationShapeType.ClosedPolygon
},{
  labelId: `al-${nanoid()}`,
  label: 'deep_lateral_femoral_notch',
  displayText: 'Deep Lateral Femoral Notch',
  plane: 'Coronal',
  shapeId: AnnotationShapeType.ClosedPolygon
},]

export const defaultHamstringAnnotationLabels: () => DatasetAnnotationLabel[] = () => [{
  labelId: `al-${nanoid()}`,
  label: 'tear_craniocaudal',
  displayText: 'Tear (cranio-caudal length)',
  plane: 'Coronal',
  shapeId: AnnotationShapeType.Line
},{
  labelId: `al-${nanoid()}`,
  label: 'tear_eccentric',
  displayText: 'Tear (eccentric length)',
  plane: 'Coronal',
  shapeId: AnnotationShapeType.OpenPolygon
},{
  labelId: `al-${nanoid()}`,
  label: 'fibrosis',
  displayText: 'Fibrosis',
  plane: 'Coronal, Axial',
  shapeId: AnnotationShapeType.Point
},{
  labelId: `al-${nanoid()}`,
  label: 't_junction',
  displayText: 'T-junction',
  plane: 'Coronal',
  shapeId: AnnotationShapeType.Point
},{
  labelId: `al-${nanoid()}`,
  label: 'tear_csa',
  displayText: 'Tear (cross-sectional area)',
  plane: 'Axial',
  shapeId: AnnotationShapeType.ClosedPolygon
},{
  labelId: `al-${nanoid()}`,
  label: 'muscle_csa',
  displayText: 'Muscle (cross-sectional area)',
  plane: 'Axial',
  shapeId: AnnotationShapeType.ClosedPolygon
},{
  labelId: `al-${nanoid()}`,
  label: 'tendon_normal',
  displayText: 'Tendon (normal)',
  plane: 'Axial',
  shapeId: AnnotationShapeType.ClosedPolygon
},{
  labelId: `al-${nanoid()}`,
  label: 'tendon_torn',
  displayText: 'Tendon (torn)',
  plane: 'Axial',
  shapeId: AnnotationShapeType.ClosedPolygon
},{
  labelId: `al-${nanoid()}`,
  label: 'tear_intratendinous',
  displayText: 'Intratendinous tear',
  plane: 'Axial',
  shapeId: AnnotationShapeType.ClosedPolygon
},{
  labelId: `al-${nanoid()}`,
  label: 'semitendinosus',
  displayText: 'Semitendinosus',
  plane: 'Axial',
  shapeId: AnnotationShapeType.ClosedPolygon
},{
  labelId: `al-${nanoid()}`,
  label: 'semimembranosus',
  displayText: 'Semimembranosus',
  plane: 'Axial',
  shapeId: AnnotationShapeType.ClosedPolygon
},{
  labelId: `al-${nanoid()}`,
  label: 'longhead_biceps',
  displayText: 'Long head biceps femoris',
  plane: 'Axial',
  shapeId: AnnotationShapeType.ClosedPolygon
},{
  labelId: `al-${nanoid()}`,
  label: 'shorthead_biceps',
  displayText: 'Short head biceps femoris',
  plane: 'Axial',
  shapeId: AnnotationShapeType.ClosedPolygon
},{
  labelId: `al-${nanoid()}`,
  label: 'hemorrhage',
  displayText: 'Hemorrhage',
  plane: 'Axial',
  shapeId: AnnotationShapeType.ClosedPolygon
},{
  labelId: `al-${nanoid()}`,
  label: 'CompositeHamstringModel_TearDetected',
  displayText: 'Tear',
  plane: '',
  shapeId: AnnotationShapeType.ClosedPolygon
}]


export type SeriesDatasetAnnotationLabelMap = {
  [seriesId: string]: DatasetAnnotationLabel[]
}