import { ColorPicker } from "app/components/ColorPicker"
import { LabelledCheckBox } from "app/components/LabelledCheckBox"
import { getUserName, User } from "auth/model/User"
import { Dispatch, SetStateAction } from "react"
import { Annotation } from "annotation/model/Annotation"
import { UserAnnotationColorMap, UserAnnotationColorMapModelId } from "uploads/model/Dataset"
import { capLengthIfNeeded } from "utils/TextUtils"

export type AnnotationUsersPanelProps = {
  userIds: string[]
  users: User[] 
  displayedUserAnnotations: string[]
  userAnnotationColors: UserAnnotationColorMap
  annotations?: Annotation[]
  displayModelAnnotations: boolean
  displayModelAnnotationsCheckbox: boolean
  hasAnnotateAccess: boolean
  toggleDisplayedUserAnnotation: (userId: string) => void
  updateUserColor?: (userId: string, color: string) => Promise<void>
  setDisplayModelAnnotations?: Dispatch<SetStateAction<boolean>>
}

export const AnnotationUsersPanel = ({
  userIds, users, userAnnotationColors, displayModelAnnotationsCheckbox, displayedUserAnnotations, displayModelAnnotations,
  hasAnnotateAccess,
  toggleDisplayedUserAnnotation, updateUserColor, setDisplayModelAnnotations
}: AnnotationUsersPanelProps) => {
  if(userIds.length === 0) {
    return <></>
  }
  
  return (
    <div>
      <h5 style={{ fontSize: '1rem' }}>Annotators:</h5>
      <div>
        { userIds.map (userId => 
        <div key={userId} style={{display: 'flex', position: 'relative', margin: '2px'}}>
          <LabelledCheckBox 
            id={`show_annotations_${userId}`} 
            label={capLengthIfNeeded(getUserName(users, userId), 25)} 
            checked={displayedUserAnnotations.includes(userId)} 
            onChange={() => toggleDisplayedUserAnnotation(userId)} 
          />
          
          <span style={{paddingTop: '2px', position: 'absolute', right: '0px'}}>
            <ColorPicker 
              color={userAnnotationColors[userId]} 
              setColor={(color) => {
                if(hasAnnotateAccess && updateUserColor !== undefined) {
                  return updateUserColor(userId, color)
                } else {
                  return Promise.resolve()
                }        
              }} 
            />
          </span>                      
        </div>
        )}
      
        { displayModelAnnotationsCheckbox && 
        <div style={{display: 'flex', position: 'relative', margin: '2px'}}>
          <LabelledCheckBox 
            id={`show_model_annotations`} 
            label="Auto-detected"
            checked={displayModelAnnotations} 
            onChange={() => {
              if(setDisplayModelAnnotations !== undefined){
                setDisplayModelAnnotations(current => !current)
              }
            }} 
          />          
          
          <span style={{paddingTop: '2px', position: 'absolute', right: '0px'}}>
            <ColorPicker 
              color={userAnnotationColors[UserAnnotationColorMapModelId]} 
              setColor={(color) => {
                if(hasAnnotateAccess && updateUserColor !== undefined) {
                  return updateUserColor(UserAnnotationColorMapModelId, color)
                } else {
                  return Promise.resolve()
                }        
              }} 
            />
          </span>         
        </div>
        }
      </div>
    </div>
  )
}
