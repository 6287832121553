import { useState } from 'react'

export type InlineEditProps = {
  value?: string
  placeHolder?: string
  width: number
  disabled?: boolean
  color?: string
  className?: string
  dataCy?: string
  backgroundHoverColor?: string
  setValue: (value: string) => void
}

export const InlineEdit = ({ 
  value, width, disabled, color, backgroundHoverColor, className, dataCy, placeHolder,
  setValue
}: InlineEditProps) => {
  const [editingValue, setEditingValue] = useState(value)

  const [isHover, setIsHover] = useState(false)
  
  const onChange = (event) => { 
    if(!disabled) {
      setEditingValue(event.target.value)
    }
  }
  
  const onKeyDown = (event) => {
    if (event.key === "Enter" || event.key === "Escape") {
      event.target.blur()
    }
  }
  
  const onBlur = (event) => {
    setValue(event.target.value)
  }

  return (
    <input
      style={{
        width: `${width}px`, 
        color: color !== undefined ? color : 'black',
        backgroundColor: (isHover && !disabled) ? (backgroundHoverColor || '#d3d3d3') : 'transparent',
        border: 0,
        cursor: disabled ? 'default' : 'pointer'
      }}
      placeholder={placeHolder}
      className={className}
      data-cy={dataCy}
      type="text"
      aria-label="Field name"
      value={editingValue || ''}
      onChange={onChange}
      onKeyDown={onKeyDown}
      onBlur={onBlur}
      disabled={disabled}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
    />
  )
}

