import { nanoid } from 'nanoid'
import React, {useState} from 'react'

export type DatasetCreatorProps = {
  createDataset: (datasetId: string, name: string) => void
  navigateToDataset: (datasetId: string) => void
}

export function DatasetCreator({createDataset, navigateToDataset}: DatasetCreatorProps){
	const [datasetName, setDatasetName] = useState<string>("")

	const handleSubmit = async (e) => {    
    e.preventDefault()
    
    const datasetId = `ds-${nanoid()}`
    createDataset(datasetId, datasetName)
    navigateToDataset(datasetId)
	}

	return(
    <form className="dataset-creator" onSubmit={handleSubmit}>
      <label>
        Create a new dataset:
        <input 
          style={{marginLeft: '10px'}}
          type="text" 
          value={datasetName} 
          placeholder="Enter a dataset name" 
          onChange={(e) => setDatasetName(e.target.value)} 
          data-cy="name-new-dataset" />
      </label>
      <input type="submit" value="Create" disabled={datasetName.length < 1} style={{marginLeft: '10px'}} />
    </form>
  )
}
