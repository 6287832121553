export type DeleteXProps = {
  title?: string
  onClick?: () => void 
}

export const DeleteX = ({title, onClick}: DeleteXProps) => 
  <svg 
    onClick={onClick} 
    style={{
      cursor: 'pointer',
      // width: '50%',
      margin: '0 auto'
    }} 
    fill="#000000" 
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24" 
    width="15px" 
    height="15px"
  >
    <title>{title}</title>
    <path fill="none" stroke="#000000" strokeMiterlimit="10" strokeWidth="2" d="M4 4L20 20M20 4L4 20"/>
  </svg>