
import WebXRPolyfill from 'webxr-polyfill';
import { Canvas } from '@react-three/fiber'

import { ThreeDScene } from './ThreeDScene'
import { VrScene } from './VrScene'
import { userAgentSupportsVR } from 'utils/UserAgent';
import { VrSceneState } from './model/VrSceneState';
import { useState } from 'react';
import { VRButton } from '@react-three/xr';
import { Leva } from 'leva';

const polyfill = new WebXRPolyfill()

console.log(`webxr-polyfill ${polyfill}`)

export type GltfSceneProps = {
  spectateClientId?: string
  showDebugUi: boolean
  broadcastVrSceneState: (state: VrSceneState) => void
}

export const GltfScene = ({spectateClientId, showDebugUi, broadcastVrSceneState}: GltfSceneProps) => {

  const useVR = userAgentSupportsVR()
  const [clicked, setClicked] = useState<boolean>(false)

  return (
    <div onClick={() => {if(!clicked) { setClicked(true)}}} style={{height: '100%'}}>

      <Leva hidden={!showDebugUi} />

      { useVR && 
      <VRButton />   
      }

      <Canvas>
        { useVR ? 
        <VrScene broadcastVrSceneState={broadcastVrSceneState} /> 
        : 
        <ThreeDScene clicked={clicked} spectateClientId={spectateClientId}  />    
        }
      </Canvas>
    </div>
  )
}

