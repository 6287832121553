import { useState } from "react"

export type TextAreaProps = {
  value: string
  onChange: (value: string) => void
}

export const TextArea = ({value, onChange}: TextAreaProps) => {

  const [editedText, setEditedText] = useState<string | undefined>(undefined)

  return (
    <textarea 
      value={editedText || value} 
      onChange={(e) => {
        const text = e.target.value

        setEditedText(text)
        onChange(text)
      }} 
    />
  )
}