import _ from 'lodash'
import { getUserName, User } from "auth/model/User"
import { Annotation, AnnotationApprovalStatus } from "annotation/model/Annotation"
import { DatasetAnnotationLabel } from "uploads/model/DatasetAnnotationLabel"
import { AnnotationMetadata } from 'annotation/components/AnnotationMetadata'

export type SliceAnnotationsListProps = {
  scanId: string
  instanceNumber: string
  users: User[]
  showDebugUi: boolean
  seriesDescription: string | undefined
  annotations: Annotation[]
  hoveredAnnotationId?: string
  annotationApprovers: string[]
  currentUserId: string
  editingAnnotation: string | undefined
  annotationLabels: DatasetAnnotationLabel[]
  savingAnnotationIds: string[]
  hiddenAnnotations: string[]
  setHiddenAnnotations: (hiddenAnnotations: string[]) => void
  setHoveredAnnotationMeta: (hoveredAnnotationId: string | undefined) => void
  deleteAnnotation: (scanId: string, annotationId: string) => Promise<void>
  updateAnnotationApproval: (scanId: string, annotationId: string, status: AnnotationApprovalStatus | undefined) => void
  displayAnnotation: (annotation: Annotation) => void
  setEditingAnnotation: (annotationId: string|undefined) => void
}

export const SliceAnnotationsList = ({
  scanId, instanceNumber, seriesDescription, annotations, hoveredAnnotationId, 
  annotationApprovers, currentUserId, editingAnnotation, annotationLabels, showDebugUi, 
  users, savingAnnotationIds, hiddenAnnotations,
  setHoveredAnnotationMeta, deleteAnnotation, displayAnnotation, 
  updateAnnotationApproval, setEditingAnnotation, setHiddenAnnotations
}: SliceAnnotationsListProps) => {

  const annotationsByUser: [string, Annotation[]][] = Object.entries(_.groupBy(annotations.filter(a => a.createdBy !== undefined), a => a.createdBy))
  const modelAnnotations = annotations.filter(a => a.createdBy === undefined)
  if(modelAnnotations.length > 0){
    annotationsByUser.push(['Auto-detected', modelAnnotations])
  }  

  return (
    <div style={{ 
      border: '1px solid lightgray', 
      marginBottom: '3px',
      paddingLeft: '1px', 
      marginLeft: '1px' 
    }}>
      <div style={{marginLeft: '5px'}}>
        <span>Slice {instanceNumber}</span>         
      </div>
      { annotationsByUser.map(([userId, annotations]) => 
        <UserAnnotationsPanel 
          key={userId}
          userId={userId} 
          annotations={annotations} 
          scanId={scanId} 
          users={users} 
          showDebugUi={showDebugUi} 
          seriesDescription={seriesDescription} 
          annotationApprovers={annotationApprovers} 
          currentUserId={currentUserId} 
          editingAnnotation={editingAnnotation} 
          annotationLabels={annotationLabels} 
          savingAnnotationIds={savingAnnotationIds} 
          setHoveredAnnotationMeta={setHoveredAnnotationMeta} 
          deleteAnnotation={deleteAnnotation} 
          updateAnnotationApproval={updateAnnotationApproval} 
          displayAnnotation={displayAnnotation} 
          setEditingAnnotation={setEditingAnnotation}
          hiddenAnnotations={hiddenAnnotations}
          setHiddenAnnotations={setHiddenAnnotations}
        />
      )}
      
    </div>
  )
}


type UserAnnotationsPanelProps = {
  userId: string
  annotations: Annotation[]
  scanId: string
  users: User[]
  showDebugUi: boolean
  seriesDescription: string | undefined
  hoveredAnnotationId?: string
  annotationApprovers: string[]
  currentUserId: string
  editingAnnotation: string | undefined
  annotationLabels: DatasetAnnotationLabel[]
  savingAnnotationIds: string[]
  hiddenAnnotations: string[]
  setHiddenAnnotations: (hiddenAnnotations: string[]) => void
  setHoveredAnnotationMeta: (hoveredAnnotationId: string | undefined) => void
  deleteAnnotation: (scanId: string, annotationId: string) => Promise<void>
  updateAnnotationApproval: (scanId: string, annotationId: string, status: AnnotationApprovalStatus | undefined) => void
  displayAnnotation: (annotation: Annotation) => void
  setEditingAnnotation: (annotationId: string|undefined) => void
}

const UserAnnotationsPanel = ({
  scanId, userId, seriesDescription, annotations, hoveredAnnotationId, 
  annotationApprovers, currentUserId, editingAnnotation, annotationLabels, showDebugUi, 
  users, savingAnnotationIds, hiddenAnnotations,
  setHoveredAnnotationMeta, deleteAnnotation, displayAnnotation, 
  updateAnnotationApproval, setEditingAnnotation, setHiddenAnnotations
}: UserAnnotationsPanelProps) => {

  const muscleCrossSectionalArea = annotations.find(a => a.label === 'muscle_csa')?.derivedData?.area
  let tearToMuscleRatio: number | undefined = undefined
  if(muscleCrossSectionalArea !== undefined) {
    annotations.filter(a => a.label === 'tear_csa').forEach(tear => {
      if(tear.derivedData?.area !== undefined) {
        const ratio = tear.derivedData.area / muscleCrossSectionalArea

        if(tearToMuscleRatio === undefined || ratio > tearToMuscleRatio) {
          tearToMuscleRatio = ratio
        }
      }      
    })
  }


  const tendinousCrossSectionalArea = annotations.find(a => a.label === 'tendon_torn' || a.label === 'Tendon')?.derivedData?.area
  let tearToTendonRatio: number | undefined = undefined
  if(tendinousCrossSectionalArea !== undefined) {
    annotations.filter(a => a.label === 'tear_intratendinous').forEach(tear => {
      if(tear.derivedData?.area !== undefined) {
        const ratio = tear.derivedData.area / tendinousCrossSectionalArea

        if(tearToTendonRatio === undefined || ratio > tearToTendonRatio) {
          tearToTendonRatio = ratio
        }
      }      
    })
  }

  return (
    <div style={{border: '1px solid lightgray', margin:'4px', }}>
      <div style={{marginLeft: '5px'}}>
        <div>{getUserName(users, userId)}</div>
        { tearToMuscleRatio !== undefined &&
        <div>CSA of tear in muscle: {Math.round(tearToMuscleRatio * 100)}%</div>            
        }        
        { tearToTendonRatio !== undefined &&
        <div>CSA of tear in tendon: {Math.round(tearToTendonRatio * 100)}%</div>            
        }        
      </div>

      <ul style={{paddingLeft: '0px'}}>
        { annotations.map( annotation => 
        <AnnotationMetadata 
          key={annotation.annotationId} 
          annotation={annotation} 
          seriesDescription={seriesDescription}
          onMouseEnter={() => setHoveredAnnotationMeta(annotation.annotationId)}
          onMouseLeave={() => setHoveredAnnotationMeta(undefined)}
          onClick={() => displayAnnotation(annotation)}
          removeAnnotation={() => deleteAnnotation(scanId, annotation.annotationId)}
          updateAnnotationApprovalStatus={(status) => updateAnnotationApproval(scanId, annotation.annotationId, status)}
          highlight={hoveredAnnotationId === annotation.annotationId}
          annotationApprovers={annotationApprovers}
          currentUserId={currentUserId}
          setEditingAnnotation={setEditingAnnotation}
          isEditingAnnotation={editingAnnotation === annotation.annotationId}
          annotationLabels={annotationLabels}
          users={users}
          showDebugUi={showDebugUi}
          isSaving={savingAnnotationIds.includes(annotation.annotationId)}
          muscleCrossSectionalArea={muscleCrossSectionalArea}
          tendinousCrossSectionalArea={tendinousCrossSectionalArea}
          hiddenAnnotations={hiddenAnnotations}
          setHiddenAnnotations={setHiddenAnnotations}
        />
        )}
      </ul>
    </div>
  )
}