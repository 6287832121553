
export const AnnotationsPalette = [
  '#edc951',
  '#eb6841',
  // '#cc2a36',
  // '#4f372d', 
  '#00a0b0',
  '#00b159',
  // '#f37735',
  // '#ffc425'
  '#d11141',
  '#00aedb', 
  
]
