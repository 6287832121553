import { useState } from "react"
import { AnnotationApproval, AnnotationApprovalStatus } from "annotation/model/Annotation"

export type AnnotationApprovalButtonsProps = {
  annotationApprovers: string[]
  currentUserId: string
  annotationCreatedBy?: string
  approvals: AnnotationApproval[]
  rejections: AnnotationApproval[]
  isEditingAnnotation: boolean
  updateAnnotationApprovalStatus: (status: AnnotationApprovalStatus | undefined) => void
  onEditingAnnotation: () => void
  clearEditingAnnotation: () => void
}

export const AnnotationApprovalButtons = ({
  annotationApprovers, currentUserId, annotationCreatedBy, approvals, rejections, isEditingAnnotation,
  updateAnnotationApprovalStatus, onEditingAnnotation, clearEditingAnnotation
}: AnnotationApprovalButtonsProps) => {
  const [_approved, setApproved] = useState<boolean | undefined>(undefined)
  const [_rejected, setRejected] = useState<boolean | undefined>(undefined)

  const canApprove = annotationApprovers.includes(currentUserId)
  const canEdit = canApprove || annotationCreatedBy === currentUserId

  const approved = _approved !== undefined ? _approved : approvals.length > 0
  const rejected = _rejected !== undefined ? _rejected : rejections.length > 0


  const onApprove = () => {    
    if(canApprove) {
      if(approvals.find(a => a.userId) === undefined) {
        setApproved(true)
        setRejected(false)
        updateAnnotationApprovalStatus(AnnotationApprovalStatus.Approved)
      } else {
        setApproved(false)
        updateAnnotationApprovalStatus(undefined)
      }
      clearEditingAnnotation()
    }
  }

  const onReject = () => {
    if(canApprove) {
      if(rejections.find(a => a.userId) === undefined) {
        setRejected(true)
        setApproved(false)
        updateAnnotationApprovalStatus(AnnotationApprovalStatus.Rejected)
      } else {
        setRejected(false)
        updateAnnotationApprovalStatus(undefined)
      }
      clearEditingAnnotation()
    }
  }

  return (
    <>
      <div 
        style={{ cursor: canApprove ? 'pointer' : 'default' }}     
        title={ canApprove ? "Approve annotation" : `${approvals.length > 0 ? 'Annotation approved' : 'Annotation not approved'}`}     
        onClick={onApprove} 
      >
        <svg viewBox="0 0 16 16" height="20" width="20"  >
          <path 
            stroke={approved ? "#3FB950" : '#A4D1A2'}
            fill="white"
            fillRule="evenodd" 
            d="M13.78 4.22a.75.75 0 010 1.06l-7.25 7.25a.75.75 0 01-1.06 0L2.22 9.28a.75.75 0 011.06-1.06L6 10.94l6.72-6.72a.75.75 0 011.06 0z" 
          />
        </svg>
      </div>  
      <div 
        style={{ cursor: canApprove ? 'pointer' : 'default' }}
        onClick={onReject} 
        title={ canApprove ? "Reject annotation" : `${rejections.length > 0 ? 'Annotation rejected' : 'Annotation not rejected'}`}     
      >
        <svg viewBox="0 0 16 16" height="20" width="20">
          <path 
            stroke={rejected ? "#F85149" : '#EF9E9E'}
            fill="white"
            fillRule="evenodd" 
            d="M3.72 3.72a.75.75 0 011.06 0L8 6.94l3.22-3.22a.75.75 0 111.06 1.06L9.06 8l3.22 3.22a.75.75 0 11-1.06 1.06L8 9.06l-3.22 3.22a.75.75 0 01-1.06-1.06L6.94 8 3.72 4.78a.75.75 0 010-1.06z"
          />
        </svg>
      </div>  
      { (canApprove || canEdit) &&
      <div 
        style={{ cursor: 'pointer', 'color': 'lightgray' }}     
        title={ isEditingAnnotation ? "Stop editing" : "Edit annotation" }     
        onClick={() => { if (isEditingAnnotation) { clearEditingAnnotation() } else { onEditingAnnotation() } }} 
      >
        <svg width="18" height="18" viewBox="0 0 528.899 528.899">
          <path 
            fill={isEditingAnnotation ? "red" : "lightgrey"} 
            d="M328.883,89.125l107.59,107.589l-272.34,272.34L56.604,361.465L328.883,89.125z M518.113,63.177l-47.981-47.981
            c-18.543-18.543-48.653-18.543-67.259,0l-45.961,45.961l107.59,107.59l53.611-53.611
            C532.495,100.753,532.495,77.559,518.113,63.177z M0.3,512.69c-1.958,8.812,5.998,16.708,14.811,14.565l119.891-29.069
            L27.473,390.597L0.3,512.69z"/>
        </svg>
      </div>  
      }
    </>  
  )
}
