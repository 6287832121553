import { connect } from 'react-redux'
import React, { useState, useRef, useEffect, MouseEventHandler } from 'react'
import _ from 'lodash'

import { RootState } from 'app/store'
import { fetchAnnotations, saveAnnotation, updateScan } from 'cases/api/ScansSlice'
import { Annotation } from 'annotation/model/Annotation'
import { extractPixelSpacing } from 'annotation/model/PixelSpacing'
import { ExtractedImage } from 'uploads/store/Model'
import { nanoid } from 'nanoid'
import { draw, shapeContainsPoint } from './CanvasFunctions'
import { Slider } from 'app/components/Slider'
import { ModelOutput, Scan, ScanInfo } from 'uploads/api/Model'
import { updateDataset } from 'datasets/api/DatasetSlice'
import { Dataset, DatasetInfo } from 'uploads/model/Dataset'
import { getAnnotations, ImageUrlWithPixelSpacing } from './ImageScrollerFunctions'
import { Point } from 'annotation/model/Point'
import { AnnotationShape, AnnotationShapeType, isSingularShape, shapeIndexClosestToPoint, updateAnnotationPoint } from 'annotation/model/AnnotationShape'
import { AnnotationsPalette } from 'app/ColorPalettes'
import { getSeriesDisplayName } from 'uploads/util/SeriesDisplayName'
import { ThumbnailScrollbar } from './ThumbnailScrollbar'
import { AnnotationThumbnailScrollbar } from './AnnotationThumbnailScrollbar'
import { SeriesProgressBar } from './SeriesProgressBar'

export type ImageScrollerProps = {
  scan: Scan
  annotateMode: boolean
  seriesId: string
  images: ExtractedImage[]
  annotations?: Annotation[]
  highlightAnnoation?: string
  labelAllAnnotations: boolean
  isMaximised?: boolean
  currentUserId: string
  displayedUserAnnotations?: string[]
  displayModelAnnotations: boolean
  displayInstanceNumber?: number
  modelOutputs?: ModelOutput[]
  showDebugUi: boolean
  showExperimentalUi: boolean
  editingAnnotation: string | undefined
  hasWriteAccess: boolean  
  dataset?: Dataset
  isActiveSeries: boolean
  contrastPercentage: number
  intensityPercentage: number
  showModelOutputs: boolean
  annotationShape?: AnnotationShapeType
  currentAnnotationLabel?: string 
  hiddenAnnotations: string[]
  clearDisplayInstanceNumber: () => void
  setDisplayInstanceNumber: (instanceNumber: number) => void
  setHoveredAnnotation?: (annotationId: string| undefined) => void
  fetchAnnotations: (scanId: string) => Promise<void>
  saveAnnotation?: (scanId: string, annotation: Annotation) => Promise<void>
  onMaximiseToggle?: () => void
  onAnnotationDraw?: () => void
  setSeriesDisplayName: (displayName: string) => void
  updateDataset: (datasetId: string, name: string, info: DatasetInfo) => Promise<void>
  updateScan: (scanId: string, name: string, info: ScanInfo) => Promise<void>
  imageUrl: (imageId: string) => string
  modelOutputUrl: (modelOutputId: string) => string
  setSavingAnnotationIds: (annotationIds: string[]) => void
}

export const ImageScroller = ({ 
  scan, annotateMode, images, annotations, highlightAnnoation, labelAllAnnotations,
  isMaximised, currentUserId, displayedUserAnnotations, displayInstanceNumber, seriesId, displayModelAnnotations, 
  modelOutputs, showDebugUi, showExperimentalUi, editingAnnotation: editingAnnotationId, hasWriteAccess, dataset,
  isActiveSeries, contrastPercentage, intensityPercentage, showModelOutputs, annotationShape, currentAnnotationLabel,
  hiddenAnnotations,
  setHoveredAnnotation, saveAnnotation, onMaximiseToggle, fetchAnnotations, onAnnotationDraw, clearDisplayInstanceNumber, 
  setSeriesDisplayName, updateDataset, updateScan, imageUrl, modelOutputUrl, setDisplayInstanceNumber,
  setSavingAnnotationIds
}: ImageScrollerProps) => {  

  const [displayIndex, setDisplayIndex] = useState<number| undefined>(undefined)
  const displayIndexRef = useRef<number>()
  displayIndexRef.current = displayIndex
  const [mouseDownPoint, setMouseDownPoint] = useState<Point>({x: 0, y: 0})
  const [mousePoint, setMousePoint] = useState<Point>({x: 0, y: 0})
  const [mousePath, setMousePath] = useState<Point[]>([])

  const annotationShapeRef = useRef<AnnotationShapeType>()
  annotationShapeRef.current = annotationShape
 
  const [mouseDownAnnotationId, setMouseDownAnnotationId] = useState<string | undefined>(undefined)
  const mouseDownAnnotationIdRef = useRef<string>()
  mouseDownAnnotationIdRef.current = mouseDownAnnotationId

  const recordCurrentMousePoint = (currentPoint: Point) => {    
    setMousePoint(currentPoint)
  
    switch(annotationShapeRef.current) {
      case AnnotationShapeType.FreeHand:
        if(drawingAnnotation){
          setMousePath([...mousePath, currentPoint])
        }        
        break
      default:
        break
    }
  }
  const [drawingAnnotation, setDrawingAnotation] = useState<boolean>(false)
  const [savingAnnotation, setSavingAnnotation] = useState<Annotation | undefined>(undefined)
  const savingAnnotationRef = useRef<Annotation>()
  savingAnnotationRef.current = savingAnnotation
  const [toSave, _setToSave] = useState<Annotation[]>([])
  const toSaveRef = useRef<Annotation[]>()
  toSaveRef.current = toSave

  const setToSave: (annotations: Annotation[]) => void = (annotations) => {
    _setToSave(annotations)
    setSavingAnnotationIds(annotations.map(a => a.annotationId))    
  }

  const onAnnotationSaved = () => {
    fetchAnnotations(scan.scanId).then(() => {
      if(toSaveRef.current !== undefined && toSaveRef.current.length > 0 && saveAnnotation !== undefined){
        const [next, ...rest] = toSaveRef.current
  
        setSavingAnnotation(next)
        setToSave(rest)
        saveAnnotation(scan.scanId, next).then(onAnnotationSaved)
      } else {
        setSavingAnnotation(undefined)
        setSavingAnnotationIds([])
      } 
    })

    if(dataset !== undefined) {
      if(dataset.datasetInfo.userAnnotationColors[currentUserId] === undefined) {
        const existingColors = Object.keys(dataset.datasetInfo.userAnnotationColors).length

        updateDataset(dataset.datasetId, dataset.name, {
          ...dataset.datasetInfo,
          userAnnotationColors: {
            ...dataset.datasetInfo.userAnnotationColors,
            [currentUserId]: AnnotationsPalette[(existingColors) % AnnotationsPalette.length]
          }
        })
      }
    } else {
      if(scan.scanInfo.userAnnotationColors[currentUserId] === undefined) {
        const existingColors = Object.keys(scan.scanInfo.userAnnotationColors).length

        updateScan(scan.scanId, scan.name || '', {
          ...scan.scanInfo,
          userAnnotationColors: {
            ...scan.scanInfo.userAnnotationColors,
            [currentUserId]: AnnotationsPalette[(existingColors) % AnnotationsPalette.length]
          }
        })
      }
    }    
  }

  const currentAnnotationLabelRef = useRef<string>()
  currentAnnotationLabelRef.current = currentAnnotationLabel
  
  useEffect(() => { 
    if(displayIndex === undefined && annotations !== undefined && annotations.length > 0) {
      setDisplayIndex(annotations[0].InstanceNumber)
    } else if(displayIndex === undefined && images !== undefined && images.length > 0) {
      setDisplayIndex(Math.floor(images.length / 2))
    } 
  }, [displayIndex, annotations?.length, images?.length])

  

  const scaleFactor = isMaximised ? 2 : 1  
  const scaleFactorRef = useRef(scaleFactor)
  scaleFactorRef.current = scaleFactor

  const scale: (n: number) => number = (n) => Math.round(n * scaleFactorRef.current)
  const descale: (n: number) => number = (n) => Math.round(n / scaleFactorRef.current)
  const descalePoint: (p: Point) => Point = (p) => ({x: descale(p.x), y: descale(p.y)})
  
  useEffect(() => {
    if(displayInstanceNumber !== undefined) {
      const displayIndexForInstanceNumber = (images || []).findIndex(i => i.InstanceNumber === displayInstanceNumber)

      if(displayIndexForInstanceNumber >= 0) {
        setDisplayIndex(displayIndexForInstanceNumber)
        clearDisplayInstanceNumber()      
      }
    }
  }, [displayInstanceNumber, clearDisplayInstanceNumber, images?.length])

  const canvasRef = useRef<HTMLCanvasElement>(null)  
  const displayedAnnotations = getAnnotations(displayIndex, images, annotations, displayedUserAnnotations || [], displayModelAnnotations)  

  const hoveredAnnotation = displayedAnnotations.find(a => 
    editingAnnotationId === undefined && 
    !drawingAnnotation && 
    shapeContainsPoint(a.shape, mousePoint))
  
  const editingAnnotation = displayedAnnotations.find(a => a.annotationId === editingAnnotationId)

  const [editingAnnotationPointIndex, setEditingAnnotationPointIndex] = useState<number | undefined>(undefined)
  const editingAnnotationPointIndexRef = useRef<number>()
  editingAnnotationPointIndexRef.current = editingAnnotationPointIndex

  const [editedAnnotation, setEditedAnnotation] = useState<Annotation | undefined>(undefined)
  const editedAnnotationRef = useRef<Annotation>()
  editedAnnotationRef.current = editedAnnotation
  useEffect(() => { if(editingAnnotationId === undefined) {
    setEditedAnnotation(undefined)
    setEditingAnnotationPointIndex(undefined)
  }}, [editingAnnotationId])

  useEffect(() => {
    if(setHoveredAnnotation !== undefined) {
      setHoveredAnnotation(hoveredAnnotation?.annotationId)
    } 
  }, [hoveredAnnotation?.annotationId, setHoveredAnnotation])

  const currentAnnotation: () => Annotation | undefined = () => {    
    if(!hasWriteAccess || !annotateMode || currentAnnotationLabelRef.current === undefined) {
      return undefined
    }

    let shape: AnnotationShape | undefined = undefined

    const currentImage: ExtractedImage | undefined = (images === undefined || displayIndexRef.current === undefined) ? 
      undefined : images[displayIndexRef.current]

    let allAnnotations: Annotation[] = []
    if(annotations !== undefined) {
      allAnnotations = [...allAnnotations, ...annotations]
    }
    if(savingAnnotationRef.current !== undefined) {
      allAnnotations = [...allAnnotations, savingAnnotationRef.current]
    }
    if(toSaveRef.current !== undefined) {
      allAnnotations = [...allAnnotations, ...toSaveRef.current]
    }

    switch(annotationShapeRef.current) {
      case AnnotationShapeType.Line:
        shape = {      
          type: AnnotationShapeType.Line,
          topLeft: mouseDownPoint,        
          bottomRight: mousePoint
        }
        break
      case AnnotationShapeType.Box:
        shape =  {      
          type: AnnotationShapeType.Box,
          topLeft: mouseDownPoint,        
          bottomRight: mousePoint
        }
        break
      case AnnotationShapeType.Circle:          
        const deltaX = mousePoint.x - mouseDownPoint.x
        const deltaY = mousePoint.y - mouseDownPoint.y
        const radius = Math.sqrt((deltaX * deltaX) + (deltaY * deltaY))
      
        shape =  {      
          type: AnnotationShapeType.Circle,
          centre: mouseDownPoint,        
          radius
        }
        break
      case AnnotationShapeType.Oval:
        shape =  {      
          type: AnnotationShapeType.Oval,
          topLeft: mouseDownPoint,        
          bottomRight: mousePoint
        }    
        break    
      case AnnotationShapeType.Point:
        shape =  {
          type: AnnotationShapeType.Point,
          point: mousePoint
        }
        break
      case AnnotationShapeType.FreeHand:
        shape =  {
          type: AnnotationShapeType.FreeHand,
          points: mousePath
        }
        break
      case AnnotationShapeType.OpenPolygon:
        const allExistingOpen = allAnnotations.filter(a => 
          a.label === currentAnnotationLabelRef.current && 
          a.InstanceNumber === currentImage?.InstanceNumber &&
          a.createdBy === currentUserId && 
          a.shape.type === AnnotationShapeType.OpenPolygon)

        const existingOpen = _.maxBy(allExistingOpen, a => a.annotationVersion)

        if(existingOpen?.shape.type === AnnotationShapeType.OpenPolygon && 
          images !== undefined 
          && displayIndexRef.current !== undefined 
          && existingOpen.InstanceNumber === images[displayIndexRef.current].InstanceNumber
        ) {
          const points = [...existingOpen.shape.points, mousePoint]
          shape = {
            type: AnnotationShapeType.OpenPolygon,
            points
          }
        } else {
          shape = {
            type: AnnotationShapeType.OpenPolygon,
            points: [mousePoint]
          }
        }
        break        
      case AnnotationShapeType.ClosedPolygon:

        const allExistingClosed = allAnnotations.filter(a => 
          a.label === currentAnnotationLabelRef.current && 
          a.InstanceNumber === currentImage?.InstanceNumber &&
          a.createdBy === currentUserId && 
          a.shape.type === AnnotationShapeType.ClosedPolygon)

        const existingClosed = _.maxBy(allExistingClosed, a => a.annotationVersion)

        if(existingClosed?.shape.type === AnnotationShapeType.ClosedPolygon) {
          const points = [...existingClosed.shape.points, mousePoint]

          shape = {
            type: AnnotationShapeType.ClosedPolygon,
            points
          }
        } else {
          shape =  {
            type: AnnotationShapeType.ClosedPolygon,
            points: [mousePoint]
          }
        }
        break
      case AnnotationShapeType.WholeImage:
      default:
        
    }
    

    const image = (images !== undefined && displayIndexRef.current !== undefined) ? images[displayIndexRef.current] : undefined

    if(shape !== undefined && 
      image !== undefined && 
      image.SeriesInstanceUID !== undefined && 
      image.InstanceNumber !== undefined && 
      currentAnnotationLabelRef.current !== undefined){
      const annotationId: string = mouseDownAnnotationIdRef.current === undefined ? `an-${nanoid()}`.substring(0, 36) : mouseDownAnnotationIdRef.current
      
      if(mouseDownAnnotationIdRef.current === undefined) {
        setMouseDownAnnotationId(annotationId)
      }      

      return {
        annotationId: annotationId,
        annotationVersion: 1,
        scanId: scan.scanId,
        SeriesInstanceUID: image.SeriesInstanceUID,
        InstanceNumber: image.InstanceNumber,
        label: currentAnnotationLabelRef.current,
        shape: shape,
        createdBy: currentUserId,
        createdDate: new Date().toString(),
        approvals: []
      }      
    } else {
      return undefined
    }
  }

  const currentInstanceNumber = (images !== undefined && displayIndexRef.current !== undefined) ? images[displayIndexRef.current]?.InstanceNumber : undefined

  let toDraw: Annotation[] = []
  const a = currentAnnotation()
  if(drawingAnnotation && a !== undefined && a.InstanceNumber === currentInstanceNumber) {    
    toDraw.push(a)
  }
  if(savingAnnotationRef.current !== undefined && savingAnnotationRef.current.InstanceNumber === currentInstanceNumber) {
    toDraw = [...toDraw, savingAnnotationRef.current]
  }
  if(toSaveRef.current !== undefined && toSaveRef.current.filter(a => a.InstanceNumber === currentInstanceNumber)) {
    toDraw = [...toDraw, ...toSaveRef.current]
  }
  if(editedAnnotationRef.current !== undefined && editedAnnotationRef.current.InstanceNumber === currentInstanceNumber) {
    const e: Annotation = editedAnnotationRef.current
    toDraw = [...toDraw.filter(a => a.annotationId !== e.annotationId), e]
  }

  const toHighlight = highlightAnnoation || hoveredAnnotation?.annotationId  

  const backgroundImage: ImageUrlWithPixelSpacing | undefined = (() => {
    if(displayIndex !== undefined && images !== undefined) {
      const image = images[displayIndex]
      if(showModelOutputs){
        let selected: ModelOutput | undefined = undefined

        modelOutputs?.forEach(current => {
          if(selected === undefined) {
            selected = current
          } else {
            if(current.instanceNumber !== undefined && selected.instanceNumber !== undefined && image.InstanceNumber !== undefined) {
              if(Math.abs(image.InstanceNumber - current.instanceNumber) < Math.abs(image.InstanceNumber - selected.instanceNumber)) {
                selected = current
              }
            }
          }
        })

        if(selected !== undefined){
          const s: ModelOutput = selected
          return {
            imageUrl: `url(${modelOutputUrl(s.modelOutputId)})`,
            pixelSpacing: extractPixelSpacing(image)
          }
        } else {
          return undefined
        }
      } else {
        return {
          imageUrl: `url(${imageUrl(image.imageId)})`,
          pixelSpacing: extractPixelSpacing(image)
        }
      }
    } else {
      return undefined
    } 
  })()

  useEffect(() => {    
    const canvas = canvasRef.current 
    if(canvas !== null) {
      const context = canvas.getContext('2d')  
      if(context !== null) {        
        draw({
          context, 
          canvas, 
          annotations: [...displayedAnnotations, ...toDraw], 
          hiddenAnnotations: hiddenAnnotations,
          highlightAnnoation: toHighlight, 
          labelAllAnnotations: labelAllAnnotations,
          pixelSpacing: backgroundImage?.pixelSpacing, 
          scaleFunction: scale,
          editingAnnotationId,
          editingAnnotationPointIndex: editingAnnotationPointIndexRef.current,
          userAnnotationColors: dataset?.datasetInfo.userAnnotationColors || scan.scanInfo.userAnnotationColors,
          labels: dataset?.annotationLabels || [],
          displayIndex,
          displayTotal: images?.length
        })
      }
    }    
  }, [displayedAnnotations, a, toHighlight, isMaximised, displayIndex, images?.length, backgroundImage?.pixelSpacing, dataset?.annotationLabels, 
    dataset?.datasetInfo.userAnnotationColors, editingAnnotationId, hiddenAnnotations, labelAllAnnotations, scan.scanInfo.userAnnotationColors, toDraw])

  if(images !== undefined && images.length < 1) {
    return (<div/>)
  }

  const onMouseDown = (e: React.MouseEvent) => {
    const canvas = canvasRef.current 
    if(canvas !== null){
      const canvasRectangle = canvas.getBoundingClientRect()
      const currentPoint = descalePoint({
        x: e.clientX - canvasRectangle.left, 
        y: e.clientY - canvasRectangle.top
      })
      switch(annotationShapeRef.current) {
        case AnnotationShapeType.OpenPolygon:      
          break
        case AnnotationShapeType.ClosedPolygon:      
          break
        default:
          setMousePath([])
          break
      }

      if(editingAnnotation !== undefined) {    
        const e: Annotation = editingAnnotation     
        setEditedAnnotation({
          ...editingAnnotation,
          annotationVersion: e.annotationVersion + 1
        })
      } else {
        setMouseDownPoint(currentPoint)
        setDrawingAnotation(true)      
      }
    }

    if(onAnnotationDraw !== undefined) {
      onAnnotationDraw()
    }
  }

  const onMouseUp = (e: React.MouseEvent) => {    
    setDrawingAnotation(false)    

    if(editedAnnotationRef.current !== undefined && saveAnnotation !== undefined) {
      const toSave: Annotation = editedAnnotationRef.current
      if(savingAnnotationRef.current === undefined){
        setSavingAnnotation(toSave)

        saveAnnotation(scan.scanId, toSave)
          .then(onAnnotationSaved)
      } else {
        const currentToSave: Annotation[] = toSaveRef.current === undefined ? [] : toSaveRef.current
        const pendingVersion = currentToSave.find(a => a.annotationId === toSave.annotationId)?.annotationVersion

          if(pendingVersion !== undefined) {
            toSave.annotationVersion = pendingVersion
          }   

        setToSave([...currentToSave.filter(a => a.annotationId !== toSave.annotationId), toSave])
      }
    } else {            
      const newestAnnotation = currentAnnotation()
      if(displayIndexRef.current !== undefined && 
        newestAnnotation !== undefined &&
        annotationShapeRef.current !== undefined && 
        currentAnnotationLabelRef.current !== undefined && 
        saveAnnotation !== undefined && 
        images !== undefined){

        const image = images[displayIndexRef.current]
        const singularAnnotationShape = isSingularShape(annotationShapeRef.current)

        let allAnnotations: Annotation[] = []
        if(annotations !== undefined) {
          allAnnotations = [...allAnnotations, ...annotations]
        }
        if(savingAnnotationRef.current !== undefined) {
          allAnnotations = [...allAnnotations, savingAnnotationRef.current]
        }
        if(toSaveRef.current !== undefined) {
          allAnnotations = [...allAnnotations, ...toSaveRef.current]
        }

        const existingByShape = allAnnotations.filter(a => a.shape.type === annotationShapeRef.current && 
          a.label === currentAnnotationLabelRef.current && 
          a.InstanceNumber === image.InstanceNumber && 
          a.createdBy === currentUserId)      

        if(singularAnnotationShape && existingByShape.length > 0 && image.SeriesInstanceUID !== undefined && image.InstanceNumber !== undefined){                  
          newestAnnotation.annotationId = existingByShape[0].annotationId       
          newestAnnotation.annotationVersion = Math.max(...existingByShape.map(e => e.annotationVersion)) + 1
          newestAnnotation.InstanceNumber = image.InstanceNumber
          newestAnnotation.SeriesInstanceUID = image.SeriesInstanceUID      
        }
    
        if(savingAnnotationRef.current === undefined){
          setSavingAnnotation(newestAnnotation)
  
          saveAnnotation(scan.scanId, newestAnnotation)
            .then(onAnnotationSaved)
        } else {
          const currentToSave: Annotation[] = toSaveRef.current === undefined ? [] : toSaveRef.current

          const pendingVersion = currentToSave.find(a => a.annotationId === newestAnnotation.annotationId)?.annotationVersion

          if(pendingVersion !== undefined) {
            newestAnnotation.annotationVersion = pendingVersion
          }        

          setToSave([...currentToSave.filter(a => a.annotationId !== newestAnnotation.annotationId), newestAnnotation])
        }

        setMouseDownAnnotationId(undefined)          
        setMousePath([])         
      }
    }

    setEditedAnnotation(undefined)
  }

  const onMouseMove: MouseEventHandler<HTMLCanvasElement> = (e: React.MouseEvent) => {
    const canvas = canvasRef.current
    if(canvas !== null){
      const canvasRectangle = canvas.getBoundingClientRect()
      const mouseLocation = descalePoint({x: e.clientX - canvasRectangle.left, y:  e.clientY - canvasRectangle.top})
      recordCurrentMousePoint(mouseLocation)
      
      if(editingAnnotation !== undefined && editedAnnotationRef.current === undefined) {
        setEditingAnnotationPointIndex(shapeIndexClosestToPoint(editingAnnotation.shape, mouseLocation))
      }
        
      if(editedAnnotationRef.current !== undefined && editingAnnotationPointIndexRef.current !== undefined) {
        const e: Annotation = editedAnnotationRef.current
        const i: number = editingAnnotationPointIndexRef.current

        setEditedAnnotation({
          ...e,
          shape: updateAnnotationPoint(e.shape, i, mouseLocation)
        })
      }
      
    }
  }  

  if(displayIndex === undefined) {
    return <>Display index undefined</>
  }

  return (
    <div style={{width: '520px', height: '617px'}}>
      <Slider className='slice-slider' width='500px' min={0} max={images.length - 1} value={displayIndex} title={`${displayIndex + 1} of ${images.length}`} onChange={(x) => setDisplayIndex(Number.parseInt(x)) } />
      <ThumbnailScrollbar 
        imageUrls={images.map(i => imageUrl(i.imageId))} 
        size={30} 
        displayIndex={displayIndex} 
        setDisplayIndex={setDisplayIndex} 
        width={scale(512)}
        contrastPercentage={contrastPercentage}
        intensityPercentage={intensityPercentage}
      />
      <SeriesProgressBar 
        sliceCount={images.length}
        displayIndex={displayIndex}
        width={scale(512)}
      />

      <div 
        id={`series-display-${seriesId}`}
        style={{
          width: `${scale(512)}px`, 
          height: `${scale(512)}px`,
          backgroundImage: backgroundImage?.imageUrl,
          backgroundSize: 'cover',
          border: (!isMaximised && isActiveSeries) ? '1px solid red' : '1px solid black',
          filter: `contrast(${contrastPercentage}%)  brightness(${intensityPercentage}%)`,
        }} 
      >
        <canvas 
          style={{
            position: 'relative',
            overflow: 'auto'
          }}
          className="annotations-canvas" 
          onMouseUp={onMouseUp}
          onMouseDown={onMouseDown}
          onMouseMove={onMouseMove}
          height={`${scale(512)}px`}
          width={`${scale(512)}px`}
          ref={canvasRef} 
        />
      </div>

      <AnnotationThumbnailScrollbar 
        seriesId={seriesId}
        images={images}
        imageUrl={imageUrl}
        annotations={annotations || []}
        displayModelAnnotations={displayModelAnnotations}
        displayedUserAnnotations={displayedUserAnnotations || []}
        size={30}
        displayIndex={displayIndex}
        setDisplayInstanceNumber={setDisplayInstanceNumber}
        scan={scan}
        dataset={dataset}
        contrastPercentage={contrastPercentage}
        intensityPercentage={intensityPercentage}
        scale={scale}
      />
    </div>      
  )  
}

const mapStateToProps = (state: RootState, { scanId, seriesId }: {scanId: string, seriesId: string}) => { 
  const images = state.scans.imagesMetadata[scanId]?.metadata.filter(i => i.SeriesInstanceUID === seriesId)

  const scan = state.scans.all?.find(scan => scan.scanId === scanId)
  const dataset = state.datasets.all?.find(d => d.datasetId === scan?.datasetId)
  const seriesDescription = images.find(i => i.SeriesDescription !== undefined)?.SeriesDescription

  const seriesDisplayName = getSeriesDisplayName(seriesId, seriesDescription, scan, dataset)
  
  const modelOutputs = scan?.modelOutputs?.filter(o => o.seriesId === seriesId) 

  const showDebugUi = state.user.features.showDebugUi

  const annotations = state.scans.annotations[scanId]?.filter(a => a.SeriesInstanceUID === seriesId).filter(a =>
    showDebugUi || a.displayMode !== "debug")

  return {
    profile: state.user.profile,
    annotations,
    seriesDisplayName,
    modelOutputs,
    dataset,
    showDebugUi,
    showExperimentalUi: state.user.features.showExperimentalUi
  }
}

const mapDispatchToProps = {
  saveAnnotation: (scanId: string, annotation: Annotation) => saveAnnotation(scanId, annotation, nanoid()),
  fetchAnnotations: (scanId: string) => fetchAnnotations(scanId, nanoid()),
  updateScan: (scanId: string, name: string, scanInfo: ScanInfo) => updateScan(scanId, name, scanInfo, undefined, nanoid()),
  updateDataset: (datasetId: string, name: string, datasetInfo: DatasetInfo) => updateDataset(datasetId, name, datasetInfo, nanoid()),
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ImageScroller)
