import { Scan, ScanInfo } from "uploads/api/Model"
import { AccessEntry } from 'auth/model/AccessEntry'
import { User } from "auth/model/User"
import { Dataset } from "uploads/model/Dataset"
import { PermissionLevel } from "auth/model/PermissionLevel"
import SharingWidget from "auth/components/SharingWidget"
import { Theme } from "app/model/Theme"
import { InlineEdit } from "app/components/InLineEdit"

export type CaseDetailsHeaderProps = {
  scan: Scan
  hasWriteAccess: boolean
  hasSharingAccess: boolean
  scanAccessEntries?: AccessEntry[]
  users?: User[]
  dataset?: Dataset
  theme: Theme
  updateScan: (scanId: string, name: string, scanInfo: ScanInfo | undefined, studyDate: string | undefined) => Promise<void>
  updateAccessPermissions: (caseId: string, userId: string, level: PermissionLevel, refresh: boolean) => void
}

export const CaseDetailsHeader = ({
  scan, hasWriteAccess, hasSharingAccess, scanAccessEntries, users, dataset, theme,
  updateScan, updateAccessPermissions
}: CaseDetailsHeaderProps) => {

  return (
    <div style={{display: 'flex', marginBottom: '8px'}}>
      <div style={{fontSize: '2.5rem', fontWeight: 500}}>
        <InlineEdit 
          value={scan.name} 
          placeHolder="Set case name..."
          width={520} 
          disabled={!hasWriteAccess}
          color={theme.sectionHeaderColor}
          setValue={rename => updateScan(scan.scanId, rename, scan.scanInfo, scan.studyDate)} 
          dataCy="rename-case"
        />   
      </div>      
      { hasSharingAccess &&
        <SharingWidget 
          entitiyName={"case"}
          accessEntries={scanAccessEntries} 
          users={users}
          caseDataset={dataset}
          addPermission={(userId: string, level: PermissionLevel, refresh: boolean) => updateAccessPermissions(scan.scanId, userId, level, refresh)}
          theme={theme}
        />
      }        
    </div>
  )
}
