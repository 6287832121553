import { BackendConfig } from 'config/ApplicationConfig'
import { UploadedFileDetails } from 'uploads/component/UploadedFileDetails'
import { UploadRecord } from 'uploads/model/UploadRecord'
import { ExtractedImage } from 'uploads/store/Model'

export type CaseUploadsTableProps = {
  downloadFile: (uploadId: string) => void
  uploads: UploadRecord[]
  images?: ExtractedImage[]
  config: BackendConfig
  isDataset: boolean
}

export const CaseUploadsTable = ({isDataset, uploads, images, config, downloadFile}: CaseUploadsTableProps) => {

  return(
    <div style={{marginTop: '20px'}}>
      <h3>{isDataset ? `DICOM files` : `Uploaded files`}</h3>
      <div className="uploads-list-component" style={{paddingTop:'15px', width: '900px', height: '500px', overflowY: 'auto'}}>
        <table className="table table-bordered table-striped" style={{ width: '860px'}}>
          <thead>
            <tr>
              <th style={{ width: '300px' }} scope="col">File Name</th>
              <th style={{ width: '160px' }} scope="col">Uploaded Date</th>
              <th style={{ width: '160px' }} scope="col">Status</th>          
              <th style={{ width: '70px' }} scope="col">Download</th>
            </tr>
          </thead>
          <tbody>
            { uploads.map(upload => 
              <UploadedFileDetails 
                key={upload.id} 
                upload={upload} 
                images={images?.filter(i => i.uploadId === upload.id)} 
                config={config} 
                downloadFile={downloadFile} 
              />
            )}
          </tbody>
        </table>    
      </div>
    </div>
  )
}

