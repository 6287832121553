import Select from 'react-select'

import { DatasetAnnotationLabel } from 'uploads/model/DatasetAnnotationLabel';
import { SelectOption } from 'utils/SelectOption';

export type AnnotationLabelSelectorProps = {
  currentLabel?: string
  labels: DatasetAnnotationLabel[]
  onClick: (label: string) => void
}

export const AnnotationLabelSelector = ({currentLabel, labels, onClick}: AnnotationLabelSelectorProps) => {

  const options: SelectOption<string>[] = labels.map(l => { return { value: l.label, label: l.displayText } })  
  const current = (currentLabel === undefined || labels.find(l => l.label === currentLabel) === undefined ) ? undefined 
    : { value: currentLabel, label: labels.find(l => l.label === currentLabel)?.displayText || currentLabel }

  return (
    <div style={{ margin: '10px', width: '200px' }}>
      <Select 
        classNamePrefix="react-select-annotation-label-selector"
        options={options} 
        placeholder="Select annotation" 
        value={current} 
        onChange={(e) => { if (e !== null) onClick(e.value) }}        
        styles={{ menuPortal: base => ({ ...base, zIndex: 3 }) }}
      />          
    </div>
  )
}