import retry from 'async-retry'

import { BackendConfig } from 'config/ApplicationConfig'
import { AsyncWebSocket, IncomingWebSocketMessage } from 'utils/AsyncWebSocket'

export class SocketApi  {

  socketUrl: string
  retryCount: number

  constructor(config: BackendConfig) {
    this.retryCount = config.retryCount
    this.socketUrl = `${config.websocket}/eliteai/socket`
  }


  async openSocket(instanceId: string, onmessage: (message: IncomingWebSocketMessage) => void) {  
    return retry(async (bail) => {
      const socket = await AsyncWebSocket({
        url: `wss://medicineserver.ucd.ie/eliteai/socket/${instanceId}`,
        onmessage
      })

      return socket
    }, {
      retries: this.retryCount
    })  
  }


}

