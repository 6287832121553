export const errorToString: (e: any) => string = (e: any) => {
  if (e.response !== undefined && e.response.data !== undefined && e.response.data.message !== undefined) {
    return `${e.toString()} - ${e.response.data.message}`
  } else if (e instanceof Error) {
    return e.toString()
  } else if (typeof e === "string") {
    return e
  } else {  
    return JSON.stringify(e)
  }  
}
