export type DatasetAmsDataTableProps = {
  header: string[]
  rows: any[]
}

export const DatasetAmsDataTable = ({
  header, rows
}: DatasetAmsDataTableProps) => {  

  return (
    <div style={{maxHeight: '800px', width: '1000px', overflowY: 'auto', marginBottom: '10px'}}>      
      <table className="table table-bordered table-striped" >
        <thead>        
          <tr>
            <th style={{ width: '50px' }} />
            {header.map((columnName, index) =>
              <th key={index} title={columnName} style={{ width: '100px', overflow: 'hidden', whiteSpace: 'nowrap' }} scope="col">{columnName}</th>  
            )}
          </tr>
        </thead>
        <tbody>
          {rows.map((row, index) =>           
            <tr key={index}>
              <td >{index + 1}</td>
              { header.map((columnName) =>
                <td data-columnName={columnName} key={columnName} title={row[columnName]} style={{overflow: 'hidden', whiteSpace: 'nowrap'}}>{row[columnName]}</td> 
              )}
            </tr>
          )}
        </tbody>
      </table> 
    </div>
  )
}
